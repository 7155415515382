/* eslint-disable import/no-cycle */
import { forwardRef } from 'react'
import s from 'styled-components'
import cn from 'classnames'
import { Styles } from 'components/form/Input'
import InputMask from 'react-input-mask'

const FormFieldMaskedContainer = s(InputMask)`
    ${(props) => Styles(props)}
    border: unset;

    &:focus {
      color: unset;
      background-color: unset;
      border-color: unset;
    }

    &:hover {
      border-color: unset;
    }
  `

const FormFieldMasked = forwardRef(({
  type = 'text',
  mask = '',
  defaultStyle,
  isError,
  className,
  readOnly,
  isPadded = true,
  size = '',
  minHeight = '',
  noBorders = '',
  bgColor,
  isAutoFocus,
  maskChar = '_',
  isAutoResize,
  onPaste = null,
  ...otherProps
}, ref) => {
  const classes = cn('w-input', {
    '--error': isError,
    '--readonly': readOnly,
  }, className)

  return (
    <FormFieldMaskedContainer
      type={type}
      mask={mask}
      ref={ref}
      alwaysShowMask={false}
      $isError={isError}
      $isPadded={isPadded}
      $size={size}
      $noBorders={noBorders}
      $minHeight={minHeight}
      readOnly={readOnly}
      className={classes}
      maskChar={maskChar}
      onPaste={onPaste}
      defaultStyle={defaultStyle}
      {...otherProps}
    />
  )
})

FormFieldMasked.displayName = 'FormFieldMasked'

export default FormFieldMasked
