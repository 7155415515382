import Card from 'components/card/Card'
import Grid from 'layout/Grid'
import GridItem from 'layout/GridItem'
import Text from 'components/typo/Text'

import WithHover from 'hooks/WithHover'

import Box from 'layout/Box'
import Divider from 'layout/Divider'
import Positioner from 'components/Positioner'
import Button from 'components/base/Button'

const CardBlockWithIcon = ({
  title = '',
  Icon = null,
  isHovered = false,
  children = null,
  isCursor = true,
  renderTitle = () => {},
  p = '5px 0',
  bgColor = undefined,
  action,
  isErrorDivider = null,
  height,
  ...otherProps
}) => (
  <Card
    {...otherProps}
    isRelative
    p={p}
    flexColumn
    bgColor={bgColor}
    height={height}
    minHeight="50px"
    onClick={isCursor ? () => {} : null}
  >
    <Grid
      grid
      gutter="big"
      itemsAlign="center"
      className="mt-a mb-a"
    >
      <GridItem
        noPointers={!action && !renderTitle}
        minHeight="50px"
        minWidth="37%"
        maxWidth="37%"
      >
        <Text
          width="100%"
          color="grey500"
        >
          <Box ai="center">
            {Icon && (
              <Icon
                width={20}
                height={20}
                style={{ marginRight: '6px' }}
                fill="currentColor"
                stroke="currentColor"
              />
            )}
            {title || renderTitle(isHovered)}
            {action ? (
              <Button
                flex="1"
                defaultStyle={action.defaultStyle || 'grey-light'}
                onClick={action.onClick}
                br="100px"
                size="small"
                hoverStop={action.hoverStop}
              >
                <Text size="normalSm">{action.title}</Text>
              </Button>
            ) : null}
          </Box>
        </Text>
      </GridItem>
      <GridItem flex="0">
        <Divider isVertical height="22px" />
      </GridItem>
      <GridItem flex="1">
        {children}
      </GridItem>
    </Grid>
    <Positioner
      bottom="0"
      left="0"
      width="100%"
    >
      <Divider color={isErrorDivider ? 'red' : null} />
    </Positioner>
  </Card>
)

export default WithHover(CardBlockWithIcon)
