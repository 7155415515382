import cn from 'classnames'
import s from 'styled-components'

const COLS_WIDTH = [
  '',
  '100%',
  '50%',
  '33.3333%',
  '25%',
  '20%',
  '16.6666%',
  '14.2857%',
  '12.5%',
  '11.1111%',
  '10%',
  '9.0909%',
  '8.3333%',
]

const styles = (props) => {
  const {
    xl,
    lg,
    md,
    lp,
    sm,
    xs,
    $maxWidth,
    $minWidth,
  } = props

  const widthXL = xl ? COLS_WIDTH[xl] : ''
  const widthLG = lg ? COLS_WIDTH[lg] : ''
  const widthMD = md ? COLS_WIDTH[md] : ''
  const widthLP = lp ? COLS_WIDTH[lp] : ''
  const widthSM = sm ? COLS_WIDTH[sm] : ''
  const widthXS = xs ? COLS_WIDTH[xs] : COLS_WIDTH[1]

  const isFlex = !Object.values(props).some((p) => !!p)

  return `
    align-self: stretch;
    width: ${widthXS};
    max-width: ${$maxWidth};
    min-width: ${$minWidth};
    flex: ${isFlex ? 1 : ''};

    @media (min-width: 481px) {
      width: ${widthSM};
    }

    @media (min-width: 601px) {
      width: ${widthLP};
    }

    @media (min-width: 769px) {
      width: ${widthMD};
    }

    @media (min-width: 1140px) {
      width: ${widthLG};
    }

    @media (min-width: 1440px) {
      width: ${widthXL};
    }
  `
}

const SCol = s.div`
  ${styles}
`

const Col = ({
  className = '',
  children = null,
  maxWidth = '',
  minWidth = '',
  ...otherProps
}) => (
  <SCol
    {...otherProps}
    $maxWidth={maxWidth}
    $minWidth={minWidth}
    className={cn('w-col', className)}
  >
    {children}
  </SCol>
)

export default Col
