import { AnalyticDashboardTypes } from 'redux/dashboard/Analytic/AnalyticDashboard/AnalyticDashboardTypes'

const todayMonth = new Date()
const previousMonth = new Date()
todayMonth.setDate(1)
previousMonth.setDate(1)
previousMonth.setMonth(previousMonth.getMonth() - 1)

const INITIAL_STATE = {
  monthA: previousMonth,
  monthB: todayMonth,
  total: null,
  deltaMonthA: null,
  deltaMonthB: null,
  delta: null,
  deltaPercent: null,
  clinics: null,
  doctors: null,
  patients: null,
  consultationsOffline: null,
  consultationsOnline: null,
  exercises: null,
  users: null,
  files: null,
}

const analyticDashboardReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
  case AnalyticDashboardTypes.SET_MONTH_A:
    return {
      ...state,
      monthA: action.payload
    }
  case AnalyticDashboardTypes.SET_MONTH_B:
    return {
      ...state,
      monthB: action.payload
    }
  case AnalyticDashboardTypes.SET_TOTAL:
    return {
      ...state,
      total: action.payload
    }
  case AnalyticDashboardTypes.SET_DELTA:
    return {
      ...state,
      deltaMonthA: action.payload.first_month_data,
      deltaMonthB: action.payload.second_month_data,
      delta: action.payload.delta_diff_data,
      deltaPercent: action.payload.delta_data,
    }
  case AnalyticDashboardTypes.SET_DOCTORS:
    return {
      ...state,
      doctors: action.payload
    }
  case AnalyticDashboardTypes.SET_PATIENTS:
    return {
      ...state,
      patients: action.payload
    }
  case AnalyticDashboardTypes.SET_CLINICS:
    return {
      ...state,
      clinics: action.payload
    }
  case AnalyticDashboardTypes.SET_CONSULTATIONS_OFFLINE:
    return {
      ...state,
      consultationsOffline: action.payload
    }
  case AnalyticDashboardTypes.SET_CONSULTATIONS_ONLINE:
    return {
      ...state,
      consultationsOnline: action.payload
    }
  case AnalyticDashboardTypes.SET_EXERCISES:
    return {
      ...state,
      exercises: action.payload
    }
  case AnalyticDashboardTypes.SET_USERS:
    return {
      ...state,
      users: action.payload
    }
  case AnalyticDashboardTypes.SET_FILES:
    return {
      ...state,
      files: action.payload
    }

  default:
    return state
  }
}

export default analyticDashboardReducer
