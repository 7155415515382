import { Route, Redirect } from 'react-router-dom'

import { PAGE_LOGIN } from 'constants/PathsTypes'

const PrivateRoute = ({
  component: Component,
  isAuth,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => (isAuth ? (
      <Component {...props} />
    ) : (
      <Redirect
        to={{
          pathname: PAGE_LOGIN,
          state: {
            isNeedAuth: true,
            from: window.location.pathname,
            search: window.location.search,
          },
        }}
      />
    ))}
  />
)

export default PrivateRoute
