import Button from 'components/base/Button'
import Box from 'layout/Box'
import { ReactComponent as IconCheck } from 'assets/icons/check-circle.svg'
import { useMemo } from 'react'
import LinkRoute from 'components/typo/Link'
import { getPatientUrl } from 'redux/helpers/PatientHelpers'
import { isToday, parseISO } from 'date-fns'
import { formatDateOrTodayTime } from 'helpers/Date'
import Text from 'components/typo/Text'
import UserAvatar from 'components/User/UserAvatar'

const NotificationCardRecordCanceled = (props) => {
  const {
    data,
    created,
    handleRecordOpen = () => {},
  } = props

  const patient = useMemo(() => {
    if (data && data.patient && data.patient.name) {
      return data.patient
    }
    return {
      id: 0,
      name: 'ИМЯ',
      bind: { status: false },
    }
  }, [ data ])

  const patientBlock = useMemo(
    () => {
      if (patient.bind.status) {
        return (
          <LinkRoute
            to={getPatientUrl(patient.id)}
            defaultStyle="blue"
          >
            {patient.name}
          </LinkRoute>
        )
      }
      return patient.name
    },
    [ patient ]
  )

  return (
    <Box
      display="flex"
    >
      <Box
        width="50px"
        minWidth="50px"
        height="50px"
        br="100px"
        bg="blue200"
        display="flex"
        ai="center"
        jc="center"
        className="mr-2"
      >
        <Box
          width="32px"
          height="32px"
          br="100px"
          bg="blue"
          display="flex"
          ai="center"
          jc="center"
        >
          {data.patient.avatar
            ? (
              <UserAvatar
                avatar={data.patient.avatar}
                size="medium"
                bgColor="grey150"
                user={data.patient}
              />
            )
            : <IconCheck width="20px" fill="white" />}
        </Box>
      </Box>
      <Box
        direction="column"
      >
        <Text
          size="normal"
          className="mb-1"
          fw="n"
        >
          Пациент
          {' '}
          <Text
            tag="span"
            fw="m"
          >
            {patientBlock}
          </Text>

          {' '}
          отменил заявку в клинику на запись к врачу
        </Text>
        <Text
          color="grey500"
          size="small"
        >
          {isToday(parseISO(created)) ? `Сегодня в ${formatDateOrTodayTime(created, 'HH:mm')}`
            : formatDateOrTodayTime(created, 'd MMMM yyyy в HH:mm')}
        </Text>
      </Box>
      <Box
        minWidth="143px"
        className="ml-2"
      >
        <Button
          flex="1"
          full
          isGradient
          defaultStyle="danger"
          title="Перейти в раздел"
          size="small"
          rounded
          onClick={handleRecordOpen}
        />
      </Box>
    </Box>
  )
}

export default NotificationCardRecordCanceled
