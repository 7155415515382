import Text from 'components/typo/Text'
import styled from 'styled-components'
import Box from 'layout/Box'
import ApiService from 'services/ApiService'
import { useState, useEffect } from 'react'
import { logError } from 'utils/utils'
import { Spinner } from 'components/base'
import { checkCorrectUrl } from 'helpers/System'
import ReactHtmlParser from 'react-html-parser'

const KnowledgeBaseMedicineContainer = styled.div`
  width: 100%;
  .titleImg {
    height: 80px;
    width: 80px;
    border-radius: 10px;
    object-fit: cover;
  }

  .htmlParse {
    p {
      margin-top: 8px;

      &:first-child {
        margin-top: 0;
      }
    }

    h1, h2, h3, h4, h5, h6 {
      margin-top: 16px;

      &:first-child {
        margin-top: 0;
      }
    }

    li {
      margin-left: 16px;
    }
  }
`

const KnowledgeBaseMedicine = ({ id }) => {
  const [ data, setData ] = useState(null)
  const [ isError, setIsError ] = useState(false)

  useEffect(() => {
    ApiService.apiCall({
      url: ApiService.paths.knowledgebase.MEDICINE_BY_ID(id),
      isToken: true
    }).then((response) => {
      setData(response)
    }).catch((error) => {
      setIsError(true)
      logError('Не удалось получить данные из базы знаний', error)
    })
  }, [ id ])

  return (
    <KnowledgeBaseMedicineContainer>
      {data ? (
        <>
          <Text fw="m" fs="20px" className="mb-20" tag="p" size="l" color="blue">
            {data.name}
          </Text>
          <Box className="mb-6" flex="1">
            {data.image && (
              <img
                src={checkCorrectUrl(data.image)}
                className="titleImg mr-6"
                alt=""
              />
            )}
            {data.indication && (
              <div>
                <Text fw="m" fs="18px" className="mb-2" tag="p" size="l">
                  Краткое описание:
                </Text>
                <div className="htmlParse">{ReactHtmlParser(data.indication)}</div>
              </div>
            )}
          </Box>
          {data.features && (
            <div className="mb-6">
              <Text fw="m" fs="18px" className="mb-2" tag="p" size="l">
                Описание:
              </Text>
              <div className="htmlParse">{ReactHtmlParser(data.features)}</div>
            </div>
          )}
          {data.negative && (
            <div className="mb-6">
              <Text fw="m" fs="18px" className="mb-2" tag="p" size="l">
                Побочные действия:
              </Text>
              <div className="htmlParse">{ReactHtmlParser(data.negative)}</div>
            </div>
          )}
          {data.instruction && (
            <div className="mb-6">
              <Text fw="m" fs="18px" className="mb-2" tag="p" size="l">
                Инструкция:
              </Text>
              <div className="htmlParse">{ReactHtmlParser(data.instruction)}</div>
            </div>
          )}
          {data.receipt && (
            <div className="mb-6">
              <Text fw="m" fs="18px" className="mb-2" tag="p" size="l">
                Нужен рецепт:
              </Text>
              <div className="htmlParse">{ReactHtmlParser(data.receipt)}</div>
            </div>
          )}
          {data.storage && (
            <div className="mb-6">
              <Text fw="m" fs="18px" className="mb-2" tag="p" size="l">
                Условия хранения:
              </Text>
              <div className="htmlParse">{ReactHtmlParser(data.storage)}</div>
            </div>
          )}
          {data.package && (
            <div className="mb-6">
              <Text fw="m" fs="18px" className="mb-2" tag="p" size="l">
                Упаковка:
              </Text>
              <div className="htmlParse">{ReactHtmlParser(data.package)}</div>
            </div>
          )}
          {data.manufacturer && (
            <div className="mb-6">
              <Text fw="m" fs="18px" className="mb-2" tag="p" size="l">
                Производитель:
              </Text>
              <div className="htmlParse">{ReactHtmlParser(data.manufacturer)}</div>
            </div>
          )}
          {data.expiration && (
            <div className="mb-6">
              <Text fw="m" fs="18px" className="mb-2" tag="p" size="l">
                Срок годности:
              </Text>
              <div className="htmlParse">{ReactHtmlParser(data.expiration)}</div>
            </div>
          )}
        </>
      ) : isError ? <div>Ошибка, страница не доступна</div> : <Spinner />}

    </KnowledgeBaseMedicineContainer>
  )
}

export default KnowledgeBaseMedicine
