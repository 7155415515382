import { ClinicActionTypes } from 'redux/dashboard/Clinic/ClinicTypes'

const INITIAL_STATE = {
  clinic: null,
  isFetching: false,
  documents: null,

  error: null,
}

const clinicReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
  case ClinicActionTypes.FETCH_CLINIC_SUCCESS:
    return {
      ...state,
      clinic: action.payload
    }
  case ClinicActionTypes.SET_FETCHING_CLINIC:
    return {
      ...state,
      isFetching: action.payload
    }
  case ClinicActionTypes.FETCH_CLINIC_ERROR:
    return {
      ...state,
      error: action.payload
    }
  case ClinicActionTypes.FETCH_CLINIC_DOCUMENT:
    return {
      ...state,
      documents: action.payload
    }

  default:
    return state
  }
}

export default clinicReducer
