import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { ReactComponent as IconSearch } from 'assets/icons/search-s.svg'

import FormField from './FormField'

const FormFieldSearch = forwardRef(({
  placeholder = 'Найти',
  size = 'small',
  noIcon = false,
  Icon,
  IconProps,
  ...props
}, ref) => (
  <FormField
    {...props}
    icon={Icon || (noIcon ? null : IconSearch)}
    noIcon={noIcon}
    IconProps={IconProps}
    iconBefore
    type="search"
    iconSize="16"
    size={size}
    placeholder={placeholder}
    ref={ref}
  />
))

FormFieldSearch.propTypes = ({ size: PropTypes.oneOfType([ PropTypes.string, PropTypes.bool ]) })

FormFieldSearch.displayName = 'FormFieldSearch'
export default FormFieldSearch
