import cn from 'classnames'
import s from 'styled-components'

const getStyles = ({ theme }) => `
  position: relative;
  width: 40px;
  height: 24px;
  padding: 3px;
  background-color: ${theme.colors.grey400};
  border-radius: 40px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  flex-shrink: 0;

  &.--hovered,
  &:hover {
    background-color: ${theme.colors.grey500};
  }

  &.--disabled {
    background-color: ${theme.colors.grey100};
    pointer-events: none;
  }

  &.--on {
    background-color: ${theme.colors.blue};

    &:hover {
      background-color: ${theme.colors.blue80};
    }

    &.--disabled {
      background-color: ${theme.colors.blue20};
    }
  }

  &.--on .w-switcher-toggle {
    left: 100%;
    transform: translate(-100%, -50%);
    // box-shadow: 0 3px 8px 0 ${theme.colors.transparent};
  }

  .w-switcher {
    &-inner {
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }

    &-toggle {
      position: absolute;
      top: 50%;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 18px;
      height: 18px;
      background-color: #fff;
      box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06);
      border-radius: 18px;
      transform: translateY(-50%);
      transition: transform 0.3s ease-in-out, left 0.3s ease-in-out;
    }
  }

  .fake-input {
    position: absolute;
    left: -9999px;
    visibility: hidden;
  }
`

const SwitcherContainer = s.div`
  ${(props) => getStyles(props)}
`

const Switcher = ({
  field: { name, value },
  form: { handleChange },
  onLabel = '',
  offLabel = '',
  className = '',
  isDisabled = false,
  readOnly = false,
  isHovered = false,
}) => {
  const handleClick = (e) => {
    e.stopPropagation()

    if (isDisabled) {
      e.preventDefault()
    }
    else {
      handleChange(name, !value)
    }
  }

  return (
    <SwitcherContainer
      type="button"
      className={cn('w-switcher', {
        '--on': value,
        '--disabled': isDisabled,
        '--hovered': isHovered,
      }, className)}
      onClick={handleClick}
    >
      <div className="w-switcher-inner">
        <input
          checked={value}
          value={value}
          name={name}
          type="checkbox"
          className="fake-input"
          disabled={isDisabled}
          readOnly={readOnly || isDisabled}
          onChange={handleChange}
        />
        <div className="w-switcher-toggle">
          {onLabel || offLabel ? (
            <span className="w-switcher-label">
              {value ? onLabel : offLabel}
            </span>
          ) : (
            ''
          )}
        </div>
      </div>
    </SwitcherContainer>
  )
}

export default Switcher
