import { useEffect, useState } from 'react'
import { DEFAULT_BOUNDS_OFFSET, DRAGGABLE_CONTAINER_SIZES } from './Call/call.config'

const Draggable = ({ children, initPos }) => {
  const [ dragging, setDragging ] = useState(false)
  const [ rel, setRel ] = useState(null)
  const [ pos, setPos ] = useState(initPos || {
    x: 0,
    y: 0
  })

  const onMouseUp = (e) => {
    if (dragging) {
      const pos = e.target.getBoundingClientRect()
      setDragging(false)

      let xCalc = pos.x
      let yCalc = pos.y

      if (pos.left < 0) {
        xCalc = DEFAULT_BOUNDS_OFFSET.xLeft
      }
      else if (pos.left > window.innerWidth - DRAGGABLE_CONTAINER_SIZES.width) {
        xCalc = DEFAULT_BOUNDS_OFFSET.xRight
      }

      if (pos.top < 0) {
        yCalc = DEFAULT_BOUNDS_OFFSET.yTop
      }
      else if (pos.top > window.innerHeight - DRAGGABLE_CONTAINER_SIZES.height) {
        yCalc = DEFAULT_BOUNDS_OFFSET.yBottom
      }

      if (xCalc !== pos.x || xCalc !== pos.y) {
        setPos({
          x: xCalc,
          y: yCalc,
        })
      }

      e.stopPropagation()
      e.preventDefault()
    }
  }

  const onMouseMove = (e) => {
    if (!dragging) return

    setPos({
      x: e.pageX - rel.x,
      y: e.pageY - rel.y
    })

    e.stopPropagation()
    e.preventDefault()
  }

  // we could get away with not having this (and just having the listeners on
  // our div), but then the experience would be possibly be janky. If there's
  // anything w/ a higher z-index that gets in the way, then you're toast,
  // etc.
  useEffect(() => {
    if (dragging) {
      document.addEventListener('mousemove', onMouseMove)
      document.addEventListener('mouseup', onMouseUp)
    }
    else {
      document.removeEventListener('mouseup', onMouseUp)
      document.removeEventListener('mousemove', onMouseMove)
    }

    return () => {
      if (dragging) {
        document.removeEventListener('mousemove', onMouseMove)
        document.removeEventListener('mouseup', onMouseUp)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ dragging ])

  // calculate relative position to the mouse and set dragging=true
  const onMouseDown = (e) => {
    // only left mouse button
    if (e.button !== 0 || (e.target.tagName === 'BUTTON')) return

    const pos = e.currentTarget.getBoundingClientRect()
    setDragging(true)

    setRel({
      x: e.pageX - pos.left,
      y: e.pageY - pos.top
    })

    e.stopPropagation()
    e.preventDefault()
  }

  // transferPropsTo will merge style & other props passed into our
  // component to also be on the child DIV.
  return children({
    onMouseDown,
    style: {
      position: 'absolute',
      left: `${pos.x}px`,
      top: `${pos.y}px`
    },
    pos,
  })
}

export default Draggable
