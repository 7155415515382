import s from 'styled-components'
import cn from 'classnames'

const getStyles = ({ theme, $bc }) => `
  width: 100%;
  border-radius: 4px;

  * + & {
    border-top: none !important;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  &.--bordered {
    border: 1px solid ${$bc ? theme.colors[$bc] : theme.colors.grey100};

    &.--outline-off {
      border: unset;
    }

    & > * {
      border-bottom: 1px solid ${$bc ? theme.colors[$bc] : theme.colors.grey150};
    }
  }

  &.--last-b-off {
    & > *:last-child {
      border-bottom: unset;
    }
  }
`

const ListContainer = s.div`
  ${getStyles}
`

const List = ({
  isBordered = true,
  bc = '',
  isOutlineOff = true,
  lastBorderOff = false,
  children = null
}) => (
  <ListContainer
    $bc={bc}
    className={cn('b-list', {
      '--bordered': isBordered,
      '--outline-off': isOutlineOff,
      '--last-b-off': lastBorderOff
    })}
  >
    {children}
  </ListContainer>
)

export default List
