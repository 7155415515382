/* eslint-disable no-promise-executor-return */
import { getUserUri } from 'redux/dashboard/Chat/ChatHelpers'
import MessengerService from 'services/Voximplant/messenger.service'
import VoxService from 'services/Voximplant/vox.service'
import { fetchGetChatSupport } from 'redux/dashboard/Chat/ChatActions'
import {
  chatSidebarInit,
  addChatUsers,
  messengerServiceInit,
  updateChatConversations,
  updateChatCurrentUsers,
  updateChatUsers,
  updateConversationsLastMessage,
} from 'redux/dashboard/Messanger/MesengerConversationsActions'
import { UserRolesTypes } from 'constants/UserTypes'

import { logError, logHelp } from 'utils/utils'

export async function getInitialVox() {
  return new Promise((resolve, reject) => {
    VoxService
      .get()
      .init()
      .then(() => {
        resolve(true)
      })
      .catch((error) => {
        logError('Getting initial Vox is failed', error)
        reject(error)
      })
  })
}

export function getInitialData(dispatch, role) {
  return MessengerService
    .get()
    .init()
    .then((data) => {
      // Get the current user data
      dispatch(updateChatCurrentUsers(data.currentUser))

      if (role === UserRolesTypes.SUPPORT) {
        dispatch(updateChatUsers(data.users))
      }
      else {
        dispatch(fetchGetChatSupport())
          .then(({ results }) => {
            const supportUser = results[0]
            const userSupportUri = getUserUri(supportUser.voximplant_username)

            if (data.users.find((u) => u.userName === userSupportUri)) {
              dispatch(updateChatUsers(data.users))
            }
            else {
              MessengerService
                .get()
                .getUserByName(supportUser.voximplant_username)
                .then(({ user }) => {
                  dispatch(updateChatUsers([{
                    userName: userSupportUri,
                    userInfo: supportUser,
                    userId: user.userId,
                  }, ...data.users ]))
                })
                .catch(() => {
                  dispatch(updateChatUsers([{
                    userName: userSupportUri,
                    userInfo: supportUser,
                  }, ...data.users ]))
                })
            }
          })
          .catch(() => {
            dispatch(updateChatUsers(data.users))
          })
      }

      dispatch(updateChatConversations(data.conversations))
      dispatch(messengerServiceInit(true))
      dispatch(chatSidebarInit())
      dispatch(updateConversationsLastMessage(data.conversations))
    })
    .catch((error) => {
      logError('Getting initial data is failed', error)
    })
}

// export const addUser = ({ user }) => (dispatch) => {
//   // if (!state.users.some((u) => u.userId === user.userId)) {
//   dispatch(addChatUsers([ user ]))
// }

export const editUser = ({ image = '', status = '' }) => (dispatch) => {
  MessengerService.get().editUserCustomData({
    image,
    status
  })
    .then((evt) => {
      dispatch('updateCurrentUser', evt.user)
    })
    .catch(logError)
}
