const BOUNDS_OFFSET = 12

export const DRAGGABLE_CONTAINER_SIZES = {
  width: 280,
  height: 200,
}

export const DEFAULT_BOUNDS_OFFSET = {
  xLeft: BOUNDS_OFFSET,
  xRight: window.innerWidth - DRAGGABLE_CONTAINER_SIZES.width - BOUNDS_OFFSET,
  yTop: BOUNDS_OFFSET,
  yBottom: window.innerHeight - DRAGGABLE_CONTAINER_SIZES.height - BOUNDS_OFFSET,
}

export const INIT_CONTAINER_POSITION = {
  x: DEFAULT_BOUNDS_OFFSET.xLeft,
  y: DEFAULT_BOUNDS_OFFSET.yBottom,
}
