import { SystemActionTypes } from 'redux/System/SystemTypes'

const INITIAL_STATE = {
  preloader: false,
  modal: null,
  init: false,
  scrollWidth: 0,
  error: false,
  isTouchDevice: false,
  isSidebarShow: true,
  isContentSidebarShow: true,
  models: { },
}

const systemReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
  case SystemActionTypes.SET_SHOW_PRELOADER:
    return {
      ...state,
      preloader: action.payload,
    }

  case SystemActionTypes.INIT_STATE_SUCCESS:
    return {
      ...state,
      init: true,
    }

  case SystemActionTypes.INIT_STATE_ERROR:
    return {
      ...state,
      error: action.payload,
    }

  case SystemActionTypes.SET_MODAL:
    if (action.payload && action.payload.close) {
      return {
        ...state,
        modal: action.payload === null ? {
          ...state.modal,
          ...action.payload,
          data: null
        } : {
          ...state.modal,
          ...action.payload,
        },
      }
    }

    return {
      ...state,
      modal: action.payload
        ? {
          active: true,
          prev: action.payload.prev
            ? action.payload.prev
            : state.modal
              ? state.modal.name
              : null,
          ...action.payload,
        }
        : null,
    }

  case SystemActionTypes.SET_BROWSER_WIDTH:
    return {
      ...state,
      scrollWidth: action.payload,
    }

  case SystemActionTypes.SET_IS_TOUCH_DEVICE:
    return {
      ...state,
      isTouchDevice: true,
    }

  case SystemActionTypes.SET_SIDEBAR_SHOW:
    return {
      ...state,
      isSidebarShow: action.payload,
    }

  case SystemActionTypes.SET_CONTENT_SIDEBAR_SHOW:
    return {
      ...state,
      isContentSidebarShow: action.payload,
    }

  case SystemActionTypes.FETCH_APP_MODELS_SUCCESS:
    return {
      ...state,
      models: action.payload,
    }

  default:
    return state
  }
}

export default systemReducer
