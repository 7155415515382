export const NotificationsActionsTypes = {
  SET_FETCHING_NOTIFICATIONS: 'SET_FETCHING_NOTIFICATIONS',
  FETCH_NOTIFICATIONS_SUCCESS: 'FETCH_NOTIFICATIONS_SUCCESS',
  FETCH_NOTIFICATIONS_ERROR: 'FETCH_NOTIFICATIONS_ERROR',

  SET_FETCHING_NOTIFICATION: 'SET_FETCHING_NOTIFICATION',
  FETCH_NOTIFICATION_SUCCESS: 'FETCH_NOTIFICATION_SUCCESS',
  FETCH_NOTIFICATION_ERROR: 'FETCH_NOTIFICATION_ERROR',

  SET_FETCHING_READ_NOTIFICATION: 'SET_FETCHING_READ_NOTIFICATION',
  FETCH_READ_NOTIFICATION_SUCCESS: 'FETCH_READ_NOTIFICATION_SUCCESS',
  FETCH_READ_NOTIFICATION_ERROR: 'FETCH_READ_NOTIFICATION_ERROR',

  SET_FETCHING_READ_ALL_NOTIFICATION: 'SET_FETCHING_READ_ALL_NOTIFICATION',
  FETCH_READ_ALL_NOTIFICATION_SUCCESS: 'FETCH_READ_ALL_NOTIFICATION_SUCCESS',
  FETCH_READ_ALL_NOTIFICATION_ERROR: 'FETCH_READ_ALL_NOTIFICATION_ERROR',

  FETCH_ADD_NOTIFICATION: 'FETCH_ADD_NOTIFICATION',
  SET_NEW_NOTIFICATIONS_COUNTS: 'SET_NEW_NOTIFICATIONS_COUNTS',
  ADD_NEW_NOTIFICATION: 'ADD_NEW_NOTIFICATION',
  REMOVE_NEW_NOTIFICATIONS: 'REMOVE_NEW_NOTIFICATIONS',
  SET_NOTIFICATIONS_COUNT: 'SET_NOTIFICATIONS_COUNT',
  SET_NOTIFICATIONS_SETTINGS: 'SET_NOTIFICATIONS_SETTINGS',
  ADD_NEW_NOTIFICATION_COUNT: 'ADD_NEW_NOTIFICATION_COUNT',
  CLEAR_NOTIFICATIONS: 'CLEAR_NOTIFICATIONS',
}
