import {
  useEffect, useRef, useState
} from 'react'

const useDimensionsVideo = () => {
  const ref = useRef(null)
  const [ dimensions, setDimensions ] = useState({
    width: 0,
    height: 0,
  })

  const updateDimensions = () => {
    if (ref.current) {
      const parentWidth = ref.current.clientWidth
      const parentHeight = (parentWidth / 7) * 4 // Пропорция 4:7
      setDimensions({
        width: parentWidth,
        height: parentHeight,
      })
    }
  }

  useEffect(() => {
    updateDimensions()

    window.addEventListener('resize', updateDimensions)

    const observer = new MutationObserver(updateDimensions)
    if (ref.current) {
      observer.observe(ref.current, {
        attributes: true,
        childList: true,
        subtree: true,
      })
    }

    return () => {
      window.removeEventListener('resize', updateDimensions)
      observer.disconnect()
    }
  }, [])

  return [ ref, dimensions ]
}

export default useDimensionsVideo
