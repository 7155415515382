import { ButtonRound } from 'components/base'
import { ReactComponent as IconIn } from 'assets/icons/scale-in.svg'
import { ReactComponent as IconOut } from 'assets/icons/scale-out.svg'

const ButtonFullScreen = ({
  onClick,
  isHovered,
  defaultStyle = 'light',
  isOut = false,
  ...otherProps
}) => (
  <ButtonRound
    {...otherProps}
    defaultStyle={defaultStyle}
    onClick={onClick}
    renderIcon={() => (isOut
      ? <IconIn />
      : <IconOut />)}
    br="round"
  />
)

export default ButtonFullScreen
