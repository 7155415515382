import Button from 'components/base/Button'
import Card from 'components/card/Card'
import LinkRoute from 'components/typo/Link'
import Text from 'components/typo/Text'
import UserAvatar from 'components/User/UserAvatar'
import { UserRolesTypes } from 'constants/UserTypes'
import { getUserFullName, getUserGenderAndAgeTitle } from 'helpers/User'
import Box from 'layout/Box'
import { useMemo } from 'react'
import { getPatientUrl } from 'redux/helpers/PatientHelpers'

const NotificationCardBindResult = ({
  onChat,
  onPatient,
  created,
  isAdmin,
  data: {
    patient = {},
    consultant,
    bind = {},
  },
}) => {
  const patientBlock = useMemo(() => {
    const patientName = getUserFullName(patient, true)

    return bind.is_accepted ? (
      <LinkRoute
        to={getPatientUrl(patient.id)}
        defaultStyle="blue"
      >
        {patientName}
      </LinkRoute>
    ) : patientName
  }, [ patient, bind ])

  const content = useMemo(() => {
    if (bind.is_accepted && bind.initiator === UserRolesTypes.CONSULTANT && !isAdmin) {
      return (
        <div>
          <Box className="mb-2" ai="center" gap="8px">
            <UserAvatar
              size="medium"
              avatar={consultant.avatar}
              bgColor="grey150"
              user={consultant}
            />
            <div>
              <Text>
                Администратор
                {' '}
                {getUserFullName(consultant)}
                {' '}
                прикрепил(-a) к вам пациента:
                {' '}
                {patientBlock}
              </Text>
              <Text className="mt-1" size="small" color="grey500">
                {created}
              </Text>
            </div>
          </Box>
          <Card
            isBordered
            brColor="grey150"
            bgColor="white"
          >
            <Box display="block" p="16px">
              <Box gap="24px" ai="center">
                <Box gap="8px">
                  <UserAvatar
                    size="normalSmall"
                    avatar={patient.avatar}
                    bgColor="grey150"
                    user={patient}
                    className="mt-1"
                  />
                  <div>
                    <Text
                      size="normalSm"
                      className="mb-1"
                      fw="m"
                    >
                      {getUserFullName(patient, true)}
                    </Text>
                    <Text color="grey500" size="xs">{getUserGenderAndAgeTitle(patient.gender, patient.date_birth)}</Text>
                  </div>
                </Box>
                <Box gap="10px" className="ml-a">
                  <Button
                    onClick={() => onPatient(patient.id)}
                    title="Пациент"
                    size="small"
                    rounded
                  />

                  <Button
                    isGradient
                    onClick={() => onChat(patient.voximplant_username)}
                    title="В чат"
                    size="small"
                    rounded
                  />
                </Box>
              </Box>
            </Box>
          </Card>
        </div>
      )
    }

    return (
      <Box gap="24px">
        <Box gap="8px">
          <UserAvatar
            size="normal"
            avatar={patient.avatar}
            bgColor="grey150"
            user={patient}
          />
          <div>
            <Text>
              Пациент
              {' '}
              {patientBlock}
              {' '}
              {bind.is_accepted
                ? 'принял приглашение'
                : 'отказался принять приглашение о прикреплении'}
            </Text>
            <Text className="mt-1" size="small" color="grey500">
              {created}
            </Text>
          </div>
        </Box>
        {bind.is_accepted && (
          <Button
            onClick={() => onChat(patient.voximplant_username)}
            isGradient
            title="Перейти в чат"
            size="small"
            rounded
            className="ml-a"
          />
        )}
      </Box>
    )
  }, [ bind, isAdmin, created, onChat, onPatient, patient, consultant, patientBlock ])

  return content
}

export default NotificationCardBindResult
