import { NewsActionTypes } from 'redux/dashboard/News/NewsTypes'

const INITIAL_STATE = {
  news: null,
  offset: 0,
  maxOffset: 0,
  listCount: 0,

  isFetching: false,
  unreadCount: null,
}

const newsReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
  case NewsActionTypes.SET_NEWS:
    return {
      ...state,
      news: action.payload.results,
      list: action.payload.results,
      maxOffset: action.payload.count,
      offset: 40,
    }

  case NewsActionTypes.ADD_NEWS:
    return {
      ...state,
      news: state.news.concat(action.payload.results),
      offset: state.offset + 40,
    }

  case NewsActionTypes.SET_NEWS_LIST_COUNT:
    return {
      ...state,
      listCount: action.payload
    }

  case NewsActionTypes.SET_FETCHING_NEWS:
    return {
      ...state,
      isFetching: action.payload,
    }
  case NewsActionTypes.SET_NEWS_NEW_COUNT:
    return {
      ...state,
      unreadCount: action.payload
    }
  case NewsActionTypes.FETCH_UNREAD_NEW:
    return {
      ...state,
      unreadCount: action.payload
    }
  case NewsActionTypes.FETCH_READ_NEW_SUCCESS:
    return {
      ...state,
      unreadCount: state.unreadCount > 0 ? state.unreadCount - 1 : 0,
      news: [ ...state.news.map((n) => (n.id === action.payload
        ? {
          ...n,
          is_read: true,
        } : n))
      ],
    }
  default:
    return state
  }
}

export default newsReducer
