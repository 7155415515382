import {
  useCallback,
  useState,
  useEffect,
  useMemo
} from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Tabs from 'components/tabs/Tabs'
import ModalSidebarLayout from 'components/modals/ModalSidebarLayout'
import ModalContent from 'components/modal/ModalContent'
import { clearNotifications } from 'redux/Notifications/NotificationsActions'
import { numeralizeNewNotifications } from 'helpers/Numeralize'
import NotificationsContent from './NotificationsContent'
import NotificationsSettings from './NotificationsSettings'

const NotificationsTabsTypes = {
  NOTIFICATIONS: 'NOTIFICATIONS',
  SETTINGS: 'SETTINGS',
}

const NotificationsTabsModel = [
  {
    id: NotificationsTabsTypes.NOTIFICATIONS,
    title: 'Уведомления',
  },
  {
    id: NotificationsTabsTypes.SETTINGS,
    title: 'Настройки',
  },
]

const ModalNotifications = ({ closeModal = () => {} }) => {
  const dispatch = useDispatch()
  const [ activeTab, setActiveTab ] = useState(NotificationsTabsTypes.NOTIFICATIONS)
  const {
    list: notifications = null,
    settings = {},
    countNew,
    // isFetchingReadAll = false
  } = useSelector((state) => state.notifications)

  useEffect(() => () => {
    dispatch(clearNotifications())
  }, [ dispatch ])

  const handleToggleTabs = useCallback((value) => {
    setActiveTab(value)
  }, [ setActiveTab ])

  const getNotificationsTabs = useCallback(() => (
    <Tabs
      active={activeTab}
      items={NotificationsTabsModel}
      onToggle={handleToggleTabs}
      isTags
    />
  ), [ activeTab, handleToggleTabs ])

  const countTitle = useMemo(() => (countNew === 0
    ? 'Уведомления'
    : `${countNew} ${numeralizeNewNotifications(countNew)}`), [ countNew ])

  return (
    <ModalSidebarLayout
      modalTitle={countTitle}
      modalSize="mediumBig"
      onClose={closeModal}
      noPointers
      sidebarOffset
      headRight={getNotificationsTabs()}
      overflow={NotificationsTabsTypes.NOTIFICATIONS ? 'hidden' : undefined}
    >
      <ModalContent
        p={activeTab === NotificationsTabsTypes.NOTIFICATIONS ? '0' : undefined}
        overflowAuto
      >
        {activeTab === NotificationsTabsTypes.NOTIFICATIONS && (
          <NotificationsContent
            closeModal={closeModal}
            notifications={notifications}
          />
        )}
        {activeTab === NotificationsTabsTypes.SETTINGS && <NotificationsSettings settings={settings} dispatch={dispatch} />}
      </ModalContent>
    </ModalSidebarLayout>
  )
}

export default ModalNotifications
