import s from 'styled-components'
import { ReactComponent as IconChecked } from 'assets/icons/check.svg'
import cn from 'classnames'

const getStyles = ({
  theme,
  $checked,
  $isDisable,
  $isError,
  $isСircle,
  $isRadio,
}) => `
  min-width: 18px;
  width: 18px;
  height: 18px;
  border: 2px solid ${theme.colors.grey300};
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${$isDisable ? `
    filter: opacity(50%);
  ` : ''}

  ${$checked && !$isRadio ? `
    background: ${theme.colors.gradient1000};
    border: 0;

    svg {
      fill: ${theme.colors.white};
    };
  ` : `
  `}

  ${$isError && !$checked && !$isDisable ? `
    border: 2px solid ${theme.colors.red};
  ` : ``};

  }

  ${$isСircle ? `
    border-radius: 50%;
    width: 22px;
    height: 22px;
  ` : ``};
  }

  ${$isRadio ? `
    border-radius: 50%;

    svg {
      display: none;
    }

    ${$checked ? `
      border: 0;
      background: ${theme.colors.gradient1000};

      &:before  {
        content: '';
        border: 2px solid #fff;
        border-radius: 50%;
        height: calc(100% - 4px);
        width: calc(100% - 4px);
      }
    ` : ``}
  ` : ``};
  }

  input {
    display: none;
  }

  svg {
    width: 100%;
    height: 100%;
  }
`

const CheckboxStyle = s.label`
  ${(props) => getStyles(props)}
`

const Checkbox = ({
  isChecked = false,
  value = isChecked,
  name,
  isСircle = false,
  isError = false,
  onChange = () => {},
  isDisable = false,
  className = '',
  isRadio = false,
  ...otherProps
}) => (
  <CheckboxStyle
    className={cn(className)}
    $checked={isChecked}
    $isDisable={isDisable}
    $isError={isError}
    $isСircle={isСircle}
    $isRadio={isRadio}
  >
    <input
      name={name}
      type={isRadio ? 'radio' : 'checkbox'}
      checked={isChecked}
      onChange={(e) => onChange(e)}
      value={value}
      disabled={isDisable}
      {...otherProps}
    />
    <IconChecked />
  </CheckboxStyle>
)

export default Checkbox
