export const NewsActionTypes = {
  SET_NEWS: 'SET_NEWS',
  ADD_NEWS: 'ADD_NEWS',
  SET_NEWS_LIST_COUNT: 'SET_NEWS_LIST_COUNT',
  SET_FETCHING_NEWS: 'SET_FETCHING_NEWS',
  SET_NEWS_NEW_COUNT: 'SET_NEWS_NEW_COUNT',
  FETCH_UNREAD_NEW: 'FETCH_UNREAD_NEW',
  SET_FETCHING_READ_NEW: 'SET_FETCHING_READ_NEW',
  FETCH_READ_NEW_SUCCESS: 'FETCH_READ_NEW_SUCCESS'
}
