import s from 'styled-components'
import cn from 'classnames'
import Spinner from 'react-spinner-material'
import { theme, colors } from 'theme'

const SpinnerBaseContainer = s.div`
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1 !important;
  height: ${({ $height }) => $height};
  background-color: ${({ $bgColor, theme }) => ($bgColor ? theme.colors[$bgColor] || $bgColor : '')};
  z-index: ${({ $zIndex }) => $zIndex};
  &:not(.--relative) {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &.--relative {
    width: ${({ $width }) => $width || '100%'};
  }

  &.--fit {
    width: ${({ $width }) => $width || '100%'};
    height: 100%;
  }
`

const SpinnerBase = ({
  relative = false,
  height = 'initial',
  visible = true,
  className = '',
  fit = false,
  size = 'normal',
  fillColor = '',
  bgColor = '',
  width = '',
  zIndex = ''
}) => {
  const { sizes, color } = theme.components.spinner
  const currentSizes = sizes[size] || sizes.normal

  return (
    <SpinnerBaseContainer
      className={cn(
        'ws-spinner',
        {
          '--relative': relative,
          '--fit': fit,
        },
        className,
      )}
      $width={width}
      $height={height}
      $bgColor={bgColor}
      $zIndex={zIndex}
    >
      <Spinner
        radius={currentSizes.radius}
        color={colors[fillColor] || color}
        stroke={currentSizes.stroke}
        visible={visible}
      />
    </SpinnerBaseContainer>
  )
}

export default SpinnerBase
