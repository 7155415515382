import { useMemo } from 'react'
import SelectBase from 'react-select'
import SelectCreatable from 'react-select/creatable'
import s from 'styled-components'
import { getDefaultSelectStyles } from './select.styles.js'

// import bgShapeImage from 'assets/images/decor/bg-white-01.png'

const SelectBaseContainer = s(SelectBase)`
  ${(props) => getDefaultSelectStyles(props)}
`

const SelectCreatableContainer = s(SelectCreatable)`
  ${(props) => getDefaultSelectStyles(props)}
`

const Select = ({
  labelName = 'label',
  valueName = 'value',
  classNames = 'select-group',
  noBorder = false,
  noPadded = false,
  isLabel = '',
  minWidth = '',
  maxWidth = '',
  isWhite = false,
  isCreatable = false,
  defaultStyle = '',
  color,
  hundleScroll = null,
  ...otherProps
}) => {
  const SelectContainer = useMemo(() => (isCreatable
    ? SelectCreatableContainer
    : SelectBaseContainer), [ isCreatable ])

  return (
    <SelectContainer
      {...otherProps}
      onMenuScrollToBottom={hundleScroll}
      $color={color}
      $defaultStyle={defaultStyle}
      $noBorder={noBorder}
      $noPadded={noPadded}
      getOptionLabel={(option) => option[labelName]}
      getOptionValue={(option) => option[valueName]}
      classNamePrefix={classNames}
      menuPlacement="auto"
      $isLabel={isLabel}
      $minWidth={minWidth}
      $maxWidth={maxWidth}
      $isWhite={isWhite}
      captureMenuScroll
    // FOR DEBUG
    // menuIsOpen
    />
  )
}

export default Select
