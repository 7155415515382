import {
  addStructureFolder,
  addStructureItem,
  deleteStructureFolder,
  deleteStructureItem,
  moveStructureItem,
  updateStructureFolder,
  updateStructureItem,
} from 'redux/Structure/StructureHelpers'
import { AuthActionTypes } from 'redux/Auth/AuthTypes'
import {
  PatientActionTypes, PatientBindsActionTypes, PatientStructureActionTypes
} from 'redux/dashboard/Patient/PatientTypes'
import { TaskStatusesTypes } from 'constants/TasksTypes'
import { UserStructureFilesTypes } from 'constants/UserTypes'
import { compareDatesDesc } from 'helpers/Date'

const STRUCTURE_INIT_STATE = Object.values(UserStructureFilesTypes)
  .reduce(((acc, structureName) => {
    acc[structureName] = null
    return acc
  }), {})

const INITIAL_STATE = {
  specializationsClinic: null,

  patient: null,
  isFetchingPatient: false,

  patientDiary: null,
  isFetchingPatientDiary: false,

  patientDiaryStat: null,
  isFetchingPatientDiaryStat: false,

  patientTasks: null,
  isFetchingPatientTasksExpiredCount: 0,
  patientTasksExpiredCount: 0,
  isFetchingPatientTasks: false,

  patientInsurances: null,
  isFetchingPatientInsurances: false,

  ...STRUCTURE_INIT_STATE,
  isFetchingStructure: false,

  consultations: null,
  isFetchingConsultations: false,

  articles: null,
  isFetchingArticles: false,

  error: null,
}

const patientBaseReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
  case AuthActionTypes.FETCH_LOGOUT_SUCCESS:
  case PatientActionTypes.CLEAR_PATIENT_STATE:
    return { ...INITIAL_STATE }

  case PatientActionTypes.FETCH_PATIENT_SUCCESS:
  case PatientActionTypes.SET_PATIENT:
    return {
      ...state,
      patient: action.payload
    }

  case PatientActionTypes.FETCH_SPECIALIZATIONS_CLINIC:
    return {
      ...state,
      specializationsClinic: action.payload
    }

  case PatientActionTypes.SET_FETCHING_PATIENT_DIARY:
    return {
      ...state,
      isFetchingPatientDiary: action.payload
    }

  case PatientActionTypes.FETCH_PATIENT_DIARY_SUCCESS:
    return {
      ...state,
      patientDiary: action.payload
    }

  case PatientActionTypes.SET_FETCHING_GET_PATIENT_DIARY_RECORDS_STAT:
    return {
      ...state,
      isFetchingPatientDiaryStat: action.payload
    }

  case PatientActionTypes.FETCH_GET_PATIENT_DIARY_RECORDS_STAT_SUCCESS:
    return {
      ...state,
      patientDiaryStat: action.payload
    }

  case PatientActionTypes.SET_FETCHING_PATIENT_COURSES_TASKS:
    return {
      ...state,
      isFetchingPatientTasks: action.payload
    }

  case PatientActionTypes.FETCH_PATIENT_COURSES_TASKS_SUCCESS:
    return {
      ...state,
      patientTasks: action.payload
    }

  case PatientActionTypes.ADD_PATIENT_TASK: {
    const created = new Date()
    const isExpired = compareDatesDesc(action.payload.scheduled_at, created) > 0

    const newTask = {
      ...action.payload,
      status: isExpired ? TaskStatusesTypes.EXPIRED : TaskStatusesTypes.WAITING,
      created_at: created.toISOString()
    }

    return {
      ...state,
      patientTasks: state.patientTasks
        ? [ ...state.patientTasks, newTask ]
        : [ newTask ],
    }
  }

  case PatientActionTypes.ADD_PATIENT_TASKS: {
    if (Array.isArray(action.payload)) {
      const mappedNewTasks = []

      action.payload.forEach((task) => {
        const created = new Date()
        const isExpired = compareDatesDesc(task.scheduled_at, created) > 0

        const newTask = {
          ...task,
          status: isExpired ? TaskStatusesTypes.EXPIRED : TaskStatusesTypes.WAITING,
          created_at: created.toISOString()
        }

        mappedNewTasks.push(newTask)
      })

      return {
        ...state,
        patientTasks: state.patientTasks
          ? [ ...state.patientTasks, ...mappedNewTasks ]
          : mappedNewTasks,
      }
    }

    return state
  }

  case PatientActionTypes.UPDATE_PATIENT_TASK: {
    if (state.patientTasks) {
      const { response, taskType } = action.payload

      return {
        ...state,
        patientTasks: [ ...state.patientTasks.map((pt) => (pt.id === response.id && pt.hasOwnProperty(taskType) ? {
          ...pt,
          ...response,
        } : pt)) ]
      }
    }

    return state
  }

  case PatientActionTypes.DELETE_PATIENT_TASK: {
    if (state.patientTasks) {
      const {
        taskType,
        taskId
      } = action.payload

      return {
        ...state,
        patientTasks: [ ...state.patientTasks.filter((pt) => (!pt.hasOwnProperty(taskType) || pt.id !== taskId)) ],
      }
    }

    return state
  }

  case PatientActionTypes.SET_FETCHING_PATIENT_EXPIRED_TASKS_COUNT: {
    return {
      ...state,
      isFetchingPatientTasksExpiredCount: action.payload
    }
  }

  case PatientActionTypes.FETCH_PATIENT_EXPIRED_TASKS_COUNT_SUCCESS: {
    const {
      patientId,
      count
    } = action.payload

    if (state.patient && patientId === state.patient.id) {
      return {
        ...state,
        patient: {
          ...state.patient,
          patientTasksExpiredCount: count,
          has_expired_tasks: count > 0
        }
      }
    }

    return state
  }

  case PatientActionTypes.SET_PATIENT_TASK_COMMENTS: {
    if (state.patientTasks) {
      const { taskId, response = [] } = action.payload

      return {
        ...state,
        patientTasks: [
          ...state.patientTasks.map((pt) => (pt.id === taskId
            ? {
              ...pt,
              comments: response,
              amount_comments: Array.isArray(response)
                ? response.length
                : 0,
            } : pt
          ))
        ],
      }
    }

    return state
  }

  case PatientActionTypes.ADD_PATIENT_TASK_COMMENT: {
    if (state.patientTasks) {
      const {
        taskId,
        comment = [],
        taskType
      } = action.payload

      return {
        ...state,
        patientTasks: [
          ...state.patientTasks.map((pt) => (pt.hasOwnProperty(taskType) && pt.id === taskId
            ? {
              ...pt,
              comments: [ ...(pt.comments || []), comment ],
              amount_comments: Array.isArray(pt.comments)
                ? pt.comments.length + 1
                : 1,
            } : pt
          ))
        ],
      }
    }

    return state
  }

  case PatientActionTypes.DELETE_PATIENT_TASK_COMMENT: {
    if (state.patientTasks) {
      const {
        taskId,
        commentId,
        taskType
      } = action.payload

      return {
        ...state,
        patientTasks: [
          ...state.patientTasks.map((pt) => {
            if (pt.hasOwnProperty(taskType) && pt.id === taskId) {
              const newComments = pt.comments.filter((c) => c.id !== commentId)

              return {
                ...pt,
                comments: newComments,
                amount_comments: newComments.length
              }
            }

            return pt
          })
        ],
      }
    }

    return state
  }

  case PatientActionTypes.SET_FETCHING_PATIENT_ARTICLES:
    return {
      ...state,
      isFetchingArticles: action.payload
    }

  case PatientActionTypes.FETCH_PATIENT_ARTICLES_SUCCESS:
    return {
      ...state,
      articles: action.payload
    }

  case PatientActionTypes.SET_FETCHING_PATIENT_INSURANCES:
    return {
      ...state,
      isFetchingPatientInsurances: action.payload
    }

  case PatientActionTypes.FETCH_PATIENT_INSURANCES_SUCCESS:
    return {
      ...state,
      patientInsurances: action.payload
    }

  case PatientStructureActionTypes.SET_FETCHING_PATIENT_STRUCTURE_BY_TYPE:
    return {
      ...state,
      isFetchingStructure: action.payload
    }

  case PatientStructureActionTypes.FETCH_PATIENT_STRUCTURE_BY_TYPE_SUCCESS:
    return {
      ...state,
      [action.payload.typeId]: action.payload.response
    }

  case PatientStructureActionTypes.FETCH_PATIENT_STRUCTURE_CREATE_FOLDER_SUCCESS: {
    const {
      typeId,
      response,
      isSearch
    } = action.payload

    return {
      ...state,
      [typeId]: addStructureFolder(state[typeId], response, isSearch)
    }
  }

  case PatientStructureActionTypes.FETCH_PATIENT_STRUCTURE_DELETE_FOLDER_SUCCESS: {
    const { typeId, ...response } = action.payload

    return {
      ...state,
      [typeId]: deleteStructureFolder(state[typeId], response),
    }
  }

  case PatientStructureActionTypes.FETCH_PATIENT_STRUCTURE_UPDATE_FOLDER_SUCCESS: {
    const { typeId, ...response } = action.payload

    return {
      ...state,
      [typeId]: updateStructureFolder(state[typeId], response),
    }
  }

  case PatientStructureActionTypes.FETCH_PATIENT_STRUCTURE_MOVE_FOLDER_SUCCESS: {
    const { typeId, ...response } = action.payload

    return {
      ...state,
      [typeId]: moveStructureItem(state[typeId], response, 'children')
    }
  }

  case PatientStructureActionTypes.FETCH_PATIENT_STRUCTURE_CREATE_FILE_SUCCESS: {
    const {
      typeId,
      response,
    } = action.payload

    return {
      ...state,
      [typeId]: addStructureItem(state[typeId], response, 'files')
    }
  }

  case PatientStructureActionTypes.PATIENT_STRUCTURE_UPDATE_FILE: {
    const {
      typeId,
      parentId,
      fileId,
      response,
      isSerach
    } = action.payload

    return {
      ...state,
      [typeId]: updateStructureItem(state[typeId], parentId, fileId, response, isSerach),
    }
  }

  case PatientStructureActionTypes.FETCH_PATIENT_STRUCTURE_DELETE_FILE_SUCCESS: {
    const {
      typeId,
      isSearch,
      file
    } = action.payload

    return {
      ...state,
      [typeId]: deleteStructureItem(state[typeId], file, isSearch, 'files'),
    }
  }

  case PatientStructureActionTypes.FETCH_PATIENT_STRUCTURE_MOVE_FILE_SUCCESS: {
    const { typeId, ...response } = action.payload

    return {
      ...state,
      [typeId]: moveStructureItem(state[typeId], response, 'files')
    }
  }

  case PatientBindsActionTypes.PATIENT_UNBIND: {
    const { doctorId } = action.payload
    const newPatient = {
      ...state.patient,
      doctors: state.patient.doctors.filter((item) => item.id !== doctorId)
    }
    return {
      ...state,
      patient: { ...newPatient }
    }
  }

  default:
    return state
  }
}

export default patientBaseReducer
