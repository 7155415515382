import { FilesExtTypes } from 'constants/MediaTypes'
import { colors } from 'theme'

const getColors = (ext = '') => {
  switch (ext) {
  case FilesExtTypes.PDF:
    return {
      fill: colors.blue200,
      color: colors.blue,
      bc: colors.blue700,
    }

  case FilesExtTypes.XLS:
    return {
      fill: colors.green200,
      color: colors.green,
      bc: colors.green,
    }

  default:
    return {
      fill: '#dadee1',
      color: colors.grey500,
      bc: colors.grey300,
    }
  }
}

const getSizes = (size) => {
  switch (size) {
  case 'big':
    return {
      s: 100,
      strokeWidth: '1',
      x: '50%',
      y: '56%',
    }

  case 'normal':
  default:
    return {
      s: 40,
      strokeWidth: '1.5',
      x: '50%',
      y: '60%',
    }
  }
}

const IconFile = ({ ext = 0, size = 'normal' }) => {
  const {
    color,
    fill,
    bc
  } = getColors(ext)

  const {
    s,
    strokeWidth,
    x,
    y
  } = getSizes(size)

  return (
    <svg
      width={s}
      height={s}
      viewBox={`0 0 ${s} ${s}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 36.75H32C34.6234 36.75 36.75 34.6234 36.75 32V13.9039C36.75 12.4735 36.1054 11.1193 34.9953 10.2173L27.729 4.31346C26.8823 3.62552 25.8246 3.25 24.7337 3.25H8C5.37665 3.25 3.25 5.37665 3.25 8V32C3.25 34.6234 5.37665 36.75 8 36.75Z"
        fill={fill}
        stroke={bc}
        strokeWidth={strokeWidth}
      />
      <text
        x={x}
        y={y}
        fontSize="10px"
        fontFamily="Roboto"
        fill={color}
        dominantBaseline="middle"
        textAnchor="middle"
        fontWeight="bold"
      >
        {ext.substring(0, 4).toUpperCase()}
      </text>
    </svg>

  // <svg
  //   width={s}
  //   height={s}
  //   viewBox="0 0 36 36"
  //   fill="none"
  //   xmlns="http://www.w3.org/2000/svg"
  // >
  //   <path
  //     d="M4.25 31V31.75H5H31H31.75V31V11.5V11.143L31.4729 10.9179L23.4729 4.41791L23.2663 4.25H23H5H4.25V5V31Z"
  //     fill={fill}
  //     stroke={bc}
  //     strokeWidth={strokeWidth}
  //   />
  //   <text
  //     x={x}
  //     y={y}
  //     fontSize="10px"
  //     fontFamily="Inter"
  //     fill={color}
  //     dominantBaseline="middle"
  //     textAnchor="middle"
  //     fontWeight="bold"
  //   >
  //     {ext.toUpperCase()}
  //   </text>
  // </svg>

  )
}

export default IconFile
