import styled from 'styled-components'
import cn from 'classnames'

const CardContainer = styled.div`
  width: ${({ $width }) => ($width || '')};
  height: ${({ $height }) => ($height || '')};
  min-height: ${({ $minHeight }) => ($minHeight || '')};
  min-width: ${({ $minWidth }) => ($minWidth || '')};
  padding: ${({ p, theme }) => (p ? theme.paddings[p] || p : '')};
  margin: ${({ $m, theme }) => ($m ? theme.paddings[$m] || $m : '')};
  background-color: ${({ $bgColor, theme }) => ($bgColor ? theme.colors[$bgColor] || $bgColor : 'white')};
  border-radius: ${({ br, theme }) => br || theme.radius.normal};
  cursor: ${({ onClick, $cursor }) => (onClick ? 'pointer' : $cursor)};
  flex: ${({ $flex }) => $flex};
  justify-content: ${({ $jc }) => $jc};
  transition: background-color 0.3s ease-in-out;
  display: ${({ $display }) => $display || ''};

  &:hover {
    transition: background-color 0.3s ease-in-out, opacity 0.3s ease-in-out;
    background-color: ${({ $bgHoverColor, theme }) => ($bgHoverColor ? theme.colors[$bgHoverColor] || $bgHoverColor : '')};
    opacity: ${({ $bgHoverOpacity }) => $bgHoverOpacity};
  }

  &.--rel {
    position: relative;
  }

  &.--bordered {
    border: 1px solid ${({ theme, $brColor }) => ($brColor ? theme.colors[$brColor] || $brColor : theme.colors.grey150)};
  }

  &.--o-hidden {
    overflow: hidden;
  }

  &.--flex-c {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  &.--ai-c {
    display: flex;
    align-items: center;
  }

  &.--t-center {
    text-align: center;
  }

  &.--sh {
    &-normal {
      box-shadow: 0 2px 20px 0  rgba(0, 0, 0, 0.15);
    }

    &-small {
      box-shadow: 0 0px 5px 0  rgba(0, 0, 0, 0.15);
    }
  }

  &.--disabled {
    pointer-events: none;
  }
`

const Card = ({
  display = 'block',
  children = null,
  className = '',
  onClick = null,
  bgColor = null,
  bgHoverColor = null,
  bgHoverOpacity = null,
  isBordered = false,
  isRelative = false,
  flexColumn = false,
  textCenter = false,
  overflowHidden = false,
  alignCenter = false,
  brColor = '',
  shadow = '',
  minHeight = '',
  minWidth = '',
  cursor = '',
  m = '',
  flex = '',
  width = '',
  height = '',
  jc = '',
  isDisabled = false,
  ...props
}) => (
  <CardContainer
    className={cn(
      's-card',
      {
        '--rel': isRelative,
        '--bordered': isBordered,
        '--o-hidden': overflowHidden,
        '--flex-c': flexColumn,
        '--ai-c': alignCenter,
        '--t-center': textCenter,
        '--disabled': isDisabled,
        [`--sh-${shadow}`]: shadow,
      },
      className
    )}
    onClick={onClick}
    $bgColor={bgColor}
    $cursor={cursor}
    $brColor={brColor}
    $bgHoverColor={bgHoverColor}
    $bgHoverOpacity={bgHoverOpacity}
    $shadow={shadow}
    $height={height}
    $width={width}
    $minHeight={minHeight}
    $minWidth={minWidth}
    $m={m}
    $flex={flex}
    $jc={jc}
    $display={display}
    {...props}
  >
    {children}
  </CardContainer>
)

export default Card
