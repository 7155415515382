import { useState } from 'react'

// components
import { ModalContent } from 'components/modal'
import { Button } from 'components/base'
import { Title, Text } from 'components/typo'

import FormField from 'components/form/FormField'
import ModalCenterLayout from './ModalCenterLayout'

const CONFIRM_TEXT = 'Удалить безвозвратно'

const ModalConfirmDelete = ({
  closeModal = () => {},
  modal: {
    title = 'Удалить навсегда?',
    description = 'Для удаления требуется ввести словосочетание «Удалить безвозвратно» и нажать кнопку удалить',
    actionTitle = 'Удалить',
    onDelete = () => {}
  },
}) => {
  const [ isFetching, setFetching ] = useState(false)
  const [ confirmValue, setConfirmValue ] = useState('')

  const handleYes = () => {
    setFetching(true)
    return onDelete(closeModal, setFetching)
  }

  return (
    <ModalCenterLayout
      onClose={closeModal}
      noPointers
      headPosition="absolute"
      modalSize="normalBig"
    >
      <ModalContent
        display="flex"
        column
        p="0px"
      >
        <div>
          <div className="mb-10">
            <Title
              center
              size="medium"
            >
              {title}
            </Title>
          </div>
          <Text
            fw="l"
            color="grey500"
            className="mb-5"
          >
            {description}
          </Text>
          <FormField
            name="confirm"
            value={confirmValue}
            onChange={(e) => setConfirmValue(e.target.value)}
            placeholder={`Введите «${CONFIRM_TEXT}»`}
          />
          <Button
            defaultStyle="purple"
            className="mt-5"
            title={actionTitle}
            full
            size="big"
            onClick={handleYes}
            isDisabled={confirmValue !== CONFIRM_TEXT}
            isFetching={isFetching}
          />
        </div>
      </ModalContent>
    </ModalCenterLayout>
  )
}

export default ModalConfirmDelete
