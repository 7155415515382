import { useMediaQuery } from 'react-responsive'

const useResponsive = () => {
  const isBigScreen = useMediaQuery({ query: '(min-device-width: 1440px)' })
  const isDesktop = useMediaQuery({ query: '(min-width: 1282px)' })
  const isDesktopSmall = useMediaQuery({ query: '(min-width: 992px) and (max-width: 1281px)' })
  const isBigDesktopOrMobile = useMediaQuery({ query: '(max-width: 1140px)' })
  const isLaptopBigOrMobile = useMediaQuery({ query: '(max-width: 991px)' })
  const isLaptopBig = useMediaQuery({ query: '(min-width: 769px) and (max-width: 991px)' })
  const isLaptopOrMobile = useMediaQuery({ query: '(max-width: 768px)' })
  const isLaptop = useMediaQuery({ query: '(max-width: 768px)' })
  const isMobile = useMediaQuery({ query: '(max-width: 640px)' })
  const isMobileSmall = useMediaQuery({ query: '(max-width: 480px)' })
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
  const isBlockedLandscape = useMediaQuery({ query: '(max-device-height: 540px) and (orientation : landscape)' })

  return {
    isBigScreen,
    isDesktop,
    isBigDesktopOrMobile,
    isDesktopSmall,
    isLaptopBig,
    isLaptopBigOrMobile,
    isLaptop,
    isLaptopOrMobile,
    isMobile,
    isMobileSmall,
    isPortrait,
    isRetina,
    isBlockedLandscape: isBlockedLandscape && window.innerWidth > window.innerHeight,
  }
}

export default useResponsive
