/* eslint-disable react/no-unstable-nested-components */
import { useMemo, useCallback } from 'react'
import { components } from 'react-select'

// components
import Select from 'components/form/Select'
import { Text } from 'components/typo'
import { Spinner } from 'components/base'
import FormSelectDropdownIndicator from 'components/form/FormSelectDropdownIndicator'
import FormSelectOption from 'components/form/FormSelectOption'

import Box from 'layout/Box'
import FormSelectClearIndicator from './FormSelectClearIndicator'

const getTogglerStyles = {
  control: (base) => ({
    ...base,
    background: 'unset !important',
  }),
}

const ClearIndicatorStyles = (
  base,
) => ({
  ...base,
  cursor: 'pointer',
})

const FormSelect = ({
  size = '',
  name = '',
  value = '',
  options = [],
  labelName = 'title',
  valueName = 'id',
  toggler,
  isFetching = false,
  isMulti = false,
  isLoading = false,
  isClearable = false,
  isSearchable = false,
  isDisabled = false,
  isLabel = '',
  onChange = () => {},
  onClick = () => {},
  onInputChange = () => {},
  hundleScroll = null,
  placeholder = '',
  defaultValue = null,
  defaultStyle = null,
  error = null,
  isError = false,
  touched = false,
  minWidth = '',
  maxWidth = '',
  noBorder = false,
  noPadded = false,
  valueSize = 'normal',
  renderSingleItem = null,
  renderOption = null,
  onMenuScrollToBottom = null,
  onMenuOpen = null,
  selectedOption = [],
  isActive = true,
  isWhite = false,
  isCreatable = false,
  hideSelectedOptions = false,
  labelOpacity = '',
  noValueContainer = false,
  menuIsOpen = undefined,
  color = '',
}) => {
  const isErrorProps = isError || !!(error && touched)

  const textColor = isActive ? '' : 'grey700'

  const selectedValue = useMemo(() => (options
    ? value
      ? isMulti
        ? value
        : options.find((option) => option[valueName] === value)
      : defaultValue
    : ''), [ options, valueName, value, defaultValue, isMulti ])

  const handleSelectChange = useCallback((option, meta) => {
    if (isMulti) {
      onChange(option || [], meta)
    }
    else {
      onChange(option ? option[valueName] : '', meta)
    }
    return option
  }, [ onChange, valueName, isMulti ])

  const handleInputChange = useCallback((value) => onInputChange(value), [ onInputChange ])

  const SingleValue = (props) => {
    const { data } = props

    return isLoading
      ? <Spinner variant="small" />
      : (
        <components.SingleValue {...props}>
          {renderSingleItem
            ? renderSingleItem(data)
            : (
              <Box
                width="100%"
                display="block"
                p="0 8px 0 0"
              >
                <Text
                  color={textColor}
                  size={valueSize}
                  isElipsed
                >
                  {data[labelName]}
                </Text>
              </Box>
            )}
        </components.SingleValue>
      )
  }

  const ValueContainer = ({ children, ...props }) => (noValueContainer
    ? null
    : (
      <components.ValueContainer {...props}>
        {isLabel && (
          <Text
            tag="span"
            className="field-label"
            fw="m"
            isElipsed
            valueSize={valueSize}
            opacity={labelOpacity}
          >
            {isLabel}
          </Text>
        )}
        {children}
      </components.ValueContainer>
    ))

  const MultiValue = (props) => {
    const {
      index,
      selectProps: { selectedOption },
    } = props

    const valueStr = selectedOption.reduce((acc, next) => {
      acc.push(next[labelName])
      return acc
    }, [])

    return index === selectedOption.length - 1
      ? (
        <Text isElipsed textOverflow>
          {valueStr.join(', ')}
        </Text>
      ) : ''
  }

  const Option = (optionProps) => (
    <FormSelectOption
      optionProps={optionProps}
      valueSize={valueSize}
      labelName={labelName}
      renderOption={renderOption}
    />
  )

  const selectComponents = {
    Option,
    SingleValue,
    MultiValue,
    DropdownIndicator: FormSelectDropdownIndicator,
    ClearIndicator: FormSelectClearIndicator,
  }

  if (!noValueContainer) {
    selectComponents.ValueContainer = ValueContainer
  }

  return (
    <Select
      size={size}
      name={name}
      color={color}
      labelName={labelName}
      valueName={valueName}
      defaultStyle={defaultStyle}
      components={selectComponents}
      isMulti={isMulti}
      isError={isErrorProps}
      closeMenuOnSelect={!isMulti}
      hideSelectedOptions={hideSelectedOptions}
      backspaceRemovesValue={false}
      isClearable={isMulti || isClearable}
      isSearchable={isSearchable}
      options={options}
      value={selectedValue}
      onChange={handleSelectChange}
      onClick={onClick}
      onInputChange={handleInputChange}
      hundleScroll={hundleScroll}
      captureMenuScroll={!!onMenuScrollToBottom}
      styles={toggler ? {
        ...getTogglerStyles,
        clearIndicator: ClearIndicatorStyles
      } : { clearIndicator: ClearIndicatorStyles }}
      isLabel={isLabel}
      isDisabled={isDisabled}
      placeholder={placeholder}
      minWidth={minWidth}
      maxWidth={maxWidth}
      selectedOption={selectedOption}
      noBorder={noBorder}
      noPadded={noPadded}
      isLoading={isFetching}
      onMenuScrollToBottom={onMenuScrollToBottom}
      onMenuOpen={onMenuOpen}
      isWhite={isWhite}
      isCreatable={isCreatable}
      menuIsOpen={menuIsOpen}
      // styles={{ clearIndicator: ClearIndicatorStyles }}
    />
  )
}

export default FormSelect
