import { ClinicActionTypes } from 'redux/dashboard/Clinic/ClinicTypes'
import ApiService from 'services/ApiService'

export const setFetchingClinic = (value) => ({
  type: ClinicActionTypes.SET_FETCHING_CLINIC,
  payload: value,
})

export const fetchClinicSuccess = (response) => ({
  type: ClinicActionTypes.FETCH_CLINIC_SUCCESS,
  payload: response,
})

export const fetchClinicError = (error) => ({
  type: ClinicActionTypes.FETCH_CLINIC_ERROR,
  payload: error,
})
export const setClinicDocuments = (value) => ({
  type: ClinicActionTypes.FETCH_CLINIC_DOCUMENT,
  payload: value,
})

export const fetchClinic = (clinicId = null) => (dispatch, getState) => {
  const { isFetching } = getState().dashboard.clinic
  if (isFetching) return

  dispatch(setFetchingClinic(true))

  return new Promise((res, rej) => {
    ApiService.apiCall({
      url: `${ApiService.paths.clinic.BY_ID(clinicId)}`,
      isToken: true,
      noErrorMessage: true
    })
      .then((response) => {
        dispatch(fetchClinicSuccess(response))
        dispatch(setFetchingClinic(false))
        return res(response)
      })
      .catch((error) => {
        dispatch(fetchClinicError(error))
        dispatch(setFetchingClinic(false))
        return rej(error)
      })
  })
}

export const fetchClinicDocuments = (clinicId = null) => (dispatch, getState) => {
  const { isFetching } = getState().dashboard.clinic
  if (isFetching) return

  dispatch(setFetchingClinic(true))
  return new Promise((res, rej) => {
    ApiService.apiCall({
      url: `${ApiService.paths.clinics.CLINIC_MED_DOCUMENT(clinicId)}`,
      isToken: true,
    })
      .then((response) => {
        dispatch(setClinicDocuments(response))
        dispatch(setFetchingClinic(false))
        return res(response)
      })
      .catch((error) => {
        dispatch(fetchClinicError(error))
        dispatch(setFetchingClinic(false))
        return rej(error)
      })
  })
}

export const fetchClinicFileDelete = (id) => (dispatch, getState) => {
  const { isFetching } = getState().dashboard.clinic
  if (isFetching) return

  dispatch(setFetchingClinic(true))
  return new Promise((res, rej) => {
    ApiService.apiCall({
      method: 'DELETE',
      url: id.certificateId ? `${ApiService.paths.clinics.CLINIC_DELETE_CERTIFICATE(id.clinicId, id.certificateId)}` : `${ApiService.paths.clinics.CLINIC_DELETE_SERVICE_AGREEMENT(id.clinicId)}`,
      isToken: true,
    })
      .then((response) => {
        dispatch(setClinicDocuments(response))
        dispatch(setFetchingClinic(false))
        return res(response)
      })
      .catch((error) => {
        dispatch(fetchClinicError(error))
        dispatch(setFetchingClinic(false))
        return rej(error)
      })
  })
}
