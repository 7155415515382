import s from 'styled-components'
import { forwardRef } from 'react'
// components
import { Input } from 'components/form'
import { ReactComponent as IconCaret } from 'assets/icons/caret-s.svg'
import ButtonClear from 'components/buttons/ButtonClear'

import { colors } from 'theme'

const FormInputDropdownContainer = s.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  input {
    padding: ${({ isPadded }) => (isPadded ? '0 56px 0 16px' : '0')};
    color: ${({ $color, theme }) => (theme.colors[$color] || '')};
    cursor: pointer;
    overflow: hidden; 
    text-overflow: ellipsis;
  }

  .clear {
    position: absolute;
    top: 50%;
    right: ${({ isPadded }) => (isPadded ? '32px' : '45px')};
    transform: translateY(-50%);
    border-radius: 50%;
  }

  .toggler {
    position: absolute;
    top: 50%;
    right: ${({ isPadded }) => (isPadded ? '16px' : '0')};
    transform: translateY(-50%);
    pointer-events: none;

    svg {
      position: relative;
      margin-left: 20px;
      transform: rotateX(${({ isActive }) => (isActive ? '180deg' : '0deg')});
    }
  }

`

const FormInputDropdown = forwardRef((props, ref) => {
  const {
    children,
    name = '',
    value = '',
    isActive = false,
    readOnly = true,
    isPadded = false,
    onClear = null,
    placeholderColor = '',
    color = '',
    bgColor = '',
    height = '',
    width = '',
    fs = 'normal',
    ...otherProps
  } = props

  return (
    <FormInputDropdownContainer $color={color} isPadded={isPadded} isActive={isActive}>
      <Input
        ref={ref}
        {...otherProps}
        readOnly={readOnly}
        placeholderColor={placeholderColor}
        color={color}
        name={name}
        value={value}
        bgColor={bgColor}
        height={height}
        width={width}
        fs={fs}
      />
      {onClear && value && (
        <span className="clear">
          <ButtonClear
            defaultStyle="transparentGrey"
            icon="circle"
            onClick={onClear}
          />
        </span>
      )}
      <span className="toggler">
        <IconCaret fill={colors[color] || colors.dark} />
      </span>
      {children}
    </FormInputDropdownContainer>
  )
})

FormInputDropdown.displayName = 'FormInputDropdown'

export default FormInputDropdown
