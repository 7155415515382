import queryString from 'query-string'

export const getQueryStringFromObject = (object) => queryString.stringify(object, {
  skipNull: true,
  skipEmptyString: true,
})

export const getQuerySearch = (object) => {
  const query = getQueryStringFromObject(object)

  return query ? `?${query}` : ''
}

export const getObjectFromQueryString = (string) => queryString.parse(string, {
  skipNull: true,
  skipEmptyString: null
})
