/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import * as Yup from 'yup'
import {
  dateRegExp,
  emailRegExp,
  phoneReg, phoneRegExp,
  timeRegExpStatic,
  urlRegExp,
} from 'helpers/RegExp'
import { ClinicRecordStatusTypes } from 'constants/ClinicRecordStatusTypes'
import { parseISO } from 'date-fns'
import { UserRolesTypes } from './UserTypes'
import { formatDate } from '../helpers/Date'

const errorMessages = {
  MIN: (value) => `Минимальная длинна ${value}`,
  MAX: (value) => `Максимальная длинна ${value}`,
  FIELD_REQUIRED: 'Заполните поле',
  IMAGE_REQUIRED: 'Загрузите изображение',
  EQUAL: 'Поля должны совпадать',
}

export const isEmail = (value) => emailRegExp.test(value)

export const isPhone = (value) => phoneReg.test(value)

export const isDateFormat = (value) => dateRegExp.test(value)

export const isChecked = (value) => value === true

export const isValidTime = (time = '') => time === '' || !time.split(',').some((t) => !t.match(timeRegExpStatic))

export const isValidTimeToo = (time, { parent }) => {
  const startDate = parent.date ? parent.date[0] : ''
  const endDate = parent.date ? parent.date[1] : ''
  if (formatDate(startDate, 'dd.MM.yyyy') === formatDate(new Date(), 'dd.MM.yyyy')) {
    return (formatDate(new Date(), 'HH:mm') < time) || !time || (endDate && (formatDate(endDate, 'dd.MM.yyyy') !== formatDate(new Date(), 'dd.MM.yyyy')))
  }
  return true
}

export const isHasTime = (_, { parent }) => {
  if ((parent.morning || parent.evening || parent.afternoon)
    && (
      isValidTime(parent.morning)
      && isValidTime(parent.afternoon)
      && isValidTime(parent.evening)
      && isValidTimeToo(parent.morning, { parent })
      && isValidTimeToo(parent.afternoon, { parent })
      && isValidTimeToo(parent.evening, { parent })
    )
  ) {
    return true
  }
}

export const checkBIK = (value) => {
  if (!value) {
    return true
  }

  if (!/^\d{9}$/.test(value)) {
    return false
  }

  const thirdPart = value.slice(-3)

  if (
    +thirdPart === 0
    || +thirdPart === 1
    || +thirdPart === 2
  ) {
    return true
  }

  return +thirdPart >= 50 && +thirdPart < 1000
}

export const checkINN = (value) => {
  if (!value || (value && (value.length === 10 || value.length === 12))) {
    return true
  }

  return false
}

export const checkKPP = (value) => {
  if (!value) {
    return true
  }
  // КПП должен представлять собой девятизначный код
  if (value.length !== 9 || !/^[0-9]{4}[0-9A-Z]{2}[0-9]{3}$/.test(value)) {
    return false
  }

  return true
}

export const checkOGRN = (value) => {
  if (!value) {
    return true
  }

  if (
    value.match(/\D/)
    || (value.length !== 13 && value.length !== 15)
  ) {
    return false
  }

  // проверка ОГРН для ЮЛ
  if (value.length === 13) {
    let dgt13
    // проверка по контрольным цифрам
    let num12 = value
    num12 = Math.floor((num12 / 10) % 11)

    if (num12 === 10) {
      dgt13 = '0'
    }
    else {
      dgt13 = String(num12)
    }

    if (value[12] === dgt13) {
      return true
    }
  }
  else if (value.length === 15) {
    // проверка по контрольным цифрам
    let num14 = value
    num14 = Math.floor((num14 / 10) % 13)
    const dgt15 = String(num14 % 10)

    if (value[14] === dgt15) {
      return true
    }
  }

  return false
}

// function validateRSorKS(value, bic, isRs) {
//   if (!value) {
//     return true
//   }

//   let result = false

//   if (
//     value
//     && value.length
//     && !/[^0-9]/.test(value)
//     && value.length === 20
//     && (!bic || checkBIK(bic))
//   ) {
//     if (bic) {
//       const bicCheck = isRs ? bic.toString().slice(-3) + value : `0${bic.slice(4, 6)}${value}`
//       let checksum = 0
//       const coefficients = [ 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1 ]

//       for (const i in coefficients) {
//         checksum += coefficients[i] * (bicCheck[i] % 10)
//       }

//       if (checksum % 10 === 0) {
//         result = true
//       }
//     }
//     else {
//       result = true
//     }
//   }

//   return result
// }

export const checkBankPA = (value) => {
  const valueToString = value ? value.toString() : ''
  return valueToString.length === 20
}

const firstNameSchema = Yup.string()
  .max(150, errorMessages.MAX(150))
  .required(errorMessages.FIELD_REQUIRED)

const lastNameSchema = Yup.string()
  .max(150, errorMessages.MAX(150))
  .required(errorMessages.FIELD_REQUIRED)

const phoneSchema = Yup.string()
  .matches(phoneReg, 'Неправильный формат телефона')
  .required('Укажите телефон')

const dateSchema = Yup.string()
  .matches(dateRegExp, 'Неправильный формат даты')
  .required('Укажите дату')

export const urlSchema = Yup.string()
  .matches(urlRegExp, 'Неправильный формат ссылки')

// const phoneSchemaNullable = Yup.string()
//   .test('phoneValidOrEmpty', 'Неверный формат телефона', (value) => {
//     if (value === '+7 (___) ___-__-__') {
//       return true
//     }

//     return !value || (value && phoneReg.test(value))
//   })

const emailSchema = Yup.string()
  .email('Неправильный формат почты')
  .required('Укажите почту')

// const emailSchemaNullable = Yup.string()
//   .email('Неправильный формат почты')
//   .nullable()

const positionSchema = Yup.string()
  .max(100, errorMessages.MAX(100))
  .nullable()

const timeSchema = Yup.string()
  .matches(timeRegExpStatic, 'Время указано не верно')

const timesSchema = Yup.string().min(1)
  .test(
    'match',
    'Добавьте промежуток времени',
    isHasTime
  )

export const SchemaText = Yup.object().shape({ value: Yup.string().required(errorMessages.FIELD_REQUIRED) })

export const SchemaLogin = Yup.object().shape({
  password: Yup.string().required(),
  username: emailSchema
})

export const SchemaLoginChange = Yup.object().shape({ email: emailSchema })

const codeVerificationSchema = Yup.number()
  .required('Укажите код')
export const SchemaLoginAccept = Yup.object().shape({ code: codeVerificationSchema })

export const SchemaSignin = Yup.object().shape({
  first_name: firstNameSchema,
  last_name: lastNameSchema,
  phone: phoneSchema,
  email: emailSchema,
  terms: Yup.bool().test(
    'match',
    'Требуется ваше согласие',
    isChecked
  ),
  politic: Yup.bool().test(
    'match',
    'Требуется ваше согласие',
    isChecked
  ),
})

export const SchemePasswordChange = Yup.object().shape({
  oldPassword: Yup.string().required('Введите старый пароль'),
  password: Yup.string()
    .required('Новый пароль')
    .min(8, 'Не менее 8 символов')
    .matches(
      /^(?=.*[a-z])/,
      'Пароль должен содержать строчные латинские буквы'
    )
    .matches(
      /^(?=.*[A-Z])/,
      'Пароль должен содержать прописные латинские буквы'
    )
    .matches(
      /^(?=.*\d)/,
      'Пароль должен содержать арабские цифры'
    )
    .test(
      'no-special-characters',
      'Пароль не должен содержать специальные символы ',
      (value) => !/[!@#$%^&*]/.test(value)
    ),
  repeatPassword: Yup.string()
    .required('Повторите пароль')
    .oneOf([ Yup.ref('password'), null ], 'Пароли должны совпадать'),
})

export const SchemaProfile = Yup.object().shape({
  first_name: firstNameSchema,
  last_name: lastNameSchema,
  position: positionSchema,
  country: Yup.number().nullable(),
  city: Yup.string().nullable(),
  address: Yup.string().nullable(),
  email: emailSchema,
  phone: phoneSchema,
  emails_list: Yup.array().of(emailSchema),
  phones_list: Yup.array().of(phoneSchema)
})

const times = {
  morning: timesSchema.test('is-valid-time-too', 'Время указано неверно', isValidTimeToo),
  afternoon: timesSchema.test('is-valid-time-too', 'Время указано неверно', isValidTimeToo),
  evening: timesSchema.test('is-valid-time-too', 'Время указано неверно', isValidTimeToo),
}

export const SchemaTaskExercise = Yup.object().shape({
  start_date: Yup.string().required(),
  end_date: Yup.string().required(),
  total_date: Yup.string().required(),
  comment: Yup.string(),
  exercise: Yup.number().required().nullable(),
  ...times,
})

export const SchemaTaskExerciseEdit = Yup.object().shape({
  end_date: Yup.string().required(),
  comment: Yup.string(),
  time: timeSchema,
  exercise: Yup.number().required().nullable(),
})

export const SchemaTaskEvent = Yup.object().shape({
  start_date: Yup.string().required(),
  end_date: Yup.string().required(),
  total_date: Yup.string().required(),
  comment: Yup.string(),
  event: Yup.number().required().nullable(),
  ...times,
})

export const SchemaTaskEventEdit = Yup.object().shape({
  end_date: Yup.string().required(),
  comment: Yup.string(),
  time: timeSchema,
  event: Yup.number().required().nullable(),
})

export const SchemaTaskMedicine = Yup.object().shape({
  start_date: Yup.string().required(),
  end_date: Yup.string().required(),
  total_date: Yup.string().required(),
  medicine: Yup.number().required().nullable(),
  tablet_count: Yup.string().required(),
  comment: Yup.string(),
  ...times
})

export const SchemaTaskMedicineWithDoctor = Yup.object().shape({
  start_date: Yup.string().required(),
  end_date: Yup.string().required(),
  total_date: Yup.string().required(),
  medicine: Yup.number().required().nullable(),
  tablet_count: Yup.string().required(),
  comment: Yup.string(),
  ...times
})

// Курсы лечения
export const SchemaTaskMedicineCourseAssign = Yup.object().shape({
  start_date: Yup.string().required().min(1),
  end_date: Yup.string().required().min(1),
  medicine: Yup.number().required().nullable(),
  tablet_count: Yup.string().required().min(1),
  period: Yup.lazy((val) => (Array.isArray(val) ? Yup.array().min(1) : Yup.string().required().min(1))),
  ...times,
})

export const SchemaTaskExerciseCourseAssign = Yup.object().shape({
  start_date: Yup.string().required().min(1),
  end_date: Yup.string().required().min(1),
  exercise: Yup.number().required().nullable(),
  period: Yup.lazy((val) => (Array.isArray(val) ? Yup.array().min(1) : Yup.string().required().min(1))),
  ...times,
})

export const SchemaTaskEventCourseAssign = Yup.object().shape({
  start_date: Yup.string().required().min(1),
  end_date: Yup.string().required().min(1),
  event: Yup.number().required().nullable(),
  period: Yup.lazy((val) => (Array.isArray(val) ? Yup.array().min(1) : Yup.string().required().min(1))),
  ...times,
})

export const SchemaTaskAnalysisCourseAssign = Yup.object().shape({
  date: Yup.string().required().min(1),
  analysis: Yup.number().required().nullable(),
  time: timeSchema.required(),
})

export const SchemaTaskResearchCourseAssign = Yup.object().shape({
  date: Yup.string().required().min(1),
  research: Yup.number().required().nullable(),
  time: timeSchema.required(),
})

export const SchemaTaskProcedureAssign = Yup.object().shape({
  start_date: Yup.string().required().min(1),
  end_date: Yup.string().required().min(1),
  procedure: Yup.number().required().nullable(),
  period: Yup.lazy((val) => (Array.isArray(val) ? Yup.array().min(1) : Yup.string().required().min(1))),
  ...times,
})

export const SchemaTaskManipulationAssign = Yup.object().shape({
  start_date: Yup.string().required().min(1),
  end_date: Yup.string().required().min(1),
  manipulation: Yup.number().required().nullable(),
  period: Yup.lazy((val) => (Array.isArray(val) ? Yup.array().min(1) : Yup.string().required().min(1))),
  ...times,
})

export const SchemaTaskExtrataskAssign = Yup.object().shape({
  start_date: Yup.string().required().min(1),
  end_date: Yup.string().required().min(1),
  extratask: Yup.string().required(),
  content: Yup.string().required(),
  comment: Yup.string(),
  extratask_task_description: Yup.string(),
  period: Yup.lazy((val) => (Array.isArray(val) ? Yup.array().min(1) : Yup.string().required().min(1))),
  ...times,
})

export const SchemaTaskConsultationCourseAssign = Yup.object().shape({
  date: Yup.string().required().min(1),
  start_scheduled_at: timeSchema.required().min(1),
  scheduled_at: timeSchema.required().min(1),
  consultation_task_type: Yup.string().required().nullable(),
})

export const SchemaTaskArticleCourseAssign = Yup.object().shape({
  article_task_type: Yup.string().required().nullable(),
  comment: Yup.string(),
  period: Yup.lazy((val) => (Array.isArray(val) ? Yup.array().min(1) : Yup.string().required().min(1))),
})

export const SchemaTaskMedicineEdit = Yup.object().shape({
  end_date: Yup.string().required(),
  medicine: Yup.number().required().nullable(),
  tablet_count: Yup.string().required(),
  comment: Yup.string(),
  time: timeSchema,
})

export const SchemaTaskAnalysis = Yup.object().shape({
  scheduled_at: Yup.string().required(),
  analysis: Yup.number().required().nullable(),
  comment: Yup.string(),
  timeTo: timeSchema.required(),
})

export const SchemaTaskResearch = Yup.object().shape({
  scheduled_at: Yup.string().required(),
  research: Yup.number().required().nullable(),
  comment: Yup.string(),
  timeTo: timeSchema.required(),
})

export const SchemaTaskProcedure = Yup.object().shape({
  start_date: Yup.string().required(),
  end_date: Yup.string().required(),
  total_date: Yup.string().required(),
  procedure: Yup.number().required().nullable(),
  comment: Yup.string(),
  ...times
})

export const SchemaTaskProcedureEdit = Yup.object().shape({
  end_date: Yup.string().required(),
  comment: Yup.string(),
  time: timeSchema,
  procedure: Yup.number().required().nullable(),
})

export const SchemaTaskManipulation = Yup.object().shape({
  start_date: Yup.string().required(),
  end_date: Yup.string().required(),
  total_date: Yup.string().required(),
  manipulation: Yup.number().required().nullable(),
  comment: Yup.string(),
  ...times
})

export const SchemaTaskManipulationEdit = Yup.object().shape({
  end_date: Yup.string().required(),
  comment: Yup.string(),
  time: timeSchema,
  manipulation: Yup.number().required().nullable(),
})

export const SchemaTaskExtratask = Yup.object().shape({
  start_date: Yup.string().required(),
  end_date: Yup.string().required(),
  total_date: Yup.string().required(),
  extratask_name: Yup.string().required(),
  comment: Yup.string(),
  content: Yup.string().required(),
  ...times
})

export const SchemaTaskExtrataskEdit = Yup.object().shape({
  end_date: Yup.string().required(),
  comment: Yup.string(),
  time: timeSchema,
  extratask_name: Yup.string().required(),
  content: Yup.string().required(),
})

export const SchemaTaskArticleEdit = Yup.object().shape({ article: Yup.number().required() })

export const SchemaTaskConsultation = Yup.object().shape({
  scheduled_at: Yup.string().required(),
  comment: Yup.string(),
  consultation_task_type: Yup.string().required().nullable(),
  timeFrom: timeSchema.required(),
  timeTo: timeSchema.required(),
})
export const SchemaTaskConsultationAdmin = Yup.object().shape({
  scheduled_at: Yup.string().required(),
  comment: Yup.string(),
  consultation_task_type: Yup.string().required().nullable(),
  doctor: Yup.string().required().nullable(),
  timeFrom: timeSchema.required(),
  timeTo: timeSchema.required(),
})

export const SchemaAddManipulation = Yup.object().shape({
  name: Yup.string().required().min(1),
  task_description: Yup.string().required().min(1),
  content: Yup.string().required().min(1),
})

export const SchemaAddExercises = Yup.object().shape({
  name: Yup.string().required().min(1),
  task_description: Yup.string().required().min(1),
  description: Yup.string().required().min(1),
  sum_time: Yup.string().matches(/^(0[0-9]|1[0-9]|2[0-3])(:([0-5][0-9]||[0-5])){2}$/),
})

export const SchemaAddAnalyses = Yup.object().shape({
  name: Yup.string().required().min(1),
  task_description: Yup.string().required().min(1),
  description: Yup.string().required().min(1),
  how_to: Yup.string().required().min(1),
})

export const SchemaAddProcedure = Yup.object().shape({
  name: Yup.string().required().min(1),
  task_description: Yup.string().required().min(1),
  description: Yup.string().required().min(1),
})

export const SchemaAddResearches = Yup.object().shape({
  name: Yup.string().required().min(1),
  task_description: Yup.string().required().min(1),
  content: Yup.string().required().min(1),
})

export const SchemaAddMedicie = Yup.object().shape({
  name: Yup.string().required().min(1),
  task_description: Yup.string().required().min(1),
  description: Yup.string().required().min(1),
})

export const SchemaAddStructureFile = Yup.object().shape({
  fullName: Yup.string().required(),
  type: Yup.string().required(),
  date: dateSchema,
})

export const SchemaAddStructureFolder = Yup.object().shape({ title: Yup.string().required() })

export const SchemaAddFileForm = Yup.object().shape({
  type: Yup.string().required(),
  fileName: Yup.string().required(),
})

export const SchemaClientInvite = Yup.object().shape({
  doctor: Yup.number().required(),
  patient: Yup.number().required(),
  initiator: Yup.string().oneOf([ UserRolesTypes.CONSULTANT, UserRolesTypes.PATIENT, UserRolesTypes.DOCTOR ]),
  clinic: Yup.number().required(),
})

export const SchemaDiaryStatChart = Yup.object().shape({
  period: Yup.string().nullable().required(),
  frequency: Yup.string().nullable().required(),
  date_start: Yup.string().nullable(),
})

export const SchemaSmsInvitation = Yup.object().shape({
  secondName: lastNameSchema,
  firstName: firstNameSchema,
  middleName: lastNameSchema,
  phone: phoneSchema,
  date: Yup.string()
    .matches(dateRegExp, 'Неправильный формат даты')
    .required('Укажите дату'),
})

export const SchemaArticleForm = Yup.object().shape({
  title: Yup.string().required(),
  description: Yup.string().required(),
  is_visible: Yup.bool(),
  file: Yup.mixed().required(),
})

export const SchemaClinicRecordBase = Yup.object().shape({
  created_at: Yup.string(),
  doctor: Yup.number(),
  consultation_task_type: Yup.string().nullable(),
  record_date: Yup.string().min(1),
  timeTo: timeSchema,
  timeFrom: timeSchema,
  status: Yup.string().required().nullable(),
  comment: Yup.string(),
})

export const SchemaClinicRecordConfirmed = Yup.object().shape({
  created_at: Yup.string(),
  doctor: Yup.number().required(),
  consultation_task_type: Yup.string().required().nullable(),
  record_date: Yup.string().required().min(1),
  timeTo: timeSchema.required(),
  timeFrom: timeSchema.required(),
  status: Yup.string().required().nullable(),
  comment: Yup.string(),
})

export const GetSchemaClinicRecord = () => Yup.object().shape({
  created_at: Yup.string(),
  status: Yup.string().required().nullable().notOneOf([ ClinicRecordStatusTypes.NEW ]),
  doctor: Yup.number().when('status', {
    is: (status) => status === ClinicRecordStatusTypes.CONFIRMED,
    then: Yup.number().required(),
    otherwise: Yup.number(),
  }),
  consultation_task_type: Yup.string().when('status', {
    is: (status) => status === ClinicRecordStatusTypes.CONFIRMED,
    then: Yup.string().required().nullable(),
    otherwise: Yup.string(),
  }),
  record_date: Yup.string().when('status', {
    is: (status) => status === ClinicRecordStatusTypes.CONFIRMED,
    then: Yup.string().required().min(1),
    otherwise: Yup.string(),
  }),
  timeTo: timeSchema.when('status', {
    is: (status) => status === ClinicRecordStatusTypes.CONFIRMED,
    then: timeSchema.required(),
    otherwise: timeSchema,
  }),
  timeFrom: timeSchema.when('status', {
    is: (status) => status === ClinicRecordStatusTypes.CONFIRMED,
    then: timeSchema.required(),
    otherwise: timeSchema,
  }),
  comment: Yup.string(),
})
