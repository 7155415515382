import Button from 'components/base/Button'
import LinkRoute from 'components/typo/Link'
import Text from 'components/typo/Text'
import UserAvatar from 'components/User/UserAvatar'
import { messageTypesColors } from 'constants/NotifierTypes'
import { getUserFullName } from 'helpers/User'
import Box from 'layout/Box'
import { getPatientUrl } from 'redux/helpers/PatientHelpers'
import NotifierMessageText from '../NotifierMessageText'

const NotifierCardConsultationRemind = ({
  patient = {},
  timeBefore,
  createdAt,
  titleColor = messageTypesColors.DEFAULT,
  onChat,
}) => (
  <div className="notifier-message-content">
    <NotifierMessageText
      className="mb-12"
      color={titleColor}
      text="Консультация с пациентом"
      fw="medium"
      size="normalSm"
      isTrim
    />
    <Box className="my-3">
      <div className="mb-2 mr-2">
        <UserAvatar
          size="normalSmall"
          avatar={patient.avatar}
          user={patient}
        />
      </div>
      <div>
        <Text>
          Консультация с пациентом
          {' '}
          <LinkRoute
            to={getPatientUrl(patient.id)}
            defaultStyle="blue"
          >
            {getUserFullName(patient, true)}
          </LinkRoute>
          {' '}
          состоится через
          {' '}
          {timeBefore}
          {' '}
        </Text>
        <Text className="mt-1" size="small" color="grey500">
          {createdAt}
        </Text>
      </div>
    </Box>
    <Button
      onClick={() => onChat(patient.voximplant_username)}
      full
      isGradient
      title="Чат с пациентом"
      size="small"
      rounded
    />
  </div>
)

export default NotifierCardConsultationRemind
