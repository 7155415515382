import Box from 'layout/Box'
import ButtonCall from 'components/buttons/ButtonCall'
import Text from 'components/typo/Text'
import UserAvatar from 'components/User/UserAvatar'
import ButtonWrapper from 'components/buttons/ButtonWrapper'
import ButtonVideo from 'components/buttons/ButtonVideo'

import VoxService from 'services/Voximplant/vox.service'
import { getUserRoleTitle } from 'helpers/User'

const CallStart = ({ call, callUser }) => (
  <Box
    display="block"
    bg="grey700"
    br="16px"
    className="call-modal"
    width="480px"
    p="24px 48px 28px"
  >
    <Box
      direction="column"
      width="100%"
      height="100%"
      ai="center"
      jc="center"
      textAlign="center"
    >
      <Text
        className="mb-8"
        size="normalBig"
        color="white"
        fw="m"
      >
        {call.isIncoming ? 'Вам звонят...' : 'Вы звоните...'}
      </Text>
      <UserAvatar
        className="mb-4"
        size="huge"
        avatar={callUser && callUser.avatar}
        placeholderName={callUser && callUser.displayName}
      />
      {callUser && (
        <Text
          className="mb-2"
          size="normalBig"
          color="white"
          fw="m"
        >
          {callUser.displayName}
        </Text>
      )}
      {callUser && (
        <Text className="mb-6" color="grey400">
          {getUserRoleTitle(callUser.role)}
        </Text>
      )}
      <Box ai="center">
        <>
          <ButtonWrapper title="Отклонить">
            <ButtonCall
              onClick={() => (call.isIncoming ? VoxService.get().declineCall() : VoxService.get().hangupCall())}
              size="medium"
              isDown
              br="round"
              defaultStyle="danger"
            />
          </ButtonWrapper>
          {call.currentCall.settings.video && call.isIncoming && (
            <ButtonWrapper title="Принять с видео">
              <ButtonVideo
                onClick={() => VoxService.get().acceptCall({ isVideo: true })}
                size="medium"
                br="round"
                defaultStyle="acsent"
              />
            </ButtonWrapper>
          )}
          {call.isIncoming && (
            <ButtonWrapper title="Принять">
              <ButtonCall
                onClick={() => VoxService.get().acceptCall({ isVideo: false })}
                size="medium"
                isDown={!call.isIncoming}
                br="round"
                defaultStyle="acsent"
              />
            </ButtonWrapper>
          )}
        </>
      </Box>
    </Box>
  </Box>
)

export default CallStart
