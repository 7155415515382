import s from 'styled-components'
import cn from 'classnames'

export const pageHeight = '76px'

const PageHeaderContainer = s.div`
  position: relative;
  height: ${pageHeight};
  padding: ${({ $p }) => $p || '0 24px'};
  background-color: ${({ $isWhite, theme }) => ($isWhite
    ? theme.colors.white
    : theme.colors.dark)};
  border-bottom: ${({ theme, $isBorder }) => ($isBorder ? `1px solid ${theme.colors.black10}` : '')};
  flex-shrink: 0;

  &, a {
    color: ${({
    $isWhite,
    $color,
    theme
  }) => ($isWhite
    ? theme.colors.dark
    : $color)};
  }

  &.--s-top {
    position: sticky;
    top: ${({ $top }) => $top || 0};
    z-index: 10;
  }
`

const PageHeader = ({
  children = null,
  p = '',
  top = '',
  color = '',
  isWhite = false,
  isBorder = false,
  isStickyTop = false,
}) => (
  <PageHeaderContainer
    $color={color}
    $top={top}
    $p={p}
    $isBorder={isBorder}
    $isWhite={isWhite}
    className={cn('p-header', { '--s-top': isStickyTop })}
  >
    {children}
  </PageHeaderContainer>
)

export default PageHeader
