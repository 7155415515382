import Button from 'components/base/Button'
import LinkRoute from 'components/typo/Link'
import Text from 'components/typo/Text'
import UserAvatar from 'components/User/UserAvatar'
import UsersAvatars from 'components/User/UsersAvatars'
import { messageTypesColors } from 'constants/NotifierTypes'
import { UserRolesTypes } from 'constants/UserTypes'
import { getUserFullName } from 'helpers/User'
import Box from 'layout/Box'
import { useMemo } from 'react'
import { getPatientUrl } from 'redux/helpers/PatientHelpers'
import NotifierMessageText from '../NotifierMessageText'

const NotifierCardPatientInvited = ({
  patient = {},
  consultant,
  bind = {},
  created,
  onPatient,
  titleColor = messageTypesColors.DEFAULT,
  onChat,
  isAdmin,
}) => {
  const patientBlock = useMemo(() => {
    const patientName = getUserFullName(patient, true)

    return bind && bind.is_accepted ? (
      <LinkRoute
        to={getPatientUrl(patient.id)}
        defaultStyle="blue"
      >
        {patientName}
      </LinkRoute>
    ) : patientName
  }, [ patient, bind ])

  const text = useMemo(() => {
    if (bind) {
      if (!bind.is_accepted) {
        return (
          <Text>
            Пациент
            {' '}
            {patientBlock}
            {' '}
            отказался принять приглашение о прикреплении
          </Text>
        )
      }

      if (bind.initiator === UserRolesTypes.CONSULTANT && !isAdmin) {
        return (
          <Text>
            Администратор
            {' '}
            {getUserFullName(consultant, true)}
            {' '}
            прикрепил к вам пациента:
            {' '}
            {patientBlock}
          </Text>
        )
      }

      return (
        <Text>
          Пациент
          {' '}
          {patientBlock}
          {' '}
          принял приглашение
        </Text>
      )
    }

    return null
  }, [ bind, consultant, isAdmin, patientBlock ])

  return (
    <div className="notifier-message-content">
      <NotifierMessageText
        className="mb-12"
        color={titleColor}
        text={bind.is_accepted === null
          ? 'Новый пациент' : bind.is_accepted
            ? 'Пациент принял приглашение'
            : 'Пациент отказался'}
        fw="medium"
        size="normalSm"
      />
      <Box className="my-3">
        <div className="mb-2 mr-2">
          {bind.initiator === UserRolesTypes.CONSULTANT && !isAdmin && bind.is_accepted ? (
            <UsersAvatars
              avatars={[{
                avatar: consultant.avatar,
                user: consultant
              }, {
                avatar: patient.avatar,
                user: patient
              }]}
              isVertical
              size="normalSmall"
            />
          ) : (
            <UserAvatar
              size="normalSmall"
              avatar={patient.avatar}
              user={patient}
            />
          )}
        </div>
        <div>
          {text}
          <Text color="grey500" size="normalSm">
            {created}
          </Text>
        </div>
      </Box>
      {bind && bind.is_accepted && (
        <Box gap="12px">
          <Button
            flex="1"
            full
            defaultStyle=""
            title="Смотреть пациента"
            size="small"
            rounded
            onClick={() => onPatient(patient.id)}
          />
          <Button
            flex="1"
            onClick={() => onChat(patient.voximplant_username)}
            full
            isGradient
            title="Чат с пациентом"
            size="small"
            rounded
          />
        </Box>
      )}
    </div>
  )
}

export default NotifierCardPatientInvited
