import Button from 'components/base/Button'
import LinkRoute from 'components/typo/Link'
import Text from 'components/typo/Text'
import UserAvatar from 'components/User/UserAvatar'
import { getUserFullName } from 'helpers/User'
import Box from 'layout/Box'
import { getPatientFolderUrl, getPatientUrl } from 'redux/helpers/PatientHelpers'
import { UserRolesNamesTypes, UserRolesTypes } from 'constants/UserTypes'

const NotificationCardFile = (props) => {
  const {
    data,
    onChat,
    onDocument,
    onClose,
    created,
  } = props

  const {
    user = {}, folder = {}, file_author_upload: author = {}
  } = data

  return (
    <Box gap="24px">
      <Box gap="8px">
        <UserAvatar
          size="normal"
          avatar={user.avatar}
          bgColor="grey150"
          user={user}
        />
        <div>
          { author.role === UserRolesTypes.PATIENT ? (
            <Text>
              Пациент
              {' '}
              <LinkRoute
                to={getPatientUrl(user.id)}
                defaultStyle="blue"
              >
                {getUserFullName(user)}
              </LinkRoute>
              {' '}
              добавил документ
              {' '}
              в папку:
              {' '}
              <LinkRoute
                to={getPatientFolderUrl(user.id, folder.partition)}
                defaultStyle="blue"
              >
                {folder.name}
              </LinkRoute>
            </Text>
          ) : (
            <Text>
              {UserRolesNamesTypes[author.role]}
              {' '}
              {getUserFullName(author.role)}
              {' '}
              загрузил документ пациенту
              {' '}
              <LinkRoute
                to={getPatientUrl(user.id)}
                defaultStyle="blue"
              >
                {getUserFullName(user)}
              </LinkRoute>
              {' '}
              в папку:
              {' '}
              <LinkRoute
                to={getPatientFolderUrl(user.id, folder.partition)}
                defaultStyle="blue"
              >
                {folder.name}
              </LinkRoute>
            </Text>
          )}
          <Text className="mt-1" size="small" color="grey500">
            {created}
          </Text>
        </div>
      </Box>
      <Box className="ml-a" direction="column" gap="8px">
        <Button
          onClick={() => onChat(user.voximplant_username)}
          isGradient
          title="Перейти в чат"
          size="small"
          rounded

        />
        <Button
          defaultStyle=""
          title="Документ"
          size="small"
          rounded
          onClick={() => onDocument(user.id, folder.partition)}
        />
      </Box>
    </Box>
  )
}

export default NotificationCardFile
