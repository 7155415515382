import s from 'styled-components'
import cn from 'classnames'

const getStyles = ({
  $position,
  $top,
  $bottom,
  $left,
  $right,
  $width,
  $minWidth,
  $height,
  $i,
  $opacity,
}) => `
  position: ${$position};
  top: ${$top};
  bottom: ${$bottom};
  left: ${$left};
  right: ${$right};
  width: ${$width};
  min-width: ${$minWidth};
  height: ${$height};
  z-index: ${$i};
  opacity: ${$opacity};
  transition: opacity 0.3s ease-in-out;

  &.--t {
    &-center {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &-center-x {
      left: 50%;
      transform: translateX(-50%);
    }

    &-center-y {
      top: 50%;
      transform: translateY(-50%);
    }

    &-cover {
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }

  &.--hidden {
    opacity: 0;
    pointer-events: none;
    height: 0px;
    width: 0px;
    overflow: hidden;
  }
`

const PositionerContainer = s.div`
  ${getStyles}
`

const Positioner = ({
  children = null,
  className = '',
  top = '',
  bottom = '',
  left = '',
  width = '',
  minWidth = '',
  height = '',
  right = '',
  position = 'absolute',
  type = '',
  opacity = '',
  isHidden = false,
  i = '',
}) => (
  <PositionerContainer
    $position={position}
    $top={top}
    $bottom={bottom}
    $left={left}
    $right={right}
    $width={width}
    $height={height}
    $minWidth={minWidth}
    $i={i}
    $opacity={opacity}
    className={cn(
      'positioner',
      {
        [`--t-${type}`]: type,
        '--hidden': isHidden
      },
      className
    )}
  >
    {children}
  </PositionerContainer>
)

export default Positioner
