import {
  useRef, useEffect, useState, useCallback
} from 'react'
import s from 'styled-components'
import cn from 'classnames'

import gsap from 'services/GsapService'
import useResponsive from 'hooks/useResponsive'

const getStyles = ({
  $ai, $jc, show, padding
}) => `
  position: fixed;
  top: 0;
  right: 0;
  z-index: 100000000;
  display: ${show ? 'block' : 'none'};
  width: 100%;
  height: 100%;

  .inner,
  .backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .inner {
    z-index: 2;
    display: flex;
    justify-content: ${$jc || 'center'};
    align-items: ${$ai || 'center'};
    opacity: 0;
    pointer-events: none;
    box-sizing: border-box;
    ${padding && `padding: ${padding}`};


    &.--dir {
      &-x {
        transform: translate3d(-100%, 0, 0);
      }

      &-y {
        transform: translate3d(0, -100%, 0);
      }
    }

    @media (max-width: 768px) {
      overflow-x: hidden;
      background-color: rgba(0, 0, 0, 1);
    }
  }

  .backdrop {
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.6);
    opacity: 0;
  }
`

const ModalSidebarWrapperContainer = s.div`
  ${getStyles}
`

const ModalSidebarWrapper = (props) => {
  const {
    show = false,
    closeModal = () => {},
    component = null,
    children = null,
    direction = 'x',
    padding = null,
    ai = '',
    jc = '',
    onSuccess = null,
  } = props

  const [ isCanClose, setIsCanClose ] = useState(false)
  const [ isVisibility, setIsVisibility ] = useState(false)

  const { isMobile } = useResponsive()

  const innerRef = useRef(null)
  const backdropRef = useRef(null)

  const handleCloseModal = useCallback(
    (event) => {
      event.stopPropagation() // Stop propagation to prevent the event from reaching parent elements
      if (isCanClose) {
        closeModal(event)
      }
    },
    [ direction, isCanClose ],
  )

  useEffect(() => {
    if (show && !!innerRef.current && !!backdropRef.current) {
      const tl = gsap.timeline()

      setIsCanClose(true)
      setIsVisibility(true)

      document.body.classList.add('noscroll')

      tl.fromTo(backdropRef.current, {
        duration: 0.3,
        alpha: 0,
      }, { alpha: 1 }, '0.3')
        .fromTo(innerRef.current, {
          duration: 0.1,
          alpha: 0,
        }, { alpha: 1 }, 0)
        .fromTo(innerRef.current, {
          duration: 0.4,
          [direction]: '100%',
        }, { [direction]: '0' }, 0.2).timeScale(1.2)
    }

    // eslint-disable-next-line
  }, [show, innerRef.current, backdropRef.current])

  const onHundleCloseModal = useCallback(
    () => {
      if (isCanClose) {
        setIsCanClose(false)
        const tl = gsap.timeline()

        tl.fromTo(backdropRef.current, {
          duration: 0.3,
          alpha: 1,
        }, {
          alpha: 0,
          onComplete() {
            document.body.classList.remove('noscroll')
            setIsVisibility(false)
          },
        }, '0.1').timeScale(1.2)
          .fromTo(innerRef.current, {
            duration: 0.4,
            [direction]: '0',
            alpha: 1,
          }, {
            [direction]: '100%',
            alpha: 0,
          }, '0').timeScale(1.2)

        setIsCanClose(false)
      }
    },
    [ direction, isCanClose ],
  )

  useEffect(() => {
    if (!show) onHundleCloseModal()
  }, [ onHundleCloseModal, show ])

  const renderModal = useCallback((renderProps) => {
    if (!component && !show) return null

    const ModalComponent = component

    return (
      <ModalComponent
        {...renderProps}
        onSuccess={onSuccess}
        closeModal={closeModal}
        isMobile={isMobile}
      />
    )
  }, [ component, isMobile, show ])

  return (
    <ModalSidebarWrapperContainer $jc={jc} $ai={ai} show={isVisibility} padding={padding}>
      <div
        ref={backdropRef}
        role="button"
        tabIndex={0}
        onClick={handleCloseModal}
        className="backdrop"
      />
      <div ref={innerRef} className={cn('inner sidebar-inner', { [`--dir-${direction}`]: direction })}>
        {isVisibility && children}
        {renderModal && renderModal(props)}
      </div>
    </ModalSidebarWrapperContainer>
  )
}

export default ModalSidebarWrapper
