/* eslint-disable default-param-last */

import { INIT_CONTAINER_POSITION } from 'components/Call/call.config'
import { CallActionTypes } from 'redux/Call/CallTypes'

const INITIAL_STATE = {
  currentCall: null,
  currentCallUser: null,
  isConnected: false,
  duration: null,
  isFullscreen: true,
  containerPosition: INIT_CONTAINER_POSITION,
  isMuteAudio: false,
  isMuteVideo: false,
  isLocalVideo: false,
  isRemoteVideo: false,
}

const callReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
  case CallActionTypes.CLEAR_CURRENT_CALL:
    return { ...INITIAL_STATE }

  case CallActionTypes.SET_CURRENT_CALL:
    return {
      ...state,
      currentCall: action.payload,
      isConnected: false,
    }

  case CallActionTypes.SET_CURRENT_CALL_CONNECTED:
    return {
      ...state,
      isConnected: true,
    }

  case CallActionTypes.SET_CURRENT_CALL_USER:
    return {
      ...state,
      currentCallUser: action.payload,
    }

  case CallActionTypes.UPDATE_CALL_DURATION:
    return {
      ...state,
      duration: action.payload,
    }

  case CallActionTypes.TOGGLE_CALL_FULLSCREEN:
    return {
      ...state,
      isFullscreen: !state.isFullscreen,
    }

  case CallActionTypes.TOGGLE_CALL_AUDIO_MUTE:
    return {
      ...state,
      isMuteAudio: action.payload,
    }

  case CallActionTypes.TOGGLE_CALL_VIDEO_MUTE:
    return {
      ...state,
      isRemoteVideo: action.payload,
    }

  case CallActionTypes.SET_CALL_CONTAINER_POSITION:
    return {
      ...state,
      containerPosition: action.payload,
    }

  case CallActionTypes.SET_LOCAL_VIDEO:
    return {
      ...state,
      isLocalVideo: action.payload,
    }

  case CallActionTypes.SET_REMOTE_VIDEO:
    return {
      ...state,
      isRemoteVideo: action.payload,
    }

  default:
    return state
  }
}

export default callReducer
