import { components } from 'react-select'
import ButtonClear from 'components/buttons/ButtonClear'

const FormSelectClearIndicator = (props) => (
  <components.ClearIndicator {...props}>
    <ButtonClear
      defaultStyle="transparentGrey"
      icon="circle"
    />
  </components.ClearIndicator>
)

export default FormSelectClearIndicator
