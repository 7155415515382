export const MedDocumentsType = {
  LICENSE: 'license',
  CERTIFICATE: 'certificate'
}

export const MedDocumentsSelectModel = [
  {
    id: MedDocumentsType.LICENSE,
    title: 'Лицензия'
  },
  {
    id: MedDocumentsType.CERTIFICATE,
    title: 'Сертификат'
  }
]
