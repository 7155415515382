import 'animate.css/animate.min.css'
import {
  ToastContainer, toast, cssTransition
} from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { clearNotifications } from 'redux/Notifications/NotificationsActions'
import {
  useEffect, useRef, useState
} from 'react'

import 'react-toastify/dist/ReactToastify.css'

import { AUTO_CLOSE_TIMER } from 'constants/NotifierTypes'
import ButtonClose from 'components/buttons/ButtonClose'
import SpecialNotification from 'components/notifier/cards/SpecialNotification'

const goRight = cssTransition({
  enter: 'animate__animated animate__fadeInRight',
  exit: 'animate__animated animate__fadeOutUp',
})

const customToastStyle = {
  padding: 0,
  background: 'none',
  boxShadow: 'none',
  border: 'none',
  borderRadius: 0,
  marginTop: '-80px',
  position: 'fixed',
  bottom: -40,
  width: '100%',
}

const Notifier = (props) => {
  const dispatch = useDispatch()
  const notifications = useSelector((state) => state.notifications.list || [])
  const [ notCount, setNotCount ] = useState(0)
  const timeoutRef = useRef(null)

  const handleClearNotifications = () => {
    dispatch(clearNotifications())
    setNotCount(0)
    toast.dismiss()
  }
  useEffect(() => {
    if (notCount === 0) {
      toast.dismiss()
    }
  }, [ notCount ])

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
      timeoutRef.current = null
    }
    if (notifications.length >= 2) {
      if (!toast.isActive('special-notification')) {
        toast(<SpecialNotification onClear={handleClearNotifications} />, {
          toastId: 'special-notification',
          position: 'bottom-right',
          autoClose: false,
          closeButton: false,
          style: customToastStyle,
        })
      }
    }
    else if (notifications.length === 0) {
      if (notCount > notifications.length) {
        toast.dismiss()
      }
    }

    timeoutRef.current = setTimeout(() => {
      setNotCount(notifications.length)
    }, 2000)
  }, [ notifications ])

  return (
    <ToastContainer
      position="bottom-right"
      autoClose={AUTO_CLOSE_TIMER}
      transition={goRight}
      hideProgressBar
      closeOnClick
      pauseOnFocusLoss
      stacked
      pauseOnHover={false}
      draggable={false}
      newestOnTop
      rtl={false}
      closeButton={(
        <ButtonClose
          style={{
            position: 'absolute',
            right: '8px',
            top: '6px'
          }}
          onClick={() => setNotCount((prevState) => prevState - 1)}
        />
      )}
      style={{
        paddingBottom: 30,
        marginBottom: 10
      }}
      {...props}
    />
  )
}

export default Notifier
