import { UserActionTypes } from 'redux/User/UserTypes'
import { AuthActionTypes } from 'redux/Auth/AuthTypes'
import { ChatActionTypes } from 'redux/dashboard/Chat/ChatTypes'

const INITIAL_STATE = {
  profile: null,
  chat: null,
  place: null,
  binds: null,
  isFetching: {
    media: false,
    binds: false,
    submit: false,
  },
  error: null,
}

const userReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
  case UserActionTypes.SET_SUBMITTING:
    return {
      ...state,
      isFetching: {
        ...state.isFetching,
        submit: action.payload,
      },
    }

  case UserActionTypes.SET_FETCHING_USER_BINDS:
    return {
      ...state,
      isFetching: {
        ...state.isFetching,
        binds: action.payload,
      },
    }

  case UserActionTypes.FETCH_USER_BINDS_SUCCESS:
    return {
      ...state,
      binds: action.payload,
    }

  case UserActionTypes.ADD_USER_BIND:
    return {
      ...state,
      binds: state.binds ? [ ...state.binds, action.payload ] : [ action.payload ],
    }

  case UserActionTypes.FETCH_UPDATE_PROFILE_SUCCESS:
    return {
      ...state,
      profile: {
        ...state.profile,
        ...action.payload,
      },
    }

  case UserActionTypes.FETCH_CREATE_PROFILE_COMPANY_SUCCESS:
  case UserActionTypes.FETCH_UPDATE_PROFILE_COMPANY_SUCCESS:
    return {
      ...state,
      profile: {
        ...state.profile,
        company: action.payload,
      },
    }

  case AuthActionTypes.FETCH_LOGIN_SUCCESS:
  case AuthActionTypes.FETCH_GET_USER_SUCCESS:
    return {
      ...state,
      profile: action.payload,
    }

  case AuthActionTypes.FETCH_LOGOUT_SUCCESS:
    return { ...INITIAL_STATE }

  case UserActionTypes.USER_LOGIN_ERROR:
    return {
      ...state,
      error: action.payload,
    }

  case ChatActionTypes.FETCH_GET_CHAT_USER_AUTH_SUCCESS:
  case ChatActionTypes.FETCH_CREATE_CHAT_USER_SUCCESS:
    return {
      ...state,
      chat: action.payload
    }

  case UserActionTypes.SET_USER_PLACE:
    return {
      ...state,
      place: action.payload
    }

  default:
    return state
  }
}

export default userReducer
