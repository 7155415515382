import { useCallback } from 'react'
import { Formik } from 'formik'
import { useDispatch } from 'react-redux'

// components
import { Button } from 'components/base'
import { Form, FormGroup } from 'components/form'
import FormikField from 'components/form/FormikField'

// constants
import { SchemaAddStructureFolder } from 'constants/ValidationSchemas'

const AddStructureFolderForm = ({
  closeModal = () => {},
  parentId = null,
  typeId = null,
  userId = null,
  isSearch = false,
  onAdd = () => {}
}) => {
  const dispatch = useDispatch()

  const handleSubmit = useCallback((values, { setSubmitting }) => {
    function onError () {
      setSubmitting(false)
    }

    dispatch(onAdd({
      title: values.title,
      typeId,
      parentId,
      userId,
      isSearch,
    }))
      .then(closeModal)
      .catch(onError)
  }, [
    isSearch,
    userId,
    typeId,
    parentId,
    onAdd,
    closeModal,
    dispatch,
  ])

  return (
    <Formik
      initialValues={{ title: '' }}
      onSubmit={handleSubmit}
      validationSchema={SchemaAddStructureFolder}
    >
      {(props) => {
        const {
          values,
          dirty,
          isSubmitting,
          handleSubmit,
          isValid,
        } = props

        const formIsValid = dirty && isValid && !isSubmitting

        return (
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <FormikField
                name="title"
                label="Название папки"
                placeholder=""
                autoFocus
                value={values.title}
                labelFluid
                defaultStyle="bordered"
                isAutoFocus
              />
            </FormGroup>
            <FormGroup>
              <Button
                type="submit"
                size="big"
                full
                title="Создать"
                isDisabled={!formIsValid || isSubmitting}
                isFetching={isSubmitting}
                isGradient
              />
            </FormGroup>
          </Form>
        )
      }}
    </Formik>
  )
}

export default AddStructureFolderForm
