import { AnalyticActionTypes } from 'redux/dashboard/Analytic/AnalyticTypes'

const INITIAL_STATE = {
  city: null,
  clinic: null,
}

const analyticReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
  case AnalyticActionTypes.SET_CITY:
    return {
      ...state,
      city: action.payload
    }
  case AnalyticActionTypes.SET_CLINIC:
    return {
      ...state,
      clinic: action.payload
    }
  default:
    return state
  }
}

export default analyticReducer
