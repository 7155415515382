export const AuthActionTypes = {
  SET_FETCHING_GET_AUTH_TOKEN: 'SET_FETCHING_GET_AUTH_TOKEN',
  FETCH_GET_AUTH_TOKEN_SUCCESS: 'FETCH_GET_AUTH_TOKEN_SUCCESS',
  FETCH_GET_AUTH_TOKEN_ERROR: 'FETCH_GET_AUTH_TOKEN_ERROR',

  SET_FETCHING_GET_USER: 'SET_FETCHING_GET_USER',
  FETCH_GET_USER_SUCCESS: 'FETCH_GET_USER_SUCCESS',
  FETCH_GET_USER_ERROR: 'FETCH_GET_USER_ERROR',

  SET_FETCHING_REGISTRATION: 'SET_FETCHING_REGISTRATION',
  FETCH_REGISTRATION_SUCCESS: 'FETCH_REGISTRATION_SUCCESS',
  FETCH_REGISTRATION_ERROR: 'FETCH_REGISTRATION_ERROR',

  SET_FETCHING_LOGIN: 'SET_FETCHING_LOGIN',
  FETCH_LOGIN_SUCCESS: 'FETCH_LOGIN_SUCCESS',
  FETCH_LOGIN_ERROR: 'FETCH_LOGIN_ERROR',

  SET_FETCHING_GET_LOGIN_CODE: 'SET_FETCHING_GET_LOGIN_CODE',
  FETCH_GET_LOGIN_CODE_SUCCESS: 'FETCH_GET_LOGIN_CODE_SUCCESS',
  FETCH_GET_LOGIN_CODE_ERROR: 'FETCH_GET_LOGIN_CODE_ERROR',

  SET_FETCHING_LOGOUT: 'SET_FETCHING_LOGOUT',
  FETCH_LOGOUT_SUCCESS: 'FETCH_LOGOUT_SUCCESS',
  FETCH_LOGOUT_ERROR: 'FETCH_LOGOUT_ERROR',

  SET_FETCHING_CODE_VERIFICATION: 'SET_FETCHING_CODE_VERIFICATION',
  FETCH_CODE_VERIFICATION_SUCCESS: 'FETCH_CODE_VERIFICATION_SUCCESS',
  FETCH_CODE_VERIFICATION_ERROR: 'FETCH_CODE_VERIFICATION_ERROR',

  SET_FETCHING_CHANGE_PASSWORD: 'SET_FETCHING_CHANGE_PASSWORD',
  FETCH_CHANGE_PASSWORD: 'FETCH_CHANGE_PASSWORD',

  SET_IS_NEW_USER: 'SET_IS_NEW_USER',
  SET_IS_404: 'SET_IS_404',
}
