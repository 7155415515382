import ModalSidebarWrapper from 'components/modals/ModalSidebarWrapper'
import ModalAnswerContent from 'components/modals/ModalAnswer/ModalAnswerConent'

const ModalAnswer = ({
  isOpen = true, onClose = () => {}, ...props
}) => (
  <ModalSidebarWrapper
    show={isOpen}
    closeModal={() => onClose()}
    component={ModalAnswerContent}
    direction="y"
    data={
      {
        onClose: () => onClose(),
        ...props
      }
    }
    className="agreeModal"
  />
)

export default ModalAnswer
