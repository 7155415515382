import { AuthActionTypes } from 'redux/Auth/AuthTypes'
import { ClinicRecordActionTypes } from 'redux/dashboard/ClinicRecord/ClinicRecordTypes'

const INITIAL_STATE = {
  list: null,
  offset: 0,
  maxOffset: 0,
  listCount: 0,
  isFetching: false,

  searchList: null,
  isFetchingSearchList: false,
  searchValue: '',

  recordsCountExpired: 0,

  filters: {},

  error: null,
}

const clinicRecordReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
  case AuthActionTypes.FETCH_LOGOUT_SUCCESS:
    return { ...INITIAL_STATE }

  case ClinicRecordActionTypes.FETCH_CLINIC_RECORD_LIST_SUCCESS:
    return {
      ...state,
      list: action.payload
    }

  case ClinicRecordActionTypes.CLEAR_CLINIC_RECORD_LIST:
    return {
      ...state,
      list: null
    }

  case ClinicRecordActionTypes.SET_CLINIC_RECORD_LIST_COUNT:
    return {
      ...state,
      listCount: action.payload
    }

  case ClinicRecordActionTypes.FETCH_UPLOAD_CLINIC_RECORD_LIST_SUCCESS:
    return {
      ...state,
      list: state.list ? [ ...state.list, ...action.payload ] : action.payload,
    }

  case ClinicRecordActionTypes.SET_CLINIC_RECORD_LIST:
    return {
      ...state,
      list: action.payload.results,
      maxOffset: action.payload.count,
      offset: 40,
    }

  case ClinicRecordActionTypes.SET_ONLY_CLINIC_RECORD_LIST:
    return {
      ...state,
      list: action.payload,
    }

  case ClinicRecordActionTypes.ADD_CLINIC_RECORD_LIST:
    return {
      ...state,
      list: state.list.concat(action.payload.results),
      offset: state.offset + 40,
    }

  case ClinicRecordActionTypes.ADD_CLINIC_RECORD_TO_LIST:
    return {
      ...state,
      list: state.list ? [ ...state.list, action.payload ] : state.list,
      listCount: state.list ? state.list.length + 1 : state.listCount,
    }

  case ClinicRecordActionTypes.SET_CLINIC_RECORD_FILTERS:
    return {
      ...state,
      filters: action.payload
    }

  case ClinicRecordActionTypes.SET_CLINIC_RECORD_FILTER:
    return {
      ...state,
      filters: {
        ...state.filters,
        [action.payload.name]: action.payload.value
      }
    }

  case ClinicRecordActionTypes.SET_FETCHING_CLINIC_RECORD_LIST:
    return {
      ...state,
      isFetching: action.payload
    }

  case ClinicRecordActionTypes.SET_FETCHING_CLINIC_RECORD_SEARCH:
    return {
      ...state,
      isFetchingSearchList: action.payload
    }

  case ClinicRecordActionTypes.FETCH_CLINIC_RECORD_SEARCH_SUCCESS:
    return {
      ...state,
      searchList: action.payload
    }

  case ClinicRecordActionTypes.CLEAR_CLINIC_RECORD_SEARCH:
    return {
      ...state,
      searchList: []
    }

  case ClinicRecordActionTypes.SET_CLINIC_RECORD_SEARCH_VALUE:
    return {
      ...state,
      searchValue: action.payload
    }

  case ClinicRecordActionTypes.SET_CLINIC_RECORD_NEW_COUNT:
    return {
      ...state,
      recordsCountExpired: action.payload
    }

  default:
    return state
  }
}

export default clinicRecordReducer
