import { ChatActionTypes } from 'redux/dashboard/Chat/ChatTypes'
import MessengerService from 'services/Voximplant/messenger.service'
import store from 'redux/store/configureStore'

import { TIME_ONLINE_NOTIFICATION } from 'services/Voximplant/messenger.config'
import { getAttachPayloadModel } from 'redux/dashboard/Chat/ChatHelpers'
import { playNotificationAcceptedSound } from 'redux/Notifications/NotificationsHelpers'
import { SETTINGS_TYPES } from 'redux/Notifications/NotificationsReducer'
import { messageTypes } from 'constants/NotifierTypes'
import { logError } from 'utils/utils'
import { updateConversationLastMessage } from './MesengerConversationsActions'
import { uploadS3Files } from '../../Media/MediaActions'
import { setNotification } from '../../System/SystemActions'

export const addMessagesToConversation = (messages) => ({
  type: ChatActionTypes.SET_CURRENT_CONVERSATION_MESSAGES,
  payload: messages
})

export const updateMessageAsRead = (seq) => ({
  type: ChatActionTypes.UPDATE_MESSAGE_AS_READ,
  payload: seq
})

export const chatScrollToBottom = () => ({ type: ChatActionTypes.SCROLLING_START })

export const chatScrollToPos = (value) => ({
  type: ChatActionTypes.SCROLLING_TO_POS,
  payload: value
})

export const markedAsRead = (lastSeq, conversationUuid) => {
  const { currentConversation, conversations = [] } = store.getState().dashboard.chat
  const conversation = currentConversation || conversations.find((c) => c.uuid === conversationUuid) || null

  if (conversation) {
    MessengerService.get().markAsRead(conversation, lastSeq)
      .catch(logError)
  }
}

export const setFetchingGetConversationHistory = (value) => ({
  type: ChatActionTypes.SET_FETCHING_GET_CONVERSATION_HISTORY,
  payload: value
})

export const getConversationHistory = (currentConversation, currentUser, users, currentConversationHistory, lastPos = null) => (dispatch) => {
  dispatch(setFetchingGetConversationHistory(true))

  const lastEvent = currentConversationHistory && currentConversationHistory.length
    ? currentConversation.lastEvent
    : currentConversation.lastSeq

  if (lastEvent !== 0) {
    MessengerService.get().retransmitMessageEvents(currentConversation, lastEvent, !currentConversationHistory.length)
      .then((messageEvents) => {
        const messages = messageEvents.map((e) => {
          e.message.timestamp = e.timestamp
          e.message.seq = e.seq

          if (e.message.sender === currentUser.userId) {
            e.message.user = currentUser
          }
          else {
            e.message.user = users.find((c) => c.userId === e.message.sender)
          }

          // TODO 'll highlight to singular dispatch
          // if one participant read a message, it marked as read
          const arrLastRead = currentConversation.participants
            .map((p) => (p.userId === currentUser.userId ? p.lastRead : 0))

          if (Math.max(...arrLastRead) >= e.seq) {
            e.message.markAsRead = true
          }

          return e.message
        })

        dispatch(addMessagesToConversation(messages))

        if (lastPos) {
          dispatch(chatScrollToPos(lastPos))
        }
        else {
          dispatch(chatScrollToBottom())
        }

        markedAsRead(currentConversation.lastSeq, currentConversation.uuid)
        dispatch(setFetchingGetConversationHistory(false))
      })
      .catch(() => {
        dispatch(setFetchingGetConversationHistory(false))
      })
  }
  else {
    dispatch(setFetchingGetConversationHistory(false))
  }
}

export const setFetchingSendMessage = (value) => ({
  type: ChatActionTypes.SET_FETCHING_SEND_MESSAGE,
  payload: value
})

export const updateMessagesInConversation = (message) => ({
  type: ChatActionTypes.UPDATE_MESSAGES_IN_CONVERSATION,
  payload: message
})

export const addNewMessageUploading = (message) => ({
  type: ChatActionTypes.ADD_NEW_MESSAGE_UPLOADING,
  payload: message
})

export const updateMessagesInConversationUploaded = (message) => ({
  type: ChatActionTypes.UPDATE_MESSAGES_IN_CONVERSATION_UPLOADED,
  payload: message
})

export const fetchSendMessageSuccess = (value) => ({
  type: ChatActionTypes.FETCH_SEND_MESSAGE_SUCCESS,
  payload: value
})

export const fetchSendMessageError = (value) => ({
  type: ChatActionTypes.FETCH_SEND_MESSAGE_ERROR,
  payload: value
})

export const addOnlineStatus = (userId) => ({
  type: ChatActionTypes.ADD_ONLINE_STATUS,
  payload: userId
})

export const deleteOnlineStatus = (userId) => ({
  type: ChatActionTypes.DELETE_ONLINE_STATUS,
  payload: userId
})

export const updateConversationSeq = (conversation, seq) => ({
  type: ChatActionTypes.UPDATE_CURRENT_CONVERSATION_SEQ,
  payload: {
    seq,
    conversation
  }
})

export const fetchSendMessage = (conversation, text = '', payload = [{}]) => (dispatch) => {
  dispatch(setFetchingSendMessage(true))

  return MessengerService.get().sendMessage(conversation, text, payload)
    .then(({ message }) => {
      dispatch(setFetchingSendMessage(false))
      dispatch(updateConversationSeq(conversation, message.seq))
    })
    .catch(() => {
      dispatch(setFetchingSendMessage(false))
      dispatch(fetchSendMessageError())
    })
}

export const sendNewMessage = (currentConversation, message) => (dispatch) => {
  dispatch(addNewMessageUploading(message))
  dispatch(chatScrollToBottom())

  if (message.payload[0] && message.payload[0].attach && message.payload[0].attach.length) {
    return dispatch(uploadS3Files(message.payload[0].attach.map((a) => a.file)))
      .then((uploadResponse) => {
        message.payload[0].attach = message.payload[0].attach.map((a, idx) => {
          const resFile = uploadResponse[idx].file
          return getAttachPayloadModel(a, resFile.id, resFile.url_link)
        })

        return dispatch(fetchSendMessage(currentConversation, message.text, message.payload))
      })
  }

  return dispatch(fetchSendMessage(currentConversation, message.text, message.payload))
}

export const deleteMessage = (context, message) => {
  MessengerService.get().removeMessage(message)
    .catch(logError)
}

export const editMessage = (context, newData) => {
  newData.message.text = newData.newText
  MessengerService.get().updateMessage(newData.message)
    .catch(logError)
}

/**
   * Resolvers for MessengerService listeners
   */

export const onMessageSent = (e) => (dispatch) => {
  const {
    dashboard: {
      chat: {
        user: currentUser = {},
        users = [],
        chatPageIsActive = false,
        currentConversation,
        // conversations = [],
      }
    },
    notifications: { settings }
  } = store.getState()
  e.message.timestamp = e.timestamp
  e.message.seq = e.seq

  if (e.message.sender === currentUser.userId) {
    e.message.user = currentUser
    // const { payload, conversation } = e.message
    // const isCall = payload && payload[0] && payload[0].call
    // let lastSeq = e.seq

    // if (isCall) {
    //   const c = conversations.find((c) => c.uuid === conversation)
    //   const me = c ? c.participants.find((u) => u.userId === currentUser.userId) : null

    //   if (me) {
    //     lastSeq = 1 + me.lastRead
    //   }
    // }

    dispatch(updateMessagesInConversationUploaded(e.message))
    markedAsRead(e.seq, e.message.conversation)
  }
  else {
    e.message.user = users.find((c) => c.userId === e.message.sender)

    if (currentConversation && currentConversation.uuid === e.message.conversation) {
      markedAsRead(e.seq, e.message.conversation)
      dispatch(updateMessagesInConversation(e.message))
    }

    // display chat notification
    if (settings[SETTINGS_TYPES.IS_DISPLAY] && !chatPageIsActive) {
      dispatch(setNotification({
        type: messageTypes.CHAT_MESSAGE,
        item: e,
        autoClose: false
      }))
    }

    // play chat notification sound
    if (settings[SETTINGS_TYPES.IS_VOICE] && (!chatPageIsActive || (document.hidden && chatPageIsActive))) {
      playNotificationAcceptedSound()
    }
  }

  dispatch(updateConversationLastMessage(e))

  if (currentConversation) {
    dispatch(chatScrollToBottom())
  }
}

const onlineTimers = {}

export const onOnlineReceived = (e) => (dispatch) => {
  const { user: currentUser = {} } = store.getState().dashboard.chat

  if (currentUser && e.initiator !== currentUser.userId) {
    if (onlineTimers[e.initiator]) {
      clearTimeout(onlineTimers[e.initiator])
      delete onlineTimers[e.initiator]
    }

    if (e.online) {
      dispatch(addOnlineStatus(e.initiator))
      onlineTimers[e.initiator] = setTimeout(() => dispatch(deleteOnlineStatus(e.initiator)), TIME_ONLINE_NOTIFICATION)
    }
    else {
      dispatch(deleteOnlineStatus(e.initiator))
    }
  }
}

export const onMessageDeleted = (context, evt) => {
  context.commit('deleteMessageFromConversation', evt.message)
}

export const onMessageEdited = (context, e) => {
  e.message.timestamp = e.timestamp
  e.message.seq = e.seq
  if (e.message.sender === context.state.currentUser.userId) {
    e.message.user = context.state.currentUser
  }
  else {
    e.message.user = context.state.users.find((c) => c.userId === e.message.sender)
  }

  context.commit('updateMessagesInConversation', e.message)
}

export const onMessageMarkAsRead = (evt) => (dispatch) => {
  const { user: currentUser = {} } = store.getState().dashboard.chat

  if (evt.initiator !== currentUser.userId) {
    dispatch(updateMessageAsRead(evt.seq))
  }
}
