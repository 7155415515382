import { AuthActionTypes } from 'redux/Auth/AuthTypes'
import { DoctorStructureActionTypes } from 'redux/dashboard/Doctor/DoctorTypes'
import { PatientStructureActionTypes } from 'redux/dashboard/Patient/PatientTypes'
import { StructureMoveTypesActionTypes } from 'redux/dashboard/StructureMove/StructureMoveTypes'

const INITIAL_STATE = {
  structureMove: [],
  isFetchingStructureMove: false,
  isFetchingItemMove: false,
  error: null,
}

const structureMoveReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
  case AuthActionTypes.FETCH_LOGOUT_SUCCESS:
  case StructureMoveTypesActionTypes.CLEAR_STRUCTURE_MOVE:
    return { ...INITIAL_STATE }

  case PatientStructureActionTypes.SET_FETCHING_PATIENT_STRUCTURE_MOVE_FILE:
  case PatientStructureActionTypes.SET_FETCHING_PATIENT_STRUCTURE_MOVE_FOLDER:
  case DoctorStructureActionTypes.SET_FETCHING_DOCTOR_STRUCTURE_MOVE_FILE:
  case DoctorStructureActionTypes.SET_FETCHING_DOCTOR_STRUCTURE_MOVE_FOLDER:
    return {
      ...state,
      isFetchingItemMove: action.payload
    }

  case StructureMoveTypesActionTypes.SET_FETCHING_STRUCTURE_MOVE:
  case PatientStructureActionTypes.SET_FETCHING_PATIENT_STRUCTURE_MOVE:
  case DoctorStructureActionTypes.SET_FETCHING_DOCTOR_STRUCTURE_MOVE:
    return {
      ...state,
      isFetchingStructureMove: action.payload
    }

  case StructureMoveTypesActionTypes.FETCH_STRUCTURE_MOVE_SUCCESS:
  case PatientStructureActionTypes.FETCH_PATIENT_STRUCTURE_MOVE_SUCCESS:
  case DoctorStructureActionTypes.FETCH_DOCTOR_STRUCTURE_MOVE_SUCCESS:
    return {
      ...state,
      structureMove: action.payload,
    }

  default:
    return state
  }
}

export default structureMoveReducer
