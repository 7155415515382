import { combineReducers } from 'redux'
import base from 'redux/dashboard/Patient/PatientBaseReducer'
import consultations from 'redux/dashboard/Patient/PatientConsultations/PatientConsultationsReducer'

const PatientReducer = combineReducers({
  base,
  consultations
})

export default PatientReducer
