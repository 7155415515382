import Text from 'components/typo/Text'

const NotifierMessageText = ({
  text,
  color,
  size,
  fw
}) => (
  <div className="notifier-message-title">
    <Text size={size} fw={fw} color={color || 'black'}>
      {text}
    </Text>
  </div>
)

export default NotifierMessageText
