export const MediaContextTypes = {
  PLAN: 'PLAN',
  TASK: 'TASK',
  PROJECT: 'PROJECT',
  GALLERY: 'GALLERY',
  TASK_PRICE_FILE: 'TASK_ESTIMATE',
  TASK_PAYMENT_FILE: 'TASK_CHECK',
}

export const MediaFileTypes = {
  IMAGE: 'image',
  FILE: 'file',
  VIDEO: 'video',
  AUDIO: 'audio',
}

export const VideoExtTypes = {
  MOV: 'mov',
  MP4: 'mp4',
  AVI: 'avi',
}

export const FullVideoExtTypes = {
  MOV: 'mov',
  MP4: 'mp4',
  AVI: 'avi',
  MPG: 'mpg',
  M4V: 'm4v',
  OGV: 'ogv',
  WMV: 'wmv',
  T3GP: '3gp',
  T3G2: '3g2',
}

export const FullFilesExtTypes = {
  DOC: 'doc',
  DOCX: 'docx',
  KEY: 'key',
  ODT: 'odt',
  PDF: 'pdf',
  XLS: 'xls',
  XLSX: 'xlsx',
  ZIP: 'zip',
}

export const FullImagesExtTypes = {
  GIF: 'gif',
  HEIC: 'heic',
  JPEG: 'jpeg',
  JPG: 'jpg',
  PNG: 'png',
}

export function checkFullImagesType(str) {
  const lowerCaseStr = str.toLowerCase()
  return Object.values(FullImagesExtTypes).some((ext) => lowerCaseStr.includes(ext))
}

export function checkFullFilesType(str) {
  const lowerCaseStr = str.toLowerCase()
  return Object.values(FullFilesExtTypes).some((ext) => lowerCaseStr.includes(ext))
}

export function checkFullVideoType(str) {
  const lowerCaseStr = str.toLowerCase()
  return Object.values(FullVideoExtTypes).some((ext) => lowerCaseStr.includes(ext))
}

export function checkFullCorectFullTypes(str) {
  return checkFullVideoType(str) || checkFullFilesType(str) || checkFullImagesType(str)
}

export function checkOnlyPDFType(str) {
  const lowerCaseStr = str.toLowerCase()
  return lowerCaseStr.includes(FullFilesExtTypes.PDF)
}

export const FilesExtTypes = {
  PDF: 'pdf',
  XLS: 'xls',
  DEFAULT: 'any',
}

export const MediaAcceptTypes = {
  IMAGE: 'image/*',
  VIDEO: 'video/*',
  // VIDEO: 'video/mp4,video/*',
  FILES: '.pdf,.word,.excel,.xls,.txt,.zip',
}

export const MediaVideoExtensions = Object.values(VideoExtTypes)

export const getFileTypeNum = (fullType) => {
  let type = ''
  if (typeof fullType !== 'string' || fullType.length === 0) {
    return MediaFileTypes.FILE
  }

  const [ t, subtype ] = fullType.split('/')

  switch (t) {
  case 'image':
    type = MediaFileTypes.PHOTO
    break
  case 'video':
    type = MediaFileTypes.VIDEO
    break
  case 'application':
    if (subtype === 'x-zip-compressed' || subtype === 'zip') {
      type = FullFilesExtTypes.ZIP
    }
    else {
      type = MediaFileTypes.FILE
    }
    break
  default:
    type = MediaFileTypes.FILE
    break
  }

  return type
}
