import Text from 'components/typo/Text'
import styled from 'styled-components'
import Box from 'layout/Box'
import VideoLazy from 'components/base/VideoLazy'
import ReactHtmlParser from 'react-html-parser'
import ApiService from 'services/ApiService'
import {
  useState, useEffect, useRef
} from 'react'
import { logError } from 'utils/utils'
import { Spinner } from 'components/base'
import { checkCorrectUrl } from 'helpers/System'
import useDimensions from '../../hooks/useDimensions'
import useDimensionsVideo from '../../hooks/useDimensionsVideo'

const KnowledgeBaseEventContainer = styled.div`
  width: 100%;
  .titleImg {
    height: 80px;
    width: 80px;
    border-radius: 10px;
    object-fit: cover;
  }

  .htmlParse {
    p {
      margin-top: 8px;

      &:first-child {
        margin-top: 0;
      }
    }

    h1, h2, h3, h4, h5, h6 {
      margin-top: 16px;

      &:first-child {
        margin-top: 0;
      }
    }

    li {
      margin-left: 16px;
    }
  }
`

const KnowledgeBaseEvent = ({ id }) => {
  const [ data, setData ] = useState(null)
  const [ isError, setIsError ] = useState(false)
  const [ parentRef, dimensions ] = useDimensionsVideo()

  useEffect(() => {
    ApiService.apiCall({
      url: ApiService.paths.knowledgebase.EVENT_BY_ID(id),
      isToken: true
    }).then((response) => {
      setData(response)
    }).catch((error) => {
      setIsError(true)
      logError('Не удалось получить данные из базы знаний', error)
    })
  }, [ id ])
  return (
    <KnowledgeBaseEventContainer ref={parentRef}>
      {data ? (
        <>
          <Text fw="m" fs="20px" className="mb-20" tag="p" size="l" color="blue">
            {data.name}
          </Text>
          <Box className="mb-6" flex="1">
            {data.image && (
              <img
                src={checkCorrectUrl(data.image)}
                className="titleImg mr-6"
                alt=""
              />
            )}
            {data.task_description && (
              <div>
                <Text fw="m" fs="18px" className="mb-2" tag="p" size="l">
                  Краткое описание:
                </Text>
                <div className="htmlParse">{ReactHtmlParser(data.task_description)}</div>
              </div>
            )}
          </Box>
          {data.description && (
            <div className="mb-6">
              <Text fw="m" fs="18px" className="mb-2" tag="p" size="l">
                Описание:
              </Text>
              <Text>{ReactHtmlParser(data.description)}</Text>
            </div>
          )}
          {data.requirements && (
            <div className="mb-6">
              <Text fw="m" fs="18px" className="mb-2" tag="p" size="l">
                Показания:
              </Text>
              <div className="htmlParse">{ReactHtmlParser(data.requirements)}</div>
            </div>
          )}
          {data.execution_description && (
            <div className="mb-6">
              <Text fw="m" fs="18px" className="mb-2" tag="p" size="l">
                Заметки по выполнению:
              </Text>
              <div className="htmlParse">{ReactHtmlParser(data.execution_description)}</div>
            </div>
          )}
          {data.contraindications && (
            <div className="mb-6">
              <Text fw="m" fs="18px" className="mb-2" tag="p" size="l">
                Противопоказания:
              </Text>
              <div className="htmlParse">{ReactHtmlParser(data.contraindications)}</div>
            </div>
          )}
          {data.level && (
            <div className="mb-6">
              <Text fw="m" fs="18px" className="mb-2" tag="p" size="l">
                Уровень:
              </Text>
              <div className="htmlParse">{ReactHtmlParser(data.level)}</div>
            </div>
          )}
          {data.intensity && (
            <div className="mb-6">
              <Text fw="m" fs="18px" className="mb-2" tag="p" size="l">
                Интенсивность:
              </Text>
              <div className="htmlParse">{ReactHtmlParser(data.intensity)}</div>
            </div>
          )}
          {data.video && (
            <div className="mb-6">
              <Text fw="m" fs="18px" className="mb-2" tag="p" size="l">
                Видео:
              </Text>
              <VideoLazy height={`${dimensions.height}px`} poster={data.preview && checkCorrectUrl(data.preview)} src={data.video} width="100%" />
            </div>
          )}
        </>
      ) : isError ? <div>Ошибка, страница не доступна</div> : <Spinner />}
    </KnowledgeBaseEventContainer>
  )
}

export default KnowledgeBaseEvent
