import s from 'styled-components'
import cn from 'classnames'

const styles = ({
  p,
  flex,
  column,
  display,
  center,
  height = 'auto',
  position = null,
  zIndex = null,
  jc = '',
  theme,
}) => `
    position: ${position || ''};
    display: ${display};
    z-index: ${zIndex || ''};
    width: 100%;
    height: ${height};
    padding: ${p ? theme.paddings[p] || p : `20px ${theme.paddings.normal} 32px`};
    flex: ${flex ? 1 : 'unset'};
    flex-direction: ${column ? 'column' : ''};
    align-items: ${column ? 'center' : ''};
    justify-content: ${jc || (column ? 'center' : '')};
    text-align: ${center ? 'center' : 'left'};

    &.--overflow-h {
      overflow-y: hidden;
    }

    &.--overflow-a {
      overflow-y: auto;
    }
  `

const ModalContentContainer = s.div`
  ${(props) => styles(props)}
`

const ModalContent = ({
  className,
  children,
  display = 'block',
  overflowHidden = false,
  overflowAuto = false,
  ...otherProps
}) => {
  const classes = cn('w-modal-content', {
    '--overflow-h': overflowHidden,
    '--overflow-a': overflowAuto
  }, className)

  const props = {
    ...otherProps,
    className: classes,
    display,
  }

  return (
    <ModalContentContainer className={classes} {...props}>
      {children}
    </ModalContentContainer>
  )
}

export default ModalContent
