import { sizeUnits } from 'constants/UnitsTypes'

const convertBtoGB = (b) => (b / 1024 / 1024 / 1024)

export const convertBtoMB = (b) => (b / 1024 / 1024)

const convertBtoKB = (b) => (b / 1024)

export const formatBitesSize = (b) => {
  let unit = sizeUnits.B
  let value = Number(b)

  if (!b || typeof value !== 'number') {
    return {
      value: 0,
      unit
    }
  }

  if (convertBtoGB(value) > 1) {
    unit = sizeUnits.GB
    value = convertBtoGB(value)
  }
  else if (convertBtoMB(value) > 0.1) {
    unit = sizeUnits.MB
    value = convertBtoMB(value)
  }
  else if (convertBtoKB(value) > 0.1) {
    unit = sizeUnits.KB
    value = convertBtoKB(value)
  }

  return {
    value: Number(value.toFixed(1)),
    unit,
  }
}
