export const CountriesActionTypes = {
  SET_FETCHING_COUNTRIES: 'SET_FETCHING_COUNTRIES',
  FETCH_COUNTRIES_SUCCESS: 'FETCH_COUNTRIES_SUCCESS',
  FETCH_COUNTRIES_ERROR: 'FETCH_COUNTRIES_ERROR',
}

export const CitiesActionTypes = {
  SET_FETCHING_CITIES: 'SET_FETCHING_CITIES',
  FETCH_CITIES_SUCCESS: 'FETCH_CITIES_SUCCESS',
  FETCH_CITIES_ERROR: 'FETCH_CITIES_ERROR',

  SET_FETCHING_PATIENT_CITIES: 'SET_FETCHING_PATIENT_CITIES',
  FETCH_PATIENT_CITIES_SUCCESS: 'FETCH_PATIENT_CITIES_SUCCESS',
  FETCH_PATIENT_CITIES_ERROR: 'FETCH_PATIENT_CITIES_ERROR',

  SET_FETCHING_CITY: 'SET_FETCHING_CITY',
  FETCH_CITY_SUCCESS: 'FETCH_CITY_SUCCESS',
  FETCH_CITY_ERROR: 'FETCH_CITY_ERROR',
}
