import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { Formik } from 'formik'
import { Button } from 'components/base'
import { Form, FormGroup } from 'components/form'
import Text from 'components/typo/Text'
import Link from 'components/typo/Link'
import Title from 'components/typo/Title'
import { Box } from 'layout/index'
import FormikField from 'components/form/FormikField'
import { fetchLogin } from 'redux/Auth/AuthActions'
import history from 'history/configureHistory'
import { SchemaLogin } from '../../constants/ValidationSchemas'
import { PAGE_LOGIN_RESET_CHANGE, SUPPORT_EMAIL } from '../../constants/PathsTypes'

const Login = () => {
  const dispatch = useDispatch()
  const handleSubmit = useCallback((values, { setSubmitting }) => {
    dispatch(fetchLogin(values))
      .then(() => {
        setSubmitting(false)
      })
      .catch(() => setSubmitting(false))
  }, [ dispatch ])
  return (
    <Box p="50px 60px" width="100%" display="block">
      <Formik
        initialValues={{
          username: '',
          password: ''
        }}
        onSubmit={handleSubmit}
        validationSchema={SchemaLogin}
      >
        {(props) => {
          const {
            values,
            dirty,
            isSubmitting,
            handleSubmit,
            isValid,
          } = props
          const formIsValid = dirty && isValid && !isSubmitting
          return (
            <Form onSubmit={handleSubmit}>
              <Title
                textAlign="center"
                size="big"
                className="mb-4"
              >
                Войти в кабинет
              </Title>
              <FormGroup>
                <FormikField
                  name="username"
                  label="Email"
                  type="text"
                  placeholder=""
                  value={values.username}
                  labelFluid
                  defaultStyle="bordered"
                  isFirstUppercase={false}
                />
              </FormGroup>
              <FormGroup>
                <FormikField
                  isPassword
                  defaultStyle="bordered"
                  name="password"
                  type="password"
                  label="Пароль"
                  placeholder=""
                  value={values.password}
                  labelFluid
                  fieldType="password"
                  isFirstUppercase={false}
                />
              </FormGroup>
              <Text
                className="mb-4 mt-4"
                color="blue"
                cursor="pointer"
                onClick={() => {
                  history.push(PAGE_LOGIN_RESET_CHANGE)
                }}
              >
                Забыли пароль?
              </Text>
              <FormGroup>
                <Button
                  type="submit"
                  size="big"
                  full
                  title="Войти"
                  isDisabled={!formIsValid || isSubmitting}
                  isFetching={isSubmitting}
                  isGradient
                />
              </FormGroup>
              <Text
                color="grey500"
                className="mt-6"
                textAlign="center"
              >
                Проблемы?
                {' '}
                <Link
                  isBrowser
                  color="dark"
                  href={`mailto:${SUPPORT_EMAIL}`}
                >
                  Написать в поддержку
                </Link>
              </Text>
            </Form>
          )
        }}
      </Formik>
    </Box>
  )
}
export default Login
