export const cutString = (string, max) => (typeof string === 'string' && string.length > max
  ? `${string.slice(0, max)}...`
  : string)

export const strToUpCase = (string) => string.toUpperCase()

export const strToLowerCase = (string) => string.toLowerCase()

export const firstLetterUp = (string) => {
  if (typeof string !== 'string' || !string.length) {
    return string
  }

  return `${strToUpCase(string.slice(0, 1))}${string.slice(1)}`
}

export const firstLetterDown = (string) => `${strToLowerCase(string.slice(0, 1))}${string.slice(1)}`

export const isValueInString = (string, value) => strToLowerCase(string).indexOf(strToLowerCase(value)) >= 0
