import cn from 'classnames'
import s from 'styled-components'

import Spinner from 'components/base/Spinner'

const styles = ({
  $size,
  theme,
  nopadding,
  defaultStyle,
  minWidth = null,
  maxWidth = null,
  tt = null,
  fs = null,
  lh = null,
  fw = null,
  caps = false,
  $jc,
  $isReversed,
  p = '',
  height = '',
  $isIcon = '',
  $flex = '',
  $br = '',
  color = '',
  $bdFilter = '',
}) => {
  const orderFirst = $isReversed ? 2 : 1
  const orderLast = $isReversed ? 1 : 2
  const btnTheme = theme.components.button
  const sizes = btnTheme.sizes[$size] || btnTheme.sizes.normal
  const sizesMobType = $size === 'big' ? 'normal' : $size
  const sizesMob = btnTheme.sizes[sizesMobType] || btnTheme.sizes.normal
  const styles = btnTheme.styles[defaultStyle] || btnTheme.styles.acsent

  return `
    position: relative;
    display: inline-flex;
    height: ${height || nopadding ? 'auto' : sizes.height || ''};
    min-width: ${sizes.height};
    padding: ${nopadding ? '0px' : p || sizes.padding};
    align-items: center;
    justify-content: ${$jc};
    white-space: nowrap;
    border: ${styles.border || 'unset'};
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
    transition-timing-function: ease-in-out;
    background-color: ${styles.bg};
    background-image: ${styles.gradient || ''};
    text-transform: ${tt || sizes.tt || 'initial'};
    color: ${color ? theme.colors[color] : styles.color};
    font-size: ${fs || sizes.fs};
    line-height: ${lh || sizes.lh || ''};
    font-weight: ${fw ? theme.fw[fw] : styles.fw || sizes.fw};
    border-radius: ${$br || sizes.br};
    letter-spacing: 0.5px;
    font-family: ${theme.ff.main};
    user-select: unset;
    min-width: ${minWidth ? btnTheme.minWidths[minWidth] || minWidth : ''};
    max-width: ${maxWidth || ''};
    pointer-events: all;
    flex-shrink: 0;
    flex: ${$flex || ''};
    ${caps ? 'text-transform: uppercase;' : ''}
    backdrop-filter: ${$bdFilter};

    &:disabled {
      background-color: ${styles.disabledBg || ''};
      background-image: unset;
      color: ${styles.disabledColor || ''};
      opacity: ${styles.disabledBg ? '' : 0.4};
      pointer-events: none;

      &.--opacity-f {
        opacity: 1;
      }
    }

    @media (max-width: 640px) {
      height: ${sizesMob.height};
      padding: ${nopadding ? 'unset' : sizes.padding};
      font-size: ${sizesMob.fs};
      font-weight: ${fw ? theme.fw[fw] : styles.fw || sizesMob.fw};
      border-radius: ${$br || sizesMob.br};
    }

    @media (max-width: 360px) {
      font-size: 14px;
    }

    .icon-wrapper {
      margin-right: ${!$isIcon && $isReversed ? sizes.offset : 0};
      margin-left: ${!$isIcon && $isReversed ? 0 : sizes.offset};

      svg {
        fill: currentColor;
        stroke: currentColor;
      }
    }

    .react-spinner-material {
      border-color: currentColor !important;
    }

    & > *:first-child {
      order: ${orderFirst};
    }

    & > *:last-child {
      order: ${orderLast};
    }

    & > * {
      white-space: nowrap !important;
    }

    &.--hovered,
    &:hover:not(.--no-hover) {
      background-color: ${styles.bgHover || styles.bg};
      color: ${styles.colorHover || styles.color};
    }

    &.--full {
      width: 100%;
    }

    &.--fetching {
      opacity: 1 !important;
    }

    &.--no-pointers {
      pointer-events: none;
    }

    &.--icon {
      padding: 10px;

      .icon-wrapper {
        margin: 0 !important;
      }

      svg {
        fill: currentColor;
        stroke: currentColor;
        margin: auto;
      }
    }

    &.--rounded {
      border-radius: 100px;
    }

    &.--grad {
      background: linear-gradient(
        90deg,
        #00A5EE 0%,
        #14D7D1 40%,
        #009FE5 60%,
        #13CDC8 100%
      );
      transition: background 1s cubic-bezier(0, 0.64, 0.17, 0.99);
      background-size: 250% 1px;
      background-position: 0%;

      &:hover {
        background-position: 100%;
      }

      &:disabled {
        background: linear-gradient(
          90deg,
          #99DBF8 0%,
          #A1EFED 100%
        );
        background-size: 100%;
        background-position: 0%;
      };
    }
  `
}

const ButtonContainer = s.button`
  ${(props) => styles(props)}
`

const Button = ({
  title = '',
  type = 'button',
  full = false,
  isDisabled = null,
  onClick = null,
  className = '',
  children = null,
  isFetching = false,
  noPointers = false,
  isHovered = false,
  isReversed = false,
  defaultStyle = 'acsent',
  opacityFull = false,
  hoverStop = false,
  icon: Icon = null,
  size = 'normal',
  isIcon = false,
  isGradient = false,
  bdFilter = null,
  flex,
  br = '',
  jc = 'center',
  rounded,
  ...otherProps
}) => {
  const titleStyles = { visibility: isFetching ? 'hidden' : null }
  const spinnerSize = size === 'big' ? 'normal' : 'small'

  return (
    <ButtonContainer
      disabled={isDisabled || isFetching}
      onClick={onClick}
      type={type}
      $isReversed={isReversed}
      $size={size}
      $jc={jc}
      $br={br}
      $flex={flex}
      $bdFilter={bdFilter}
      isTitle={!!title}
      className={cn(
        'button',
        {
          [`--full`]: full,
          '--no-pointers': noPointers,
          '--fetching': isFetching,
          '--hovered': isHovered,
          '--opacity-f': opacityFull,
          '--no-hover': hoverStop,
          '--icon': isIcon,
          '--grad': isGradient,
          '--rounded': rounded,
        },
        className
      )}
      defaultStyle={defaultStyle}
      {...otherProps}
    >
      {isFetching && (
        <Spinner
          size={spinnerSize}
          radius={20}
        />
      )}
      {title && !children && (
        <span style={titleStyles}>
          {title}
        </span>
      )}
      {Icon && (
        <span className="icon-wrapper" style={titleStyles}>
          <Icon />
        </span>
      )}
      {children}
    </ButtonContainer>
  )
}

export default Button
