import {
  PAGE_DASHBOARD_REFERENCE_ANALYSES,
  PAGE_DASHBOARD_REFERENCE_ANALYSES_EDIT,
  PAGE_DASHBOARD_REFERENCE_EXERCISES,
  PAGE_DASHBOARD_REFERENCE_EXERCISES_EDIT,
  PAGE_DASHBOARD_REFERENCE_MANIPULATION,
  PAGE_DASHBOARD_REFERENCE_MANIPULATION_EDIT,
  PAGE_DASHBOARD_REFERENCE_MEDICINE,
  PAGE_DASHBOARD_REFERENCE_MEDICINE_EDIT,
  PAGE_DASHBOARD_REFERENCE_PROCEDURE,
  PAGE_DASHBOARD_REFERENCE_PROCEDURE_EDIT,
  PAGE_DASHBOARD_REFERENCE_RESEARCHES, PAGE_DASHBOARD_REFERENCE_RESEARCHES_EDIT
} from 'constants/PathsTypes'

export const ReferenceTypes = {
  SET_FETCHING_REFERENCE_LIST: 'SET_FETCHING_REFERENCE_LIST',
  FETCH_REFERENCE_LIST_SUCCESS: 'FETCH_REFERENCE_LIST_SUCCESS',
  FETCH_REFERENCE_LIST_ERROR: 'FETCH_REFERENCE_LIST_ERROR',
  CLEAR_REFERENCE_LIST: 'CLEAR_REFERENCE_LIST',
  SET_REFERENCE_LIST_LAST_SEARCH: 'SET_REFERENCE_LIST_LAST_SEARCH',
  SET_REFERENCE_LIST_COUNT: 'SET_REFERENCE_LIST_COUNT',
  ADD_REFERENCE_LIST: 'ADD_REFERENCE_LIST',
  DELETE_REFERENCE_LIST_ITEM: 'DELETE_REFERENCE_LIST_ITEM',

  SET_FETCHING_REFERENCE_SEARCH: 'SET_FETCHING_REFERENCE_SEARCH',
  FETCH_REFERENCE_SEARCH_SUCCESS: 'FETCH_REFERENCE_SEARCH_SUCCESS',
  FETCH_REFERENCE_SEARCH_ERROR: 'FETCH_REFERENCE_SEARCH_ERROR',
  CLEAR_REFERENCE_SEARCH: 'CLEAR_REFERENCE_SEARCH',

  SET_ANY_REFERENCE: 'SET_ANY_REFERENCE',
  SET_REFERENCE_LIST: 'SET_REFERENCE_LIST',

  SET_REFERENCE_HAS_CREATED: 'SET_REFERENCE_HAS_CREATED',
  SET_REFERENCE_INDEX_LAST_CREATED: 'SET_REFERENCE_INDEX_LAST_CREATED',

  SET_REFERENCE_OFFSET: 'SET_REFERENCE_OFFSET',

  SET_REFERENCE_SEARCH_VALUE: 'SET_REFERENCE_SEARCH_VALUE',
}

export const ReferenceEditTypes = {
  ADD: 'add',
  CHANGE: 'change',
}

export const ReferencePageType = {
  REFERENCE_MANIPULATION: {
    name: 'REFERENCE_MANIPULATION',
    url: {
      knowledgebase: 'MANIPULATION',
      base: 'manipulation',
    },
    paths: {
      main: PAGE_DASHBOARD_REFERENCE_MANIPULATION,
      edit: PAGE_DASHBOARD_REFERENCE_MANIPULATION_EDIT,
    },
    texts: {
      titleDel: 'Удалить манипуляцию',
      titleAdd: 'Добавить манипуляцию',
      name: 'Манипуляция',
      pluralName: 'Малипуляции',
    }
  },
  REFERENCE_EXERCISES: {
    name: 'REFERENCE_EXERCISES',
    url: {
      knowledgebase: 'EXERCISE',
      base: 'exercises',
    },
    paths: {
      main: PAGE_DASHBOARD_REFERENCE_EXERCISES,
      edit: PAGE_DASHBOARD_REFERENCE_EXERCISES_EDIT,
    },
    texts: {
      titleDel: 'Удалить упражнение',
      titleAdd: 'Добавить упражнение',
      name: 'Упражнение',
      pluralName: 'Упражнении'
    }
  },
  REFERENCE_ANALYSES: {
    name: 'REFERENCE_ANALYSES',
    url: {
      knowledgebase: 'ANALYSIS',
      base: 'analyses',
    },
    paths: {
      main: PAGE_DASHBOARD_REFERENCE_ANALYSES,
      edit: PAGE_DASHBOARD_REFERENCE_ANALYSES_EDIT,
    },
    texts: {
      titleDel: 'Удалить анализ',
      titleAdd: 'Добавить анализ',
      name: 'Анализ',
      pluralName: 'Анализы'
    }
  },
  REFERENCE_MEDICINES: {
    name: 'REFERENCE_MEDICINES',
    url: {
      knowledgebase: 'MEDICINE',
      base: 'medicines',
    },
    paths: {
      main: PAGE_DASHBOARD_REFERENCE_MEDICINE,
      edit: PAGE_DASHBOARD_REFERENCE_MEDICINE_EDIT,
    },
    texts: {
      titleDel: 'Удалить лекарство',
      titleAdd: 'Добавить лекарство',
      name: 'Лекарство',
      pluralName: 'Лекарства'
    }
  },
  REFERENCE_PROCEDURES: {
    name: 'REFERENCE_PROCEDURES',
    url: {
      knowledgebase: 'PROCEDURE',
      base: 'procedures',
    },
    paths: {
      main: PAGE_DASHBOARD_REFERENCE_PROCEDURE,
      edit: PAGE_DASHBOARD_REFERENCE_PROCEDURE_EDIT,
    },
    texts: {
      titleDel: 'Удалить процедуру',
      titleAdd: 'Добавить процедуру',
      name: 'Процедура',
      pluralName: 'Процедуры'
    }
  },
  REFERENCE_RESEARCHES: {
    name: 'REFERENCE_RESEARCHES',
    url: {
      knowledgebase: 'RESEARCH',
      base: 'research',
    },
    paths: {
      main: PAGE_DASHBOARD_REFERENCE_RESEARCHES,
      edit: PAGE_DASHBOARD_REFERENCE_RESEARCHES_EDIT,
    },
    texts: {
      titleDel: 'Удалить иследование',
      titleAdd: 'Добавить иследование',
      name: 'Иследование',
      pluralName: 'Иследовании'
    }
  }
}
