export const KnowledgeBaseActionTypes = {
  SET_EXERCISE: 'SET_EXERCISE',
  SET_ANALYSIS: 'SET_ANALYSIS',
  SET_MEDICINE: 'SET_MEDICINE',
  SET_PROCEDURE: 'SET_PROCEDURE',
  SET_MANIPULATION: 'SET_MANIPULATION',
  SET_RESEARCH: 'SET_RESEARCH',
  SET_EXTRATASK: 'SET_EXTRATASK',
  SET_EVENT: 'SET_EVENT',
  SET_INSTRUCTION_DOCTOR: 'SET_INSTRUCTION_DOCTOR',
  SET_INSTRUCTION_ADMIN: 'SET_INSTRUCTION_ADMIN',
  SET_INSTRUCTION_CLINIC_SUPERVISOR: 'SET_INSTRUCTION_CLINIC_SUPERVISOR',
  SET_SPECIALIZATION: 'SET_SPECIALIZATION',
  ADD_EXERCISE: 'ADD_EXERCISE',
  ADD_ANALYSIS: 'ADD_ANALYSIS',
  ADD_MEDICINE: 'ADD_MEDICINE',
  ADD_PROCEDURE: 'ADD_PROCEDURE',
  ADD_MANIPULATION: 'ADD_MANIPULATION',
  ADD_RESEARCH: 'ADD_RESEARCH',
  ADD_EXTRATASK: 'ADD_EXTRATASK',
  ADD_EVENT: 'ADD_EVENT',
  ADD_INSTRUCTION_DOCTOR: 'ADD_INSTRUCTION_DOCTOR',
  ADD_INSTRUCTION_ADMIN: 'ADD_INSTRUCTION_ADMIN',
  ADD_INSTRUCTION_CLINIC_SUPERVISOR: 'ADD_INSTRUCTION_CLINIC_SUPERVISOR',
  ADD_SPECIALIZATION: 'ADD_SPECIALIZATION',
}
