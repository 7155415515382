import s from 'styled-components'

import Text from 'components/typo/Text'

const styled = ({
  theme,
  $tt,
  $isLight
}) => `
  padding: 4px 10px;
  background-color: ${$isLight ? 'rgba(255, 255, 255, 0.16)' : 'rgba(0, 0, 0, 0.32)'};
  box-shadow: 0px 2px 50px rgba(0, 0, 0, 0.24);
  backdrop-filter: blur(24px);
  border-radius: 100px;
  color: ${theme.colors.white};
  line-height: 1;
  text-transform: ${$tt};
`

const CardLabelContainer = s.div`
  ${styled}
`

const CardLabel = ({
  title = '',
  tt,
  isLight = false,
}) => (
  <CardLabelContainer $tt={tt} $isLight={isLight}>
    <Text size="xs">{title}</Text>
  </CardLabelContainer>
)

export default CardLabel
