import { useMemo } from 'react'

import Box from 'layout/Box'
import Text from 'components/typo/Text'
import IconFile from 'components/icons/IconFile'

import { getMediaExt } from 'redux/Media/MediaHelpers'

const CardFileIconTitle = ({
  filename = '',
  title,
  isAlt = false,
  isBig = false,
  isTitle = true,
  isElipsed = true,
}) => {
  const fileExt = useMemo(() => getMediaExt(filename), [ filename ])
  const fileTitle = title ? `${title}.${fileExt}` : filename

  const icon = useMemo(() => <IconFile ext={fileExt} />, [ fileExt ])

  return (
    <Box ai="center">
      {icon}
      {isTitle && (
        <Text
          fw={isBig ? 'm' : 'n'}
          size={isBig ? 'normalBig' : 'normal'}
          className="ml-2"
          isElipsed={isElipsed}
          alt={isAlt ? fileTitle : ''}
          isBreakAll={!isElipsed}
        >
          {fileTitle}
        </Text>
      )}
    </Box>
  )
}

export default CardFileIconTitle
