import s from 'styled-components'
import cn from 'classnames'
import { theme } from 'theme/index'

const getStyles = ({
  $p,
  $minHeight,
  $hoverColor,
  $bgColor,
}) => `
  padding: ${$p ? theme.paddings[$p] || $p : ''};
  min-height: ${$minHeight};
  background-color: ${theme.colors[$bgColor] || $bgColor};

  &.--hover {
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;

    &.active,
    &:hover {
      background-color: ${theme.colors[$hoverColor] || $hoverColor};
    }
  }
`

const ListItemContainer = s.div`
  ${getStyles}
`

const ListItem = ({
  children = null,
  p = null,
  minHeight = '',
  hoverColor = '',
  bgColor = '',
  isActive = false,
  className = '',
}) => (
  <ListItemContainer
    $hoverColor={hoverColor}
    $p={p}
    $bgColor={bgColor}
    $minHeight={minHeight}
    className={cn('l-item', {
      '--hover': hoverColor || isActive,
      active: isActive
    }, className)}
  >
    {children}
  </ListItemContainer>
)

export default ListItem
