/* eslint-disable no-promise-executor-return */
import StorageService from 'services/StorageService'
import store from 'redux/store/configureStore'

import { UserActionTypes } from 'redux/User/UserTypes'
import ApiService from 'services/ApiService'
import { fetchGetUser } from 'redux/Auth/AuthActions'

export const getUserSession = (callback) => {
  const token = StorageService.getToken()

  if (token) {
    store.dispatch(fetchGetUser(token))
      .then((user) => callback(user))
      .catch(() => callback(null))
  }
  else {
    callback(null)
  }
}

export const setFetchingUpdateProfile = (value) => ({
  type: UserActionTypes.SET_FETCHING_UPDATE_PROFILE,
  payload: value,
})

export const fetchUpdateProfileError = (value) => ({
  type: UserActionTypes.FETCH_UPDATE_PROFILE_ERROR,
  payload: value,
})

export const fetchUpdateProfileSuccess = (value) => ({
  type: UserActionTypes.FETCH_UPDATE_PROFILE_SUCCESS,
  payload: value,
})

export const setUserPlace = (value) => ({
  type: UserActionTypes.SET_USER_PLACE,
  payload: value,
})

export const fetchUpdateUserProfile = (data) => (dispatch) => {
  dispatch(setFetchingUpdateProfile(true))

  return new Promise((res, rej) => ApiService.apiCall({
    method: 'PATCH',
    url: ApiService.paths.user.UPDATE_MY_PROFILE,
    data,
    isToken: true,
  })
    .then((response) => {
      dispatch(fetchUpdateProfileSuccess(response))
      dispatch(setFetchingUpdateProfile(false))
      return res(response)
    })
    .catch((error) => {
      dispatch(setFetchingUpdateProfile(false))
      dispatch(fetchUpdateProfileError(false))
      return rej(error)
    }))
}

const setFetchingUserBinds = (value) => ({
  type: UserActionTypes.SET_FETCHING_USER_BINDS,
  payload: value,
})

const fetchUserBindsSuccess = (binds) => ({
  type: UserActionTypes.FETCH_USER_BINDS_SUCCESS,
  payload: binds,
})

export const addUserBind = (bind) => ({
  type: UserActionTypes.ADD_USER_BIND,
  payload: bind,
})

const fetchUserBindsError = () => ({ type: UserActionTypes.FETCH_USER_BINDS_ERROR })

export const fetchUserBinds = ({ patientId }) => (dispatch) => {
  dispatch(setFetchingUserBinds(true))

  return new Promise((res, rej) => ApiService.apiCall({
    url: `${ApiService.paths.user.BINDS}?patient=${patientId}`,
    isToken: true,
  })
    .then((response) => {
      dispatch(setFetchingUserBinds(false))
      dispatch(fetchUserBindsSuccess(response.results))
      return res(response)
    })
    .catch((error) => {
      dispatch(setFetchingUserBinds(false))
      dispatch(fetchUserBindsError())
      return rej(error)
    }))
}
