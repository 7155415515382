import { combineReducers } from 'redux'

import articles from 'redux/dashboard/Articles/ArticelsReducer'
import clients from './Clients/ClientsReducer'
import consultants from './Consultants/ConsultantsReducer'
import call from '../Call/CallReducer'
import chat from './Chat/ChatReducer'
import diarys from '../Diarys/DiarysReducer'
import doctor from './Doctor/DoctorReducer'
import doctors from './Doctors/DoctorsReducer'
import administrators from './Administrators/AdministratorsReducer'
import documents from './Documents/DocumentsReducer'
import patient from './Patient/PatientReducer'
import patients from './Patients/PatientsReducer'
import structureMove from './StructureMove/StructureMoveReducer'
import knowledgeBase from './KnowledgeBase/KnowledgeBaseReducer'
import myCourses from './MyCourses/MyCoursesReducer'
import newsReducer from './News/NewsReducer'
import analytic from './Analytic/rootAnalyticReducer'
import clinicRecord from './ClinicRecord/ClinicRecordReducer'
import clinic from './Clinic/ClinicReducer'
import reference from './Reference/ReferenceReducer'

const rootDashboardReducer = combineReducers({
  chat,
  call,
  clients,
  consultants,
  diarys,
  doctor,
  doctors,
  administrators,
  documents,
  patient,
  patients,
  structureMove,
  knowledgeBase,
  myCourses,
  newsReducer,
  analytic,
  clinicRecord,
  clinic,
  reference,
  articles
})

export default rootDashboardReducer
