export const ChatActionTypes = {
  SET_CHAT_MESSAGE: 'SET_CHAT_MESSAGE',
  ADD_CHAT_MESSAGE: 'ADD_CHAT_MESSAGE',
  SET_ACTIVE_CHAT_ID: 'SET_ACTIVE_CHAT_ID',
  SET_ACTIVE_USERS_TYPE: 'SET_ACTIVE_USERS_TYPE',
  SET_CHAT_PAGE_ACTIVE: 'SET_CHAT_PAGE_ACTIVE',

  SET_FETCHING_GET_CHAT_USER_AUTH: 'SET_FETCHING_GET_CHAT_USER_AUTH',
  FETCH_GET_CHAT_USER_AUTH_ERROR: 'FETCH_GET_CHAT_USER_AUTH_ERROR',
  FETCH_GET_CHAT_USER_AUTH_SUCCESS: 'FETCH_GET_CHAT_USER_AUTH_SUCCESS',

  SET_FETCHING_GET_CHAT_USERS_BY_NAMES: 'SET_FETCHING_GET_CHAT_USERS_BY_NAMES',
  FETCH_GET_CHAT_USERS_BY_NAMES_ERROR: 'FETCH_GET_CHAT_USERS_BY_NAMES_ERROR',
  FETCH_GET_CHAT_USERS_BY_NAMES_SUCCESS: 'FETCH_GET_CHAT_USERS_BY_NAMES_SUCCESS',

  SET_FETCHING_GET_CHAT_SUPPORT: 'SET_FETCHING_GET_CHAT_SUPPORT',
  FETCH_GET_CHAT_SUPPORT_ERROR: 'FETCH_GET_CHAT_SUPPORT_ERROR',
  FETCH_GET_CHAT_SUPPORT_SUCCESS: 'FETCH_GET_CHAT_SUPPORT_SUCCESS',

  SET_FETCHING_CREATE_CHAT_USER: 'SET_FETCHING_CREATE_CHAT_USER',
  FETCH_CREATE_CHAT_USER_ERROR: 'FETCH_CREATE_CHAT_USER_ERROR',
  FETCH_CREATE_CHAT_USER_SUCCESS: 'FETCH_CREATE_CHAT_USER_SUCCESS',

  CHAT_SERVICE_INIT: 'CHAT_SERVICE_INIT',
  CHAT_USER_LOGIN_SUCCESS: 'CHAT_USER_LOGIN_SUCCESS',

  MESSENGER_SERVICE_INIT: 'MESSENGER_SERVICE_INIT',
  CHAT_UPDATE_CONVERSATIONS: 'CHAT_UPDATE_CONVERSATIONS',
  CHAT_ADD_CONVERSATION: 'CHAT_ADD_CONVERSATION',
  CHAT_UPDATE_USERS: 'CHAT_UPDATE_USERS',
  CHAT_ADD_USERS: 'CHAT_ADD_USERS',
  CHAT_UPDATE_CURRENT_USER: 'CHAT_UPDATE_CURRENT_USER',
  CHAT_UPDATE_CONVERSATION: 'CHAT_UPDATE_CONVERSATION',
  CHAT_UPDATE_CURRENT_CONVERSATION_ID: 'CHAT_UPDATE_CURRENT_CONVERSATION_ID',
  SET_CURRENT_CONVERSATION_MESSAGES: 'SET_CURRENT_CONVERSATION_MESSAGES',
  CHAT_UPDATE_CURRENT_CONVERSATION_LAST_EVENT: 'CHAT_UPDATE_CURRENT_CONVERSATION_LAST_EVENT',
  CHAT_CLEAR_CURRENT_CONVERSATION: 'CHAT_CLEAR_CURRENT_CONVERSATION',
  SET_FETCHING_GET_CONVERSATION_HISTORY: 'SET_FETCHING_GET_CONVERSATION_HISTORY',
  UPDATE_CURRENT_CONVERSATION_SEQ: 'UPDATE_CURRENT_CONVERSATION_SEQ',

  ADD_NEW_MESSAGE: 'ADD_NEW_MESSAGE',
  UPDATE_MESSAGES_IN_CONVERSATION: 'UPDATE_MESSAGES_IN_CONVERSATION',
  SET_FETCHING_SEND_MESSAGE: 'SET_FETCHING_SEND_MESSAGE',
  FETCH_SEND_MESSAGE_SUCCESS: 'FETCH_SEND_MESSAGE_SUCCESS',
  FETCH_SEND_MESSAGE_ERROR: 'FETCH_SEND_MESSAGE_ERROR',
  UPDATE_MESSAGE_AS_READ: 'UPDATE_MESSAGE_AS_READ',
  ADD_ONLINE_STATUS: 'ADD_ONLINE_STATUS',
  DELETE_ONLINE_STATUS: 'DELETE_ONLINE_STATUS',
  UPDATE_CONVERSATION_LAST_MESSAGE: 'UPDATE_CONVERSATION_LAST_MESSAGE',
  UPDATE_CONVERSATION_USER_UNREADED: 'UPDATE_CONVERSATION_USER_UNREADED',
  UPDATE_MESSAGES_IN_CONVERSATION_UPLOADED: 'UPDATE_MESSAGES_IN_CONVERSATION_UPLOADED',
  ADD_NEW_MESSAGE_UPLOADING: 'ADD_NEW_MESSAGE_UPLOADING',
  UPDATE_CONVERSATIONS_UNREADED: 'UPDATE_CONVERSATIONS_UNREADED',

  SCROLLING_START: 'SCROLLING_START',
  SCROLLING_TO_POS: 'SCROLLING_TO_POS',
  SET_USERS_SEARCH: 'SET_USERS_SEARCH',
  SET_OPPOSITE_USER: 'SET_OPPOSITE_USER',
  CHAT_SIDEBAR_INIT: 'CHAT_SIDEBAR_INIT',
  IS_CHAT_LAST_MESSAGE_READY: 'IS_CHAT_LAST_MESSAGE_READY',
}
