import s from 'styled-components'
import cn from 'classnames'

const getStyles = ({
  theme,
  $color,
  $width,
  $height,
  $isVertical,
}) => {
  const componentColor = $color || 'grey150'

  return `
  height: ${$height || ($isVertical ? '100%' : '1px')};
  width: ${$width || ($isVertical ? '1px' : '')};
  background: ${theme.colors[componentColor] || componentColor};
`
}

const DividerContainer = s.div`
  ${getStyles}
`

const Divider = ({
  className,
  color = '',
  height = '',
  width = '',
  isVertical = false,
  ...props
}) => (
  <DividerContainer
    $color={color}
    $width={width}
    $height={height}
    $isVertical={isVertical}
    className={cn('w-divider', className)}
    {...props}
  />
)

export default Divider
