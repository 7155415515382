import store from 'redux/store/configureStore'
import { ChatActionTypes } from 'redux/dashboard/Chat/ChatTypes'
import MessengerService from 'services/Voximplant/messenger.service'
import {
  log,
  logError,
  logHelp
} from 'utils/utils'
import { chatScrollToBottom } from './MessengerMessagesActions'

export const TYPE_CONVERSATION = {
  direct: 'direct',
  chat: 'chat',
  channel: 'channel',
}

export const updateChatConversations = (conversations) => ({
  type: ChatActionTypes.CHAT_UPDATE_CONVERSATIONS,
  payload: conversations
})

export const addChatConversation = (conversation) => ({
  type: ChatActionTypes.CHAT_ADD_CONVERSATION,
  payload: conversation
})

export const updateChatConversation = (conversation) => ({
  type: ChatActionTypes.CHAT_UPDATE_CONVERSATION,
  payload: conversation
})

export const updateChatUsers = (users) => ({
  type: ChatActionTypes.CHAT_UPDATE_USERS,
  payload: users
})

export const addChatUsers = (users) => ({
  type: ChatActionTypes.CHAT_ADD_USERS,
  payload: users
})

export const updateChatCurrentUsers = (currentUser) => ({
  type: ChatActionTypes.CHAT_UPDATE_CURRENT_USER,
  payload: currentUser
})

export const updateCurrentConversationId = (id) => ({
  type: ChatActionTypes.CHAT_UPDATE_CURRENT_CONVERSATION_ID,
  payload: id
})

export const updateCurrentConversationLastEvent = (event) => ({
  type: ChatActionTypes.CHAT_UPDATE_CURRENT_CONVERSATION_LAST_EVENT,
  payload: event
})

export const clearCurrentConversationMessages = (event) => ({
  type: ChatActionTypes.CHAT_CLEAR_CURRENT_CONVERSATION,
  payload: event
})

export const messengerServiceInit = (newStatus) => ({
  type: ChatActionTypes.MESSENGER_SERVICE_INIT,
  payload: newStatus
})

export const getDirectConversation = (context, userId) => (dispatch) => {
  if (context.getters.currentDirectUsersId.includes(userId)) {
    const index = context.getters.currentDirectUsersId.indexOf(userId)
    const chatUuid = context.getters.currentDirectConversations[index].uuid

    dispatch(updateCurrentConversationId(chatUuid))
    dispatch(chatScrollToBottom())
  }
  else {
    MessengerService.get().createDirect(userId).catch(logError)
  }
}

export const getCurrentConversation = (conversations, chatUuid) => function(dispatch) {
  // if (chatUuid === currentConversationId) {
  // dispatch(chatScrollToBottom())

  //   return null
  // }

  if (typeof chatUuid === 'string') {
    dispatch(updateCurrentConversationId(chatUuid))
    // dispatch(updateChatConversation(conversations, chatUuid))

    // router.push({
    //   name: 'currentChat',
    //   params: { chatUuid: context.getters.currentConversation.uuid },
    // });

    // dispatch(chatScrollToBottom())

    // dispatch({
    //   type: 'LOADING_START',
    //   payload: true
    // })
    // dispatch(getConversationHistory).then(() => {
    //   dispatch({
    //     type: 'LOADING_STOP',
    //     payload: false
    //   })
    // dispatch(chatScrollToBottom())

    // })
  }
  else if (typeof chatUuid === 'number') {
    dispatch(getDirectConversation(chatUuid))
  }
}

export const updateConversationLastRead = () => ({ type: ChatActionTypes.UPDATE_CONVERSATION_USER_UNREADED })

export const updateConversationLastMessage = (lastEvent) => ({
  type: ChatActionTypes.UPDATE_CONVERSATION_LAST_MESSAGE,
  payload: lastEvent
})

export const isChatLastMessageReady = () => ({ type: ChatActionTypes.IS_CHAT_LAST_MESSAGE_READY })

export const chatSidebarInit = () => ({ type: ChatActionTypes.CHAT_SIDEBAR_INIT })

export const updateConversationsLastMessage = (conversations) => (dispatch) => {
  const conv = []

  conversations.forEach((conversation) => {
    const lastEvent = conversation.lastEvent || conversation.lastSeq || 1

    if (lastEvent > 1) {
      conv.push(conversation)
    }
  })

  function sleep(ms) {
    // eslint-disable-next-line no-promise-executor-return
    return new Promise((resolve) => setTimeout(resolve, ms))
  }

  const retransmitWithRetry = async (conversation, lastEvent) => {
    const maxRetries = 3
    let retries = 0

    while (retries < maxRetries) {
      try {
        const response = await conversation.retransmitEvents(Number(lastEvent), Number(lastEvent))
        dispatch(updateConversationLastMessage(response.events[0]))
        break
      }
      catch (error) {
        console.error('Ошибка ретрансмитера', error.code)
        logHelp('Ошибка ретрансмитера', error)
        await sleep(60000)
        retries += 1
      }
    }

    if (retries === maxRetries) {
      console.error('Достигнут лимит попыток, прекращаем повторы')
    }
  }

  Promise.all(conv.map((conversation) => {
    const lastEvent = conversation.lastEvent || conversation.lastSeq
    return retransmitWithRetry(conversation, lastEvent)
  })).then(() => dispatch(isChatLastMessageReady()))
}

export const onConversationCreated = (e) => (dispatch) => {
  const { chat } = store.getState().dashboard
  // log('Conversation created', e)

  // Update conversation list
  // TODO commit('updateConversations', e.conversation);
  if (e.conversation.customData.type === 'direct') {
    const participant = e.conversation.participants.find((p) => p.userId !== chat.user.userId)
    e.conversation.directUserId = participant.userId
  }

  dispatch(addChatConversation(e.conversation))

  // Set the conversation as a current if it is created by this user
  if (e.initiator === chat.user.userId) {
    dispatch(updateCurrentConversationId(e.conversation.uuid))
    // router.push({ name: 'currentChat', params: { chatUuid: e.conversation.uuid } });
  }

  const supportUser = chat.users && chat.users.find((u) => chat.support
    && u.userInfo
    && u.userInfo.voximplant_username === chat.support.voximplant_username)

  // Get participants user data if there are new
  const newUsers = e.conversation.participants.filter((p) => chat.user.userId !== p.userId && (!supportUser || supportUser.userId !== p.userId))

  if (newUsers.length) {
    const usersIds = newUsers.map((u) => u.userId)

    MessengerService.messenger.getUsersById(usersIds)
      .then((events) => {
        dispatch(addChatUsers(events.map((e) => e.user)))
      })
      .catch(logError)
  }

  // Get participant ids excluding the current user
  const otherParticipantIds = e.conversation.participants.map((p) => p.userId).filter((id) => id !== chat.user.userId)
  // Subscribe to other participant events (info and presence status change)
  if (otherParticipantIds.length) {
    MessengerService.messenger.subscribe(otherParticipantIds)
      .then((e) => {
        // log('Subscribed to conversation participants', e)
      })
      .catch(logError)
  }
}

export const createConversation = (newChat) => (dispatch) => {
  dispatch({
    type: 'CREATE_CONVERSATION',
    payload: newChat.type
  })

  try {
    if (newChat.type === TYPE_CONVERSATION.chat) {
      return MessengerService.get().createChat(newChat)
    } if (newChat.type === TYPE_CONVERSATION.channel) {
      return MessengerService.get().createChannel(newChat)
    } if (newChat.type === TYPE_CONVERSATION.direct) {
      return MessengerService.get().createDirect(newChat.usersId)
    } logError(`Conversation type ${newChat.type} is unknown`)
  }
  catch (e) {
    logError(e)
  }
}

// TODO: вынести в сервис!!!!!!
export const editConversation = (newData, currentConversation) => {
  if (newData.title && newData.title !== currentConversation.title) {
    currentConversation.setTitle(newData.title)
      .catch(logError)
  }

  if (newData.customData) {
    currentConversation.setCustomData({
      ...currentConversation.customData,
      ...newData.customData
    })
      .catch(logError)
  }
}

export const onConversationEdited = (state, e) => (dispatch) => {
  // If the current user was added to the conversation
  if (!state.conversations.some((c) => c.uuid === e.conversation.uuid)) {
    // log('Joined conversation', e)

    // TODO to mutation
    // @ts-ignore
    state.conversations.push(e.conversation)
    // If the current user has left the conversation
  }
  else if (!e.conversation.participants.some((p) => p.userId === state.currentUser.userId)) {
    // log(`Left conversation ${e.conversation.title}`, e)

    // Update conversation list
    // TODO to mutation
    state.conversations = state.conversations.filter((c) => c.uuid !== e.conversation.uuid)

    if (e.conversation.uuid === state.currentConversationId) {
      dispatch(updateCurrentConversationId(null))
      // router.push('/')
    }
    // TODO update sidebar
  }
  else {
    // log('Conversation edited', e)

    // TODO to mutation
    // @ts-ignore
    state.conversations = state.conversations.map((c) => {
      if (c.uuid === e.conversation.uuid) {
        return e.conversation
      }
      return c
    })
  }
}

export const leaveCurrentConversation = ({ state }) => {
  if (state.currentConversationId) {
    MessengerService.get().leaveConversation(state.currentConversationId)
  }
}

export const addNewParticipants = (context, userIds) => (dispatch) => {
  MessengerService.get().addParticipants(context.getters.currentConversation, userIds)
    .then((evt) => {
      logHelp('New members were added', evt)
      dispatch('updateConversation', evt.conversation)
    })
    .catch(logError)
}

export const deleteParticipants = (context, userIds) => (dispatch) => {
  MessengerService.get().removeParticipants(context.getters.currentConversation, userIds)
    .then((evt) => {
      logHelp('Some members were removed', evt)
      dispatch('updateConversation', evt.conversation)
    })
    .catch(logError)
}

export const addNewAdmins = (currentConversation, userIds) => (dispatch) => {
  MessengerService.get().addAdmins(currentConversation, userIds)
    .then((evt) => {
      logHelp('New admins were added', evt)
      dispatch('updateConversation', evt.conversation)
    })
    .catch(logError)
}

export const editChatPermissions = (possibleChatAdmins, currentConversation, permissions) => (dispatch) => {
  const allUsersIds = possibleChatAdmins.map((u) => u.userId)
  MessengerService.get().editPermissions(currentConversation, permissions, allUsersIds)
    .then((evt) => {
      // logHelp('Chat permissions were edited', evt)
      dispatch('updateConversation', evt.conversation)
    })
    .catch(logError)
}

export const notifyTyping = ({ getters }) => {
  MessengerService.get().notifyTyping(getters.currentConversation)
    .catch(logError)
}

export const onNotifyTyping = (context, evt) => (dispatch) => {
  if (evt.initiator !== context.state.currentUser.userId && evt.conversation === context.state.currentConversationId) {
    // logHelp('another user typing event', evt)

    const userName = context.state.users.find((u) => u.userId === evt.initiator).displayName
    dispatch('addTyping', userName)
    setTimeout(() => dispatch('deleteTyping', userName), 9000)
  }
}
