import { AuthActionTypes } from 'redux/Auth/AuthTypes'
import { ConsultantsActionTypes } from 'redux/dashboard/Consultants/ConsultantsTypes'

const INITIAL_STATE = {
  doctorConsultants: null,
  doctorConsultantsCount: 0,
  isFetching: false,

  error: null,
}

const consultantsReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
  case AuthActionTypes.FETCH_LOGOUT_SUCCESS:
    return { ...INITIAL_STATE }

  case ConsultantsActionTypes.FETCH_DOCTOR_CONSULTANTS_LIST_SUCCESS:
    return {
      ...state,
      doctorConsultants: action.payload
    }

  case ConsultantsActionTypes.SET_DOCTOR_CONSULTANTS_LIST_COUNT:
    return {
      ...state,
      doctorConsultantsCount: action.payload
    }

  case ConsultantsActionTypes.CLEAR_DOCTOR_CONSULTANTS_LIST:
    return {
      ...state,
      doctorConsultants: null
    }

  case ConsultantsActionTypes.FETCH_UPLOAD_DOCTOR_CONSULTANTS_LIST_SUCCESS:
    return {
      ...state,
      doctorConsultants: [ ...state.doctorConsultants, ...action.payload ]
    }

  default:
    return state
  }
}

export default consultantsReducer
