/* eslint-disable no-promise-executor-return */
import ApiService from 'services/ApiService'
import { ClinicRecordActionTypes } from 'redux/dashboard/ClinicRecord/ClinicRecordTypes'
import { getQuerySearch } from 'helpers/Query'
import { formatDate } from 'helpers/Date'

export const setClinicRecordFilters = (filters) => ({
  type: ClinicRecordActionTypes.SET_CLINIC_RECORD_FILTERS,
  payload: filters,
})

export const setClinicRecordFilter = ({ name, value }) => ({
  type: ClinicRecordActionTypes.SET_CLINIC_RECORD_FILTER,
  payload: {
    name,
    value
  },
})

export const addClinicRecordToList = (patient) => ({
  type: ClinicRecordActionTypes.ADD_PATIENT_TO_LIST,
  payload: patient,
})

export const setClinicRecordSearchValue = (value) => ({
  type: ClinicRecordActionTypes.SET_CLINIC_RECORD_SEARCH_VALUE,
  payload: value,
})

export const setFetchingClinicRecordList = (value) => ({
  type: ClinicRecordActionTypes.SET_FETCHING_CLINIC_RECORD_LIST,
  payload: value,
})

export const fetchClinicRecordListSuccess = (response, isAdd = false) => ({
  type: isAdd ? ClinicRecordActionTypes.ADD_CLINIC_RECORD_LIST : ClinicRecordActionTypes.SET_CLINIC_RECORD_LIST,
  payload: response,
})

export const fetchClinicRecordListError = (error) => ({
  type: ClinicRecordActionTypes.FETCH_CLINIC_RECORD_LIST_ERROR,
  payload: error,
})

export const setClinicRecordListCount = (value) => ({
  type: ClinicRecordActionTypes.SET_CLINIC_RECORD_LIST_COUNT,
  payload: value
})

export const setClinicRecordCountAll = (value) => ({
  type: ClinicRecordActionTypes.SET_CLINIC_RECORD_COUNT_ALL,
  payload: value
})

export const clearClinicRecordList = () => ({ type: ClinicRecordActionTypes.CLEAR_CLINIC_RECORD_LIST })

export const setFetchingClinicRecordSearch = (value) => ({
  type: ClinicRecordActionTypes.SET_FETCHING_CLINIC_RECORD_SEARCH,
  payload: value,
})

export const fetchClinicRecordSearchSuccess = (list) => ({
  type: ClinicRecordActionTypes.FETCH_CLINIC_RECORD_SEARCH_SUCCESS,
  payload: list,
})

export const fetchClinicRecordSearchError = (error) => ({
  type: ClinicRecordActionTypes.FETCH_CLINIC_RECORD_SEARCH_ERROR,
  payload: error,
})

export const clearClinicRecordSearch = () => ({ type: ClinicRecordActionTypes.CLEAR_CLINIC_RECORD_SEARCH })

export const updateClinicRecordList = (updateItem) => (dispatch, getState) => {
  const { list } = getState().dashboard.clinicRecord
  const newList = list.map((item) => (item.id === updateItem.id ? updateItem : item))

  dispatch({
    type: ClinicRecordActionTypes.SET_ONLY_CLINIC_RECORD_LIST,
    payload: newList,
  })
}

export const fetchClinicRecordList = ({
  isAdd = false,
  isSearch = false,
  cancelTokenSource,
  ...queryProps
}) => (dispatch, getState) => {
  const {
    offset,
    maxOffset,
    isFetching,
  } = getState().dashboard.clinicRecord
  if (!isSearch && isFetching) return
  if (!isSearch && isAdd && offset >= maxOffset) return

  if (isSearch) {
    dispatch(setFetchingClinicRecordSearch(true))
  }
  else {
    dispatch(setFetchingClinicRecordList(true))
  }

  const queryConstProps = {
    limit: 40,
    offset: isAdd ? offset : 0,
    ...queryProps
  }

  if (queryConstProps.created_at) queryConstProps.created_at = formatDate(queryConstProps.created_at, 'yyyy-MM-dd')

  const queryString = getQuerySearch({ ...queryConstProps })

  return new Promise((res, rej) => ApiService.apiCall({
    url: `${ApiService.paths.clinicRecord.ROOT}${queryString}`,
    isToken: true,
    cancelTokenSource
  })
    .then((response) => {
      if (isSearch) {
        dispatch(fetchClinicRecordSearchSuccess(response.results))
        dispatch(setFetchingClinicRecordSearch(false))
      }
      else {
        dispatch(fetchClinicRecordListSuccess(response, isAdd))
        dispatch(setClinicRecordListCount(response.results.length))
        dispatch(setFetchingClinicRecordList(false))
      }

      return res(response.results)
    })
    .catch((error) => {
      if (isSearch) {
        dispatch(fetchClinicRecordSearchError(error))
        dispatch(setFetchingClinicRecordSearch(false))
      }
      else {
        dispatch(fetchClinicRecordListError(error))
        dispatch(setFetchingClinicRecordList(false))
      }

      return rej(error)
    }))
}

export const setClinicRecordsNewCount = (value) => ({
  type: ClinicRecordActionTypes.SET_CLINIC_RECORD_NEW_COUNT,
  payload: value
})

export const fetchGetClinicRecordsNewCount = (clinicId = '') => (dispatch) => new Promise((res, rej) => ApiService.apiCall({
  url: ApiService.paths.clinics.NEW_RECORDS(clinicId),
  isToken: true,
})
  .then((response) => {
    dispatch(setClinicRecordsNewCount(response.new_appointments_at_the_clinic || 0))
    return res(response)
  })
  .catch((error) => rej(error)))
