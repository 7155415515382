export const phoneReg = /^((8|\+7|\d)[- ]?)?(\(?\d{3}\)?[- ]?)?[\d- ]{7,10}$/
export const phoneRegExp = /^\+\d{1,3}\s?\(\d{3}\)\s?\d{3}(-\d{2}){2}$/
export const phoneOrEmailReg = /^([_a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,5}))|(\d+$)$/
export const emailRegExp = /\b[\w.-]+@[\w.-]+\.\w{2,4}\b/gi
export const usernameRegExp = /^[A-Z\d][A-Z\d_-]{1,100}$/i
export const emailOrUsernameRegExp = /^(?:[A-Z\d][A-Z\d_-]{1,100}|[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4})$/i
export const birthdayRegExp = /^([012]\d|30|31)\.(0\d|10|11|12)\.\d{2}$/
export const dateRegExp = /^([012]\d|30|31)\.(0\d|10|11|12)\.\d{4}$/
export const passportCodeRegExp = /^\d{3}-\d{3}$/
export const urlRegExp = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{2,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/
export const timeRegExpDinamic = /^\d{0,2}?:?\d{0,2}$/u
export const timeRegExpStatic = /^\d{2}:\d{2}$/
export const colorHEXRegExp = /^([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/

export const getNumbers = (maskedPhone) => maskedPhone.replace(/\D+/g, '')

export const getPhone = (maskedPhone) => maskedPhone.replace(/ /g, '')

export const isNumber = (value) => /\d+/.test(value)
export const isAnyNumber = (value) => /^-?[\d.]+(?:e-?\d+)?$/.test(value)

export const isLetter = (value) => /^[А-яа-яA-Za-z]+$/.test(value)

export const isLetterOrNumbers = (value) => /^[0-9А-яа-яA-Za-z]+$/.test(value)

export const isHex = (value) => value.match(/^[A-Fa-f0-9]+$/)
export const isDateMask = (value) => value.match(/(^(((0[1-9]|1[0-9]|2[0-8])[-.](0[1-9]|1[012]))|((29|30|31)[-.](0[13578]|1[02]))|((29|30)[-.](0[4,6,9]|11)))[-.](19|[2-9][0-9])\d\d$)|(^29[-.]02[-.](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)/)
