import Text from 'components/typo/Text'
import Box from 'layout/Box'
import { ReactComponent as Icon } from './notifications-empty.svg'

const NotificationsEmptyList = () => (
  <Box
    height="100%"
    ai="center"
    jc="center"
  >
    <Box
      direction="column"
      ai="center"
    >
      <Icon className="mb-6" />
      <Text textAlign="center">
        Здесь будут отображаться уведомления
      </Text>
    </Box>
  </Box>
)

export default NotificationsEmptyList
