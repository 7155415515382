/* eslint-disable prefer-object-spread */
import {
  addNewConversationMessage,
  addOnline,
  deleteOnline,
  updateConversationLastEvent,
  updateConversationLastMessage,
  updateConversationSeq,
  updateMessagesAsRead,
  updateConversationUnreaded,
  updateConversationMessagePreloaded,
  updateUnreaded,
} from 'redux/dashboard/Chat/ChatHelpers'
import { AuthActionTypes } from 'redux/Auth/AuthTypes'
import { ChatActionTypes } from 'redux/dashboard/Chat/ChatTypes'
import { USER_ROLE_ALL } from 'constants/UserTypes'

const INITIAL_STATE = {
  message: '',
  activeChatId: null,
  activeUsersType: USER_ROLE_ALL,
  chatPageIsActive: false,

  user: null,
  support: null,
  oppositeUser: null,
  users: [],
  conversations: [],

  chatServiceInit: false,
  messengerServiceInit: false,
  chatSidebarInit: false,
  isChatLastMessageReady: false,
  chatUserLogin: false,
  usersSearch: '',

  currentConversation: null,
  currentConversationId: null,
  conversationsHistory: [],
  conversationMessages: null,
  isFetchingConversationMessages: false,
  onlineUsers: [],
  unreaded: [],

  scrollToBottom: 0,
  scrollToPos: null,

  refreshToken: null,

  error: null,
}

const chatReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
  case AuthActionTypes.FETCH_LOGOUT_SUCCESS:
    return {
      ...INITIAL_STATE,
      chatServiceInit: false
    }

  case ChatActionTypes.SET_CHAT_PAGE_ACTIVE:
    return {
      ...state,
      chatPageIsActive: action.payload
    }

  case ChatActionTypes.SET_CHAT_MESSAGE:
    return {
      ...state,
      message: action.payload
    }

  case ChatActionTypes.IS_CHAT_LAST_MESSAGE_READY:
    return {
      ...state,
      isChatLastMessageReady: true
    }

  case ChatActionTypes.CHAT_SIDEBAR_INIT:
    return {
      ...state,
      chatSidebarInit: true
    }

  case ChatActionTypes.SET_ACTIVE_CHAT_ID:
    return {
      ...state,
      activeChatId: action.payload
    }

  case ChatActionTypes.SET_ACTIVE_USERS_TYPE:
    return {
      ...state,
      activeUsersType: action.payload
    }

  case ChatActionTypes.SET_OPPOSITE_USER:
    return {
      ...state,
      oppositeUser: action.payload
    }

  case ChatActionTypes.SET_FETCHING_GET_CONVERSATION_HISTORY:
    return {
      ...state,
      isFetchingConversationMessages: action.payload
    }

  case ChatActionTypes.CHAT_SERVICE_INIT:
    return {
      ...state,
      chatServiceInit: action.payload
    }

  case ChatActionTypes.MESSENGER_SERVICE_INIT:
    return {
      ...state,
      messengerServiceInit: action.payload
    }

  case ChatActionTypes.CHAT_UPDATE_CONVERSATIONS:
    return {
      ...state,
      conversations: action.payload
    }

  case ChatActionTypes.CHAT_CLEAR_CURRENT_CONVERSATION:
    return {
      ...state,
      currentConversation: null,
      currentConversationId: null,
      conversationMessages: [],
    }

  case ChatActionTypes.CHAT_ADD_CONVERSATION:
    return {
      ...state,
      conversations: [ ...state.conversations, action.payload ]
    }

  case ChatActionTypes.CHAT_UPDATE_USERS:
    return {
      ...state,
      users: action.payload
    }

  case ChatActionTypes.CHAT_ADD_USERS:
    return {
      ...state,
      users: state.users
        ? [ ...state.users, ...action.payload ]
        : action.payload
    }

  case ChatActionTypes.CHAT_UPDATE_CURRENT_USER:
    return {
      ...state,
      user: action.payload
    }

  case ChatActionTypes.FETCH_GET_CHAT_SUPPORT_SUCCESS:
    return {
      ...state,
      support: action.payload
    }

  case ChatActionTypes.CHAT_USER_LOGIN_SUCCESS:
    return {
      ...state,
      chatUserLogin: action.payload
    }

  case ChatActionTypes.CHAT_UPDATE_CONVERSATION:
    return {
      ...state,
      currentConversation: action.payload
    }

  case ChatActionTypes.CHAT_UPDATE_CURRENT_CONVERSATION_ID:
    return {
      ...state,
      currentConversationId: action.payload
    }

  case ChatActionTypes.SET_CURRENT_CONVERSATION_MESSAGES:
    return {
      ...state,
      conversationMessages: action.payload || []
    }

  case ChatActionTypes.CHAT_UPDATE_CURRENT_CONVERSATION_LAST_EVENT:
    return {
      ...state,
      conversations: updateConversationLastEvent(state, action.payload)
    }

  case ChatActionTypes.SCROLLING_START:
    return {
      ...state,
      scrollToBottom: state.scrollToBottom + 1
    }

  case ChatActionTypes.UPDATE_MESSAGES_IN_CONVERSATION:
    return {
      ...state,
      conversationMessages: addNewConversationMessage(state.conversationMessages, action.payload),
      conversations: updateConversationUnreaded(state, action.payload)
    }

  case ChatActionTypes.ADD_NEW_MESSAGE_UPLOADING:
    if (state.currentConversation) {
      action.payload.uploading = true
      action.payload.uuid = action.payload.payload[0].uploadId
      action.payload.sender = state.user.userId
      action.payload.timestamp = new Date().getTime()

      return {
        ...state,
        conversationMessages: state.conversationMessages
          ? [ ...state.conversationMessages, action.payload ]
          : [ action.payload ]
      }
    }

    return state

  case ChatActionTypes.UPDATE_MESSAGES_IN_CONVERSATION_UPLOADED:
    return {
      ...state,
      conversations: updateConversationUnreaded(state, action.payload),
      conversationMessages: updateConversationMessagePreloaded(state, action.payload)
    }

    // case ChatActionTypes.FETCH_SEND_MESSAGE_SUCCESS:
    //   return {
    //     ...state,
    //     conversationMessages: addNewConversationMessage(state.conversationMessages, action.payload)
    //   }

  case ChatActionTypes.UPDATE_MESSAGE_AS_READ:
    return {
      ...state,
      conversationMessages: updateMessagesAsRead(state.conversationMessages, action.payload)
    }

  case ChatActionTypes.ADD_ONLINE_STATUS:
    return {
      ...state,
      onlineUsers: addOnline(state.onlineUsers, action.payload)
    }

  case ChatActionTypes.DELETE_ONLINE_STATUS:
    return {
      ...state,
      onlineUsers: deleteOnline(state.onlineUsers, action.payload)
    }

  case ChatActionTypes.UPDATE_CURRENT_CONVERSATION_SEQ:
    return {
      ...state,
      conversations: updateConversationSeq(state, action.payload),
      currentConversation: state.currentConversation
        ? Object.assign(state.currentConversation, { _lastSeq: Number(action.payload.seq) })
        : state.currentConversation
    }

  case ChatActionTypes.UPDATE_CONVERSATION_LAST_MESSAGE:
    return {
      ...state,
      conversations: updateConversationLastMessage(state, action.payload),
    }

  case ChatActionTypes.UPDATE_CONVERSATION_USER_UNREADED:
    return {
      ...state,
      conversations: updateConversationUnreaded(state, {}),
    }

  case ChatActionTypes.SET_USERS_SEARCH:
    return {
      ...state,
      usersSearch: action.payload,
    }

  case ChatActionTypes.UPDATE_CONVERSATIONS_UNREADED:
    return {
      ...state,
      unreaded: updateUnreaded(state.unreaded, action.payload),
    }

  case ChatActionTypes.SCROLLING_TO_POS:
    return {
      ...state,
      scrollToPos: action.payload,
    }

  default:
    return state
  }
}

export default chatReducer
