import { AuthActionTypes } from 'redux/Auth/AuthTypes'
import { DiarysActionTypes } from 'redux/Diarys/DiarysTypes'

const INITIAL_STATE = {
  types: [],
  isFetchingTypes: false,

  records: [],
  isFetchingRecords: false,

  error: null,
}

const patientsReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
  case AuthActionTypes.FETCH_LOGOUT_SUCCESS:
    return { ...INITIAL_STATE }

  case DiarysActionTypes.SET_FETCHING_DIARYS_TYPES:
    return {
      ...state,
      isFetchingTypes: action.payload
    }

  case DiarysActionTypes.FETCH_DIARYS_TYPES_SUCCESS:
    return {
      ...state,
      types: action.payload
    }

  case DiarysActionTypes.SET_FETCHING_USER_DIARY_RECORDS:
    return {
      ...state,
      isFetchingRecords: action.payload
    }

  case DiarysActionTypes.FETCH_USER_DIARY_RECORDS_SUCCESS:
    return {
      ...state,
      records: action.payload
    }

  default:
    return state
  }
}

export default patientsReducer
