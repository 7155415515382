/* eslint-disable no-promise-executor-return */
import { DiarysActionTypes } from 'redux/Diarys/DiarysTypes'
import ApiService from 'services/ApiService'

export const setFetchingDiarysTypes = (value) => ({
  type: DiarysActionTypes.SET_FETCHING_DIARYS_TYPES,
  payload: value,
})

export const fetchDiarysTypesError = (value) => ({
  type: DiarysActionTypes.FETCH_DIARYS_TYPES_ERROR,
  payload: value,
})

export const fetchDiarysTypesSuccess = (value) => ({
  type: DiarysActionTypes.FETCH_DIARYS_TYPES_SUCCESS,
  payload: value,
})

export const fetchDiarysTypes = () => (dispatch) => {
  dispatch(setFetchingDiarysTypes(true))

  return new Promise((res, rej) => ApiService.apiCall({
    url: ApiService.paths.my_events.EVENTS_GET,
    isToken: true,
  })
    .then((response) => {
      dispatch(fetchDiarysTypesSuccess(response))
      dispatch(setFetchingDiarysTypes(false))
      return res(response)
    })
    .catch((error) => {
      dispatch(setFetchingDiarysTypes(false))
      dispatch(fetchDiarysTypesError(false))
      return rej(error)
    }))
}

const setFetchingUserDiaryRecords = (payload) => ({
  type: DiarysActionTypes.SET_FETCHING_USER_DIARY_RECORDS,
  payload,
})

const fetchUserDiaryRecordsSuccess = (payload) => ({
  type: DiarysActionTypes.FETCH_USER_DIARY_RECORDS_SUCCESS,
  payload,
})

const fetchUserDiaryRecordsError = (error) => ({
  type: DiarysActionTypes.FETCH_USER_DIARY_RECORDS_ERROR,
  payload: error,
})

export const fetchUserDiaryRecordsAsync = (query = '') => (dispatch) => {
  dispatch(setFetchingUserDiaryRecords(true))

  return new Promise((res, rej) => {
    ApiService.apiCall({
      url: `${ApiService.paths.diarys.USER_RECORDS}${query}`,
      isToken: true,
    })
      .then((response) => {
        dispatch(setFetchingUserDiaryRecords(false))
        dispatch(fetchUserDiaryRecordsSuccess(response.results))
        return res(response)
      })
      .catch((error) => {
        dispatch(setFetchingUserDiaryRecords(false))
        dispatch(fetchUserDiaryRecordsError(error))
        return rej(error)
      })
  })
}

const setFetchingUserDiarysRecordsStats = (value) => ({
  type: DiarysActionTypes.SET_FETCHING_USER_DIARYS_RECORDS_STATS,
  payload: value,
})

const fetchUserDiarysRecordsStatsSuccess = (item) => ({
  type: DiarysActionTypes.FETCH_USER_DIARYS_RECORDS_STATS_SUCCESS,
  payload: item,
})

const fetchUserDiarysRecordsStatsError = (error) => ({
  type: DiarysActionTypes.FETCH_USER_DIARYS_RECORDS_STATS_ERROR,
  payload: error,
})

export const fetchUserDiarysRecordsStatsAsync = (query = '') => (dispatch) => {
  dispatch(setFetchingUserDiarysRecordsStats(true))

  return new Promise((res, rej) => {
    ApiService.apiCall({
      url: `${ApiService.paths.diarys.RECORDS_STATS}${query}`,
      isToken: true,
    })
      .then((response) => {
        dispatch(setFetchingUserDiarysRecordsStats(false))
        dispatch(fetchUserDiarysRecordsStatsSuccess(response))
        return res(response)
      })
      .catch((error) => {
        dispatch(setFetchingUserDiarysRecordsStats(false))
        dispatch(fetchUserDiarysRecordsStatsError(error))
        return rej(error.data)
      })
  })
}
