export const dateUnits = {
  YEAR: 'year',
  MONTH: 'month',
  WEEK: 'week',
  DAY: 'day',
  HOUR: 'hour',
  MINUTE: 'minute',
  SECOND: 'second',
}

export const sizeUnits = {
  GB: 'ГБ',
  MB: 'MB',
  B: 'Б',
  KB: 'KБ',
}
