import { components } from 'react-select'

import { ReactComponent as CaretDownIcon } from 'assets/icons/caret-s.svg'

const FormSelectDropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    <CaretDownIcon />
  </components.DropdownIndicator>
)

export default FormSelectDropdownIndicator
