import {
  useRef,
  useEffect,
  useState,
  useMemo,
  useCallback
} from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, Field } from 'formik'

import { Grid } from 'layout'
import Box from 'layout/Box'
import Text from 'components/typo/Text'
import Link from 'components/typo/Link'
import { PAGE_SUPPORT } from 'constants/PathsTypes'
import Button from 'components/base/Button'
import Title from 'components/typo/Title'
import {
  Form,
  Input,
  FormGroup,
  FormError,
} from 'components/form'

import { fetchLogin, fetchCodeVerification } from 'redux/Auth/AuthActions'

import GridItem from 'layout/GridItem'
import { getMaskedPhone } from 'helpers/User'

const PhoneConfirm = ({ data }) => {
  const [ isCodeError, setCodeError ] = useState(false)
  const { isFetchingCode, isFetchingAuth } = useSelector((state) => state.auth)
  const history = useHistory()
  const codeRef = useRef([])
  const dispatch = useDispatch()

  const { phone } = data

  const maskedPhone = useMemo(() => getMaskedPhone(phone), [ phone ])

  useEffect(() => {
    if (codeRef.current && codeRef.current[0]) {
      codeRef.current[0].focus()
    }
    // eslint-disable-next-line
  }, [codeRef.current])

  const handleCodeVerify = useCallback((code, setSubmitting) => {
    setCodeError(false)

    function onError() {
      setSubmitting(false)
      setCodeError(true)
    }

    dispatch(fetchCodeVerification({
      code,
      phone
    }))
      .then((token) => {
        dispatch(fetchLogin(token, history))
          .catch(onError)
      })
      .catch(onError)
  }, [
    dispatch,
    setCodeError,
    history,
    phone
  ])

  const handleKeyPress = (e, handleSubmit) => {
    if (codeRef.current) {
      const idx = +e.target.name
      const { value } = e.target
      const { length } = codeRef.current

      if (e.keyCode === 37) {
        if (idx === 0) codeRef.current[length - 1].focus()
        else codeRef.current[idx - 1].focus()
      }
      else if (e.keyCode === 39) {
        if (idx === length - 1) codeRef.current[0].focus()
        else codeRef.current[idx + 1].focus()
      }
      else if (e.keyCode === 8 && value.length === 0 && idx > 0) {
        codeRef.current[idx - 1].focus()
      }
      else if (e.keyCode === 13) {
        handleSubmit()
      }
    }
  }

  return (
    <div>
      <Formik
        initialValues={{ code: new Array(6).fill('') }}
        onSubmit={(values, {
          setSubmitting,
          setFieldError,
        }) => {
          if (values.code.some((num) => num.length === 0)) {
            setFieldError('code', true)
            setSubmitting(false)
            return
          }

          // dispatch(setPhoneConfirmationForm(values))
          handleCodeVerify(values.code.join(''), setSubmitting)
        }}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            handleSubmit,
            handleBlur,
            setFieldValue,
            isSubmitting,
          } = props

          const isInvalid = values.code.some((c) => c.length === 0)

          const handleCodeChange = (e) => {
            if (isCodeError) {
              setCodeError(false)
            }

            const { target } = e

            if (target.value.length <= 1) {
              if (/\d+/.test(target.value)) target.value.replace(/\d+/, '')
              setFieldValue(`code[${target.name}]`, target.value)
            }

            if (
              values.code
                .filter((_, i) => i !== +target.name)
                .every((v) => v.length === 1)
            ) {
              handleSubmit()
            }
            else if (target.value.length === 1) {
              const nextField = codeRef.current[+target.name + 1]

              if (nextField) {
                nextField.focus()
              }
            }
          }

          const attrs = {
            onChange: handleCodeChange,
            onBlur: handleBlur,
            errors,
            touched,
          }

          // const formIsValid = dirty && !errors.code && values.username.length && !errors.password && values.password.length && !isSubmitting;

          return (
            <Form onSubmit={handleSubmit}>
              <Title
                textAlign="center"
                size="big"
                className="mb-4"
              >
                Введите код
              </Title>
              <Text
                textAlign="center"
                className="mb-4"
                color="grey400"
              >
                Код отправлен на номер
                {' '}
                <Text color="dark" tag="span">
                  {maskedPhone}
                </Text>
              </Text>
              <FormGroup>
                <Grid grid jc="center">
                  {values.code.map((value, idx) => (
                    <GridItem key={idx}>
                      <Field>
                        {() => (
                          <Input
                            autoComplete="false"
                            className="confirmation-code"
                            disabled={isFetchingCode || isFetchingAuth}
                            readOnly={isFetchingCode || isFetchingAuth}
                            max={1}
                            onKeyDown={(e) => handleKeyPress(e, handleSubmit)}
                            square
                            ref={(el) => (codeRef.current[idx] = el)}
                            align="center"
                            {...attrs}
                            name={idx}
                            type="text"
                            value={value}
                            isError={isCodeError}
                          />
                        )}
                      </Field>
                    </GridItem>
                  ))}
                </Grid>
                {errors.code && <FormError>{errors.code}</FormError>}
              </FormGroup>
              <FormGroup ta="center">
                <Button
                  type="submit"
                  size="big"
                  full
                  title="Подтвердить"
                  isDisabled={isInvalid || isSubmitting}
                  isFetching={isSubmitting}
                  maxWidth="386px"
                />
              </FormGroup>
              <Box jc="center" className="mt-6">
                <Text
                  color="grey500"
                  textAlign="center"
                >
                  Неприходит код?
                  {' '}
                  <Link fs="inherit" to={PAGE_SUPPORT}>Обратиться в поддержку</Link>
                </Text>
              </Box>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default PhoneConfirm
