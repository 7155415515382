import s from 'styled-components'
import cn from 'classnames'

import { Box } from 'layout'
import UserAvatar from './UserAvatar'

const UsersAvatarsContainer = s.div`
  position: relative;

  .u-avatar {
    position: relative;
    border: 2px solid ${({ theme }) => theme.colors.white};
    border-radius: 50%;
  }

  .--h {
    flex-direction: row;

    .u-avatar + .u-avatar {
      margin-left: -10px;
    }
  }

  .--v {
    flex-direction: column;

    &.--normalSmall {
      .u-avatar + .u-avatar {
        margin-top: -20px;
      }
    }

    .u-avatar + .u-avatar {
      margin-top: -15px;
    }
  }

`

const UsersAvatars = ({
  avatars = null,
  size = 'small',
  className = '',
  limit = 3,
  isVertical = false,
}) => (Array.isArray(avatars) ? (
  <UsersAvatarsContainer className={className}>
    <Box ai="center" className={cn([ `--${isVertical ? 'v' : 'h'}`, `--${size}` ])}>
      {avatars.slice(0, limit).map((avatar, idx) => (
        <div key={idx} className="u-avatar">
          <UserAvatar
            size={size}
            {...avatar}
          />
        </div>
      ))}
    </Box>
  </UsersAvatarsContainer>
) : null)

export default UsersAvatars
