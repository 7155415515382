import { ReactComponent as IconCall } from 'assets/icons/phone-fill.svg'
import { ReactComponent as IconCallEnd } from 'assets/icons/phone-down.svg'

import ButtonRound from 'components/base/ButtonRound'

const ButtonCall = ({
  defaultStyle = 'white',
  br = '8px',
  isDown = false,
  ...props
}) => (
  <ButtonRound
    br={br}
    defaultStyle={defaultStyle}
    // fill={isDown ? 'red' : 'green'}
    renderIcon={() => (isDown
      ? <IconCallEnd />
      : <IconCall />)}
    {...props}
  />
)

export default ButtonCall
