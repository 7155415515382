/* eslint-disable prefer-object-spread */

import { ArticlesActionTypes } from 'redux/dashboard/Articles/ArticlesTypes'

export const fetchArticleType = {
  SHARE: 'share',
  UN_SHARE: 'unShare',
  NONE: 'none'
}

const INITIAL_STATE = {
  isFetching: false,
  isFetchingMy: false,
  articlesList: [],
  myArticlesList: [],
  offset_me: 0,
  max_offset_me: 0,
  offset_another: 0,
  max_offset_another: 0,
}

const articlesReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
  case ArticlesActionTypes.SET_ARTICLES:
    return {
      ...state,
      articlesList: action.payload,
      offset_another: 40
    }

  case ArticlesActionTypes.ADD_ARTICLES:
    return {
      ...state,
      articlesList: state.articlesList.concat(action.payload),
      offset_another: state.offset_another + 40
    }

  case ArticlesActionTypes.ADD_MY_ARTICLES:
    return {
      ...state,
      myArticlesList: state.myArticlesList.concat(action.payload),
      offset_me: state.offset_me + 40
    }

  case ArticlesActionTypes.SET_MY_ARTICLES:
    return {
      ...state,
      myArticlesList: action.payload,
      offset_me: 40
    }

  case ArticlesActionTypes.SET_FETCH_ARTICLES:
    return {
      ...state,
      isFetching: action.payload,
    }

  case ArticlesActionTypes.SET_ARTICLES_MAX_OF_SET:
    return {
      ...state,
      max_offset_another: action.payload,
    }

  case ArticlesActionTypes.SET_ARTICLES_MY_MAX_OF_SET:
    return {
      ...state,
      max_offset_me: action.payload,
    }

  case ArticlesActionTypes.SET_FETCH_MY_ARTICLES:
    return {
      ...state,
      isFetchingMy: action.payload,
    }

  case ArticlesActionTypes.ARTICLE_DELETE:
    return {
      ...state,
      articlesList: [ ...state.articlesList.filter((item) => item.id !== Number(action.payload)) ],
      myArticlesList: [ ...state.myArticlesList.filter((item) => item.id !== Number(action.payload)) ]
    }

  case ArticlesActionTypes.SET_ARTICLES_EDIT_MODE:
    return {
      ...state,
      editeMode: action.payload
    }

  default:
    return state
  }
}

export default articlesReducer
