import React from 'react'
import Box from 'layout/Box'

const SpecialNotification = ({ onClear }) => (
  <Box
    jc="center"
    p="10px 20px"
    zIndex={9999}
    width="100%"
    br="6px"
    bg="#FFDFDF"
    bs="0px 2px 20px 0px #0000001A"
    onClick={onClear}
    style={{ color: 'FD5E5E' }}
    color="red"
  >
    Скрыть все уведомления
  </Box>
)

export default SpecialNotification
