import React, { useMemo } from 'react'
import styled from 'styled-components'
import Button from 'components/base/Button'
import ButtonClose from 'components/buttons/ButtonClose'
import Text from 'components/typo/Text'
import { Modal } from 'components/modal'
import { Spinner } from 'components/base'

const StyledComponent = styled.div`
  padding: 40px 32px 32px 32px;
  display: flex;
  flex-direction: column;
  cursor: auto;

  .closebutton {
    position: absolute;
    right: 16px;
    top: 16px;
  }
`

const ModalAnswerContent = ({ closeModal, data }) => {
  const {
    title = '',
    description = '',
    confirmText = '',
    cancelText = '',
    onSuccess = null,
    isSuccessCenter = false,
    typeConfirm = 'isGradient',
    isLoad = false,
  } = data

  const buttonConfirmProps = useMemo(() => {
    const atr = {
      className: `mt-8 ${isSuccessCenter ? 'ml-a mr-a' : ''}`,
      type: 'submit',
      size: 'big',
      title: isLoad ? (<Spinner />) : confirmText,
      isGradient: typeConfirm === 'isGradient',
      onClick: () => (onSuccess ? onSuccess() : closeModal()),
    }

    if (typeConfirm) {
      atr.defaultStyle = typeConfirm
    }

    return atr
  }, [ onSuccess, closeModal, typeConfirm, confirmText, isLoad ])

  return (
    <Modal
      onClick={(e) => {
        e.stopPropagation()
      }}
      height="auto"
      position="absolute"
    >
      <StyledComponent>
        <ButtonClose
          className="closebutton"
          size="xs"
          onClick={(e) => {
            e.preventDefault()
            closeModal()
          }}
        />
        {
          title ? (
            <Text fw="500" size="mediumSm" textAlign="center">
              {title}
            </Text>
          ) : ''
        }
        {
          description ? (
            <Text className="mt-3" color="grey600" size="normal" textAlign="center">
              {description}
            </Text>
          ) : ''
        }
        {
          confirmText ? (
            <Button
              isDisabled={isLoad}
              {...buttonConfirmProps}
            />
          ) : ''
        }
        {
          cancelText ? (
            <Button
              className="mt-3"
              type="submit"
              size="big"
              title={cancelText}
              onClick={() => closeModal()}
              defaultStyle="secondaryGray"
            />
          ) : ''
        }
      </StyledComponent>
    </Modal>
  )
}

export default ModalAnswerContent
