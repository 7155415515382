/* eslint-disable no-promise-executor-return */
// types
import { ClinicsActionTypes } from 'redux/Clinics/ClinicsTypes'

// services
import ApiService from 'services/ApiService'
// import StorageService from 'services/StorageService'

// constants
// import * as PathsTypes from 'constants/PathsTypes'

export const setFetchingClinics = (value) => ({
  type: ClinicsActionTypes.SET_FETCHING_CLINICS,
  payload: value,
})

export const fetchClinicsSuccess = (value) => ({
  type: ClinicsActionTypes.FETCH_CLINICS_SUCCESS,
  payload: value,
})

export const setFetchingSoloClinic = (value) => ({
  type: ClinicsActionTypes.SET_SOLO_FETCHING_CLINIC,
  payload: value,
})

export const fetchClinicSoloSuccess = (clinic) => ({
  type: ClinicsActionTypes.FETCH_SOLO_CLINIC_SUCCESS,
  payload: clinic,
})

export const setClinicsSuccess = (value) => ({
  type: ClinicsActionTypes.SET_CLINICS,
  payload: value,
})

export const setClinicsCountSuccess = (count) => ({
  type: ClinicsActionTypes.SET_CLINICS_COUNT,
  payload: count,
})

export const setClinicsPageSuccess = (page) => ({
  type: ClinicsActionTypes.SET_CLINICS_PAGE,
  payload: page,
})

export const setClinicsCount = (count) => (dispatch) => dispatch(setClinicsCountSuccess(count))

export const setClinics = (value) => (dispatch) => dispatch(setClinicsSuccess(value))

export const setClinicsPage = (page) => (dispatch) => dispatch(setClinicsPageSuccess(page))

// export const fetchClinicsError = (error) => ({
// type: ClinicsActionTypes.FETCH_CLINICS_ERROR,
//   payload: error,
// })

// export const fetchSoloClinicError = (error) => ({
// type: ClinicsActionTypes.FETCH_SOLO_CLINIC_ERROR,
//   payload: error,
// })

export const fetchClinicsAsync = ({ name, query = '' }) => (dispatch) => {
  dispatch(setFetchingClinics(true))

  return ApiService.apiCall({
    url: `${ApiService.paths.clinics.LIST}${query}`,
    isToken: true
  })
    .then((response) => {
      dispatch(setFetchingClinics(false))
      dispatch(fetchClinicsSuccess({
        name,
        value: response.results
      }))
      return new Promise((res) => res(response))
    })
    .catch((error) => {
      dispatch(setFetchingClinics(false))
      // fetchRegisterError(error.data)
    })
}

export const fetchClinicAsync = (id) => (dispatch) => {
  dispatch(setFetchingSoloClinic(true))

  return ApiService.apiCall({
    url: `${ApiService.paths.clinics.LIST}${id}/`,
    isToken: true
  })
    .then((response) => {
      dispatch(setFetchingSoloClinic(false))
      dispatch(fetchClinicSoloSuccess(response))
      return new Promise((res) => res(response))
    })
    .catch((error) => {
      dispatch(setFetchingSoloClinic(false))
      // fetchRegisterError(error.data)
    })
}

export const setFetchingClinicsProfiles = (value) => ({
  type: ClinicsActionTypes.SET_FETCHING_CLINICS_PROFILES,
  payload: value,
})

export const fetchClinicsProfilesSuccess = (profiles) => ({
  type: ClinicsActionTypes.FETCH_CLINICS_PROFILES_SUCCESS,
  payload: profiles,
})

export const fetchClinicsProfilesAsync = (query = '?limit=10000') => (dispatch) => {
  dispatch(setFetchingClinicsProfiles(true))

  return ApiService.apiCall({ url: `${ApiService.paths.clinics.PROFILES}${query}` })
    .then((response) => {
      dispatch(setFetchingClinicsProfiles(false))
      dispatch(fetchClinicsProfilesSuccess(response.results))
      return new Promise((res) => res(response))
    })
    .catch(() => {
      dispatch(setFetchingClinicsProfiles(false))
      // fetchRegisterError(error.data)
    })
}
