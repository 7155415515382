export const getPrevLocation = () => {
  const pn = window.location.pathname
  return pn.slice(0, pn.lastIndexOf('/'))
}

export const getPrevLocationDinamic = (path, repeat = 1) => {
  const newPath = path.split('/')

  for (let length = 0; length < repeat; length++) {
    newPath.pop()
  }

  return newPath.join('/')
}

export const getCurrentPath = (location) => (location
  ? `${location.pathname}${location.search}`
  : `${window.location.pathname}${window.location.search}`)

export function getUrlParamsFromLocationSearch(locationSearch) {
  const params = new URLSearchParams(locationSearch)
  const paramsObject = {}

  params.forEach((value, key) => {
    paramsObject[key] = value
  })

  return paramsObject
}
