import { Box } from 'layout'
import { Modal } from 'components/modal'
import ModalHeadSidebar from 'components/modal/ModalHeadSidebar'

const ModalSidebarLayout = ({
  children,
  modalSize = 'normal',
  modalTitle = null,
  renderTitle = null,
  noPointers = false,
  modalBorderBottom = null,
  onClose = () => {},
  onBack = null,
  renderModalContent = () => {},
  headRight = null,
  headPadding = '',
  sidebarOffset = false,
  overflow = '',
}) => (
  <Modal
    variant="full"
    height="100%"
    bg="transparent"
    noPointers={noPointers}
    p="0"
  >
    <Box
      height="100%"
      ai="center"
      jc="flex-end"
      p={sidebarOffset ? '12px' : 0}
    >
      <Modal
        variant={modalSize}
        relative
        height="100%"
        width="100%"
        p="0"
        isFlexFull
        br="24px"
        overflow={overflow}
      >
        <ModalHeadSidebar
          p={headPadding}
          onClose={onClose}
          onBack={onBack}
          title={modalTitle}
          renderTitle={renderTitle}
          headRight={headRight}
          renderModalContent={renderModalContent}
          borderBottom={modalBorderBottom}
        />
        {children}
      </Modal>
    </Box>
  </Modal>
)

export default ModalSidebarLayout
