import { MyCoursesActionTypes } from 'redux/dashboard/MyCourses/MyCoursesTypes'

const INITIAL_STATE = {
  course_template: null,

  myList: null,
  myCoursesOffset: 0,
  myCoursesMaxOffset: 0,
  isMyFetching: false,
  myError: null,

  anotherList: null,
  anotherCoursesOffset: 0,
  anotherCoursesMaxOffset: 0,
  isAnotherFetching: false,
  anotherError: null,
}

const MyCoursesReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
  case MyCoursesActionTypes.SET_COURSE_TEMPLATE:
    return {
      ...state,
      course_template: action.payload,
    }

  case MyCoursesActionTypes.FETCH_MY_TEMPLATE_SUCCESS:
    return {
      ...state,
      myList: action.payload.results,
      myCoursesMaxOffset: action.payload.count,
      myCoursesOffset: 40,
    }

  case MyCoursesActionTypes.ADD_MY_TEMPLATE_LIST:
    return {
      ...state,
      myList: state.myList.concat(action.payload.results),
      myCoursesOffset: state.myCoursesOffset + 40,
    }

  case MyCoursesActionTypes.CLEAR_MY_TEMPLATE:
    return {
      ...state,
      myList: null,
      myCoursesOffset: 0,
      myCoursesMaxOffset: 0,
      isMyFetching: false,
      myError: null,
    }

  case MyCoursesActionTypes.FETCH_MY_TEMPLATE_ERROR:
    return {
      ...state,
      myError: action.payload
    }

  case MyCoursesActionTypes.FETCH_ANOTHER_TEMPLATE_SUCCESS:
    return {
      ...state,
      anotherList: action.payload.results,
      anotherCoursesMaxOffset: action.payload.count,
      anotherCoursesOffset: 40,
    }

  case MyCoursesActionTypes.ADD_ANOTHER_TEMPLATE_LIST:
    return {
      ...state,
      anotherList: state.anotherList.concat(action.payload.results),
      anotherCoursesOffset: state.anotherCoursesOffset + 40,
    }

  case MyCoursesActionTypes.CLEAR_ANOTHER_TEMPLATE:
    return {
      ...state,
      anotherList: null,
      anotherCoursesOffset: 0,
      anotherCoursesMaxOffset: 0,
      isAnotherFetching: false,
      anotherError: null,
    }

  case MyCoursesActionTypes.FETCH_ANOTHER_TEMPLATE_ERROR:
    return {
      ...state,
      anotherError: action.payload
    }

  default:
    return state
  }
}

export default MyCoursesReducer
