import Button from 'components/base/Button'
import LinkRoute from 'components/typo/Link'
import Text from 'components/typo/Text'
import UserAvatar from 'components/User/UserAvatar'
import { TaskNotificationTypes, TasksTypes } from 'constants/TasksTypes'
import { getUserFullName } from 'helpers/User'
import Box from 'layout/Box'
import { useMemo } from 'react'
import { getPatientUrl } from 'redux/helpers/PatientHelpers'
import { getTaskUrl } from 'redux/Tasks/TasksHelpers'

export const getTaskNamePrefix = (type) => {
  switch (type) {
  case TaskNotificationTypes.UserCourseExercise:
    return TasksTypes.EXERCISES
  case TaskNotificationTypes.UserCourseMedicine:
    return TasksTypes.MEDICINE
  case TaskNotificationTypes.UserCourseConsultation:
    return TasksTypes.CONSULTATION
  case TaskNotificationTypes.UserCourseProcedure:
    return TasksTypes.PROCEDURE
  case TaskNotificationTypes.UserCourseAnalysis:
    return TasksTypes.ANALYSES
  case TaskNotificationTypes.UserCourseManipulation:
    return TasksTypes.MANIPULATION
  case TaskNotificationTypes.UserCourseResearch:
    return TasksTypes.RESEARCHES
  case TaskNotificationTypes.UserCourseExtratask:
    return TasksTypes.EXTRATASKS

  default:
    return ''
  }
}

const NotificationCardTask = ({
  data: {
    patient = {},
    task = {},
    type = '',
  },
  onChat,
  onTask,
  created,
  closeModal,
}) => {
  const taskName = useMemo(() => {
    const propName = getTaskNamePrefix(type)

    if ((task || {}).hasOwnProperty(propName)) {
      return (task[propName] || {}).name || ''
    }

    return ''
  }, [ task, type ])

  return (
    <Box gap="24px">
      <Box gap="8px">
        <UserAvatar
          size="normal"
          avatar={patient.avatar}
          bgColor="grey150"
          user={patient}
        />
        <div>
          <Text>
            Пациент
            {' '}
            <LinkRoute
              to={getPatientUrl(patient.id)}
              defaultStyle="blue"
            >
              {getUserFullName(patient, true)}
            </LinkRoute>
            {' '}
            выполнил задание:
            {' '}
            <LinkRoute defaultStyle="blue" onClick={() => closeModal()} to={getTaskUrl(patient.id, task.scheduled_at)}>
              {type === TaskNotificationTypes.UserCourseConsultation ? 'Консультация' : taskName}
            </LinkRoute>
          </Text>
          <Text className="mt-1" size="small" color="grey500">
            {created}
          </Text>
        </div>
      </Box>
      <Box className="ml-a" direction="column" gap="8px">
        <Button
          onClick={() => onChat(patient.voximplant_username)}
          isGradient
          title="Перейти в чат"
          size="small"
          rounded
          full
          className="ml-a"
        />
        <Button
          onClick={() => onTask(patient.id, task.created_at)}
          title="Задание"
          size="small"
          rounded
          full
          className="ml-a"
        />
      </Box>
    </Box>
  )
}

export default NotificationCardTask
