import Login from 'components/forms/Login'

// redux
import ModalCenterLayout from './ModalCenterLayout'

const ModalLogin = ({ closeModal }) => (
  <ModalCenterLayout
    modalLogo
    modalTitle="Войти"
    onClose={closeModal}
    noPointers
  >
    <Login closeModal={closeModal} />
  </ModalCenterLayout>
)

export default ModalLogin
