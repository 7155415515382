import { AnalyticClinicalIndicatorsActionTypes } from 'redux/dashboard/Analytic/AnalyticClinicalIndicators/AnalyticClinicalIndicatorsTypes'

const todayMonth = new Date()
const previousMonth = new Date()
todayMonth.setDate(1)
previousMonth.setDate(1)
previousMonth.setMonth(previousMonth.getMonth() + 1)

const INITIAL_STATE = {
  sortBy: 'clinic_name',
  monthDateStart: todayMonth,
  monthDateEnd: previousMonth,
  consultationsInfo: null,
  doctorsInfo: null,
  patientsInfo: null,
  tasksInfo: null,
  dataTable: null,
  summaryTable: null,
  offset: 0,
  maxOffset: 0,
  isFullScreen: false,
}

const analyticClinicalIndicatorsReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
  case AnalyticClinicalIndicatorsActionTypes.SET_FULL_SCREEN:
    return {
      ...state,
      isFullScreen: action.payload
    }
  case AnalyticClinicalIndicatorsActionTypes.SET_SORT_BY:
    return {
      ...state,
      sortBy: action.payload
    }
  case AnalyticClinicalIndicatorsActionTypes.SET_MONTH_START:
    return {
      ...state,
      monthDateStart: action.payload
    }
  case AnalyticClinicalIndicatorsActionTypes.SET_MONTH_END:
    return {
      ...state,
      monthDateEnd: action.payload
    }
  case AnalyticClinicalIndicatorsActionTypes.SET_CONSULTATIONS_INFO:
    return {
      ...state,
      consultationsInfo: action.payload
    }
  case AnalyticClinicalIndicatorsActionTypes.SET_DOCTORS_INFO:
    return {
      ...state,
      doctorsInfo: action.payload
    }
  case AnalyticClinicalIndicatorsActionTypes.SET_PATIENTS_INFO:
    return {
      ...state,
      patientsInfo: action.payload
    }
  case AnalyticClinicalIndicatorsActionTypes.SET_TASKS_INFO:
    return {
      ...state,
      tasksInfo: action.payload
    }
  case AnalyticClinicalIndicatorsActionTypes.SET_DATA_TABLE:
    return {
      ...state,
      dataTable: action.payload.results,
      summaryTable: action.payload.summary,
      maxOffset: action.payload.summary.clinic_count,
      offset: 40,
    }
  case AnalyticClinicalIndicatorsActionTypes.ADD_DATA_TABLE:
    return {
      ...state,
      dataTable: state.dataTable.concat(action.payload.results),
      offset: state.offset + 40,
    }

  default:
    return state
  }
}

export default analyticClinicalIndicatorsReducer
