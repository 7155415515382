import { FiltersFormTypes, FiltersTypes } from 'constants/FiltersTypes'
import { compareDatesAsc, compareDatesDesc } from 'helpers/Date'

export const getNewFilters = (stateFilters, payload) => ({
  ...Object.keys(stateFilters)
    .reduce((acc, filterKey) => {
      const payloadFilterValue = payload[filterKey]
      acc[filterKey] = payloadFilterValue

      return acc
    }, {})
})

export const mapReducerInitFilters = (filtersNames = []) => ({
  ...filtersNames.reduce((acc, filterKey) => {
    acc[filterKey] = null
    return acc
  }, {})
})

export const setNewFilters = (filters, newFilters, models = {}) => {
  if (newFilters && filters) {
    if (FiltersTypes.SEARCH in filters) {
      if (!newFilters[FiltersTypes.SEARCH] && filters[FiltersTypes.SEARCH]) {
        newFilters[FiltersTypes.SEARCH] = filters[FiltersTypes.SEARCH]
      }
      else if (!filters[FiltersTypes.SEARCH] && !newFilters[FiltersTypes.SEARCH]) {
        newFilters[FiltersTypes.SEARCH] = ''
      }
    }

    if (FiltersTypes.ORDERING in filters && models[FiltersTypes.ORDERING]) {
      if ((
        !newFilters[FiltersTypes.ORDERING]
        && !filters[FiltersTypes.ORDERING]
      ) || (
        newFilters[FiltersTypes.ORDERING]
        && !models[FiltersTypes.ORDERING].find((f) => f.id === newFilters[FiltersTypes.ORDERING])
      )
      ) {
        newFilters[FiltersTypes.ORDERING] = models[FiltersTypes.ORDERING][0].id
      }
      else if (!newFilters[FiltersTypes.ORDERING] && filters[FiltersTypes.ORDERING]) {
        newFilters[FiltersTypes.ORDERING] = filters[FiltersTypes.ORDERING]
      }
    }
  }

  return newFilters
}

const filterTasksByValue = (tasks, filter, filterKey) => tasks.filter((t) => {
  const taskPriority = t[filterKey]

  if (taskPriority === null) {
    return false
  }

  return filter.includes(taskPriority)
})

const filterTasksByDate = (tasks, filter, filterKey) => {
  const compareFunc = filterKey === 'date_start' ? compareDatesAsc : compareDatesDesc

  return tasks.filter((t) => {
    const taskStart = t[filterKey]

    if (!taskStart) {
      return true
    }

    return compareFunc(new Date(taskStart), new Date(filter)) > 0
  })
}

const filterTasksByTags = (tasks, filter, filterKey) => tasks.filter((t) => {
  const taskTags = t[filterKey]

  if (!Array.isArray(taskTags) || taskTags.length === 0) {
    return false
  }

  return taskTags.some((tag) => filter.includes(tag))
})

const filterTasksByUsers = (tasks, filter, filterKey) => tasks.filter((t) => {
  const taskUsers = t[filterKey]

  if (!Array.isArray(taskUsers) || taskUsers.length === 0) {
    return false
  }

  return taskUsers.some((user) => filter.includes(String(user.id)))
})

export const filterTasks = (tasks, filters) => {
  if (filters && typeof filters === 'object' && Array.isArray(tasks)) {
    return Object.keys(filters).reduce((filteredTasks, filterKey) => {
      const filterValue = filters[filterKey]

      if (!filterValue || (Array.isArray(filterValue) && filterValue.length === 0)) {
        return filteredTasks
      }

      switch (filterKey) {
      case FiltersFormTypes.DATE_START:
      case FiltersFormTypes.DATE_END:
        return filterTasksByDate(filteredTasks, filterValue, filterKey)
      case FiltersFormTypes.PRIORITY:
        return filterTasksByValue(filteredTasks, filterValue, filterKey)
      case FiltersFormTypes.TAGS:
        return filterTasksByTags(filteredTasks, filterValue, filterKey)
      case FiltersFormTypes.PARTICIPANTS:
        return filterTasksByUsers(filteredTasks, filterValue, filterKey)

      default:
        return filteredTasks
      }
    }, tasks)
  }

  return tasks
}
