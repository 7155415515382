import StorageService from 'services/StorageService'
import { ListLayoutTypes } from 'constants/ListTypes'
import { ReferenceTypes } from 'redux/dashboard/Reference/ReferenceTypes'

const INITIAL_STATE = {
  list: null,
  offset: 0,
  maxOffset: 0,
  listCount: 0,
  lastSearch: null,
  isFetching: false,
  patientsCountExpired: 0,
  patientsCountIsAccepted: 0,
  layout: StorageService.getStructureLayout('patients') || ListLayoutTypes.TABLE,

  searchList: null,
  isFetchingSearchList: false,
  searchValue: '',

  filters: StorageService.getPatientFilters() || {},

  hasCreated: false,
  indexLastCreated: null,

  error: null,
}

const referenceReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
  case ReferenceTypes.FETCH_REFERENCE_LIST_SUCCESS: {
    return {
      ...state,
      list: action.payload
    }
  }

  case ReferenceTypes.CLEAR_REFERENCE_LIST:
    return {
      ...state,
      hasCreated: false,
      indexLastCreated: null,
      list: null,
      offset: 0,
    }

  case ReferenceTypes.SET_REFERENCE_LIST_COUNT:
    return {
      ...state,
      listCount: action.payload
    }

  case ReferenceTypes.SET_REFERENCE_LIST_LAST_SEARCH:
    return {
      ...state,
      lastSearch: action.payload
    }

  case ReferenceTypes.SET_REFERENCE_LIST:
    return {
      ...state,
      list: action.payload.results,
      maxOffset: action.payload.count,
      offset: 40,
    }

  case ReferenceTypes.ADD_REFERENCE_LIST:
    return {
      ...state,
      list: state.list.concat(action.payload.results),
      offset: state.offset + 40,
    }

  case ReferenceTypes.SET_FETCHING_REFERENCE_LIST:
    return {
      ...state,
      isFetching: action.payload
    }

  case ReferenceTypes.DELETE_REFERENCE_LIST_ITEM:
    return {
      ...state,
      list: state.list.filter((item) => item.id !== action.payload)
    }

  case ReferenceTypes.SET_FETCHING_REFERENCE_SEARCH:
    return {
      ...state,
      isFetchingSearchList: action.payload
    }

  case ReferenceTypes.FETCH_REFERENCE_SEARCH_SUCCESS:
    return {
      ...state,
      searchList: action.payload
    }

  case ReferenceTypes.CLEAR_REFERENCE_SEARCH:
    return {
      ...state,
      searchList: [],
    }
  case ReferenceTypes.SET_REFERENCE_SEARCH_VALUE:
    return {
      ...state,
      searchValue: action.payload
    }
  case ReferenceTypes.SET_REFERENCE_HAS_CREATED:
    return {
      ...state,
      hasCreated: action.payload
    }
  case ReferenceTypes.SET_REFERENCE_INDEX_LAST_CREATED:
    return {
      ...state,
      indexLastCreated: action.payload
    }
  case ReferenceTypes.SET_REFERENCE_OFFSET:
    return {
      ...state,
      offset: action.payload
    }
  default:
    return state
  }
}

export default referenceReducer
