import { useField } from 'formik'

import FormSelect from './FormSelect'
import FormLabel from './FormLabel'

const FormikSelect = (props) => {
  const {
    options = [],
    labelName = 'title',
    valueName = 'id',
    toggler,
    isMulti = false,
    isLoading = false,
    isClearable = false,
    isSearchable = false,
    isDisabled = false,
    label = '',
    isLabel = '',
    onInputChange = () => {},
    placeholder = 'Выбрать...',
    size = '',
    noBorder = false,
    noPadded = false,
    renderSingleItem = null,
    renderOption = null,
    onMenuScrollToBottom = null,
    onKeyPress = null,
    isRequired = false,
    isCreatable = false,
    labelOpacity = '',
    onChange = () => {},
    onClear = () => {},
    color = '',
    defaultStyle = '',
    ...otherProps
  } = props

  const [ field, meta, helpers ] = useField(props)

  const handleOnChange = (data, meta) => {
    helpers.setValue(data, meta)
    onChange(data, meta)
    return data
  }

  return (
    <label>
      {!!label && (
        <FormLabel title={label} isRequired={isRequired} />
      )}
      <FormSelect
        defaultStyle={defaultStyle}
        size={size}
        color={color}
        noBorder={noBorder}
        noPadded={noPadded}
        valueName={valueName}
        labelName={labelName}
        name={field.name}
        value={field.value}
        error={meta.error}
        labelOpacity={labelOpacity}
        touched={meta.touched}
        isMulti={isMulti}
        closeMenuOnSelect={!isMulti}
        hideSelectedOptions={false}
        backspaceRemovesValue={false}
        isClearable={isMulti || isClearable}
        isSearchable={isSearchable}
        options={options}
        selectedOption={field.value}
        onChange={handleOnChange}
        onClear={onClear}
        onInputChange={onInputChange}
        onKeyPress={onKeyPress}
        captureMenuScroll={false}
        toggler={toggler}
        isLabel={isLabel}
        isDisabled={isDisabled}
        placeholder={placeholder}
        isLoading={isLoading}
        renderSingleItem={renderSingleItem}
        renderOption={renderOption}
        onMenuScrollToBottom={onMenuScrollToBottom}
        isCreatable={isCreatable}
        {...otherProps}
      />
    </label>
  )
}

export default FormikSelect
