export const USER_ROLE_ALL = 'all'
export const UserRolesTypes = {
  DOCTOR: 'doctor',
  CONSULTANT: 'consultant',
  COACH: 'coach',
  PATIENT: 'patient',
  SUPPORT: 'support',
  SUPERVISOR: 'supervisor',
  CLINIC_SUPERVISOR: 'clinic_supervisor',
}

export const UserBindStatusTypes = {
  accepted: 'accepted',
  canceled: 'canceled',
  invited: 'invited',
  untethered: 'untethered',
}

export const UserToDoctorBindStatusTypes = {
  bind: 'bind',
  noBind: 'no_bind',
  mixed: 'mixed',
}

export const UserBindStatusModel = {
  [UserBindStatusTypes.accepted]: {
    id: UserBindStatusTypes.accepted,
    title: 'Привязан'
  },
  [UserBindStatusTypes.invited]: {
    id: UserBindStatusTypes.invited,
    title: 'Запрос отправлен'
  },
  [UserBindStatusTypes.untethered]: {
    id: UserBindStatusTypes.untethered,
    title: 'Не привязан'
  }
}

export const UserBindStatusOptions = Object.keys(UserBindStatusModel).map((key) => UserBindStatusModel[key])

export const UserRolesNamesTypes = {
  [UserRolesTypes.DOCTOR]: 'Врач',
  [UserRolesTypes.CONSULTANT]: 'Администратор',
  [UserRolesTypes.COACH]: 'Тренер',
  [UserRolesTypes.PATIENT]: 'Пациент',
  [UserRolesTypes.SUPPORT]: 'Тех. поддержка',
  [UserRolesTypes.CLINIC_SUPERVISOR]: 'Руководитель клиники',
}

export const UserGenderTypes = {
  MAN: 'male',
  WOMEN: 'female',
}

export const UserGenderModel = {
  [UserGenderTypes.MAN]: {
    id: UserGenderTypes.MAN,
    title: 'Мужчина',
    titleShort: 'муж',
  },
  [UserGenderTypes.WOMEN]: {
    id: UserGenderTypes.WOMEN,
    title: 'Женщина',
    titleShort: 'жен',
  },
}

export const UserGenderOptions = Object.keys(UserGenderModel).map((key) => UserGenderModel[key])

export const UserRolesModel = {
  [UserRolesTypes.DOCTOR]: {
    id: UserRolesTypes.DOCTOR,
    title: UserRolesNamesTypes[UserRolesTypes.DOCTOR]
  },
  [UserRolesTypes.CONSULTANT]: {
    id: UserRolesTypes.CONSULTANT,
    title: UserRolesNamesTypes[UserRolesTypes.CONSULTANT]
  },
  [UserRolesTypes.COACH]: {
    id: UserRolesTypes.COACH,
    title: UserRolesNamesTypes[UserRolesTypes.COACH]
  },
}

export const UserMedicalHistoryTypes = {
  USER_COURSE_EXERCISES: 'UserCourseExercise',
  USER_COURSE_MEDICINE: 'UserCourseMedicine',
  USE_COURSE_ANALYSIS: 'UserCourseAnalysis',
  USER_ANALYSIS: 'UserAnalysis',
  DIARY_RECORDS: 'DiaryRecord',
  DIAGNOSIS: 'Diagnosis',
  DOCTOR_CALENDAR_RECORDS: 'DoctorCalendarRecord'
}

export const UserStructureFilesTypes = {
  INSTRUMENTAL_RESEARCH: 'instrumental_research',
  ANALYSIS: 'analysis',
  ELECTRONIC_RECIPE: 'electronic_recipe',
  SICK_LEAVE: 'sick_leave',
  CERTIFICATE_RECOVERY: 'certificate_recovery',
  MEDICAL_DOCUMENTS: 'medical_documents',
  CONCLUSIONS: 'conclusions',
  INFO: 'info',
}

export const UserInsuranceTypes = {
  OMS: 'oms',
  DMS: 'dms'
}

export const UserInsuranceNames = {
  [UserInsuranceTypes.OMS]: 'ОМС',
  [UserInsuranceTypes.DMS]: 'ДМС',
}
