export const MyCoursesActionTypes = {
  SET_COURSE_TEMPLATE: 'SET_COURSE_TEMPLATE',
  GET_COURSE_TEMPLATE: 'GET_COURSE_TEMPLATE',

  SET_FETCHING_MY_TEMPLATE: 'SET_FETCHING_MY_TEMPLATE',
  ADD_MY_TEMPLATE_LIST: 'ADD_MY_TEMPLATE_LIST',
  FETCH_MY_TEMPLATE_SUCCESS: 'FETCH_MY_TEMPLATE_SUCCESS',
  FETCH_MY_TEMPLATE_ERROR: 'FETCH_MY_TEMPLATE_ERROR',
  CLEAR_MY_TEMPLATE: 'CLEAR_MY_TEMPLATE',

  SET_FETCHING_ANOTHER_TEMPLATE: 'SET_FETCHING_ANOTHER_TEMPLATE',
  ADD_ANOTHER_TEMPLATE_LIST: 'ADD_ANOTHER_TEMPLATE_LIST',
  FETCH_ANOTHER_TEMPLATE_SUCCESS: 'FETCH_ANOTHER_TEMPLATE_SUCCESS',
  FETCH_ANOTHER_TEMPLATE_ERROR: 'FETCH_ANOTHER_TEMPLATE_ERROR',
  CLEAR_ANOTHER_TEMPLATE: 'CLEAR_ANOTHER_TEMPLATE',
}
