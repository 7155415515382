import * as VoxImplant from 'voximplant-websdk'

import { playCallingSound, stopCallingSound } from 'components/Call/call.helpers'
import store from 'redux/store/configureStore'
import { getUserUri } from 'redux/dashboard/Chat/ChatHelpers'
import {
  sendChatMessage, setChatServiceInit, setChatUserLoginStatus,
} from 'redux/dashboard/Chat/ChatActions'

import { log, logError } from 'utils/utils'
import {
  clearCurrentCall,
  setCurrentCall,
  setCurrentCallConnected,
  setCurrentCallUser,
  setLocalVideo,
  setRemoteVideo,
  toggleCallMicMute
} from 'redux/Call/CallActions'
import { getUnicId } from 'helpers/Numbers'
import { CallStatusTypes } from 'constants/CallTypes'
import { setNotification } from 'redux/System/SystemActions'
import {
  CallErrors,
  LOCAL_VIDEO_CONTAINER,
  REMOTE_VIDEO_CONTAINER
} from './call.config'

const appName = process.env.REACT_APP_VOXIMPLANT_APP_NAME
const account = process.env.REACT_APP_VOXIMPLANT_ACCOUNT_ID
const { dispatch, getState } = store

export default class VoxService {
  constructor() {
    this.inst = null
    this.currentCall = null
    this.instGet = null
    this.isInit = false
    this.reconnection = () => {
      log('Connection was closed')
      this.connectToVoxCloud(true)
    }
  }

  static get = () => {
    if (!VoxService.instGet) {
      VoxService.instGet = new VoxService()
    }
    return VoxService.instGet
  }

  init = async () => {
    // Create Voximplant Web SDK instance
    if (!VoxService.inst) VoxService.inst = VoxImplant.getInstance()

    // Reconnect to the Voximplant cloud when disconnected
    VoxService.inst.on(VoxImplant.Events.ConnectionClosed, this.reconnection)

    // Init Voximplant
    if (!this.isInit) {
      VoxService.inst.init({
      // showDebugInfo: true,
        experiments: { messagingV2: true },
        localVideoContainerId: LOCAL_VIDEO_CONTAINER,
        remoteVideoContainerId: REMOTE_VIDEO_CONTAINER
      })
        .then(() => {
          log('SDK initialized')
          this.isInit = true
          // Connect to the Voximplant cloud
          this.connectToVoxCloud()
        })
        .catch(logError)
    }
    else {
      log('SDK already initialized')
      this.isInit = true
      this.connectToVoxCloud()
    }

    VoxService.currentCall = null
  }

  onLogout() {
    dispatch(setChatUserLoginStatus(false))
    dispatch(setChatServiceInit(false))
    dispatch(setChatUserLoginStatus(false))
    // MessengerService.get().removeChat()
    VoxService.inst.off(VoxImplant.Events.ConnectionClosed, this.reconnection)
    VoxService.inst.disconnect()
  }

  getLoginUri(appUserName) {
    return `${appUserName}@${appName}.${account}.voximplant.com`
  }

  /**
   * Sign in the Voximplant cloud
   * return Promise with new tokens
   * @param loginForm data from new filled login form
   * @param accessToken previously saved token
   */
  onLogin({ username, password }) {
    const uri = this.getLoginUri(username)
    return VoxService.inst.login(uri, password)
  }

  refreshTokens(password = '', refreshToken = '') {
    return VoxService.inst.tokenRefresh(password, refreshToken)
  }

  /**
   * Re-login with tokens if connection breaks
   */
  connectToVoxCloud(login) {
    VoxService.inst.connect(false)
      .then(() => {
        log('Connection was established successfully')

        if (login) {
          const auth = getState().user.chat

          if (auth) {
            this.onLogin({
              username: auth.username,
              password: auth.password
            })
          }
        }

        dispatch(setChatServiceInit(true))
      })
      .catch(() => {
        logError('Connection failed')
      })
  }

  setUpCall (props, to) {
    const {
      currentCall,
      isIncoming,
      number,
      video,
    } = props

    let isSuccess = false

    function handleSendCallToChat() {
      const { duration } = getState().dashboard.call

      if (!isIncoming) {
        const sendData = {
          data: {
            text: '',
            payload: [{
              uploadId: getUnicId(),
              attach: [],
              call: {
                duration: duration || 0,
                status: isSuccess ? CallStatusTypes.SUCCESS : CallStatusTypes.MISSED
              }
            }],
          },
          currentConversation: to.currentConversation,
          userName: to.userName
        }

        dispatch(sendChatMessage(sendData))
      }
    }

    dispatch(setRemoteVideo(video))
    dispatch(setCurrentCall(props))
    playCallingSound()

    const streamManager = VoxImplant.Hardware.StreamManager.get()

    streamManager.on(VoxImplant.Hardware.HardwareEvents.BeforeMediaRendererRemoved, (e) => {
      console.log(e, 'BEFORE MEDIA RENDERER REMOVED')
    })

    streamManager.on(VoxImplant.Hardware.HardwareEvents.MediaRendererRemoved, (e) => {
      console.log(e, 'MEDIA RENDERER REMOVED')
    })

    streamManager.on(VoxImplant.Hardware.HardwareEvents.MediaRendererAdded, (e) => {
      console.log(e, 'MEDIA RENDERER ADDED')
      const localNode = document.getElementById(LOCAL_VIDEO_CONTAINER)
      e.renderer.render(localNode)
    })

    streamManager.on(VoxImplant.Hardware.HardwareEvents.MediaRendererUpdated, (e) => {
      console.log(e, 'MEDIA RENDERER UPDATED')
    })

    currentCall.addEventListener(VoxImplant.CallEvents.Updated, (e) => {
      console.log(`CALL UPDATED:`, e)
    })

    currentCall.addEventListener(VoxImplant.CallEvents.Connected, (e) => {
      const { isLocalVideo } = store.getState().dashboard.call.currentCall || {}
      console.log(`CALL CONNECTED`, e)
      dispatch(setCurrentCallConnected())
      stopCallingSound()
      isSuccess = true

      VoxService.inst.showLocalVideo(isLocalVideo)
      dispatch(setRemoteVideo(currentCall.settings.video))
    })

    // subscribe to the endpoint added event
    currentCall.on(VoxImplant.CallEvents.EndpointAdded, (e) => {
      console.log(e, 'MEDIA ENDPOINT ADD')

      e.endpoint.on(VoxImplant.EndpointEvents.RemoteMediaAdded, (e) => {
        console.log(e, 'REMOTE MEDIA ADDED')
        const { kind } = e.mediaRenderer

        if (kind === 'video') {
          const container = document.getElementById(REMOTE_VIDEO_CONTAINER)
          e.mediaRenderer.render(container)
          dispatch(setRemoteVideo(true))
        }
        else {
          dispatch(setRemoteVideo(false))
        }
        // point to the remote video container id
      })

      e.endpoint.on(VoxImplant.EndpointEvents.RemoteMediaRemoved, (e) => {
        const { kind } = e.mediaRenderer

        console.log(e, 'REMOTE MEDIA REMOVED')
        if (kind === 'video') {
          dispatch(setRemoteVideo(false))
        }
      })
    })

    currentCall.addEventListener(VoxImplant.CallEvents.Disconnected, (e) => {
      console.log(`CALL DISCONNECTED!`)
      handleSendCallToChat(e)
      streamManager.hideLocalVideo()
      dispatch(setLocalVideo(false))
      dispatch(setRemoteVideo(false))

      stopCallingSound()
      this.clearCall()
    })

    currentCall.addEventListener(VoxImplant.CallEvents.Failed, (e) => {
      console.log(`CALL FAILED: ${number} failed: ${e.reason} (${e.code})`, e)
      const message = CallErrors[e.code] || `Ошибка звонка: ${e.reason} (${e.code})`

      dispatch(setNotification({
        type: 'error',
        autoClose: true,
        message
      }))
      handleSendCallToChat(e)
      streamManager.hideLocalVideo()
      dispatch(setLocalVideo(false))
      dispatch(setRemoteVideo(false))
      stopCallingSound()
      this.clearCall()
    })
  }

  startCall({
    from = {},
    to = {},
    isVideo = true,
  }) {
    const number = getUserUri(to.userName)
    dispatch(setCurrentCallUser(to))
    dispatch(setLocalVideo(isVideo))

    // prepare settings
    const callSettings = {
      number,
      video: {
        sendVideo: isVideo,
        receiveVideo: true
      },
      extraHeaders: {
        'X-CallerName': from.displayName,
        'X-CallerRole': from.role,
        'X-Avatar': from.avatar,
      }
    }

    // const message

    // start the call
    const call = VoxService.inst.call(callSettings)
    const incoming = {
      currentCall: call,
      isIncoming: false,
      number,
      video: isVideo
    }
    this.setUpCall(incoming, to)
    VoxService.currentCall = call
  }

  addInboundCallListener = () => {
    VoxService.inst.on(VoxImplant.Events.IncomingCall, (e) => {
      this.handleIncomingCall(e)
    })
  }

  clearCall() {
    VoxService.currentCall = null
    dispatch(clearCurrentCall())
  }

  acceptCall({ isVideo = false }) {
    dispatch(setLocalVideo(isVideo))

    if (VoxService.currentCall) {
      VoxService.currentCall.answer(
        '',
        {},
        {
          sendVideo: isVideo,
          receiveVideo: isVideo,
        }
      )
    }
  }

  declineCall() {
    if (VoxService.currentCall) {
      VoxService.currentCall.decline()
    }
  }

  hangupCall() {
    if (VoxService.currentCall) {
      VoxService.currentCall.hangup()
    }
  }

  toggleMuteAudioCall(value) {
    if (VoxService.currentCall) {
      const action = value ? 'muteMicrophone' : 'unmuteMicrophone'
      VoxService.currentCall[action](value)
      dispatch(toggleCallMicMute(value))
    }
  }

  toggleMuteVideoCall(value) {
    if (VoxService.currentCall) {
      VoxService.currentCall.sendVideo(value)
      dispatch(setLocalVideo(value))
    }
  }

  handleIncomingCall({ call }) {
    const { extraHeaders, video } = call.settings
    VoxService.currentCall = call

    const incoming = {
      currentCall: call,
      isIncoming: true,
      number: getUserUri(call.settings.displayName),
      video,
    }

    dispatch(setCurrentCallUser({
      displayName: extraHeaders['X-CallerName'],
      role: extraHeaders['X-CallerRole'],
      avatar: extraHeaders['X-Avatar'],
    }))

    this.setUpCall(incoming)
  }
}
