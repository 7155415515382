import { AuthActionTypes } from 'redux/Auth/AuthTypes'
import StorageService from 'services/StorageService'
import { NotificationsActionsTypes } from './NotificationsTypes'

export const SETTINGS_TYPES = {
  IS_VOICE: 'isVoice',
  IS_DISPLAY: 'isDisplay',
}

export const settingsModel = {
  [SETTINGS_TYPES.IS_VOICE]: true,
  [SETTINGS_TYPES.IS_DISPLAY]: true,
}

const INITIAL_STATE = {
  list: null,
  settings: {
    ...settingsModel,
    ...StorageService.getNotificationsSettings()
  },
  countNew: 0,
  isFetching: false,
  isFetchingReadAll: false,
}

const notificationsReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
  case NotificationsActionsTypes.SET_NEW_NOTIFICATIONS_COUNTS:
    return {
      ...state,
      countNew: action.payload
    }
  case NotificationsActionsTypes.ADD_NEW_NOTIFICATION_COUNT:
    return {
      ...state,
      countNew: state.countNew + 1
    }

  case NotificationsActionsTypes.SET_FETCHING_NOTIFICATIONS:
    return {
      ...state,
      isFetching: action.payload,
    }

  case NotificationsActionsTypes.SET_NOTIFICATIONS_SETTINGS:
    return {
      ...state,
      settings: {
        ...state.settings,
        ...action.payload
      },
    }

  case NotificationsActionsTypes.FETCH_READ_NOTIFICATION_SUCCESS:
    return {
      ...state,
      countNew: state.countNew > 0 ? state.countNew - 1 : 0,
      list: [ ...state.list.map((n) => (n.id === action.payload
        ? {
          ...n,
          is_read: true,
        } : n))
      ],
    }

  case NotificationsActionsTypes.SET_FETCHING_READ_ALL_NOTIFICATION:
    return {
      ...state,
      isFetchingReadAll: action.payload,
    }

  case NotificationsActionsTypes.FETCH_READ_ALL_NOTIFICATION_SUCCESS:
    return {
      ...state,
      countNew: 0,
      list: [ ...state.list.map((n) => ({
        ...n,
        is_read: true,
      })) ],
    }

  case NotificationsActionsTypes.ADD_NEW_NOTIFICATION: {
    return {
      ...state,
      list: state.list
        ? [ action.payload, ...state.list ]
        : [ action.payload ],
    }
  }

  case NotificationsActionsTypes.REMOVE_NEW_NOTIFICATIONS: {
    return {
      ...state,
      list: state.list ? [ ...state.list.filter((n) => n.id !== action.payload) ] : null
    }
  }

  case NotificationsActionsTypes.FETCH_NOTIFICATIONS_SUCCESS:
    return {
      ...state,
      list: state.list
        ? [ ...state.list, ...action.payload ]
        : action.payload,
    }

  case NotificationsActionsTypes.CLEAR_NOTIFICATIONS:
    return {
      ...state,
      list: null,
    }

  case AuthActionTypes.FETCH_LOGOUT_SUCCESS:
    return { ...INITIAL_STATE }

  default:
    return state
  }
}

export default notificationsReducer
