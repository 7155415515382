import { PATIENT_TASKS } from 'constants/PathsTypes'
import { getPatientUrl } from '../helpers/PatientHelpers'

export const filterTaskById = (tasks, taskId) => (Array.isArray(tasks)
  ? tasks.filter((t) => (t.id !== taskId))
  : tasks
)

export const addTask = (tasks, task) => (Array.isArray(tasks)
  ? [ ...tasks, task ]
  : tasks
)

export const updateTasksChecklistCountCompleted = (tasks, payload) => (Array.isArray(tasks)
  ? tasks.map((t) => (t.id === payload.task
    ? {
      ...t,
      check_list_completed: payload.completed
        ? t.check_list_completed + 1
        : t.check_list_completed - 1
    }
    : t)) : tasks)

export const updateTasksChecklistCountsOnCreate = (tasks, payload) => (Array.isArray(tasks)
  ? tasks.map((t) => (t.id === payload.task
    ? {
      ...t,
      check_list_all: t.check_list_all + 1,
      check_list_completed: payload.completed
        ? t.check_list_completed + 1
        : t.check_list_completed
    }
    : t)) : tasks)

export const updateTasksChecklistCountsOnDelete = (tasks, payload) => (Array.isArray(tasks)
  ? tasks.map((t) => (t.id === payload.task
    ? {
      ...t,
      check_list_all: t.check_list_all - 1,
      check_list_completed: payload.completed
        ? t.check_list_completed - 1
        : t.check_list_completed
    }
    : t)) : tasks)

export const updateTasksCommentCountOnCreate = (tasks, payload) => (Array.isArray(tasks)
  ? tasks.map((t) => (t.id === payload.task
    ? {
      ...t,
      comment: t.comment + 1,
    }
    : t)) : tasks)

export const updateTasksCommentCountOnDelete = (tasks, payload) => (Array.isArray(tasks)
  ? tasks.map((t) => (t.id === payload.task
    ? {
      ...t,
      comment: t.comment - 1,
    }
    : t)) : tasks)

export const getTaskUrl = (patientId, taskDate = null) => `${getPatientUrl(patientId)}${PATIENT_TASKS}?current=${taskDate ? taskDate.slice(0, taskDate.indexOf('T')) : ''}`
