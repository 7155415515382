import { PatientConsultationsActionTypes } from 'redux/dashboard/Patient/PatientConsultations/PatientConsultationsTypes'

const INITIAL_STATE = {
  consultations: null,
  isFetching: false,
  isAddFetching: false,
  offset: 0,
  maxOffset: 0,
  listCount: 0,
}

const patientConsultationsReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
  case PatientConsultationsActionTypes.SET_FETCHING_PATIENT_CONSULTATIONS:
    return {
      ...state,
      isFetching: action.payload
    }

  case PatientConsultationsActionTypes.SET_ADD_FETCHING_PATIENT_CONSULTATIONS:
    return {
      ...state,
      isAddFetching: action.payload
    }

  case PatientConsultationsActionTypes.FETCH_PATIENT_CONSULTATIONS_SUCCESS:
    return {
      ...state,
      consultations: action.payload
    }

  case PatientConsultationsActionTypes.SET_PATIENT_CONSULTATIONS:
    return {
      ...state,
      consultations: action.payload.results,
      list: action.payload.results,
      maxOffset: action.payload.count,
      offset: 30,
    }

  case PatientConsultationsActionTypes.ADD_PATIENT_CONSULTATIONS:
    return {
      ...state,
      consultations: state.consultations.concat(action.payload.results),
      offset: state.offset + 30,
    }

  case PatientConsultationsActionTypes.SET_PATIENT_CONSULTATIONS_LIST_COUNT:
    return {
      ...state,
      listCount: action.payload
    }

  default:
    return state
  }
}

export default patientConsultationsReducer
