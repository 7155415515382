// SYSTEM
const isLocalhost = window.location.hostname === 'localhost'
export const PROTOCOL = isLocalhost ? 'https:' : window.location.protocol || 'https:'
// export const PROTOCOL = isLocalhost ? 'http:' : window.location.protocol || 'http:'
export const SERVER_URL = `${PROTOCOL}//${process.env.REACT_APP_HOST}`
export const APP_API_URL = `${SERVER_URL}/api/v1/`
export const APP_API_MEDIA = `https://${process.env.REACT_APP_HOST}/media/`
export const APP_API_MEDIA_SHORT = `https://${process.env.REACT_APP_HOST}`
export const APP_IMAGES = '/assets/images'

// MAIN PAGES
export const PAGE_INDEX = '/'
export const PAGE_POLITIC = '/privacy-policy'
export const PAGE_TERMS = '/terms'
export const PAGE_SUPPORT = '/support'
export const PAGE_LOGIN = '/login'
export const PAGE_LOGIN_RESET_CHANGE = '/login/reset/change'
export const PAGE_LOGIN_RESET_ACCEPT = '/login/reset/accept'
export const PAGE_LOGIN_RESET_NEW = '/login/reset/new'
export const PAGE_ERROR = '/error'

export const SUPPORT_EMAIL = 'support@urbanmedic.ru'

export const PAGE_DASHBOARD = '/dashboard'
export const PAGE_PROFILE = '/profile'

export const DASHBOARD_CALENDAR = 'calendar'
export const DASHBOARD_CHAT = 'messages'
export const DASHBOARD_PATIENTS = 'patients'
export const DASHBOARD_DOCUMENTS = 'documents'
export const DASHBOARD_DOCTORS = 'doctors'
export const DASHBOARD_ADMINISTRATORS = 'administrators'
export const DASHBOARD_CLINICS = 'clinics'
export const DASHBOARD_USERS = 'users'
export const DASHBOARD_CLIENTS = 'clients'
export const DASHBOARD_KNOWLEDGE_BASE = 'knowledge-base'
export const DASHBOARD_MY_COURSES = 'my-courses'
export const DASHBOARD_NEWS = 'news'
export const DASHBOARD_CLINIC_RECORD = 'clinic-record'
export const DASHBOARD_ANALYTICS = 'analytics'
export const DASHBOARD_REFERENCE = 'reference'
export const DASHBOARD_REFERENCE_EDIT = 'edit'
export const DASHBOARD_ARTICLES = 'articles'
export const DASHBOARD_ARTICLES_EDIT = 'editing'
export const DASHBOARD_ARTICLE_SHARE = 'share'
export const DASHBOARD_CLINIC = 'clinic'
export const DASHBOARD_CLINIC_INFO = 'info'
export const DASHBOARD_CLINIC_PREVIEW = 'preview'
export const DASHBOARD_CLINIC_DOCUMENTS = 'documents'
export const DASHBOARD_CLINIC_COMMENTS = 'comments'
export const DASHBOARD_CLINIC_DOCTOR = 'doctor'
export const DASHBOARD_CLINIC_SERVICES = 'services'
export const DASHBOARD_ARTICLES_EDIT_NEW = 'editing/new'

export const DASHBOARD_REFERENCE_ANALYTIC = 'reference'
export const DASHBOARD_REFERENCE_MEDICINE = 'medicine'
export const DASHBOARD_REFERENCE_EXERCISES = 'exercises'
export const DASHBOARD_REFERENCE_ANALYSES = 'analyses'
export const DASHBOARD_REFERENCE_PROCEDURE = 'procedure'
export const DASHBOARD_REFERENCE_CONSULTATION = 'consultation'
export const DASHBOARD_REFERENCE_MANIPULATION = 'manipulation'
export const DASHBOARD_REFERENCE_RESEARCHES = 'researches'
export const DASHBOARD_REFERENCE_EXTRATASKS = 'extratasks'

export const DASHBOARD_ANALYTICS_DASHBOARD = 'dashboard'
export const DASHBOARD_ANALYTICS_CLINICAL_INDICATORS = 'clinical-indicators'
export const DASHBOARD_ANALYTICS_CLINIC = 'clinic'
export const PAGE_DASHBOARD_REFERENCE = `${PAGE_DASHBOARD}/${DASHBOARD_REFERENCE}`
export const PAGE_DASHBOARD_CALENDAR = `${PAGE_DASHBOARD}/${DASHBOARD_CALENDAR}`
export const PAGE_DASHBOARD_CHAT = `${PAGE_DASHBOARD}/${DASHBOARD_CHAT}`
export const PAGE_DASHBOARD_PATIENTS = `${PAGE_DASHBOARD}/${DASHBOARD_PATIENTS}`
export const PAGE_DASHBOARD_DOCTORS = `${PAGE_DASHBOARD}/${DASHBOARD_DOCTORS}`
export const PAGE_DASHBOARD_ADMINISTRATORS = `${PAGE_DASHBOARD}/${DASHBOARD_ADMINISTRATORS}`
export const PAGE_DASHBOARD_CLINIC_RECORD = `${PAGE_DASHBOARD}/${DASHBOARD_CLINIC_RECORD}`
export const PAGE_DASHBOARD_DOCUMENTS = `${PAGE_DASHBOARD}/${DASHBOARD_DOCUMENTS}`
export const PAGE_DASHBOARD_CLINICS = `${PAGE_DASHBOARD}/${DASHBOARD_CLINICS}`
export const PAGE_DASHBOARD_USERS = `${PAGE_DASHBOARD}/${DASHBOARD_USERS}`
export const PAGE_DASHBOARD_CLIENTS = `${PAGE_DASHBOARD}/${DASHBOARD_CLIENTS}`
export const PAGE_DASHBOARD_KNOWLEDGE_BASE = `${PAGE_DASHBOARD}/${DASHBOARD_KNOWLEDGE_BASE}`
export const PAGE_DASHBOARD_MY_COURSES = `${PAGE_DASHBOARD}/${DASHBOARD_MY_COURSES}`
export const PAGE_DASHBOARD_NEWS = `${PAGE_DASHBOARD}/${DASHBOARD_NEWS}`
export const PAGE_DASHBOARD_ANALYTICS = `${PAGE_DASHBOARD}/${DASHBOARD_ANALYTICS}`
export const PAGE_DASHBOARD_ARTICLES = `${PAGE_DASHBOARD}/${DASHBOARD_ARTICLES}`
export const PAGE_DASHBOARD_ARTICLE_EDIT = `${PAGE_DASHBOARD}/${DASHBOARD_ARTICLES}/${DASHBOARD_ARTICLES_EDIT}`

export const PAGE_DASHBOARD_CLINIC = `${PAGE_DASHBOARD}/${DASHBOARD_CLINIC}`
export const PAGE_DASHBOARD_CLINIC_BY_ID = (id) => `${PAGE_DASHBOARD}/${DASHBOARD_CLINIC}/${id}`
export const PAGE_DASHBOARD_CLINIC_INFO = (id) => `${PAGE_DASHBOARD}/${DASHBOARD_CLINIC}/${id}/${DASHBOARD_CLINIC_INFO}`

export const PAGE_DASHBOARD_CLINIC_PREWIEW = `${PAGE_DASHBOARD}/${DASHBOARD_CLINIC}/${DASHBOARD_CLINIC_PREVIEW}`

export const PAGE_DASHBOARD_ARTICLE_SHARE = `${PAGE_DASHBOARD}/${DASHBOARD_ARTICLES}/${DASHBOARD_ARTICLE_SHARE}`
export const PAGE_DASHBOARD_ANALYTICS_DASHBOARD = `${PAGE_DASHBOARD}/${DASHBOARD_ANALYTICS}/${DASHBOARD_ANALYTICS_DASHBOARD}`
export const PAGE_DASHBOARD_CLINICAL_INDICATORS = `${PAGE_DASHBOARD}/${DASHBOARD_ANALYTICS}/${DASHBOARD_ANALYTICS_CLINICAL_INDICATORS}`
export const PAGE_DASHBOARD_ANALYTICS_CLINIC = `${PAGE_DASHBOARD}/${DASHBOARD_ANALYTICS}/${DASHBOARD_ANALYTICS_CLINIC}`

/* REFERENCES */
export const PAGE_DASHBOARD_REFERENCE_EXERCISES = `${PAGE_DASHBOARD}/${DASHBOARD_REFERENCE}/${DASHBOARD_REFERENCE_EXERCISES}`
export const PAGE_DASHBOARD_REFERENCE_EXERCISES_EDIT = `${PAGE_DASHBOARD}/${DASHBOARD_REFERENCE}/${DASHBOARD_REFERENCE_EXERCISES}/${DASHBOARD_REFERENCE_EDIT}`
export const PAGE_DASHBOARD_REFERENCE_ANALYSES = `${PAGE_DASHBOARD}/${DASHBOARD_REFERENCE}/${DASHBOARD_REFERENCE_ANALYSES}`
export const PAGE_DASHBOARD_REFERENCE_ANALYSES_EDIT = `${PAGE_DASHBOARD}/${DASHBOARD_REFERENCE}/${DASHBOARD_REFERENCE_ANALYSES}/${DASHBOARD_REFERENCE_EDIT}`
export const PAGE_DASHBOARD_REFERENCE_MEDICINE = `${PAGE_DASHBOARD}/${DASHBOARD_REFERENCE}/${DASHBOARD_REFERENCE_MEDICINE}`
export const PAGE_DASHBOARD_REFERENCE_MEDICINE_EDIT = `${PAGE_DASHBOARD}/${DASHBOARD_REFERENCE}/${DASHBOARD_REFERENCE_MEDICINE}/${DASHBOARD_REFERENCE_EDIT}`
export const PAGE_DASHBOARD_REFERENCE_PROCEDURE = `${PAGE_DASHBOARD}/${DASHBOARD_REFERENCE}/${DASHBOARD_REFERENCE_PROCEDURE}`
export const PAGE_DASHBOARD_REFERENCE_PROCEDURE_EDIT = `${PAGE_DASHBOARD}/${DASHBOARD_REFERENCE}/${DASHBOARD_REFERENCE_PROCEDURE}/${DASHBOARD_REFERENCE_EDIT}`
export const PAGE_DASHBOARD_REFERENCE_CONSULTATION = `${PAGE_DASHBOARD}/${DASHBOARD_REFERENCE}/${DASHBOARD_REFERENCE_CONSULTATION}`
export const PAGE_DASHBOARD_REFERENCE_MANIPULATION = `${PAGE_DASHBOARD}/${DASHBOARD_REFERENCE}/${DASHBOARD_REFERENCE_MANIPULATION}`
export const PAGE_DASHBOARD_REFERENCE_MANIPULATION_EDIT = `${PAGE_DASHBOARD}/${DASHBOARD_REFERENCE}/${DASHBOARD_REFERENCE_MANIPULATION}/${DASHBOARD_REFERENCE_EDIT}`
export const PAGE_DASHBOARD_REFERENCE_RESEARCHES = `${PAGE_DASHBOARD}/${DASHBOARD_REFERENCE}/${DASHBOARD_REFERENCE_RESEARCHES}`
export const PAGE_DASHBOARD_REFERENCE_RESEARCHES_EDIT = `${PAGE_DASHBOARD}/${DASHBOARD_REFERENCE}/${DASHBOARD_REFERENCE_RESEARCHES}/${DASHBOARD_REFERENCE_EDIT}`
export const PAGE_DASHBOARD_REFERENCE_EXTRATASKS = `${PAGE_DASHBOARD}/${DASHBOARD_REFERENCE}/${DASHBOARD_REFERENCE_EXTRATASKS}`
export const PAGE_DASHBOARD_REFERENCE_EDIT = `${PAGE_DASHBOARD}/${DASHBOARD_REFERENCE}/${DASHBOARD_REFERENCE_EDIT}`

// PATIENT PATHS
export const PATIENT_TASKS = '/tasks'
export const PATIENT_INFO = '/info'
export const PATIENT_ARTCLES = '/articles'
export const PATIENT_ANALYTICS = '/analytics'
export const PATIENT_ANALYZES = '/analyzes'
export const PATIENT_CONSULTATIONS = '/consultations'
export const PATIENT_PROFILE = '/profile'
export const PATIENT_DIARY = '/diary'
export const PATIENT_RESEARCHES = '/researches'
export const PATIENT_INSURANCE = '/insurance'
export const PATIENT_RECIPES = '/recipes'
export const PATIENT_SICKS = '/sicks'
export const PATIENT_EXTRACTS = '/extracts'
export const PATIENT_MEDICAL_DOCUMENTS = '/documents'
export const PATIENT_CONCLUSIONS = '/conclusions'

// ADMINISTRATOR PATHS
export const ADMINISTRATOR_INFO = '/info'

// DOCTOR PATHS
export const DOCTOR_PATIENTS = '/patients'
export const DOCTOR_ADMINISTRATORS = '/administrators'
export const DOCTOR_CONSULTATIONS = '/consultations'
export const DOCTOR_SERVICES_PRICES = '/services-prices'
export const DOCTOR_PROFILE = '/profile'
export const DOCTOR_OPINIONS = '/opinions'
export const DOCTOR_СERTIFICATES = '/certificates'
export const DOCTOR_DOCUMENTS = '/documents'
export const DOCTOR_INFO = '/info'
