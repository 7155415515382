import { useMemo } from 'react'
import cn from 'classnames'
import s from 'styled-components'
import { getTypoCommonStyles } from './typo.styles'

const TitleContainerH1 = s.h1`
  ${getTypoCommonStyles}
`

const TitleContainerH2 = s.h2`
  ${getTypoCommonStyles}
`

const TitleContainerH3 = s.h3`
  ${getTypoCommonStyles}
`

const TitleContainerH4 = s.h4`
  ${getTypoCommonStyles}
`

const TitleContainerH5 = s.h5`
  ${getTypoCommonStyles}
`

const TitleContainerH6 = s.h6`
  ${getTypoCommonStyles}
`

const TitleContainerSpan = s.span`
  ${getTypoCommonStyles}
`

const TitleContainerDiv = s.div`
  ${getTypoCommonStyles}
`

const Title = ({
  children,
  size = 'normal',
  className = '',
  title = '',
  tag = null,
  textAlign = '',
  noSelect = false,
  cursor = '',
  hoverColor = '',
  display = '',
  maxWidth = '',
  color = 'dark',
  noPointers = false,
  nowrap = false,
  caps = false,
  td = '',
  fw = 'b',
  lh = '',
  fs = '',
  ff = '',
  ls = '',
  isElipsed = false,
  ...otherProps
}) => {
  const classes = useMemo(() => cn(
    'w-text',
    {
      [`--ta-${textAlign}`]: textAlign,
      '--no-select': noSelect,
      'text-elipsed': isElipsed,
    },
    className
  ), [ textAlign, noSelect, className, isElipsed ])

  const ComponentContainer = useMemo(() => {
    switch (tag) {
    case 'h1':
      return TitleContainerH1
    case 'h2':
      return TitleContainerH2
    case 'h3':
      return TitleContainerH3
    case 'h4':
      return TitleContainerH4
    case 'h5':
      return TitleContainerH5
    case 'h6':
      return TitleContainerH6
    case 'span':
      return TitleContainerSpan
    case 'div':
    default:
      return TitleContainerDiv
    }
  }, [ tag ])

  const props = {
    className: classes,
    cursor,
    size,
    $hoverColor: hoverColor,
    display,
    maxWidth,
    color,
    noPointers,
    nowrap,
    $caps: caps,
    td,
    fw,
    lh,
    fs,
    ff,
    ls,
    ...otherProps,
  }

  return (
    <ComponentContainer {...props}>
      {children || title}
    </ComponentContainer>
  )
}

export default Title
