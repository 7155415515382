import { Button } from 'components/base'
import Box from 'layout/Box'
import { isToday, parseISO } from 'date-fns'
import { formatDateOrTodayTime } from 'helpers/Date'
import Text from 'components/typo/Text'
import NotifierMessageText from '../NotifierMessageText'

const NotifierCardRecordCanceled = ({
  titleColor, onClinicRecord, createdAt, description
}) => (
  <Box
    direction="column"
    minWidth="412px"
    className="notifier-message-content"
  >
    <Box
      className="mb-3"
    >
      <NotifierMessageText
        color={titleColor}
        text="Заявка отменена"
        fw="m"
        size="normalSm"
      />
    </Box>
    <Box
      className="mb-3"
      direction="column"
    >
      <Text
        fw="n"
      >
        {description}
      </Text>
      <Text
        color="grey500"
        fw="n"
        size="small"
      >
        {isToday(parseISO(createdAt)) ? `Сегодня в ${formatDateOrTodayTime(createdAt, 'HH:mm')}`
          : formatDateOrTodayTime(createdAt, 'd MMMM yyyy в HH:mm')}
      </Text>
    </Box>
    <Button
      flex="1"
      full
      isGradient
      defaultStyle=""
      size="small"
      rounded
      onClick={onClinicRecord}
    >
      <Text
        color="white"
        fw="n"
        size="normalSm"
      >
        Перейти в раздел заявки в клинику
      </Text>
    </Button>
  </Box>
)

export default NotifierCardRecordCanceled
