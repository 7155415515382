/* eslint-disable no-promise-executor-return */
import { toast } from 'react-toastify'
import { setMessage } from 'components/notifier/NotifierMessage'

// types
import { messageTypes } from 'constants/NotifierTypes'
import ApiService from 'services/ApiService'
import { firstLetterDown } from 'utils/string'

import { fetchClinicRecordList, fetchGetClinicRecordsNewCount } from 'redux/dashboard/ClinicRecord/ClinicRecordActions'
import store from 'redux/store/configureStore'
import { fetchDiarysTypes } from '../Diarys/DiarysActions'
import { SystemActionTypes } from './SystemTypes'
// import { fetchCountries } from './LocationActions'
// import { fetchRates } from './RatesActions'

export const setShowPreloader = (value) => ({
  type: SystemActionTypes.SET_SHOW_PRELOADER,
  payload: value,
})

export const setSidebarShow = (value) => ({
  type: SystemActionTypes.SET_SIDEBAR_SHOW,
  payload: value,
})

export const setContentSidebarShow = (value) => ({
  type: SystemActionTypes.SET_CONTENT_SIDEBAR_SHOW,
  payload: value,
})

const setFetchingGetAppModels = (value) => ({
  type: SystemActionTypes.SET_FETCHING_APP_MODELS,
  payload: value,
})

const fetchAppModelsSuccess = (value) => ({
  type: SystemActionTypes.FETCH_APP_MODELS_SUCCESS,
  payload: value,
})

const fetchAppModelsError = (error) => ({
  type: SystemActionTypes.FETCH_APP_MODELS_ERROR,
  payload: error,
})

export const fetchAppModels = () => (dispatch) => {
  dispatch(setFetchingGetAppModels(true))

  return new Promise((res, rej) => ApiService.apiCall({
    url: ApiService.paths.system.MODELS,
    isToken: true,
  })
    .then((response) => {
      dispatch(setFetchingGetAppModels(false))

      const mappedModels = response.reduce((acc, next) => {
        acc[firstLetterDown(next.title)] = [ ...next.choices.map((c) => ({
          id: c.value,
          title: c.verbose_name,
        })) ]

        return acc
      }, {})

      dispatch(fetchAppModelsSuccess(mappedModels))
      res(mappedModels)
    })
    .catch((error) => {
      dispatch(setFetchingGetAppModels(false))
      dispatch(fetchAppModelsError(error))
      rej(error)
    }))
}

export const setNotificationsModal = (value) => ({
  type: SystemActionTypes.SET_NOTIFICATIONS_MODAL,
  payload: value,
})

export const setBrowserScrollWidth = (value) => ({
  type: SystemActionTypes.SET_BROWSER_WIDTH,
  payload: value,
})

export const setModal = (payload) => ({
  type: SystemActionTypes.SET_MODAL,
  payload,
})

function displayObjErrors(data) {
  Object.keys(data).forEach((errorKey) => {
    const errors = data[errorKey]

    if (errors && Array.isArray(errors)) {
      errors.forEach((message) => {
        toast(setMessage({ message: `${errorKey}: ${message}` }), { type: messageTypes.ERROR })
      })
    }
  })
}

export const setNotification = ({
  type = '',
  autoClose = false,
  ...otherProps
}) => (dispatch) => {
  if (dispatch) {
    dispatch({ type: SystemActionTypes.SET_NOTIFICATION })
  }
  const { profile } = store.getState().user
  if (profile.consultant_info && profile.consultant_info.clinic && type === messageTypes.RECORD_NEW) {
    const { id: clinicId } = profile.consultant_info.clinic
    dispatch(fetchGetClinicRecordsNewCount(clinicId))
    dispatch(fetchClinicRecordList({}))
  }
  toast(setMessage(otherProps), {
    type,
    autoClose
  })
}

export const setNotificationError = (data = {}) => (dispatch) => {
  const { non_field_errors: errorMessages, detail } = data

  dispatch({ type: SystemActionTypes.SET_NOTIFICATION_ERROR })

  if (errorMessages) {
    if (Array.isArray(errorMessages)) {
      errorMessages.forEach((message) => toast(setMessage({ message }), { type: messageTypes.ERROR }))
    }
  }
  else if (detail) {
    toast(setMessage({ message: detail }), { type: messageTypes.ERROR })
  }
  else if (Array.isArray(data)) {
    data.forEach((message) => {
      toast(setMessage({ message }), { type: messageTypes.ERROR })
    })
  }
  else if (typeof data === 'object') {
    displayObjErrors(data)
  }
  else {
    toast(setMessage({ message: JSON.stringify(data) }), { type: messageTypes.ERROR })
  }
}

export const setIsTouchDevice = () => ({ type: SystemActionTypes.SET_IS_TOUCH_DEVICE })

const initStateSuccess = (value) => ({
  type: SystemActionTypes.INIT_STATE_SUCCESS,
  payload: value,
})

export const loadInitState = () => (dispatch) => {
  dispatch(setShowPreloader(false))

  return new Promise((res, rej) => {
    Promise.all([
      dispatch(fetchDiarysTypes()),
      // dispatch(fetchCountries()),
      // dispatch(fetchRates())
    ])
      .then(() => {
        dispatch(setShowPreloader(false))
        dispatch(initStateSuccess())
        res()
      })
      .catch((error) => {
        dispatch(setShowPreloader(false))
        rej(error)
      })
  })
}
