/* eslint-disable comma-dangle */
import { useMemo, useState } from 'react'

import { Box } from 'layout'
import Positioner from 'components/Positioner'
import Text from 'components/typo/Text'
import CardImageLazy from 'components/card/CardImageLazy'

import { firstLetterUp } from 'utils/string'
import { colors } from 'theme'

import { getUserAvatarAbr } from 'helpers/User'
import { ReactComponent as IconInfo } from './avatar-warn.svg'

const UserAvatar = ({
  avatar = null,
  placeholderName = '',
  size = 'normal',
  textSize = '',
  br = '50%',
  bs = '',
  className = '',
  isHidden = false,
  isLoader = false,
  brColor = '',
  isWarn = false,
  bgColor = 'blue100',
  overflowCardImage = '',
  user,
  width = null,
  height = null,
}) => {
  const [ error, setError ] = useState(false)

  const avatarImg = useMemo(() => (avatar
    ? typeof avatar === 'string' && !error
      ? avatar
      : avatar.img_file
        ? avatar.img_file
        : null
    : null), [ avatar, error ])

  const firstLetter = typeof placeholderName === 'string' && placeholderName.length > 0
    ? placeholderName.slice(0, 1).toUpperCase()
    : ''

  const abr = (getUserAvatarAbr(user) || '').toUpperCase()

  const warnOffset = size === 'medium' ? '-4px' : size === 'big' ? '-2px' : 0
  const warnBs = isWarn ? `0 0 0 ${size === 'big' ? 3 : 2}px ${colors.red}` : bs

  const iconInfoSize = size === 'big' ? 24 : 22

  return (
    <Box
      style={{
        visibility: isHidden ? 'hidden' : '',
        padding: overflowCardImage === 'visible' ? '1px' : '',
      }}
      ai="center"
      className={className}
      isRelative
      display="inline-block"
    >
      <CardImageLazy
        background={!!avatarImg}
        variant={`avatar${firstLetterUp(size)}`}
        bs={warnBs}
        src={avatarImg}
        isLoader={isLoader}
        bgColor={bgColor}
        br={br}
        brColor={brColor}
        alt="аватар"
        onError={() => setError(true)}
        loaderSmall={size === 'normal' || size === 'small' || size === 'xs' || size === 'xxs' || size === 'medium'}
        overflow={overflowCardImage}
        contentWidth={width || ''}
        contentHeight={height || ''}
      >
        <Positioner type="center">
          <Text
            fw="m"
            size={textSize || (size === 'medium' ? 'normalBig' : size === 'normalSmall' ? 'small' : size)}
            color="blue"
          >
            {abr || firstLetter}
          </Text>
        </Positioner>
      </CardImageLazy>
      {isWarn && (
        <Positioner
          i={1}
          right={warnOffset}
          top={warnOffset}
        >
          <Box
            display="inline-block"
            bs="0 0 0 2px white"
            br="50%"
          >
            <IconInfo width={iconInfoSize} height={iconInfoSize} />
          </Box>
        </Positioner>
      )}
    </Box>
  )
}

export default UserAvatar
