import Switcher from 'components/base/Switcher'
import List from 'components/list/List'
import ListItem from 'components/list/ListItem'
import Text from 'components/typo/Text'
import Box from 'layout/Box'
import { useCallback } from 'react'
import { setNotificationSettings } from 'redux/Notifications/NotificationsActions'
import { SETTINGS_TYPES } from 'redux/Notifications/NotificationsReducer'

const NotificationsSettings = ({ settings, dispatch }) => {
  const listSettings = [
    {
      id: SETTINGS_TYPES.IS_VOICE,
      title: 'Уведомлять звуковым сигналом',
      field: {
        name: SETTINGS_TYPES.IS_VOICE,
        value: settings[SETTINGS_TYPES.IS_VOICE],
      },
      form: { handleChange: () => {} }
    },
    {
      id: SETTINGS_TYPES.IS_DISPLAY,
      title: 'Показывать новое сообщение на экране',
      field: {
        name: SETTINGS_TYPES.IS_DISPLAY,
        value: settings[SETTINGS_TYPES.IS_DISPLAY],
      },
      form: { handleChange: () => {} }
    },
  ]

  const handleToggleSetting = useCallback((name, value) => {
    dispatch(setNotificationSettings({ [name]: value }))
  }, [ dispatch ])

  return (
    <List>
      {listSettings.map((setting) => (
        <ListItem p="18px 0" key={setting.id}>
          <Box jc="space-between" ai="center">
            <Text>{setting.title}</Text>
            <Switcher
              field={setting.field}
              form={{ handleChange: handleToggleSetting }}
            />
          </Box>
        </ListItem>
      ))}

    </List>
  )
}

export default NotificationsSettings
