export const colors = {
  acsent: 'rgba(82, 196, 26, 1)',
  acsent10: 'rgba(82, 196, 26, 0.1)',
  acsent20: 'rgba(224, 247, 212, 1)',
  acsent70: 'rgba(154, 231, 116, 1)',
  acsent80: 'rgba(82, 196, 26, 0.8)',
  acsentLight: 'rgba(238, 249, 232, 1)',
  acsentHover: 'rgba(70, 172, 20, 1)',
  acsentDisabled: 'rgba(70, 172, 20, 0.4)',

  gradient1000: 'linear-gradient(135deg, #00A5EE 1.73%, #14D7D1 100%)',

  black: '#000',
  black10: 'rgba(0, 0, 0, 0.1)',
  black20: 'rgba(0, 0, 0, 0.2)',
  black30: 'rgba(0, 0, 0, 0.3)',
  black80: 'rgba(0, 0, 0, 0.8)',
  black70: 'rgba(0, 0, 0, 0.7)',
  black40: 'rgba(0, 0, 0, 0.4)',

  blue: '#00A5EE',
  blue6: 'rgba(0, 165, 238, 0.06)',
  blue8: '#F2FAFE',
  blue10: 'rgba(236, 243, 255, 1)',
  blue20: '#BAE7FF',
  blue70: 'rgba(105, 192, 255, 1)',
  blue75: '#00A5EE',
  blue80: 'rgba(69, 137, 255, 0.8)',
  blue100: '#E5F6FD',
  blue150: '#CCEDFC',
  blue200: 'rgba(204, 237, 252, 1)',
  blue300: '#B3E4FA',
  blue700: '#4DC0F3',
  blue30: '#F7FCFE',

  dark: '#131417',
  dark30: 'rgba(1, 2, 32, 0.3)',

  green: '#80D188',
  subGreen: '#8BC83D',
  green6: 'rgba(96, 197, 106, 0.06)',
  green200: '#DFF3E1',
  greenLight: 'rgba(223, 243, 225, 1)',
  green500: '#60C56A',
  green100: 'rgba(96, 197, 106, 0.1)',
  green100Clean: '#F0FAF1',

  grey20: 'rgba(239, 239, 239, 0.2)',
  grey100: '#F2F2F7',
  grey150: '#E5E5EA',
  grey200: '#D1D1D6',
  grey250: '#F6F6F6',
  grey300: '#C7C7CC',
  grey400: '#AEAEB2',
  grey500: '#8E8E93',
  grey600: '#636366',
  grey700: '#48484A',
  grey800: '#3A3A3C',
  grey900: '#1C1C1E',
  grey1000: '#404040',

  turquoise: '#27CDDF',
  turquoise200: '#D4F5F9',

  yellow: '#FFC634',
  yellow200: '#FFEDCB',

  orange: '#FF9245',
  subOrange: '#FA8A43',
  orangeLight: 'rgba(255, 244, 236, 1)',

  purple: '#9B71FF',
  purpleLight: 'rgba(246, 240, 255, 1)',
  purpleDark: '#995ff8',
  purple80: '#D2B7FF',
  purple10: '#F6F0FF',
  purple200: '#EBE3FF',

  redLight: 'rgba(255, 237, 238, 1)',
  red: 'rgba(253, 94, 94, 1)',
  subRed: 'rgba(255, 104, 40, 1)',
  red6: 'rgba(253, 94, 94, 0.06)',
  red10: 'rgba(250, 77, 86, 0.1)',
  red30: 'rgba(250, 77, 86, 0.3)',
  red80: 'rgba(250, 77, 86, 0.8)',
  red200: 'rgba(255, 223, 223, 1)',

  white: '#fff',
  white10: 'rgba(255, 255, 255, 0.1)',
  white15: 'rgba(255, 255, 255, 0.15)',
  white20: 'rgba(255, 255, 255, 0.20)',
  white40: 'rgba(255, 255, 255, 0.4)',
  white50: 'rgba(255, 255, 255, 0.5)',
  white70: 'rgba(255, 255, 255, 0.7)',

  inherit: 'inherit',
  current: 'currentColor',
  transparent: 'rgba(0, 0, 0, 0)',
}

const shadow = { main: '0px 6px 10px 0 rgba(0, 0, 0, 0.12)' }

const widths = {
  full: '100%',
  vw: '100vw',
  vh: '100vh',
  auto: 'auto',
  initial: 'initial',
  inherit: 'inherit',
}

const spacers = {
  0: 0,
  1: 4,
  2: 8,
  3: 12,
  4: 16,
  5: 20,
  6: 24,
  7: 28,
  8: 32,
  9: 36,
  10: 40,
}

export const mediaQuery = {
  mobileXs: 480,
  mobile: 600,
  laptop: 768,
  laptopBig: 1040,
  desktop: 1240,
}

export const fontFamily = {
  main: 'Roboto',
  secondary: 'Arial',
}

export const letterSpacing = {
  main: '',
  none: '0px',
}

export const fontSizes = {
  xl: '34px',
  big: '28px',
  medium: '22px',
  mediumSm: '20px',
  normalBig: '18px',
  normal: '16px',
  normalSm: '14px',
  small: '13px',
  xs: '12px',
  xxs: '11px',
}

export const lineHeight = {
  xl: '41px',
  big: '34px',
  medium: '28px',
  mediumSm: '24px',
  normalBig: '22px',
  normal: '22px',
  normalSm: '20px',
  small: '18px',
  xs: '16px',
  xxs: '13px',
}

export const fontWeights = {
  b: '700',
  sb: '600',
  m: '500',
  n: '400',
  l: '300',
  inherit: 'inherit',
}

export const sizes = {
  full: '100%',
  xl: '100px',
  big: '60px',
  normal: '50px',
  small: '44px',
  xs: '36px',
  xxs: '28px',
  auto: 'auto',
  initial: 'initial',
}

export const theme = {
  colors,
  fs: fontSizes,
  fw: fontWeights,
  lh: lineHeight,
  ls: letterSpacing,
  ff: fontFamily,
  mq: mediaQuery,
  spacers,
  shadow,
  widths,
  components: {
    container: {
      defaultPadding: '0 16px',
      sizes: {
        normal: { maxWidth: '1175px' },
        full: {
          maxWidth: '1342px',
          padding: 0,
        },
        small: { maxWidth: '1070px' },
        xs: { maxWidth: '860px' },
      },
    },
    typo: {
      styles: {
        ff: fontFamily.main,
        ls: letterSpacing.main,
        fs: fontSizes.normal,
      },
      sizes: {
        xl: {
          fs: fontSizes.xl,
          lh: lineHeight.xl,
        },
        big: {
          fs: fontSizes.big,
          lh: lineHeight.big,
        },
        medium: {
          fs: fontSizes.medium,
          lh: lineHeight.medium,
        },
        mediumSm: {
          fs: fontSizes.mediumSm,
          lh: lineHeight.mediumSm,
        },
        normalBig: {
          fs: fontSizes.normalBig,
          lh: lineHeight.normalBig,
        },
        normal: {
          fs: fontSizes.normal,
          lh: lineHeight.normal,
        },
        normalSm: {
          fs: fontSizes.normalSm,
          lh: lineHeight.normalSm,
        },
        small: {
          fs: fontSizes.small,
          lh: lineHeight.small,
        },
        xs: {
          fs: fontSizes.xs,
          lh: lineHeight.xs,
        },
        xxs: {
          fs: fontSizes.xxs,
          lh: lineHeight.xxs,
        },
      },
    },
    modal: {
      common: {
        br: '24px',
        padding: '32px',
        width: '100%',
        height: '100%',
        maxWidth: 'unset',
        bg: colors.white,
      },
      variant: {
        full: {
          maxWidth: 'unset',
          height: '100vh',
          br: '0',
        },
        img: {
          maxWidth: '90vw',
          height: 'auto',
          maxHeight: '90vh',
          br: '0',
        },
        xl: {
          maxWidth: '838px',
          br: '0'
        },
        big: {
          maxWidth: '770px',
          br: '0'
        },
        mediumBig: {
          maxWidth: '590px',
          br: '0'
        },
        medium: {
          maxWidth: '620px',
          br: '0'
        },
        normalHuge: {
          maxWidth: '488px',
          br: '16px'
        },
        normalBig: {
          maxWidth: '445px',
          br: '16px'
        },
        normal: {
          maxWidth: '438px',
          br: '24px'
        },
        sidebarSmall: {
          maxWidth: '423px',
          br: '0'
        },
        sidebarXs: {
          maxWidth: '384px',
          br: '0'
        },
        auto: {
          maxWidth: '800px',
          width: 'auto',
          minWidth: '300px',
          br: '0'
        },
      },
    },
    sidebar: { width: '255px' },
    logo: {
      sizes: {
        big: {
          width: '220px',
          height: '65px',
        },
        normal: {
          width: '108px',
          height: '32px',
        },
        small: {
          width: '54px',
          height: '16px',
        },
      },
      styles: {
        acsent: {
          fill: colors.acsent,
          fillHover: colors.black,
        },
        white: {
          fill: colors.white,
          color: colors.white,
        },
        black: {
          fill: colors.black,
          color: colors.black,
        },
      },
    },
    cardImage: {
      auto: {
        width: '100%',
        height: '100%',
      },
      full: {
        width: '100%',
        height: 'auto',
        padding: '100% 0 0 0',
        br: 30,
      },
      huge: {
        width: '116px',
        height: '116px',
        padding: '0',
        br: 20,
      },
      big: {
        width: '100px',
        height: '100px',
        padding: '0',
        br: 30,
      },
      medium: {
        width: '50px',
        height: '50px',
        padding: '0',
        br: 12,
      },
      normal: {
        width: '36px',
        height: '36px',
        padding: '0',
        br: 3,
      },
      small: {
        width: '24px',
        height: '24px',
        padding: '0',
      },
      avatarHuge: {
        width: '116px',
        height: '116px',
        padding: '0',
        br: '50%',
      },
      avatarBig: {
        width: '70px',
        height: '70px',
        padding: '0',
        br: '50%',
      },
      avatarMedium: {
        width: '50px',
        height: '50px',
        padding: '0',
        br: '50%',
      },
      avatarNormal: {
        width: '38px',
        height: '38px',
        padding: '0',
        br: '50%',
      },
      avatarNormalSmall: {
        width: '30px',
        height: '30px',
        padding: '0',
        br: '50%',
      },
      avatarSmall: {
        width: '24px',
        height: '24px',
        padding: '0',
        br: '50%',
      },
      avatarXs: {
        width: '20px',
        height: '20px',
        padding: '0',
        br: '50%',
      },
      filePreview: {
        width: '100px',
        height: 'auto',
        padding: '0',
        br: 8,
      },
    },
    dropdown: {
      positions: {
        'top-right': {
          bottom: '100%',
          right: '0',
          marginBottom: '4px',
        },
        'top-left': {
          bottom: '100%',
          left: '0',
          marginBottom: '4px',
        },
        'bottom-right': {
          top: '100%',
          right: '0',
          marginTop: '4px',
        },
        'bottom-left': {
          top: '100%',
          left: '0',
          marginTop: '4px',
        },
      },
    },
    dropzone: {
      color: colors.acsent,
      border: '1px solid currentColor',
      sizes: {
        square: {
          width: '82px',
          height: '82px',
          br: '12px',
        },
        avatar: {
          width: '82px',
          height: '82px',
          br: '50%',
          clear: {
            top: '0',
            right: '0',
          },
        },
        normal: {
          width: '176px',
          height: '224px',
        },
        article: {
          width: '350px',
          height: '200px',
        },
        big: {
          width: '100%',
          height: '410px',
        },
      },
    },
    spinner: {
      color: colors.black,
      sizes: {
        big: {
          radius: '44px',
          stroke: '3px',
        },
        medium: {
          radius: '32px',
          stroke: '3px',
        },
        normal: {
          radius: '24px',
          stroke: '2.5px',
        },
        small: {
          radius: '16px',
          stroke: '2px',
        },
        xs: {
          radius: '10px',
          stroke: '2px',
        },
      },
    },
    cardPlaceholder: {
      story: {
        normal: {
          width: 130,
          height: 140,
          radius: 8,
        },
      },
      square: {
        xl: {
          width: 100,
          height: 100,
          radius: 16,
        },
        bigRound: {
          width: 66,
          height: 66,
          radius: 50,
        },
        big: {
          width: 60,
          height: 60,
          radius: 10,
        },
        normal: {
          width: 44,
          height: 44,
          radius: 12,
        },
        small: {
          width: 28,
          height: 28,
          radius: 6,
        },
      },
      line: {
        xl: {
          width: null,
          height: 46,
          radius: 12,
        },
        big: {
          width: null,
          height: 71,
          radius: 8,
        },
        normal: {
          width: null,
          height: 50,
          radius: 8,
        },
        small: {
          width: null,
          height: 18,
          radius: 9,
        },
      },
    },
    button: {
      minWidths: { normal: '160px' },
      sizes: {
        big: {
          height: '50px',
          padding: '14px 20px',
          offset: '9px',
          spinner: 'normal',
          fs: fontSizes.normal,
          fw: fontWeights.m,
          br: '8px',
        },
        normal: {
          height: '44px',
          padding: '6px 20px',
          spinner: 'small',
          offset: '8px',
          fs: fontSizes.normal,
          fw: fontWeights.m,
          br: '8px',
        },
        small: {
          height: '30px',
          padding: '6px 12px',
          spinner: 'small',
          offset: '5px',
          fs: fontSizes.small,
          fw: fontWeights.m,
          br: '4px',
        },
        xs: {
          height: '26px',
          padding: '5px 10px',
          // spinner: 'small',
          offset: '5px',
          fs: fontSizes.xs,
          fw: fontWeights.m,
          br: '4px',
        },
      },
      styles: {
        acsent: {
          bg: colors.acsent,
          bgHover: colors.acsentHover,
          color: colors.white,
          fill: colors.white,
          spinnerColor: colors.white,
          disabledBg: colors.acsentDisabled,
          disabledColor: colors.white,
        },
        white: {
          bg: colors.white,
          bgHover: colors.grey100,
          color: colors.dark,
          colorHover: colors.acsent,
          colorLoad: colors.dark,
          spinnerColor: colors.dark,
          disabledBg: colors.grey200,
          disabledColor: colors.grey500,
        },
        black: {
          bg: colors.dark,
          color: colors.white,
          colorHover: colors.black80,
          spinnerColor: colors.white,
        },
        green: {
          bg: colors.green,
          color: colors.white,
          colorHover: colors.green500,
          spinnerColor: colors.white,
        },
        transparent: {
          bgHover: colors.grey100,
          bg: colors.transparent,
          color: colors.dark,
          fill: colors.black,
          spinnerColor: colors.dark,
        },
        transparentLight: {
          bgHover: colors.white15,
          bg: colors.transparent,
          color: colors.white,
          spinnerColor: colors.white,
        },
        transparentWhite: {
          bgHover: colors.white15,
          bg: colors.transparent,
          color: colors.white,
          spinnerColor: colors.white,
        },
        transparentGrey: {
          bgHover: colors.transparent,
          bg: colors.transparent,
          color: colors.grey400,
          colorHover: colors.white,
          fillHover: colors.white,
          spinnerColor: colors.grey400,
        },
        transparentGreyDark: {
          bgHover: colors.transparent,
          bg: colors.transparent,
          color: colors.grey400,
          colorHover: colors.grey600,
          fillHover: colors.grey600,
          spinnerColor: colors.grey400,
        },
        transparentBlue: {
          bgHover: colors.transparent,
          bg: colors.transparent,
          color: colors.blue,
          colorHover: colors.white,
          fill: colors.blue,
          fillHover: colors.black,
          spinnerColor: colors.grey400,
        },
        transparentBlueLight: {
          bgHover: colors.transparent,
          bg: colors.transparent,
          color: colors.blue700,
          colorHover: colors.blue700,
          fill: colors.blue700,
          fillHover: colors.blue,
          spinnerColor: colors.grey400,
        },
        link: {
          bg: colors.transparent,
          color: colors.acsent,
          colorHover: colors.acsent80,
          spinnerColor: colors.acsent,
          fw: fontWeights.m,
          p: 0,
        },
        'link-light': {
          bg: colors.transparent,
          color: colors.grey500,
          colorHover: colors.grey700,
          spinnerColor: colors.grey700,
          fw: fontWeights.n,
          p: 0,
        },
        'acsent-light': {
          bg: colors.acsent10,
          color: colors.acsent,
          colorHover: colors.acsent80,
          spinnerColor: colors.acsent,
          fw: fontWeights.m,
        },
        'grey-light': {
          bg: colors.grey100,
          bgHover: colors.grey200,
          fill: colors.grey300,
          color: colors.grey500,
          colorHover: colors.dark,
          spinnerColor: colors.grey500,
          fw: fontWeights.m,
        },
        'green-light': {
          bg: colors.green100,
          bgHover: colors.green200,
          fill: colors.green500,
          color: colors.green500,
          colorHover: colors.green500,
          spinnerColor: colors.green500,
          fw: fontWeights.m,
        },
        blue: {
          bg: colors.blue,
          bgHover: colors.blue80,
          color: colors.white,
          spinnerColor: colors.white,
          fw: fontWeights.sb,
        },
        'blue-light': {
          bg: colors.blue100,
          bgHover: colors.blue200,
          color: colors.black,
          spinnerColor: colors.black,
          fw: fontWeights.sb,
        },
        dark: {
          bg: colors.grey900,
          fill: colors.white,
          color: colors.white,
          bgHover: colors.grey1000,
          disabledColor: colors.grey700,
          disabledBg: colors.grey900
        },
        'dark-active': {
          bg: colors.grey1000,
          fill: colors.white,
          color: colors.white,
          bgHover: colors.grey1000,
          disabledColor: colors.grey700,
          disabledBg: colors.grey900
        },
        light: {
          bg: colors.grey100,
          fill: colors.black,
          color: colors.black,
          bgHover: colors.grey150,
        },
        settings: {
          bg: colors.transparent,
          fill: colors.grey500,
          fillHover: colors.dark,
        },
        'settings-shadow': {
          bg: colors.white,
          fill: colors.black,
          fillHover: colors.black,
          bs: '0 2px 4px 0 rgba(0, 0, 0, 0.08)',
          bc: colors.grey600
        },
        secondaryGray: {
          bgHover: colors.grey150,
          bg: colors.grey100,
          color: colors.grey800,
          spinnerColor: colors.black,
        },
        play: {
          bg: colors.black40,
          bgHover: colors.black70,
          fill: colors.white,
        },
        purple: {
          bg: colors.purple,
          color: colors.white,
          bgHover: colors.purpleDark,
          fill: colors.white,
        },
        danger: {
          bg: colors.red,
          color: colors.white,
          bgHover: colors.red80,
          fill: colors.white,
        },
        greyDark: {
          bg: colors.white15,
          color: colors.white,
          bgHover: colors.white40,
          fill: colors.white,
        },
      },
    },
    buttonRound: {
      sizes: {
        xl: {
          size: '90px',
          padding: '15px',
        },
        big: {
          size: '50px',
          padding: '15px',
        },
        medium: {
          size: '40px',
          padding: '0px',
        },
        normal: {
          size: '44px',
          padding: '8px',
        },
        normalSm: {
          size: '38px',
          padding: '6px',
        },
        small: {
          size: '32px',
          padding: '3px',
        },
        xs: {
          size: '24px',
          padding: '0px',
        },
        xxs: {
          size: '16px',
          padding: '3px',
        },
        reset: {
          size: '30px',
          padding: '7px',
        },
        settings: {
          size: '28px',
          padding: '0px',
        },
      },
    },
  },
  sizes,
  radius: {
    xl: '30px',
    round: '50%',
    huge: '24px',
    big: '16px',
    medium: '12px',
    normal: '8px',
    small: '4px',
    unset: 'unset',
  },
  gutters: {
    xl: 30,
    big: 16,
    medium: 12,
    normal: 8,
    small: 10,
    xs: 5,
  },
  paddings: {
    big: '40px',
    modalBig: '20px 40px',
    modalBigY: '20px 0',
    modalBigX: '0 40px',
    modalBigNoTop: '0 40px 20px',
    normal: '24px',
    small: '20px',
    'card-list': '24px 12px',
    'card-list-head': '7px 12px',
    unset: 'unset',
  },
}
