import Text from 'components/typo/Text'
import CardBlockWithIcon from 'components/card/CardBlockWithIcon'

import { ReactComponent as IconCalendar } from 'assets/icons/calendar.svg'
import { DATE_FORMAT_MAIN, formatDate } from 'helpers/Date'

import { Box } from 'layout/index'
import Divider from 'layout/Divider'
import Positioner from 'components/Positioner'
import GridItem from 'layout/GridItem'
import FormFieldDatepicker from './FormFieldDatepicker'

const FormBlockDatepicker = ({
  placeholder = 'Выберите дату',
  onSelect = () => {},
  name = 'date',
  title = '',
  maxDate = null,
  minDate = null,
  isEdit = true,
  value = '',
  formatter = DATE_FORMAT_MAIN,
  mixed = false,
  mixedPlaceholders = [ '', '' ],
  mixedNames = [ '', '' ],
  mixedEdits = [ true, true ],
}) => (
  <CardBlockWithIcon title={title} Icon={IconCalendar}>
    {
      mixed ? (
        <Box
          ai="center"
        >
          {
            mixedEdits[0] ? (
              <FormFieldDatepicker
                placeholder={mixedPlaceholders[0]}
                defaultStyle="transparent"
                onSelect={onSelect}
                name={mixedNames[0]}
                maxDate={maxDate[0]}
                minDate={minDate[0]}
                small
              />
            ) : <Text color="grey500">{formatDate(value[0], formatter)}</Text>
          }
          <GridItem
            className="ml-10 mr-10"
            flex="0"
          >
            <Divider isVertical height="22px" />
          </GridItem>
          {
            mixedEdits[1] ? (
              <Box
                flex="0 0.22 auto"
              >
                <FormFieldDatepicker
                  placeholder={mixedPlaceholders[1]}
                  defaultStyle="transparent"
                  onSelect={onSelect}
                  name={mixedNames[1]}
                  maxDate={maxDate[1]}
                  minDate={minDate[1]}
                  positionRight
                  small
                />
              </Box>

            ) : <Text color="grey500">{formatDate(value[1], formatter)}</Text>
          }
        </Box>
      ) : isEdit ? (
        <FormFieldDatepicker
          placeholder={placeholder}
          defaultStyle="transparent"
          onSelect={onSelect}
          name={name}
          maxDate={maxDate}
          minDate={minDate}
        />
      ) : <Text color="grey500">{formatDate(value, formatter)}</Text>
    }

  </CardBlockWithIcon>
)

export default FormBlockDatepicker
