export const ClinicRecordActionTypes = {
  SET_FETCHING_CLINIC_RECORD_LIST: 'SET_FETCHING_CLINIC_RECORD_LIST',
  FETCH_CLINIC_RECORD_LIST_SUCCESS: 'FETCH_CLINIC_RECORD_LIST_SUCCESS',
  FETCH_CLINIC_RECORD_LIST_ERROR: 'FETCH_CLINIC_RECORD_LIST_ERROR',
  CLEAR_CLINIC_RECORD_LIST: 'CLEAR_CLINIC_RECORD_LIST',
  SET_CLINIC_RECORD_LIST_LAST_SEARCH: 'SET_CLINIC_RECORD_LIST_LAST_SEARCH',
  SET_CLINIC_RECORD_LIST_COUNT: 'SET_CLINIC_RECORD_LIST_COUNT',
  ADD_CLINIC_RECORD_TO_LIST: 'ADD_PATIENT_TO_LIST',

  SET_FETCHING_CLINIC_RECORD_SEARCH: 'SET_FETCHING_CLINIC_RECORD_SEARCH',
  FETCH_CLINIC_RECORD_SEARCH_SUCCESS: 'FETCH_CLINIC_RECORD_SEARCH_SUCCESS',
  FETCH_CLINIC_RECORD_SEARCH_ERROR: 'FETCH_CLINIC_RECORD_SEARCH_ERROR',
  CLEAR_CLINIC_RECORD_SEARCH: 'CLEAR_CLINIC_RECORD_SEARCH',

  SET_FETCHING_UPLOAD_CLINIC_RECORD_LIST: 'SET_FETCHING_UPLOAD_CLINIC_RECORD_LIST',
  FETCH_UPLOAD_CLINIC_RECORD_LIST_ERROR: 'FETCH_UPLOAD_CLINIC_RECORD_LIST_ERROR',
  FETCH_UPLOAD_CLINIC_RECORD_LIST_SUCCESS: 'FETCH_UPLOAD_CLINIC_RECORD_LIST_SUCCESS',
  SET_CLINIC_RECORD_LIST: 'SET_CLINIC_RECORD_LIST',
  ADD_CLINIC_RECORD_LIST: 'ADD_CLINIC_RECORD_LIST',
  SET_ONLY_CLINIC_RECORD_LIST: 'SET_ONLY_CLINIC_RECORD_LIST',

  SET_CLINIC_RECORD_FILTERS: 'SET_CLINIC_RECORD_FILTERS',
  SET_CLINIC_RECORD_FILTER: 'SET_CLINIC_RECORD_FILTER',
  SET_CLINIC_RECORD_SEARCH_VALUE: 'SET_CLINIC_RECORD_SEARCH_VALUE',

  SET_CLINIC_RECORD_NEW_COUNT: 'SET_CLINIC_RECORD_NEW_COUNT',
}
