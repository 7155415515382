import React, {
  useState,
  useMemo,
  useCallback,
  useRef,
  useEffect
} from 'react'
import { useField, useFormikContext } from 'formik'
import styled from 'styled-components'

import FormDropdown from 'components/form/FormDropdown'
import Datepicker from 'components/Datepicker'

import { isDateMask } from 'helpers/RegExp'
import { formatDate } from 'helpers/Date'
import { addDays } from 'date-fns'

function getDateProps(value) {
  return [ value.substr(0, 2), value.substr(3, 2), value.substr(6, 4) ]
}

const DropdownContainer = styled.div`
`

const FormFieldDatepicker = ({
  placeholder = '',
  defaultStyle = 'field',
  dateFormat = 'dd MMMM yyyy',
  size = '',
  label = '',
  isDisabled = false,
  onClear = null,
  readOnly = false,
  positionLeft = false,
  positionRight = false,
  placeholderColor = '',
  dataDivider = '-',
  onBlur = () => {},
  onSelect = () => {},
  onClose = () => {},
  bgColor = '',
  color = '',
  minDate = '',
  maxDate = '',
  isRange = false,
  height = '',
  width = '',
  fs = '',
  isError = false,
  className = '',
  small = false,
  date = '',
  setSelectOption,
  duration,
  isMultipleInstall,
  nameSection = null,
  ...props
}) => {
  const { setFieldValue, errors } = useFormikContext()
  const [ field ] = useField(props)
  const [ searchValue, setSearchValue ] = useState('')
  const [ isFocused, setFocused ] = useState(false)
  const [ dropdownPosition, setDropdownPosition ] = useState(false)
  const isAbort = useRef(false)
  const isTouched = useRef(false)
  const dropdownRef = useRef(null)
  const dateSearchFormatter = `dd${dataDivider}MM${dataDivider}yyyy`
  const fieldTouched = isTouched && isTouched.current
  const fieldError = (fieldTouched && errors && errors[field.name]) || (isFocused && searchValue && !isDateMask(searchValue) ? `Некорректная дата, формат 02-10-1989` : '') || isError
  const fieldValue = useMemo(() => {
    if (date[0] && (date[1] || !isRange)) {
      return `${formatDate(date[0], dateFormat)} - ${formatDate(date[1], dateFormat)}`
    }
    if (isFocused) {
      return searchValue
    }

    if (!field.value) {
      return ''
    }

    if (isRange) {
      const [ start, end ] = field.value
      if (start && !end) return formatDate(start, dateFormat)
      return `${formatDate(start, dateFormat)} - ${formatDate(end, dateFormat)}`
    }

    return formatDate(field.value, dateFormat)
  }, [ isRange, isFocused, field, searchValue, dateFormat ])

  const handleFieldBlur = useCallback(() => {
    onBlur(field.value, field.name)
    if (!readOnly) {
      if (!isAbort.current) {
        if (searchValue && isDateMask(searchValue) && !searchValue !== formatDate(field.value, dateSearchFormatter)) {
          setFieldValue(field.name, field.value)
          onSelect(field.value, field.name)
        }

        if (!isRange) setFocused(false)
      }
      else {
        isAbort.current = false
      }
    }
  }, [
    readOnly,
    setFieldValue,
    field,
    searchValue,
    dateSearchFormatter,
    setSearchValue,
    setFocused,
    onSelect,
    isRange,
  ])

  const handleFieldClose = useCallback(() => {
    onClose(field.value, field.name)
    setFocused(false)
  }, [
    readOnly,
    setFieldValue,
    field,
    searchValue,
    dateSearchFormatter,
    setSearchValue,
    setFocused,
    onSelect,
    isRange,
  ])

  const handleFieldFocus = useCallback(() => {
    if (!readOnly) {
      setFocused(true)
      if (!isRange) setSearchValue(field.value ? formatDate(field.value, dateSearchFormatter) : '')
    }
  }, [
    readOnly,
    field,
    setFocused,
    setSearchValue,
    dateSearchFormatter,
    isRange
  ])

  const handleFieldChange = useCallback(
    (e) => {
      if (isFocused) {
        isTouched.current = true

        const { value } = e.target

        if (isDateMask(value)) {
          const [ d, m, y ] = getDateProps(value)
          const mappedValue = new Date(`${y}${dataDivider}${m}${dataDivider}${d}`)
          setFieldValue(field.name, mappedValue)
          onSelect(mappedValue, field.name)
        }

        setSearchValue(value)
      }
    },
    [
      isFocused,
      field,
      dataDivider,
      setFieldValue,
      setSearchValue,
      onSelect
    ]
  )

  const handleDatepickerSelect = useCallback((val, closeDropdown) => {
    isTouched.current = true

    if (!readOnly) {
      isAbort.current = true

      if (duration && Array.isArray(val) && val[0] && !props.endDate) {
        let endDate = val[1]

        if (!endDate) {
          endDate = addDays(val[0], Number(duration) - 1)
        }

        setFieldValue(field.name, [ val[0], endDate ])
        onSelect([ val[0], endDate ], field.name)
      }
      else {
        setFieldValue(field.name, val)
        onSelect(val, field.name)
      }

      setFocused(false)
    }
    else {
      setFieldValue(field.name, val)
      onSelect(val, field.name)
    }
    if (!nameSection) {
      if (isRange) {
        if (val[1]) closeDropdown()
      }
      else {
        closeDropdown()
      }

      onSelect(val, field.name)
    }
  }, [ isRange, duration, readOnly, field, setFieldValue, setFocused, onSelect ])

  const calculateDropdownPosition = useCallback(() => {
    if (dropdownRef.current) {
      const rect = dropdownRef.current.getBoundingClientRect()
      const spaceBelow = window.innerHeight - rect.bottom
      const spaceAbove = rect.top
      if (spaceBelow < 300 && spaceAbove > spaceBelow) {
        setDropdownPosition(true)
      }
      else {
        setDropdownPosition(false)
      }
    }
  }, [])

  useEffect(() => {
    if (isFocused) {
      calculateDropdownPosition()
    }
  }, [ isFocused, calculateDropdownPosition ])

  return (
    <DropdownContainer ref={dropdownRef}>
      <FormDropdown
        className={className}
        size={size}
        small={small}
        defaultStyle={defaultStyle}
        placeholder={placeholder}
        value={fieldValue}
        name={`${field.name}-input`}
        isError={fieldError}
        isTouched={fieldTouched}
        label={label}
        isPadded={false}
        isDisabled={isDisabled}
        readOnly={readOnly}
        onClear={onClear}
        fieldHeight={height}
        placeholderColor={placeholderColor}
        onFocus={handleFieldFocus}
        onBlur={handleFieldBlur}
        onChange={handleFieldChange}
        onClose={handleFieldClose}
        color={color}
        height={height}
        width={width}
        bgColor={bgColor}
        fs={fs}
        positionLeft={positionLeft}
        positionRight={positionRight}
        positionTop={dropdownPosition}
        renderDropdown={({ closeDropdown }) => (
          <Datepicker
            {...field}
            {...props}
            isMultipleInstall={isMultipleInstall}
            setSelectOption={setSelectOption}
            minDate={minDate}
            maxDate={maxDate}
            selected={field.value && !isRange
              ? new Date(field.value)
              : null}
            onChange={(val) => handleDatepickerSelect(val, closeDropdown)}
            color={color}
            selectsRange={isRange}
            nameSection={nameSection}
            fieldValue={fieldValue}
          />
        )}
      />
    </DropdownContainer>
  )
}

export default FormFieldDatepicker
