import s from 'styled-components'
import cn from 'classnames'

const renderDefaultStyle = ({
  $display,
  $dir,
  $m,
  $p,
  $ai,
  $jc,
  $width,
  $height,
}) => `
  height: ${$height};
  width: ${$width};
  display: ${$display};
  flex-direction: ${$dir};
  align-items: ${$ai};
  justify-content: ${$jc};
  margin: ${$m};
  padding: ${$p};
`

const renderRowStyles = ({
  $dir,
  $flex,
  $gutter,
  theme,
  $itemsAlign
}) => {
  const marginRight = $gutter && theme.gutters[$gutter] ? `${theme.gutters[$gutter]}px` : $gutter || 0

  return `
    display: flex;
    align-items: ${$itemsAlign ? `${$itemsAlign} !important` : ''};

    & > * {
      flex: ${$flex};
      margin-right: ${$dir === 'row' && marginRight} !important;
      margin-bottom: ${$dir === 'column' ? `${marginRight} !important` : ''};

      &:last-child {
        ${$dir === 'column' ? 'margin-bottom: 0' : 'margin-right: 0'} !important;
      }
    }
  `
}

const renderGridStyles = ({
  $flex,
  theme,
  $gutter = 'small',
  $itemsAlign = '',
  $itemsVerOffset = '',
  $itemsFlex = '1'
}) => `
  flex: ${$flex || ''};
  flex-direction: row;
  flex-wrap: wrap;
  margin: ${$itemsVerOffset ? `-${theme.gutters[$itemsVerOffset]}px` : 0} -${theme.gutters[$gutter] / 2}px;

  & > * {
    align-self: stretch;
    display: flex;
    flex: ${$itemsFlex};
    align-items: ${$itemsAlign ? `${$itemsAlign} !important` : ''};
    padding: ${$itemsVerOffset ? `${theme.gutters[$itemsVerOffset]}px` : 0} ${theme.gutters[$gutter] / 2}px;
  }
`

const GridContainerDiv = s.div`
  ${renderDefaultStyle}
  ${(props) => (props.grid
    ? renderGridStyles(props)
    : renderRowStyles(props))}
`

const GridContainerSpan = s.span`
  ${renderDefaultStyle}
  ${(props) => (props.grid
    ? renderGridStyles(props)
    : renderRowStyles(props))}
`

const Grid = ({
  children,
  width = '',
  dir = 'row',
  height = '',
  className,
  as = 'div',
  ai = 'flex-start',
  jc = 'flex-start',
  m = 'unset',
  p = 'unset',
  grid = false,
  col = 3,
  flex = '',
  gutter = 'normal',
  itemsVerOffset = null,
  itemsAlign = false,
  itemsFlex = '',
  display = 'flex',
  ...otherProps
}) => {
  const classes = cn('w-grid', className)

  const props = {
    className: classes,
    $width: width,
    $height: height,
    $display: display,
    $dir: dir,
    $m: m,
    $p: p,
    $ai: ai,
    $jc: jc,
    $flex: flex,
    grid,
    $gutter: gutter,
    col,
    $itemsVerOffset: itemsVerOffset,
    $itemsFlex: itemsFlex,
    $itemsAlign: itemsAlign,
    ...otherProps,
  }

  if (as === 'span') {
    return (
      <GridContainerSpan {...props}>
        {children}
      </GridContainerSpan>
    )
  }
  return (
    <GridContainerDiv {...props}>
      {children}
    </GridContainerDiv>
  )
}

export default Grid
