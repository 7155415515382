export const numeralizeTypes = {
  AGE: 'age',
  MEMBERS: 'members',
  PROJECTS: 'projects',
  TASKS: 'tasks',
  CONTACTS: 'contacts',
  FILES: 'files',
  OPINIONS: 'opinions',
  DAYS: 'days',
  HOURS: 'hours',
  MINUTES: 'minutes',
  SECONDS: 'seconds',
  COMMENTS: 'comments',
  NEW_NOTIFICATIONS: 'new_notification',
  DOCTORS: 'doctors',
  CLINICS: 'clinics'
}

const numeralizeData = {
  [numeralizeTypes.DAYS]: [ 'день', 'дня', 'дней' ],
  [numeralizeTypes.HOURS]: [ 'час', 'часа', 'часов' ],
  [numeralizeTypes.MINUTES]: [ 'минута', 'минуты', 'минут' ],
  [numeralizeTypes.SECONDS]: [ 'секунда', 'секунды', 'секунд' ],
  [numeralizeTypes.AGE]: [ 'год', 'года', 'лет' ],
  [numeralizeTypes.MEMBERS]: [ 'участник', 'участника', 'участников' ],
  [numeralizeTypes.PROJECTS]: [ 'проект', 'проекта', 'проектов' ],
  [numeralizeTypes.TASKS]: [ 'задача', 'задачи', 'задач' ],
  [numeralizeTypes.CONTACTS]: [ 'контакт', 'контакта', 'контактов' ],
  [numeralizeTypes.FILES]: [ 'файл', 'файла', 'файлов' ],
  [numeralizeTypes.OPINIONS]: [ 'отзыв', 'отзыва', 'отзывов' ],
  [numeralizeTypes.COMMENTS]: [ 'комментарий', 'комментария', 'комментариев' ],
  [numeralizeTypes.NEW_NOTIFICATIONS]: [ 'новое уведомление', 'новых уведомления', 'новых уведомлений' ],
  [numeralizeTypes.DOCTORS]: [ 'врач', 'врача', 'врачей' ],
  [numeralizeTypes.CLINICS]: [ 'клиника', 'клиник', 'клиник' ],
}

const declOfNum = (n, titles) => titles[
  n % 10 === 1 && n % 100 !== 11
    ? 0
    : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)
      ? 1
      : 2
]

export const numeralizeString = (count, name = '') => declOfNum(count, numeralizeData[name.toLowerCase()]) || '!!!нет в словаре!!!'

export const numeralizeAge = (age) => declOfNum(age, numeralizeData.age)

export const numeralizeProjects = (count) => declOfNum(count, numeralizeData.projects)

export const numeralizeMembers = (count) => declOfNum(count, numeralizeData.members)

export const numeralizeTasks = (count) => declOfNum(count, numeralizeData.tasks)

export const numeralizeContacts = (count) => declOfNum(count, numeralizeData[numeralizeTypes.CONTACTS])

export const numeralizeFiles = (count) => declOfNum(count, numeralizeData.files)

export const numeralizeOpinions = (count) => declOfNum(count, numeralizeData.opinions)

export const numeralizeTime = (count, type) => declOfNum(count, numeralizeData[numeralizeTypes[type.toUpperCase()]])

export const numeralizeComments = (count) => declOfNum(count, numeralizeData[numeralizeTypes.COMMENTS])

export const numeralizeNewNotifications = (count) => declOfNum(count, numeralizeData[numeralizeTypes.NEW_NOTIFICATIONS])

export const numeralizeDoctors = (count) => declOfNum(count, numeralizeData[numeralizeTypes.DOCTORS])

export const numeralizeClinics = (count) => declOfNum(count, numeralizeData[numeralizeTypes.CLINICS])

export const numeralizeDays = (count) => declOfNum(count, numeralizeData[numeralizeTypes.DAYS])
