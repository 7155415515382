import CardBlockWithIcon from 'components/card/CardBlockWithIcon'
import Text from 'components/typo/Text'
import { useCallback } from 'react'
import FormikSelect from './FormikSelect'

const FormBlockSelect = ({
  placeholder = 'Выберите...',
  onChange = () => {},
  onInputChange = () => {},
  onClear = () => {},
  name = '',
  title = '',
  Icon = null,
  options = [],
  renderOption = null,
  isDisabled = false,
  isSearchable = false,
  isClearable = false,
  value,
  isEdit = true,
  ...otherProps
}) => {
  const getOptionTitle = useCallback(() => {
    if (!isEdit && value && Array.isArray(options)) {
      return (options.find((o) => o.id === value) || { title: '' }).title
    }

    return ''
  }, [ isEdit, value, options ])

  return (
    <CardBlockWithIcon title={title} Icon={Icon}>
      {isEdit ? (
        <FormikSelect
          name={name}
          noPadded
          defaultStyle="transparent"
          renderOption={renderOption}
          placeholder={placeholder}
          onChange={onChange}
          onInputChange={onInputChange}
          options={options}
          onClear={onClear}
          isClearable={isClearable}
          isDisabled={isDisabled}
          isSearchable={isSearchable}
          {...otherProps}
        />
      ) : <Text color="grey500">{getOptionTitle()}</Text>}
    </CardBlockWithIcon>
  )
}

export default FormBlockSelect
