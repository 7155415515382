export const SystemActionTypes = {
  SET_SHOW_PRELOADER: 'SET_SHOW_PRELOADER',
  INIT_STATE_SUCCESS: 'INIT_STATE_SUCCESS',
  INIT_STATE_ERROR: 'INIT_STATE_ERROR',
  SET_MODAL: 'SET_MODAL',
  SET_BROWSER_WIDTH: 'SET_BROWSER_WIDTH',
  SET_NOTIFICATIONS_MODAL: 'SET_NOTIFICATIONS_MODAL',
  SET_IS_TOUCH_DEVICE: 'SET_IS_TOUCH_DEVICE',
  SET_SIDEBAR_SHOW: 'SET_SIDEBAR_SHOW',
  SET_CONTENT_SIDEBAR_SHOW: 'SET_CONTENT_SIDEBAR_SHOW',
  SET_UPLOAD_PROGRESS: 'SET_UPLOAD_PROGRESS',

  SET_NOTIFICATION: 'SET_NOTIFICATION',
  SET_NOTIFICATION_ERROR: 'SET_NOTIFICATION_ERROR',

  SET_FETCHING_APP_MODELS: 'SET_FETCHING_APP_MODELS',
  FETCH_APP_MODELS_SUCCESS: 'FETCH_APP_MODELS_SUCCESS',
  FETCH_APP_MODELS_ERROR: 'FETCH_APP_MODELS_ERROR',
}
