import {
  useCallback, useEffect, useRef, useState
} from 'react'
import { Formik } from 'formik'
import { useDispatch } from 'react-redux'

import { Button } from 'components/base'
import { Form, FormGroup } from 'components/form'

import { SchemaAddFileForm } from 'constants/ValidationSchemas'
import FilesDropzone from 'components/dropzones/FilesDropzone'
import FormBlockField from 'components/form/FormBlockField'
import FormGroupBlock from 'components/form/FormGroupBlock'
import DropzoneCardFile from 'components/Dropzone/DropzoneCardFile'
import ModalAnswer from 'components/modals/ModalAnswer/ModalAnswer'
import ApiService from 'services/ApiService'
import { fetchClinicDocuments } from 'redux/dashboard/Clinic/ClinicActions'
import FormBlockSelect from 'components/form/FormBlockSelect'
import { checkOnlyPDFType } from '../../constants/MediaTypes'
import { convertBtoMB } from '../../helpers/Files'
import { MedDocumentsSelectModel } from '../../constants/MedDocumentsType'

const initialModalData = {
  title: '',
  description: '',
  confirmText: 'Ок'
}

const AddStructureFileForm = ({
  closeModal = () => {},
  parentId = null,
  typeId = null,
  userId = null,
  isSearch = false,
  onAdd = () => {},
  clinicId,
  data,
}) => {
  const [ isFile, setFile ] = useState(false)
  const [ isModal, setIsModal ] = useState(false)
  const [ modalData, setModalData ] = useState(initialModalData)
  const dispatch = useDispatch()
  const formRef = useRef()

  useEffect(() => {
    if (!data.isCertificate && formRef.current) {
      formRef.current.setFieldValue('type', 'Договор на оказание услуг')
    }
  }, [ data.isCertificate ])

  const onUpload = useCallback((files) => {
    if (files && files.length > 0) {
      if (files.length > 1) {
        setIsModal(true)
        setModalData({
          ...initialModalData,
          title: 'Можно загружать только один файл.',
          description: 'Выберете только один файл.'
        })
        return
      }
      if (!checkOnlyPDFType(files[0].type)) {
        setIsModal(true)
        setModalData({
          ...initialModalData,
          title: 'Формат файла не поддерживается',
          description: 'Формат загружаемого файла не поддерживается'
        })
        return
      }

      const maxSize = 50 // мбайт
      if (convertBtoMB(files[0].size) > maxSize) {
        setIsModal(true)
        setModalData({
          ...initialModalData,
          title: `Максимальный размер ${maxSize === 50 ? 'файла' : 'видео'}`,
          description: 'Максимальный размер загружаемого файла \n'
            + '- 50 мб'
        })
        return
      }

      setFile(true)

      if (formRef && formRef.current) {
        formRef.current.setFieldValue('file', files[0])

        setTimeout(() => {
          formRef.current.setFieldTouched('file', true)
        }, 300)
      }
    }
  }, [])

  const onClear = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.setFieldValue('file', null)
      formRef.current.setFieldTouched('file', false)
      setFile(false)
    }
  }, [])

  const handleSubmit = useCallback((values, { setSubmitting }) => {
    const { file } = values

    const title = `${values.fileName}_${values.type}.pdf`
    const renamedFile = new File([ file ], title, {
      type: file.type,
      lastModified: file.lastModified
    })
    const formData = new FormData()
    if (data.isCertificate) {
      formData.append('certificate', renamedFile)
    }
    else {
      formData.append('service_agreement', renamedFile)
    }

    return ApiService.apiCall({
      url: data.isCertificate ? ApiService.paths.clinics.CLINIC_ADD_CERTIFICATE(clinicId) : ApiService.paths.clinics.CLINIC_ADD_SERVICE_AGREEMENT(clinicId),
      isToken: true,
      method: data.isCertificate ? 'POST' : 'PATCH',
      data: formData,
      noErrorMessage: true,
    })
      .then((response) => {
        dispatch(fetchClinicDocuments(clinicId))
        closeModal()
      })
      .catch((e) => {
        if (e.data.errors?.title[0]) console.log(e)
        return false
      })
  }, [
    isSearch,
    userId,
    typeId,
    parentId,
    onAdd,
    closeModal,
    dispatch,
  ])
  return (

    <>
      <Formik
        innerRef={formRef}
        initialValues={{
          type: '',
          fileName: '',
          file: null,
        }}
        onSubmit={handleSubmit}
        validationSchema={SchemaAddFileForm}
      >
        {(props) => {
          const {
            values,
            dirty,
            isSubmitting,
            handleSubmit,
            isValid,
          } = props
          const formIsValid = dirty && isValid && isFile && !isSubmitting
          const fileTitle = [ values.fileName, values.type ]
            .filter((i) => !!i)
            .join(' ')
          const isDropDisabled = !isValid || !dirty
          return (
            <Form
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                width: '100%'
              }}
              onSubmit={handleSubmit}
            >
              <div>

                <FormGroupBlock title="Выберите тип документа">
                  {data.isCertificate
                    ? (
                      <FormBlockSelect
                        name="type"
                        title="Тип документа"
                        options={MedDocumentsSelectModel}
                        placeholder="Выберите тип документа"
                        isEdit
                        onChange={(selectedId) => {
                          const selectedOption = MedDocumentsSelectModel.find((option) => option.id === selectedId)
                          if (selectedOption) {
                            props.setFieldValue('type', selectedOption.title)
                          }
                        }}
                        value={values.type}
                      />
                    )
                    : (
                      <FormBlockField
                        name="type"
                        title="Название документа"
                        placeholder="Договор на оказание услуг"
                        value="Договор на оказание услуг"
                        readOnly
                      />
                    )}

                  <FormBlockField
                    name="fileName"
                    title="Название документа"
                    placeholder="Введите название документа"
                    value={values.fileName}
                  />
                </FormGroupBlock>
                <FormGroupBlock title="Файл">
                  {isFile ? <DropzoneCardFile onClear={onClear} title={fileTitle} file={values.file} /> : (
                    <FilesDropzone
                      width="100%"
                      onUpload={onUpload}
                      accept=""
                      isPreview={false}
                      isDisabled={isDropDisabled}
                      validFormat="pdf"
                    />
                  )}
                </FormGroupBlock>
              </div>
              <FormGroup className="mt-a">
                <Button
                  type="submit"
                  size="big"
                  full
                  title="Загрузить файл"
                  isDisabled={!formIsValid || isSubmitting}
                  isFetching={isSubmitting}
                  isGradient
                />
              </FormGroup>
            </Form>
          )
        }}
      </Formik>
      <ModalAnswer
        isOpen={isModal}
        onClose={() => {
          setIsModal(null)
          setModalData(initialModalData)
        }}
        onSuccess={() => {
          setIsModal(null)
          setModalData(initialModalData)
        }}
        title={modalData.title}
        description={modalData.description}
        confirmText={modalData.confirmText}
      />
    </>
  )
}

export default AddStructureFileForm
