import PropTypes from 'prop-types'
import {
  useCallback, useState, forwardRef
} from 'react'

import { ReactComponent as IconShow } from 'assets/icons/eye-opened.svg'
import { ReactComponent as IconHide } from 'assets/icons/eye-closed.svg'

import FormField from './FormField'

const FormFieldPassword = forwardRef(({ type, ...props }, ref) => {
  const [ typeState, setTypeState ] = useState(type)

  const handlePasswordClick = useCallback((e) => {
    e.preventDefault()

    if (type === 'password') {
      setTypeState(typeState === 'password' ? 'text' : 'password')
    }
  }, [ type, setTypeState, typeState ])

  return (
    <FormField
      {...props}
      type={typeState}
      onIconClick={handlePasswordClick}
      icon={typeState === 'password' ? IconHide : IconShow}
      ref={ref}
      isFirstUppercase={false}
    />
  )
})

FormFieldPassword.propTypes = ({ size: PropTypes.oneOfType([ PropTypes.string, PropTypes.bool ]) })

FormFieldPassword.displayName = 'FormFieldPassword'

export default FormFieldPassword
