/* eslint-disable max-len */
import axios from 'axios'

import * as PathTypes from 'constants/PathsTypes'
import StorageService from 'services/StorageService'
import store from 'redux/store/configureStore'
// import axiosCancel from 'axios-cancel'

// redux
import { setNotificationError } from 'redux/System/SystemActions'
import { TasksTypes } from 'constants/TasksTypes'
import { fetchLogout } from 'redux/Auth/AuthActions'
import MessengerService from 'services/Voximplant/messenger.service'
import VoxService from 'services/Voximplant/vox.service'

// axiosCancel(axios, { debug: true }) // default

const errorBeautifier = (error) => (error.hasOwnProperty('payload')
  ? error.payload.data.error
  : {
    name: 'Error',
    message: error.message,
    status: (error.message && error.message.status) || 'Unhandled error',
  })

class ApiService {
  constructor() {
    this.client = axios.create({
      baseURL: PathTypes.APP_API_URL,
      timeout: 60000, // 1 min
    })
    this.paths = {
      auth: {
        LOGIN: '/auth/login/',
        GET_ME: '/auth/users/me/',
        CODE_VERIFY: '/auth/users/verify_code/',
        CHANGE_PASSWORD: `/auth/change/password/`,
        SET_PASSWORD: `/auth/set/password/`,
        RESET_PASSWORD_EMAIL: `/auth/reset/email/`,
        RESET_CODE_VERIFY: `/auth/reset/code/verify/`,
        RESET_PASSWORD_EMAIL_SUBMIT: `/auth/reset/email/submit`,
        RESET_PASSWORD_SET: `/auth/reset/email/submit`,
        INVITE_BY_SMS: '/auth/invite_by_sms/',
        REINVITE_BY_SMS: (id) => `/auth/reinvite_by_sms/?id=${id}`,
        VERIFY: '/auth/verify_ws/',
      },
      clinics: {
        LIST: '/clinics/',
        LIST_ANALYTIC: '/clinics/alternative_clinics_filter/',
        PROFILES: '/clinic_profiles/',
        CLINIC_DOCTORS_BY_ID: (clinicId) => `/clinics/${clinicId}/doctors/`,
        CLINIC_DOCTORS_BY_PATIENT: (clinicId) => `/clinics/${clinicId}/doctors_bind_by_clinic/`,
        NEW_RECORDS: (id) => `clinics/${id}/get_new_appointments_at_the_clinic/`,
        CLINIC_CAUTION: (id) => `/clinics_caution/${id}`,
        CLINIC_MED_DOCUMENT: (id) => `/clinics/${id}/clinic_documents`,
        CLINIC_ADD_CERTIFICATE: (id) => `/clinics/${id}/add_certificate/`,
        CLINIC_DELETE_CERTIFICATE: (clinicId, certificateId) => `/clinics/${clinicId}/delete-certificate/${certificateId}`,
        CLINIC_ADD_SERVICE_AGREEMENT: (id) => `/clinics/${id}/upload_service_agreement/`,
        CLINIC_DELETE_SERVICE_AGREEMENT: (id) => `/clinics/${id}/delete_service_agreement/`,
      },
      chat: {
        ROOT: `/user_voximplant/`,
        GET_AUTH: (id) => `/users/${id}/get_chat_user/`,
        MY_CHAT_USERS: '/users/get_application_users/',
        SUPPORT_USER: '/supports/',
      },
      coaches: { ROOT: `/coaches/` },
      consultations: { ROOT: (search = null) => `/consultations/${search || ''}` },
      diarys: {
        USER_RECORDS: '/diary_records/',
        RECORD_INTENSIVITIES: '/diary_records/selectors_intensity/',
        RECORD_TYPES: '/diary_record_types/',
        RECORDS_STATS: '/diary_records/stats/',
        TYPES: '/diary_record_types/',
        TYPES_BY_ID: (id) => `/diary_record_types/${id}/`,
      },
      doctors: {
        ROOT: `/doctors/`,
        PATIENTS_BY_ID: (doctorId) => `/doctors/${doctorId}/patients/`,
        CONSULTANTS_BY_ID: (doctorId) => `/doctors/${doctorId}/consultants/`,
        WORK_EXPERIENCE_BY_ID: (doctorId) => `/doctors/${doctorId}/work_experience/`,
        INTERNSHIPS_BY_ID: (doctorId) => `/doctors/${doctorId}/internships/`,
        SCIENTIFIC_ACTIVITY_BY_ID: (doctorId) => `/doctors/${doctorId}/scientific_activity/`,
        REVIEWS_BY_ID: (doctorId) => `/doctors/${doctorId}/reviews/`,
        GET_STRUCTURE_BY_ID: (doctorId) => `/doctors/${doctorId}/structure_files/`,
        CERTIFICATES: (doctorId) => `/doctors/${doctorId}/certificates/`,
        SPECIALIZATIONS: '/doctor_specializations/',
        BY_ID: (doctorId) => `/doctors/${doctorId}/`,
        UNBIND_PATIENT: (patientId) => `/doctors/${patientId}/unbind_patient/`,
      },
      consultants: { BIND: (userId) => `/consultant_place_bind/update_notifications_status/${userId}/` },
      knowledgebase: {
        ROOT: '/knowledgebase/',
        EXERCISE: '/knowledgebase/exercise/',
        EXTRATASK: '/knowledgebase/extratask/',
        EVENT: '/knowledgebase/event/',
        ANALYSIS: '/knowledgebase/analysis/',
        MEDICINE: '/knowledgebase/medicine/',
        PROCEDURE: '/knowledgebase/procedure/',
        MANIPULATION: '/knowledgebase/manipulation/',
        RESEARCH: '/knowledgebase/research/',
        INSTRUCTION: '/knowledgebase/instruction/',
        SPECIALIZATION: '/knowledgebase/specialization/',
        MEDICINE_BY_ID: (id) => `/knowledgebase/medicine/${id}`,
        ANALYSIS_BY_ID: (id) => `/knowledgebase/analysis/${id}`,
        PROCEDURE_BY_ID: (id) => `/knowledgebase/procedure/${id}`,
        MANIPULATION_BY_ID: (id) => `/knowledgebase/manipulation/${id}`,
        RESEARCH_BY_ID: (id) => `/knowledgebase/research/${id}`,
        EXTRATASK_BY_ID: (id) => `/knowledgebase/extratask/${id}`,
        INSTRUCTION_BY_ID: (id) => `/knowledgebase/instruction/${id}`,
        EXERCISE_BY_ID: (id) => `/knowledgebase/exercise/${id}`,
        EVENT_BY_ID: (id) => `/knowledgebase/event/${id}`,
        SEARCH: (string) => `/knowledgebase/search/${string}`,
      },
      myCourses: {
        ROOT_TEMPLATE: '/course_template/',
        TEMPLATE: `/course_template/template/`,
        ASSIGN: `/user_courses/mass/create/`,
        TEMPLATE_BY_ID: (id) => `/course_template/template/${id}/`,
        TEMPLATE_SEARCH: (search) => `/course_template/template/?search=${search}&limit=5&offset=0`,
      },
      articles: {
        ROOT_TEMPLATE: '/articles/',
        TEMPLATE_BY_ID: (id) => `/articles/${id}/`,
        SHARE_BY_ID: (id) => `/articles/${id}/share/`,
        SHARE_REMOVE_BY_ID: (id) => `/articles/${id}/remove_subscribers/`,
        TEMPLATE_SEARCH: (search) => `/articles/?search=${search}&limit=5&offset=0`,
        GET_SUBSCRIBERS_BY_ID: (id) => `/articles/${id}/get_subscribers/`,
        ARTICLES_USER: `/articles/get_appointment_articles/`,
      },
      news: {
        ROOT: '/news/',
        NEW_NEWS: `/news/get_unread_news/`,
        READ: (id) => `/news/${id}/mark-as-read/`,
        NEWS: (search = null) => `/news/${search || ''}`,
      },
      folders: {
        ROOT: '/folders/',
        BY_ID: (id) => `/folders/${id}/`
      },
      files: {
        PATIENT: '/patient_files/',
        PATIENT_BY_ID: (id) => `/patient_files/${id}/`,
        DOCTOR: '/doctor_files/',
        DOCTOR_BY_ID: (id) => `/doctor_files/${id}/`
      },
      gallery: { ROOT: `/gallery/` },
      user: {
        UPDATE_MY_PROFILE: '/users/edit/',
        BINDS: '/binds/',
        PATIENTS: (search = null) => `/patients/small/${search || ''}`,
        ADMINISTRATORS: (search = null) => `/consultants/${search || ''}`,
        NOTIFICATION_UNSUBSCRIBE: (id) => `/users/${id}/notification_unsubscribe/`,
      },
      users: {
        ROOT: `/users/`,
        GLOBAL: `/global_search/`,
        BY_ID: (id) => `/users/${id}/`,
      },
      location: {
        COUNTRIES: '/countries/',
        CITIES: '/cities/',
        CITY_BY_ID: (id) => `/cities/${id}/`,
        PATIENT_CITIES: '/cities/patient_cities/',
        CLINIC_CITIES: '/cities/clinic_cities/',
      },
      media: {
        ROOT: `/media_files/`,
        S3_PRESIGN_URL: '/media_files/create_presign_url_post/',
        CONFIRM_UPLOAD: (id) => `/media_files/${id}/confirm_upload`,
      },
      medicines: {
        ROOT: '/medicines/',
        LIST: (search = null) => `/medicines/${search || ''}`,
        BY_ID: (id) => `/medicines/${id}/`,
      },
      exercises: {
        ROOT: '/exercises/',
        LIST: (search = null) => `/exercises/${search || ''}`,
        BY_ID: (id) => `/exercises/${id}/`,
        BY_DOCTOR_ID: (doctorId) => `/exercises/get_doctor_exercises/${doctorId}/`,
      },
      analyses: {
        ROOT: '/analysis/',
        LIST: (search = null) => `/analysis/${search || ''}`,
        BY_ID: (id) => `/analysis/${id}/`,
        BY_DOCTOR_ID: (doctorId) => `/analysis/get_doctor_analysis/${doctorId}/`,
      },
      procedures: {
        ROOT: '/procedures/',
        LIST: (search = null) => `/procedures/${search || ''}`,
        BY_ID: (id) => `/procedures/${id}/`,
        BY_DOCTOR_ID: (doctorId) => `/procedures/get_doctor_procedures/${doctorId}/`,
      },
      manipulation: {
        ROOT: '/manipulations/',
        LIST: (search = null) => `/manipulations/${search || ''}`,
        BY_ID: (id) => `/manipulations/${id}/`,
        BY_DOCTOR_ID: (doctorId) => `/manipulations/get_doctor_manipulations/${doctorId}/`,
      },
      research: {
        ROOT: '/research/',
        LIST: (search = null) => `/research/${search || ''}`,
        BY_ID: (id) => `/research/${id}/`,
        BY_DOCTOR_ID: (doctorId) => `/research/get_doctor_researches/${doctorId}/`,
      },
      my_events: {
        ROOT: '/my_events/my_event_tasks/',
        EVENTS_GET: `/my_events/my_event/get/`,
      },
      notifications: {
        ROOT: '/user_push/',
        READ: '/user_push/update_read_status/',
        READ_ALL: '/notifications/read_all/'
      },
      patients: {
        ROOT: `/users/`,
        BY_ID: (id) => `/users/${id}/`,
        GET_STRUCTURE_BY_ID: (id) => `/users/${id}/structure_files/`,
        GET_MEDICAL_HISTORY: (id, type) => `/users/${id}/medical_history/?event_type=${type}`,
        GET_DIARY_RECORDS_STAT: (id) => `/users/${id}/diary_record/stats/`,
        GET_INSURANCES: (id) => `/users/${id}/insurances/`,
        GET_COURSES_TASKS: (id) => `/user_courses/${id}/get_tasks/`,
        GET_EXPIRED_TASKS_AMOUNT: (id) => `/users/${id}/amount_expired_tasks/`,
        BINDS: '/binds/',
        BINDS_BY_ID: (id) => `/binds/${id}/`,
        BINDS_CREATE: (id) => `/binds/${id}/accept/`,
        BINDS_REJECT: (id) => `/binds/${id}/reject/`,
        GET_DOCTOR_EXPIRED: (doctorId) => `/doctors/${doctorId}/amount_patient_expired_tasks/`,
        GET_CLINIC_EXPIRED: (clinicId) => `/clinics/${clinicId}/amount_patient_expired_tasks/`,
        GET_SPECIALIZATIONS_CLINIC: (clinicId) => `/clinics/${clinicId}/specializations/`,
      },
      clinicRecord: {
        ROOT: '/clinic_record/',
        BY_ID: (id) => `/clinic_record/${id}/`,
      },
      tasksComments: {
        ROOT: '/user_course_task_comments/',
        BY_ID: (id) => `/user_course_task_comments/${id}/`,
      },
      tasks: {
        [TasksTypes.EXERCISES]: '/user_course_exercises/',
        [TasksTypes.MEDICINE]: '/user_course_medicines/',
        [TasksTypes.PROCEDURE]: '/user_course_procedures/',
        [TasksTypes.ANALYSES]: '/user_course_analysis/',
        [TasksTypes.CONSULTATION]: '/user_course_consultations/',
        [TasksTypes.MANIPULATION]: '/user_course_manipulations/',
        [TasksTypes.EXTRATASKS]: '/user_course_extratasks/',
        [TasksTypes.RESEARCHES]: '/user_course_researches/',
        [TasksTypes.EVENT]: '/user_course_events/',
      },
      system: { MODELS: '/users/profile_enums/' },
      trainers: { ROOT: `/trainers/` },
      analytic: {
        ROOT: `/analytics/`,
        dashboard: {
          totals: '/analytics/dashboard/totals/',
          monthlyData: '/analytics/dashboard/monthly_data',
          doctors: '/analytics/dashboard/doctors',
          patients: '/analytics/dashboard/patients',
          clinics: '/analytics/dashboard/clinics',
          consultations_offline: '/analytics/dashboard/consultations_offline',
          consultations_online: '/analytics/dashboard/consultations_online',
          exercises: '/analytics/dashboard/tasks',
          users: '/analytics/dashboard/users/',
          files: '/analytics/dashboard/files/',
        },
        clinicalIndicators: {
          doctorsInfo: '/analytics/indicators/doctors_info/',
          patientsInfo: '/analytics/indicators/patients_info/',
          tasksInfo: '/analytics/indicators/tasks_info/',
          consultationsInfo: '/analytics/indicators/consultations_info/',
          byClinic: '/analytics/indicators/by_clinic/',
        },
        clinic: {
          main: '/analytics/by_clinic/get_analytics/',
          patients: '/analytics/by_clinic/patients/',
          task: '/analytics/by_clinic/task_info/',
          doctors: '/analytics/by_clinic/doctors_tasks_with_patients/',
          daysCount: '/analytics/by_clinic/doctor_with_patients_by_days_count/',
          excel: '/analytics/by_clinic/xls_file/'
        }
      },
      onboarding: { disable: 'disable_onboarding' },
      userCourses: {
        ROOT: 'user_courses',
        bulkDelete: '/user_courses/bulk_delete/'
      },
      support: {
        ROOT: '/support_info/',
        BY_ID: (id) => `/support_info/${id}`,
        CLINICS_BY_ID: (id) => `/support_info/patient_clinics/${id}`,
      },
      info: { RESOLUTION: '/patients/app_info/user_info_resolution/' },
      myEvents: {
        ROOT: '/my_events/user_event/get/',
        BY_ID: (id) => `/my_events/user_event/?user_id=${id}`,
      },
      clinic: {
        ROOT: '/clinics/',
        BY_ID: (id) => `/clinics/${id}/`
      }
    }
  }

  apiCall({
    url = '',
    method = 'GET',
    token = null,
    isToken = false,
    headers = {},
    cancelTokenSource = null,
    noErrorMessage = false,
    ...otherParams
  }) {
    let options = {
      url,
      method,
      headers: this.buildHeaders(token, isToken, headers),
    }

    if (cancelTokenSource) {
      options.cancelToken = cancelTokenSource.token
    }

    if (otherParams) {
      options = {
        ...options,
        ...otherParams,
      }
    }
    return this.client(options)
      .then((res) => this.handleCommonSuccess(res, cancelTokenSource))
      .catch((error) => this.handleCommonError({
        error,
        cancelTokenSource,
        noErrorMessage
      }))
  }

  buildHeaders(token, isToken, addHeaders = {}) {
    const headers = {
      'Content-type': 'application/json',
      Accept: 'application/json',
      ...addHeaders
    }

    if (token || isToken) {
      headers.Authorization = `Token ${token || StorageService.getToken()}`
    }

    return headers
  }

  handleCommonSuccess(response, cancelTokenSource) {
    if (cancelTokenSource && cancelTokenSource.current) {
      cancelTokenSource.current = null
    }

    return Promise.resolve(response.data)
  }

  handleCommonError({
    error,
    cancelTokenSource,
    noErrorMessage
  }) {
    const { dispatch } = store

    if (cancelTokenSource && cancelTokenSource.current) {
      cancelTokenSource.current = null
    }

    // handle canceled axios requests
    if (axios.isCancel(error)) {
      console.log('request cancelled')
    }

    if (error && !noErrorMessage) {
      if (!error.response || error.response === 'Network Error') {
        dispatch(setNotificationError({ detail: error.message || 'Network Error' }))
      }
      else if (error.response) {
        const { data } = error.response

        if (error.response.status === 500) {
          dispatch(setNotificationError({ detail: 'Ooops! Server error (500)' }))
        }
        else if (error.response.status === 404) {
          dispatch(setNotificationError({ detail: 'Ooops! Resource not found (404)' }))
        }
        else if (error.response.status === 401) {
          dispatch(setNotificationError(data))
          MessengerService.get().removeChat().then(() => {
            dispatch(fetchLogout(true))
            VoxService.get().onLogout()
          })
        }
        else {
          dispatch(setNotificationError(data.errors || data.error || data))
        }
      }
    }

    // FOR DEBUG ONLY
    if (process.env.NODE_ENV === 'development') {
      console.info('DEBUG ERROR START')
      console.warn('ERROR:', error)
      console.warn('ERROR RESPONSE:', error.response)
      console.warn('ERROR MESSAGE:', error && error.message)
      console.info('DEBUG ERROR END', error && error.message)
    }

    return Promise.reject(
      error && error.response ? error.response : errorBeautifier(error)
    )
  }
}

export default new ApiService()
