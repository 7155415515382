import {
  useMemo,
  useCallback,
  useState,
} from 'react'

import { ReactComponent as IconSave } from 'assets/icons/confirmModal/save.svg'
import { ReactComponent as IconReset } from 'assets/icons/confirmModal/reset.svg'
import { ReactComponent as IconDelete } from 'assets/icons/trash.svg'
import { ReactComponent as IconExit } from 'assets/icons/confirmModal/exit.svg'

// components
import { ModalContent } from 'components/modal'
import {
  Box,
  Grid,
  GridItem
} from 'layout'
import { Button } from 'components/base'
import { Title, Text } from 'components/typo'

import { colors } from 'theme'

import { MODAL_CONFIRM_TYPES } from 'constants/SystemTypes'
import ModalCenterLayout from './ModalCenterLayout'

const ModalConfirm = (props) => {
  const [ isFetching, setFetching ] = useState(false)
  const {
    closeModal = () => {},
    // IsMobile = false,
    modal: {
      onYes = () => {},
      isSubmitting = false,
      type = '',
      title = '',
      btnYesTitle = '',
      btnNoTitle = '',
    },
  } = props

  const renderIcon = useCallback(() => {
    let Icon = null

    switch (type) {
    case MODAL_CONFIRM_TYPES.RESET:
      Icon = IconReset
      break
    case MODAL_CONFIRM_TYPES.DELETE:
      Icon = IconDelete
      break
    case MODAL_CONFIRM_TYPES.SAVE:
      Icon = IconSave
      break
    case MODAL_CONFIRM_TYPES.EXIT:
      Icon = IconExit
      break

    default:
      break
    }

    return Icon ? (
      <Icon
        width="64px"
        height="64px"
        stroke={colors.dark}
        fill={colors.dark}
      />
    ) : ''
  }, [ type ])

  const modalTitle = useMemo(() => {
    if (title) return title

    switch (type) {
    case MODAL_CONFIRM_TYPES.RESET:
      return 'Сбросить все последние дейстивия?'

    case MODAL_CONFIRM_TYPES.DELETE:
      return 'Вы действительно хотите удалить?'

    case MODAL_CONFIRM_TYPES.SAVE:
      return 'Сохранить последние действия?'

    case MODAL_CONFIRM_TYPES.EXIT:
      return 'Вы действительно хотите выйти?'

    default:
      return 'Заголовок не указан'
    }
  }, [ type, title ])

  const btnAttributes = useMemo(() => {
    const attrs = {
      defaultStyle: 'acsent',
      title: 'Сохранить',
    }

    switch (type) {
    case MODAL_CONFIRM_TYPES.RESET:
      attrs.title = 'Сбросить'
      attrs.defaultStyle = 'acsent'
      break

    case MODAL_CONFIRM_TYPES.DELETE:
      attrs.title = btnYesTitle || 'Удалить'
      attrs.defaultStyle = 'red'
      break

    case MODAL_CONFIRM_TYPES.EXIT:
      attrs.title = btnYesTitle || 'Выйти'
      attrs.defaultStyle = 'acsent'
      break

    case MODAL_CONFIRM_TYPES.SAVE:
    default:
      attrs.title = btnYesTitle || 'Сохранить'
      break
    }

    return attrs
  }, [ type, btnYesTitle ])

  const handleYes = () => {
    setFetching(true)
    return onYes(closeModal, setFetching)
  }

  return (
    <ModalCenterLayout
      onClose={closeModal}
      noPointers
      headPosition="absolute"
    >
      <ModalContent
        display="flex"
        column
        center
        p="0px"
      >
        <Box
          className="mb-5"
          jc="center"
          isRelative
        >
          {renderIcon()}
        </Box>
        <Box
          display="block"
          isRelative
        >
          <div className="mb-10">
            <Title
              center
              size="medium"
            >
              {modalTitle}
            </Title>
          </div>
          {type === MODAL_CONFIRM_TYPES.DELETE && (
            <Text
              fw="l"
              textAlign="center"
              color="grey500"
            >
              Это действие нельзя будет отменить.
            </Text>
          )}
          <Grid
            grid
            className="mt-5"
            gutter="small"
          >
            <GridItem flex="1">
              <Button
                {...btnAttributes}
                full
                size="big"
                onClick={handleYes}
                isDisabled={isSubmitting}
                isFetching={isSubmitting || isFetching}
                isGradient
              />
            </GridItem>
            <GridItem flex="1">
              <Button
                full
                defaultStyle="grey-light"
                size="big"
                title={btnNoTitle || 'Нет'}
                isDisabled={isSubmitting}
                isFetching={isSubmitting}
                onClick={closeModal}
              />
            </GridItem>
          </Grid>
        </Box>
      </ModalContent>
    </ModalCenterLayout>
  )
}

export default ModalConfirm
