export const DocumentsActionTypes = {
  SET_FETCHING_DOCUMENTS_LIST: 'SET_FETCHING_DOCUMENTS_LIST',
  FETCH_DOCUMENTS_LIST_SUCCESS: 'FETCH_DOCUMENTS_LIST_SUCCESS',
  FETCH_DOCUMENTS_LIST_ERROR: 'FETCH_DOCUMENTS_LIST_ERROR',
  CLEAR_DOCUMENTS_LIST: 'CLEAR_DOCUMENTS_LIST',
  SET_DOCUMENTS_LIST_LAST_SEARCH: 'SET_DOCUMENTS_LIST_LAST_SEARCH',
  SET_DOCUMENTS_LIST_COUNT: 'SET_DOCUMENTS_LIST_COUNT',

  SET_FETCHING_DOCUMENTS_SEARCH: 'SET_FETCHING_DOCUMENTS_SEARCH',
  FETCH_DOCUMENTS_SEARCH_SUCCESS: 'FETCH_DOCUMENTS_SEARCH_SUCCESS',
  FETCH_DOCUMENTS_SEARCH_ERROR: 'FETCH_DOCUMENTS_SEARCH_ERROR',
  CLEAR_DOCUMENTS_SEARCH: 'CLEAR_DOCUMENTS_SEARCH',

  SET_FETCHING_UPLOAD_DOCUMENTS_LIST: 'SET_FETCHING_UPLOAD_DOCUMENTS_LIST',
  FETCH_UPLOAD_DOCUMENTS_LIST_SUCCESS: 'FETCH_UPLOAD_DOCUMENTS_LIST_SUCCESS',
  FETCH_UPLOAD_DOCUMENTS_LIST_ERROR: 'FETCH_UPLOAD_DOCUMENTS_LIST_ERROR',

  SET_DOCUMENTS_FILTERS: 'SET_DOCUMENTS_FILTERS',
  SET_DOCUMENTS_FILTER: 'SET_DOCUMENTS_FILTER',
  SET_DOCUMENTS_SEARCH_VALUE: 'SET_DOCUMENTS_SEARCH_VALUE',
}
