import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import Box from 'layout/Box'
import Text from 'components/typo/Text'

import { getPrevLocationDinamic } from 'helpers/History'

import { ReactComponent as ArrowLeft } from 'assets/icons/arrow-left.svg'

const LinkBack = ({
  path = null,
  onBack = null,
  slice = null,
  state = {},
  title = ''
}) => {
  const history = useHistory()

  const handlerBack = useCallback(() => {
    if (onBack) {
      onBack()
    }
    else if (path) {
      history.push(path, state)
    }
    else if (slice) {
      history.push(getPrevLocationDinamic(history.location.pathname, slice), state)
    }
    else {
      history.goBack()
    }
  }, [
    history,
    onBack,
    path,
    slice,
    state
  ])

  return (
    <Box
      display="inline-flex"
      ai="center"
      onClick={handlerBack}
      pointer
    >
      <ArrowLeft stroke="currentColor" />
      {!!title && (
        <Text
          size="medium"
          fw="m"
          className="ml-3"
        >
          {title}
        </Text>
      )}
    </Box>
  )
}

export default LinkBack
