import { useMemo } from 'react'

import Box from 'layout/Box'
import Grid from 'layout/Grid'
import GridItem from 'layout/GridItem'
import Card from 'components/card/Card'
import CardFileIconTitle from 'components/card/CardFileIconTitle'

import { getMediaExt } from 'redux/Media/MediaHelpers'
import ButtonClose from 'components/buttons/ButtonClose'

const DropzoneCardFile = ({
  title,
  file,
  onClear = () => {},
}) => {
  const fileExt = useMemo(() => getMediaExt(file.name), [ file.name ])

  const fileTitle = useMemo(() => `${title}.${fileExt}`, [ title, fileExt ])

  return file && (
    <Card
      width="100%"
      isRelative
      height="64px"
      className="cc-file"
      bgColor="transparent"
    >
      <Box
        ai="center"
        flex="1"
        height="100%"
      >
        <Grid
          row
          gutter="20px"
          width="100%"
          itemsAlign="center"
        >
          <GridItem
            noPointers
            flex="1"
          >
            <CardFileIconTitle
              filename={fileTitle}
              title={title}
              isAlt
              isElipsed={false}
            />
          </GridItem>
          <GridItem className="ml-a" flex="0">
            <div>
              <ButtonClose
                defaultStyle="settings"
                onClick={onClear}
              />
            </div>
          </GridItem>
        </Grid>
      </Box>
    </Card>
  )
}

export default DropzoneCardFile
