export const AnalyticDashboardTypes = {
  SET_TOTAL: 'SET_TOTAL',
  SET_DELTA: 'SET_DELTA',
  SET_MONTH_A: 'SET_MONTH_A',
  SET_MONTH_B: 'SET_MONTH_B',
  SET_DOCTORS: 'SET_DOCTORS',
  SET_PATIENTS: 'SET_PATIENTS',
  SET_CLINICS: 'SET_CLINICS',
  SET_EXERCISES: 'SET_EXERCISES',
  SET_USERS: 'SET_USERS',
  SET_FILES: 'SET_FILES',
  SET_CONSULTATIONS_OFFLINE: 'SET_CONSULTATIONS_OFFLINE',
  SET_CONSULTATIONS_ONLINE: 'SET_CONSULTATIONS_ONLINE',
}
