import {
  Suspense,
  lazy,
  useEffect,
  useState, memo
} from 'react'
import {
  Switch,
  Route,
  Redirect,
  useHistory
} from 'react-router-dom'

import { Spinner } from 'components/base'
import PrivateRoute from 'components/PrivateRoute'

import { PAGE_DASHBOARD, PAGE_LOGIN } from 'constants/PathsTypes'

const PageDashboard = lazy(() => import('pages/Dashboard'))
const LoginsRoutes = lazy(() => import('pages/LoginPage/LoginRoutes'))

const RoutesMain = memo(({ isAuth }) => {
  const history = useHistory()
  const [ prevLocation, setPrevLocation ] = useState(history.location)
  const [ prevScroll, setPrevScroll ] = useState(null)

  // scroll to top custom scroll component on history change
  useEffect(() => {
    setPrevScroll(window.pageYOffset)

    const unlisten = history.listen((location, action) => {
      const { state } = location
      setPrevLocation(location)

      if (action === 'POP' && typeof prevScroll === 'number') {
        setTimeout(() => {
          window.scrollTo(0, prevScroll)
        }, 100)
      }

      if (action === 'PUSH') { // scroll to top only if new pathname (not search changed)
        setPrevScroll(window.pageYOffset)

        if (
          prevLocation.pathname !== location.pathname
          && (!state || (state && !state.noScrollTop))
        ) {
          setTimeout(() => {
            window.scrollTo(0, 0)
          }, 100)
        }
      }
    })

    return () => unlisten()
    // eslint-disable-next-line
    }, [])

  return (
    <Suspense fallback={<Spinner />}>
      <Switch>
        <Route
          exact
          path="/"
          render={() => <Redirect to={PAGE_DASHBOARD} />}
        />
        <Route
          path={PAGE_LOGIN}
        >
          <LoginsRoutes history={history} isAuth={isAuth} />
        </Route>
        <PrivateRoute
          isAuth={isAuth}
          path={PAGE_DASHBOARD}
          component={PageDashboard}
        />
        <Route
          exact
          path="*"
          render={() => <Redirect to={PAGE_LOGIN} />}
        />
      </Switch>
    </Suspense>
  )
})

export default memo(RoutesMain)
