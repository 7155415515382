import { AuthActionTypes } from 'redux/Auth/AuthTypes'
import { PatientsActionTypes } from 'redux/dashboard/Patients/PatientsTypes'
import { PatientActionTypes } from 'redux/dashboard/Patient/PatientTypes'
import StorageService from 'services/StorageService'
import { ListLayoutTypes } from 'constants/ListTypes'
import { UserToDoctorBindStatusTypes } from 'constants/UserTypes'
import { isSelected } from 'react-big-calendar/lib/utils/selection'
import { fetchArticleType } from 'redux/dashboard/Articles/ArticelsReducer'

const INITIAL_STATE = {
  list: null,
  offset: 0,
  maxOffset: 0,
  listCount: 0,
  lastSearch: null,
  isFetching: false,
  patientsCountExpired: 0,
  patientsCountIsAccepted: 0,
  layout: StorageService.getStructureLayout('patients') || ListLayoutTypes.TABLE,

  searchList: null,
  isFetchingSearchList: false,
  searchValue: '',

  filters: StorageService.getPatientFilters() || {},

  articlePatientsSelected: [],
  articlePatientsUnSelected: [],
  isAll: false,
  isSelected: true,
  isUnSelect: false,
  editeMode: fetchArticleType.NONE,

  patientsSubscriber: [],

  error: null,
}

const patientsReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
  case AuthActionTypes.FETCH_LOGOUT_SUCCESS:
    return { ...INITIAL_STATE }

  case PatientsActionTypes.FETCH_PATIENTS_LIST_SUCCESS:
    return {
      ...state,
      list: action.payload
    }

  case PatientsActionTypes.CLEAR_PATIENTS_LIST:
    return {
      ...state,
      list: null,
      articlePatientsSelected: [],
      articlePatientsUnSelected: [],
      isAll: false,
      isSelected: true,
      isUnSelect: false,
    }

  case PatientsActionTypes.SET_PATIENTS_LIST_LAYOUT:
    return {
      ...state,
      layout: action.payload
    }

  case PatientsActionTypes.SET_PATIENTS_LIST_COUNT:
    return {
      ...state,
      listCount: action.payload
    }

  case PatientsActionTypes.SET_PATIENTS_COUNT_ALL:
    return {
      ...state,
      patientsCountExpired: action.payload
    }

  case PatientsActionTypes.SET_PATIENTS_COUNT_IS_ACCEPTED:
    return {
      ...state,
      patientsCountIsAccepted: action.payload
    }

  case PatientsActionTypes.SET_PATIENTS_LIST_LAST_SEARCH:
    return {
      ...state,
      lastSearch: action.payload
    }

  case PatientsActionTypes.FETCH_UPLOAD_PATIENTS_LIST_SUCCESS:
    return {
      ...state,
      list: state.list ? [ ...state.list, ...action.payload ] : action.payload,
    }

  case PatientsActionTypes.SET_PATIENTS_LIST:
    return {
      ...state,
      list: action.payload.results,
      maxOffset: action.payload.count,
      offset: 40,
    }

  case PatientsActionTypes.ADD_PATIENTS_LIST:
    return {
      ...state,
      list: state.list.concat(action.payload.results),
      offset: state.offset + 40,
    }

  case PatientsActionTypes.ADD_PATIENT_TO_LIST:
    return {
      ...state,
      list: state.list ? [ ...state.list, action.payload ] : state.list,
      listCount: state.list ? state.list.length + 1 : state.listCount,
      patientsCountExpired: state.patientsCountExpired + 1
    }

  case PatientsActionTypes.SET_PATIENTS_FILTERS:
    return {
      ...state,
      filters: action.payload
    }

  case PatientsActionTypes.SET_PATIENTS_FILTER:
    return {
      ...state,
      filters: {
        ...state.filters,
        [action.payload.name]: action.payload.value
      }
    }

  case PatientsActionTypes.SET_FETCHING_PATIENTS_LIST:
    return {
      ...state,
      isFetching: action.payload
    }

  case PatientsActionTypes.SET_FETCHING_PATIENTS_SEARCH:
    return {
      ...state,
      isFetchingSearchList: action.payload
    }

  case PatientsActionTypes.FETCH_PATIENTS_SEARCH_SUCCESS:
    return {
      ...state,
      searchList: action.payload
    }

  case PatientsActionTypes.CLEAR_PATIENTS_SEARCH:
    return {
      ...state,
      searchList: []
    }

  case PatientsActionTypes.SET_PATIENTS_SEARCH_VALUE:
    return {
      ...state,
      searchValue: action.payload
    }

  case PatientsActionTypes.PATIENTS_UNBIND_PATIENT: {
    const {
      patientId,
      doctorId,
    } = action.payload
    const newList = state.list.map((item) => {
      if (item.patient.id === patientId) {
        let newBind = null
        const newPatient = {
          ...item,
          doctors: item.doctors.filter((item) => item.id !== doctorId)
        }
        newPatient.doctors.forEach((item) => {
          if (item.is_accepted === true) {
            if (newBind === UserToDoctorBindStatusTypes.noBind || newBind === UserToDoctorBindStatusTypes.mixed) {
              newBind = UserToDoctorBindStatusTypes.mixed
            }
            else {
              newBind = UserToDoctorBindStatusTypes.bind
            }
          }
          else if (newBind === UserToDoctorBindStatusTypes.bind || newBind === UserToDoctorBindStatusTypes.mixed) {
            newBind = UserToDoctorBindStatusTypes.mixed
          }
          else {
            newBind = UserToDoctorBindStatusTypes.noBind
          }
        })
        return {
          ...newPatient,
          is_accepted: newBind
        }
      }
      return item
    }).filter((item) => item.doctors.length > 0)
    return {
      ...state,
      list: newList
    }
  }

  case PatientActionTypes.FETCH_PATIENT_EXPIRED_TASKS_COUNT_SUCCESS: {
    if (state.list) {
      const {
        patientId,
        count
      } = action.payload

      return {
        ...state,
        list: state.list.map((patient) => {
          if (patient.id === patientId) {
            return {
              ...patient,
              has_expired_tasks: count > 0
            }
          }

          return patient
        })
      }
    }

    return state
  }

  case PatientsActionTypes.SET_PATIENTS_LIST_ARTICLE: {
    const { results, count } = action.payload
    const newSelected = [ ...state.articlePatientsSelected ]
    for (const { patient } of results) {
      if (!state.isUnSelect && state.patientsSubscriber.find((item) => item.id === patient.id)) {
        newSelected.push(patient.id)
      }
    }
    return {
      ...state,
      list: results,
      maxOffset: count,
      offset: 40,
      articlePatientsSelected: newSelected
    }
  }

  case PatientsActionTypes.ADD_PATIENTS_LIST_ARTICLE: {
    const { results, myId } = action.payload

    const newSelected = [ ...state.articlePatientsSelected ]

    for (const { patient } of results) {
      if (state.patientsSubscriber.find((item) => item.id === patient.id)) {
        if (!state.isUnSelect) {
          newSelected.push(patient.id)
        }
        else if (state.patientsSubscriber.find(
          (patientSubscriber) => patientSubscriber.id === patient.id
                 && patientSubscriber?.assigned_by?.id !== myId
        )) {
          newSelected.push(patient.id)
        }
      }
    }
    return {
      ...state,
      list: state.list.concat(results),
      offset: state.offset + 40,
      articlePatientsSelected: newSelected
    }
  }

  case PatientsActionTypes.FETCH_PATIENTS_SEARCH_SUCCESS_ARTICLE: {
    const newSelected = [ ...state.articlePatientsSelected ]

    for (const { patient } of action.payload.results) {
      if (!state.isUnSelect && state.patientsSubscriber.find((item) => item.id === patient.id)) {
        newSelected.push(patient.id)
      }
    }
    return {
      ...state,
      searchList: action.payload,
      articlePatientsSelected: newSelected
    }
  }

  case PatientsActionTypes.ARTICLE_SELECT_PATIENT: {
    const id = action.payload

    if (state.isSelected) {
      const isMark = state.articlePatientsSelected.find((item) => item === id)

      if (isMark) {
        return {
          ...state,
          articlePatientsSelected: state.articlePatientsSelected.filter((item) => item !== id),
          isAll: false,
        }
      }
      return {
        ...state,
        articlePatientsSelected: [ ...state.articlePatientsSelected, id ],
        isAll: state.listCount === state.articlePatientsSelected.length + 1,
      }
    }
    const isUnMark = state.articlePatientsUnSelected.find((item) => item === id)
    if (isUnMark) {
      return {
        ...state,
        articlePatientsUnSelected: state.articlePatientsUnSelected.filter((item) => item !== id),
        isAll: state.articlePatientsUnSelected.filter((item) => item !== id).length === 0
      }
    }
    return {
      ...state,
      articlePatientsUnSelected: [ ...state.articlePatientsUnSelected, id ],
      isAll: false,
    }
  }

  case PatientsActionTypes.RESET_PATIENTS_SELECTIONS:
    return {
      ...state,
      articlePatientsSelected: [],
      articlePatientsUnSelected: [],
      isAll: false,
      isSelected: true,
      isUnSelect: false,
      editeMode: fetchArticleType.NONE,
    }

  case PatientsActionTypes.ARTICLE_UN_SELECT_ALL_PATIENTS: {
    const profileId = action.payload
    const newSelected = state.articlePatientsSelected.filter((patient) => {
      if (state.patientsSubscriber.find(
        (patientSubscriber) => patientSubscriber.id === patient
          && patientSubscriber?.assigned_by?.id !== profileId
      )) {
        return true
      }
      return false
    })

    return {
      ...state,
      isAll: false,
      isSelected: true,
      isUnSelect: true,
      articlePatientsSelected: [ ...newSelected ],
    }
  }

  case PatientsActionTypes.ARTICLE_SELECT_ALL_PATIENTS:
    return {
      ...state,
      isAll: true,
      isSelected: false,
      articlePatientsUnSelected: [],
    }

  case PatientsActionTypes.SET_PATIENTS_SUBSCRIBER:
    return {
      ...state,
      patientsSubscriber: action.payload
    }

  default:
    return state
  }
}

export default patientsReducer
