import { Box } from 'layout'
import { Modal } from 'components/modal'
import ModalHead from 'components/modal/ModalHead'

const ModalCenterLayoutFull = ({
  children,
  modalSize = 'normal',
  modalTitle = null,
  noPointers = false,
  textCenter = false,
  modalInnerHeight = null,
  headPosition = null,
  isDark = false,
  onClose = () => {},
}) => (
  <Modal
    variant="full"
    height="full"
    bg="white"
    className="center-full"
    noPointers={noPointers}
    p="0"
    br="0"
  >
    <ModalHead
      onClose={onClose}
      title={modalTitle}
      isDark={isDark}
      position={headPosition}
      isFull
    />
    <Box
      height="100%"
      ai="center"
      jc="center"
    >
      <Modal
        isOverflow="auto"
        height={modalInnerHeight}
        bg={isDark ? 'dark' : 'white'}
        variant={modalSize}
        textCenter={textCenter}
        p="64px 0 40px 0"
      >
        {children}
      </Modal>
    </Box>
  </Modal>
)

export default ModalCenterLayoutFull
