import { Spinner } from 'components/base'
import { List, ListItem } from 'components/list'
import {
  useRef, useEffect, useCallback
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { findChatUser, getChatUserUrl } from 'redux/dashboard/Chat/ChatHelpers'
import { fetchReadNotification } from 'redux/Notifications/NotificationsActions'
import { getPatientFolderUrl, getPatientUrl } from 'redux/helpers/PatientHelpers'
import { getTaskUrl } from 'redux/Tasks/TasksHelpers'
import { getRole } from 'helpers/User'
import NotificationCard from './NotificationCard'
import NotificationsEmptyList from './NotificationsEmptyList'

const NotificationsList = ({
  notifications,
  isFetching,
  closeModal,
  ...props
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const currentPathname = useRef(history.location.pathname)

  const { users } = useSelector((state) => state.dashboard.chat)
  const { profile } = useSelector((state) => state.user)
  const { isAdmin } = getRole(profile.role)

  const getChatUserFromConversations = useCallback((userVoxId) => !!findChatUser(users, { voximplant_username: userVoxId }), [ users ])

  useEffect(() => {
    const { pathname } = history.location

    if (currentPathname.current !== pathname) {
      closeModal()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ history.location.pathname ])

  const handleChatOpen = useCallback((voxId) => {
    closeModal()
    const isChatUser = getChatUserFromConversations(voxId)
    history.push(`${getChatUserUrl(voxId)}${!isChatUser ? `?new=true` : ''}`)
  }, [ history, closeModal, getChatUserFromConversations ])

  const handleDocumentOpen = useCallback((userId, partition) => {
    closeModal()
    history.push(getPatientFolderUrl(userId, partition))
  }, [ history, closeModal ])

  const handleTaskOpen = useCallback((userId, createdAt) => {
    closeModal()
    if (userId && createdAt) history.push(getTaskUrl(userId, createdAt))
  }, [ history, closeModal ])

  const handlePatientOpen = useCallback((userId) => {
    history.push(getPatientUrl(userId))
  }, [ history ])

  const handleReadNotification = useCallback((id) => {
    dispatch(fetchReadNotification(id))
  }, [ dispatch ])

  if (!isFetching
    && notifications
    && notifications.length === 0) {
    return <NotificationsEmptyList />
  }

  return notifications === null
    ? <Spinner />
    : (
      <List>
        {notifications && notifications.map((notification) => (
          <ListItem
            p="24px"
            bgColor={notification.is_read ? 'white' : 'blue100'}
            key={notification.id}
          >
            <NotificationCard
              onChatOpen={handleChatOpen}
              onPatientOpen={handlePatientOpen}
              onTaskOpen={handleTaskOpen}
              onDocumentOpen={handleDocumentOpen}
              notification={notification}
              onCloseModal={closeModal}
              isAdmin={isAdmin}
              onRead={handleReadNotification}
              {...props}
            />
          </ListItem>
        ))}
      </List>
    )
}

export default NotificationsList
