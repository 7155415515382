import Box from 'layout/Box'
import { ModalContent } from 'components/modal'
import Positioner from 'components/Positioner'

import Video from 'components/base/Video'
import ModalCenterLayout from './ModalCenterLayout'

const ModalVideo = ({
  closeModal = () => {},
  modal: { video = {} },
}) => {
  const { isEmbed = false } = video

  return (
    <ModalCenterLayout
      onClose={closeModal}
      noPointers
      modalTitle={video.title}
      headPosition="absolute"
      modalSize="xl"
      modalTitleSize="normalBig"
      modalNoPadding
      modalTitleElipsed
      modalHeadAlign="flex-start"
      modalHeadPaddingSmall
      isOverflow="hidden"
    >
      <ModalContent p="0px 16px 16px 16px">
        <Box
          isRelative
          height="0"
          p="56.2% 0 0 0"
          width="100%"
        >
          <Positioner top="0" left="0" width="100%" height="100%">
            {isEmbed ? (
              <iframe
                width="100%"
                height="100%"
                src={video.src}
                title={video.title}
                frameBorder="0"
                allowFullScreen
              />
            ) : <Video width="100%" src={video.src} />}
          </Positioner>
        </Box>
      </ModalContent>
    </ModalCenterLayout>
  )
}

export default ModalVideo
