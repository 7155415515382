import cn from 'classnames'
import s from 'styled-components'

const FormGroupContainer = s.div`
  width: 100%;
  text-align: ${({ $ta }) => $ta};

  & + & {
    margin-top: ${({ $offsetSmall }) => ($offsetSmall ? '16px' : '24px')};
  }
`

const FormGroup = ({
  children,
  className = '',
  inline,
  offsetSmall = false,
  ta = 'left',
  ...otherProps
}) => (
  <FormGroupContainer
    $ta={ta}
    $offsetSmall={offsetSmall}
    className={cn('form-group', className)}
    {...otherProps}
  >
    {children}
  </FormGroupContainer>
)

export default FormGroup
