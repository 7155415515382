import { AuthActionTypes } from 'redux/Auth/AuthTypes'
import { CitiesActionTypes, CountriesActionTypes } from 'redux/Location/LocationTypes'

const INITIAL_STATE = {
  name: 'location',

  countries: [],
  isFetchingCountries: false,
  isFetchingCity: false,
  isFetchingPatientCities: false,
}

const locationReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
  case AuthActionTypes.FETCH_LOGOUT_SUCCESS:
    return { ...INITIAL_STATE }

  case CountriesActionTypes.SET_FETCHING_COUNTRIES:
    return {
      ...state,
      isFetchingCountries: action.payload,
    }

  case CitiesActionTypes.SET_FETCHING_CITY:
    return {
      ...state,
      isFetchingCountries: action.payload,
    }

  case CitiesActionTypes.SET_FETCHING_PATIENT_CITIES:
    return {
      ...state,
      isFetchingPatientCities: action.payload,
    }

  case CountriesActionTypes.FETCH_COUNTRIES_SUCCESS:
    return {
      ...state,
      countries: action.payload,
    }

  default:
    return state
  }
}

export default locationReducer
