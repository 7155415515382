import s from 'styled-components'
import cn from 'classnames'

import { Text } from 'components/typo'

const tabStyles = ({ theme }) => `
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  // flex: 1;
  white-space: nowrap;
  width: auto;
  height: 32px;

  &.--active.--no-point {
    pointer-events: none;
  }

  &.--tab {
    color: ${theme.colors.grey400};
    padding: 0 0 8px;

    &:before {
      content: '';
      position: absolute;
      bottom: -1px;
      left: 0;
      right: 0;
      height: 2px;
      background-color: ${theme.colors.transparent};
    }

    &:not(.--active):hover {
      color: ${theme.colors.black};
    }

    &.--active {
      color: ${theme.colors.dark};

      &:before {
        background-color: ${theme.colors.blue};
      }
    }
  }

  &.--tag {
    padding: 6px 14px;
    border-radius: 32px;
    background-color: ${theme.colors.grey100};
    color: ${theme.colors.black};
    transition: background-color 0.3s ease-in-out;

    &:not(.--active):hover {
      color: ${theme.colors.black};
      background-color: ${theme.colors.grey150};
    }

    &.--active {
      color: ${theme.colors.white};
      background-color: ${theme.colors.blue};
    }
  }


  &.--disabled {
    color: ${theme.colors.grey150};
    pointer-events: none;
  }
`

const TabsItemContainer = s.button`
  ${(props) => tabStyles(props)}
`

const TabsItem = ({
  title = '',
  className = '',
  active = false,
  isDisabled = false,
  isSingle = false,
  isTag = false,
  onClick = () => {},
}) => (
  <TabsItemContainer
    type="button"
    onClick={isDisabled ? null : onClick}
    className={cn('w-tab', {
      '--active': active,
      '--no-point': isSingle,
      '--disabled': isDisabled,
      '--tag': isTag,
      '--tab': !isTag
    }, className)}
  >
    <Text
      className="w-tab-text"
      fw="m"
      size={isTag ? 'normalSm' : 'normal'}
      color="current"
      ls="0.15px"
      transitionOn
    >
      {title}
    </Text>
  </TabsItemContainer>
)

export default TabsItem
