import PropTypes from 'prop-types'
import {
  useState,
  forwardRef,
  useEffect, useRef
} from 'react'
import s from 'styled-components'
import cn from 'classnames'

import { Input } from 'components/form'
import {
  isAnyNumber,
  isLetter,
  isLetterOrNumbers
} from 'helpers/RegExp'

import { colors } from 'theme'
import ButtonClear from 'components/buttons/ButtonClear'
import ButtonInfoKnowledgeBase from 'components/buttons/ButtonInfoKnowledgeBase'
import Spinner from 'components/base/Spinner'
import { ReactComponent as IconCaret } from 'assets/icons/caret-s.svg'
import FormLabel from './FormLabel'
import FormError from './FormError'

const Styles = ({
  theme,
  $size,
  $maxWidth,
  $isInfo,
  $bgColor,
  $defaultStyle,
  $isCaret,
  $inputPadding,
  $opacityReadOnly,
}) => {
  const padding = $size === 'small' ? '0 8px 0 8px' : '0 8px'

  return `
    position: relative;
    width: 100%;
    max-width: ${$maxWidth};
    background-color: ${$bgColor ? theme.colors[$bgColor] || $bgColor : theme.colors.white};
    // ${$bgColor ? 'padding-left: 8px;' : ''}
    border-radius: 8px;


    .toggler {
      transform: rotateX(0deg);
      pointer-events: none;
      transition: transform .3s;
    }



    input {
      text-overflow: ellipsis;
      max-width: 100%;
      overflow: hidden;
      ${$bgColor ? `border: 1px solid ${theme.colors[$bgColor]};` : ''}
      padding-left: 8px;

      &.--error:not(:focus)  {
        border-color: ${theme.colors.red};
        ${$bgColor ? `color: ${theme.colors.grey500};` : ''};
        color: ${theme.colors.red};

        &:hover {
          color: ${theme.colors.grey800};
        }

      }

      &.--error {
        &::placeholder {
          ${$bgColor ? `color: ${theme.colors.grey500};` : ''};
        }

        &::placeholder:hover {
          ${$bgColor ? `color: ${theme.colors.grey800};` : ''};
        }
      }
    }

    &.--fluid input {
      padding-top: 17px;
      padding-bottom: 11px;
    }

    &.--s-transparent input {
      background-color: ${theme.colors.transparent} !important;
      padding-left: 0;
      padding-right: 0;
    }

    &.--s-bordered input {
      border-radius: 0;
      padding-left: 0;
      padding-right: 0;
      border-bottom: 1px solid ${theme.colors.grey150};
      background-color: ${theme.colors.white} !important;
      transition: border-color 0.3s ease-in-out, color 0.3s ease-in-out;

      &:focus {
        border-color: ${theme.colors.black};
      }

      &.--error:not(:focus) {
        border-color: ${theme.colors.red};
      }

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        box-shadow: 0 0 0 30px ${theme.colors.white} inset !important;
      }
    }

    &:hover:not(.--focused) {
      .form-field-icon {
        svg {
          fill: ${theme.colors.grey600};
          stroke: ${theme.colors.grey600};
        }
      }

    }

    &.--value,
    &.--focused:not(.--fake) {

      .form-field-icon:not(.--clear) {
        color: ${theme.colors.dark};

        & > svg {
          stroke: ${theme.colors.dark} !important;
          fill: ${theme.colors.dark} !important;
        }

        & > * {
          svg {
            stroke: ${theme.colors.dark} !important;
            fill: ${theme.colors.dark} !important;
          }
        }
      }
    }

    &.--readonly {
      user-select: none;
      pointer-events: none;
      opacity: ${$opacityReadOnly || '0.5'};
    }

    &.--outputOnly input,
     &.--outputOnly textarea{
      color: ${theme.colors.grey600};
    }


    &.--error {

      // .form-field-icon > * {
      //   color: ${theme.colors.red} !important;

      //   svg {
      //     fill: ${theme.colors.red} !important;
      //     stroke: ${theme.colors.red} !important;
      //   }
      // }

      // .form-field-icon > svg {
      //   fill: ${theme.colors.red} !important;
      //   stroke: ${theme.colors.red} !important;
      // }
    }

    &.--focused {

      .toggler {
        transform: rotateX(180deg);
        transition: transform .3s;
      }
    }

    &.--focused,
    &.--error.--focused {
      background-color: ${$bgColor ? theme.colors[$bgColor] || $bgColor : theme.colors.white};

      .form-field-icon > * {
        color: ${theme.colors.dark} ;

        svg {
          fill: ${theme.colors.dark};
          stroke: ${theme.colors.dark};
        }
      }

      .form-field-icon.--hover > *,
      .form-field-icon.--hover > svg {
        svg {
          fill: ${theme.colors.grey300};
          stroke: ${theme.colors.grey300};
        }

        &:hover {
          svg {
            fill: ${theme.colors.dark};
            stroke: ${theme.colors.dark};
          }
        }
      }

      .form-field-icon > svg {
        fill: ${theme.colors.dark};
        stroke: ${theme.colors.dark};
      }
    }

    .form-field-icon {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      display: flex;
      align-items: center;
      align-self: stretch;
      padding: ${padding};
      color: ${theme.colors.grey500};
      padding: 0;
      width: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 2px;


      svg {
        fill: ${theme.colors.grey500};
        stroke: ${theme.colors.grey500};
        transition stroke 0.3s ease-in-out, fill 0.3s ease-in-out;
      }

      &.--i-left {
        left: 0;
        right: unset;
        ${$defaultStyle === 'bordered' || ' width: 36px;'}
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &.--icon.--before input {
      padding-left: ${$isInfo ? '52px' : $size === 'small' ? '36px' : '52px'};
    }

    &.--clear input {
      padding-right: calc(26px ${$isCaret ? '+ 20px' : ''} ${$isInfo ? '+ 24px' : ''});
    }

    input, textarea {
      padding: ${$inputPadding || ''} !important;
    }

    &.--fluid textarea {
      border: 1px solid ${theme.colors.grey150};
    }
  `
}

const FormFieldContainer = s.div`
  ${(props) => Styles(props)}
`

const FormField = forwardRef(({
  className = '',
  tag = '',
  cursor = '',
  name = '',
  label = '',
  value = '',
  type = 'text',
  placeholder = '',
  icon: Icon,
  IconProps,
  isCaret = false,
  defaultStyle = '',
  size = 'normal',
  numbers = false,
  letters = false,
  addString = null,
  outputOnly = false,
  readOnly = false,
  opacityReadOnly = '',
  max = null,
  min = null,
  minNumber = null,
  maxNumber = null,
  minHeight = '',
  minWidth = '',
  maxWidth = '',
  inputPadding = '',
  minLength = null,
  maxLength = null,
  isAutoFocus = false,
  isAutoResize = false,
  iconBefore = false,
  autoComplete = '',
  customComponent = '',
  isError = false,
  onChange = () => {},
  onBlur = () => {},
  onFocus = () => {},
  onIconClick = () => {},
  onKeyDown = () => {},
  onClear = () => {},
  bgColor = '',

  isClearable = false,
  noBorders = false,
  isRequired = false,
  noMessage = '',
  labelFluid = false,
  isFetching = false,
  error = null,
  iconSize = '20px',
  noIcon = false,
  mask,
  maskChar,
  onPaste = null,
  itemInfo = null,
  typeInfo = null,
  fs = '',
  isFirstUppercase = true,
  isDisabled = false,
  isOnlyShow = false,
  rows = '',
}, ref) => {
  const [ focused, setFocused ] = useState(false)
  const [ isAppendedString, setIsAppendedString ] = useState(false)

  /* Эффект повзоляет при принудительном изменении с помощью формика не ломать логику добавления строки */
  useEffect(() => {
    if (addString && !focused && value) {
      setIsAppendedString(true)
    }
  }, [ value, focused, addString ])
  const FieldComponent = customComponent || Input
  const inputRef = useRef(null)
  const handleChange = (e) => {
    const { value } = e.target
    const start = e.target.selectionStart
    const end = e.target.selectionEnd
    let newValue = value
    if (isFirstUppercase && !numbers) {
      newValue = newValue.charAt(0).toUpperCase() + newValue.slice(1)
    }
    requestAnimationFrame(() => {
      const element = inputRef.current
      if (element && typeof element.setSelectionRange === 'function') {
        inputRef.current.setSelectionRange(start, end)
      }
    })
    if (newValue.length) {
      if ((!numbers && letters && !isLetter(newValue))
      || (numbers && !letters && !isAnyNumber(newValue))
      || (numbers && letters && !isLetterOrNumbers(newValue))
      || (maxLength && newValue.length > maxLength)
      || (minLength && newValue.length < minLength)
      ) {
        e.preventDefault()
        return false
      }

      /* Используется для ограничений чисел */
      if (numbers) {
        if (maxNumber && parseInt(newValue) > maxNumber) {
          e.target.value = maxNumber.toString()
          return onChange(e, maxNumber.toString())
        }
        if (minNumber && parseInt(newValue) < minNumber) {
          e.target.value = minNumber.toString()
          return onChange(e, minNumber.toString())
        }
      }

      if (numbers) {
        if (max && newValue > max) {
          e.target.value = max
        }
        if (min && newValue < min) {
          e.target.value = min
        }
      }
    }

    e.target.value = newValue

    if (e.target.value) {
      setIsAppendedString(false)
    }

    return onChange(e, value)
  }

  const handleBlur = (e) => {
    setFocused(false)
    if (value) {
      setIsAppendedString(true)
    }
    else {
      setIsAppendedString(false)
    }
    onBlur(e)
  }

  return (
    <label
      style={cursor ? { cursor } : {}}
      className={cn('form-wrapper', { '--rel': labelFluid }, className)}
    >
      {!!label && (
        <div className={cn('form-label mb-1', {
          '--fluid': labelFluid,
          '--active': true // focused || value
        })}
        >
          <FormLabel
            size={focused || value ? 'small' : 'normal'}
            color={focused || value ? 'grey400' : 'grey500'}
            title={label}
            isRequired={isRequired}
          />
        </div>
      )}
      <FormFieldContainer
        $maxWidth={maxWidth}
        className={cn('form-field-wrap', {
          '--icon': !noIcon && (Icon || type === 'search'),
          '--before': iconBefore,
          '--error': isError,
          '--focused': focused,
          [`--s-${defaultStyle}`]: defaultStyle,
          '--readonly': readOnly,
          '--outputOnly': outputOnly,
          '--value': value,
          '--fluid': labelFluid,
          '--clear': isClearable,
        })}
        icon={Icon}
        isError={isError}
        $size={size}
        $bgColor={bgColor}
        $isInfo={!!typeInfo}
        $defaultStyle={defaultStyle}
        $isCaret={isCaret}
        $inputPadding={inputPadding}
        $opacityReadOnly={opacityReadOnly}
      >
        <FieldComponent
          rows={rows}
          tag={tag}
          name={name}
          value={addString && isAppendedString ? value + addString(value) : value}
          size={size}
          readOnly={readOnly || outputOnly}
          type={type}
          maskChar={maskChar}
          noBorders={noBorders}
          placeholder={placeholder}
          onChange={handleChange}
          onKeyDown={onKeyDown}
          isError={isError}
          bgColor={bgColor}
          mask={mask}
          defaultStyle={defaultStyle}
          isAutoFocus={isAutoFocus}
          isAutoResize={isAutoResize}
          minHeight={minHeight}
          minWidth={minWidth}
          maxWidth={maxWidth}
          onFocus={(e) => {
            setFocused(true)
            if (value) {
              setIsAppendedString(false)
            }
            return onFocus(e)
          }}
          onBlur={handleBlur}
          autoComplete={autoComplete || label || placeholder}
          ref={ref || inputRef}
          onPaste={onPaste}
          fs={fs}
          isDisabled={isDisabled}
          isOnlyShow={isOnlyShow}
        />

        {Icon && (
          <div onClick={onIconClick} className={cn('form-field-icon', { '--i-left': iconBefore })}>
            <Icon
              width={iconSize}
              height={iconSize}
              stroke={isError ? colors.purple : colors.grey300}
              {...IconProps}
            />
          </div>
        )}
        <div className={cn('form-field-icon --hover --clear')}>
          {isClearable && value && (
            <>
              {typeInfo && itemInfo && (
                <ButtonInfoKnowledgeBase
                  defaultStyle="transparentGrey"
                  icon="circle"
                  typeInfo={typeInfo}
                  itemInfo={itemInfo}
                />
              )}

              {(!isDisabled && !isOnlyShow) && (isFetching
                ? (
                  <Spinner
                    className="mr-2"
                    relative
                    size="small"
                    fillColor="grey400"
                  />
                ) : (
                  <ButtonClear
                    defaultStyle="transparentGrey"
                    icon="circle"
                    onClick={onClear}
                  />
                ))}
            </>
          )}

          {isCaret ? (
            <span className="toggler mr-1">
              <IconCaret />
            </span>
          ) : ''}
        </div>
      </FormFieldContainer>
      {isError && !noMessage && (
        <FormError
          isFocused={focused}
          title={error}
        />
      )}
    </label>
  )
})

FormField.propTypes = ({ size: PropTypes.oneOfType([ PropTypes.string, PropTypes.bool ]) })
FormField.displayName = 'FormField'

export default FormField
