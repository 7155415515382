import s from 'styled-components'
import cn from 'classnames'

const getStyles = ({
  theme,
  color,
  $width,
  $height,
  // $isCenter,
  $maxHeight,
  $bs,
  p = null,
  $variant = 'normal',
  maxWidth = null,
  minWidth = null,
  br = null,
  bg = null,
  position = 'initial',
  relative = false,
  border = null,
  overflow = '',
}) => {
  const themeComponent = theme.components.modal
  const stylesCommon = themeComponent.common
  const stylesComponent = themeComponent.variant[$variant] || {}

  return `
    position: ${relative ? 'relative' : position};
    width: ${$width ? theme.widths[$width] || $width : stylesComponent.width || stylesCommon.width};
    max-width: ${maxWidth || stylesComponent.maxWidth || ''};
    min-width: ${minWidth || stylesComponent.minWidth || ''};
    padding: ${p ? theme.spacers[p] || p : ''};
    border-radius: ${br ? theme.radius[br] || br : stylesComponent.br || themeComponent.common.br};
    background-color: ${bg ? theme.colors[bg] : stylesCommon.bg};
    color: ${theme.colors[color] || theme.colors.dark};
    height: ${$height ? theme.widths[$height] || $height : stylesComponent.height || 'initial'};
    max-height: ${$maxHeight ? theme.widths[$maxHeight] || $maxHeight : stylesComponent.maxHeight || '100%'};
    pointer-events: initial;
    border: ${border ? `1px solid ${theme.colors.dark}` : ''};
    overflow: ${overflow || ''};
    box-shadow: ${$bs};

    &.--t-center {
      text-align: center;
    }

    &.--np {
      pointer-events: none !important;
    }

    &.--fixed {
      position: fixed;
      z-index: 1000000;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background-color: ${theme.colors.dark30};
      border-radius: 0;
    }

    @media (max-width: 768px) {
      max-width: unset !important;
      width: 100%;
    }
`
}

const ModalContainer = s.div`
  ${getStyles}
  ${({ $isFlexFull }) => ($isFlexFull ? `
    display: flex;
    flex-direction: column;
  ` : '')}
  ${({ $isCenter }) => ($isCenter ? `
    display: flex;
    justify-content: center;
    align-items: center;
  ` : '')}
`

const Modal = ({
  className,
  children,
  width = null,
  height = null,
  maxWidth = null,
  variant = 'normal',
  isFixed = false,
  noPointers = false,
  textCenter = false,
  isFlexFull = false,
  isCenter = false,
  onClick = () => {},
  bs = '',
  ...otherProps
}) => {
  const classes = cn('w-modal', {
    [`--v-${variant}`]: variant,
    '--fixed': isFixed,
    '--np': noPointers,
    '--t-center': textCenter,
  }, className)

  const props = {
    className: classes,
    $width: width,
    $variant: variant,
    $height: height,
    $isFlexFull: isFlexFull,
    $isCenter: isCenter,
    $bs: bs,
    maxWidth,
    ...otherProps,
  }

  return (
    <ModalContainer
      onClick={onClick}
      {...props}
    >
      {children}
    </ModalContainer>
  )
}

export default Modal
