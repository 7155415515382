import Button from 'components/base/Button'
import LinkRoute from 'components/typo/Link'
import Text from 'components/typo/Text'
import UserAvatar from 'components/User/UserAvatar'
import { messageTypesColors } from 'constants/NotifierTypes'
import { getUserFullName } from 'helpers/User'
import Box from 'layout/Box'
import { getPatientFolderUrl, getPatientUrl } from 'redux/helpers/PatientHelpers'
import { UserRolesNamesTypes, UserRolesTypes } from 'constants/UserTypes'
import NotifierMessageText from '../NotifierMessageText'

const NotifierCardPatientFile = ({
  user = {},
  author = null,
  created = '',
  folder = {},
  titleColor = messageTypesColors.DEFAULT,
  onChat,
  onDocument,
}) => (
  <div className="notifier-message-content">
    <NotifierMessageText
      className="mb-12"
      color={titleColor}
      text="Добавил(а) документ"
      fw="medium"
      size="normalSm"
    />
    <Box className="my-3">
      <div className="mb-2 mr-2">
        <UserAvatar
          size="normalSmall"
          avatar={user.avatar}
          user={user}
        />
      </div>
      <div>
        { author.role === UserRolesTypes.PATIENT ? (
          <Text>
            Пациент
            {' '}
            <LinkRoute
              to={getPatientUrl(user.id)}
              defaultStyle="blue"
            >
              {getUserFullName(user)}
            </LinkRoute>
            {' '}
            добавил документ
            {' '}
            в папку:
            {' '}
            <LinkRoute
              to={getPatientFolderUrl(user.id, folder.partition)}
              defaultStyle="blue"
            >
              {folder.name}
            </LinkRoute>
          </Text>
        ) : (
          <Text>
            {UserRolesNamesTypes[author.role]}
            {' '}
            {getUserFullName(author.role)}
            {' '}
            загрузил документ пациенту
            {' '}
            <LinkRoute
              to={getPatientUrl(user.id)}
              defaultStyle="blue"
            >
              {getUserFullName(user)}
            </LinkRoute>
            {' '}
            в папку:
            {' '}
            <LinkRoute
              to={getPatientFolderUrl(user.id, folder.partition)}
              defaultStyle="blue"
            >
              {folder.name}
            </LinkRoute>
          </Text>
        )}
        <Text color="grey500" size="normalSm">
          {created}
        </Text>
      </div>
    </Box>
    <Box gap="12px">
      <Button
        flex="1"
        full
        defaultStyle=""
        title="Смотреть документ"
        size="small"
        rounded
        onClick={() => onDocument(user.id, folder.partition)}
      />
      <Button
        flex="1"
        onClick={() => onChat(user.voximplant_username)}
        full
        isGradient
        title="Чат с пациентом"
        size="small"
        rounded
      />
    </Box>
  </div>
)

export default NotifierCardPatientFile
