export const ClinicRecordStatusTypes = {
  NEW: 'new',
  CANCELED: 'canceled',
  NOT_CONFIRMED: 'not_confirmed',
  CONFIRMED: 'confirmed',
  IN_PROGRESS: 'in_progress',
}

export const ClinicRecordStatusTagTypes = {
  [ClinicRecordStatusTypes.NEW]: {
    title: 'Новая',
    color: 'blue',
    background_color: 'white',
  },
  [ClinicRecordStatusTypes.CANCELED]: {
    title: 'Отменена',
    color: 'red',
    background_color: 'red200',
  },
  [ClinicRecordStatusTypes.NOT_CONFIRMED]: {
    title: 'Не подтверждена',
    color: 'grey500',
    background_color: 'grey100',
  },
  [ClinicRecordStatusTypes.CONFIRMED]: {
    title: 'Подтверждена',
    color: 'green',
    background_color: 'green200',
  },
  [ClinicRecordStatusTypes.IN_PROGRESS]: {
    title: 'В работе',
    color: 'yellow',
    background_color: 'white',
  }
}

export const ClinicRecordStatusOptions = [
  {
    id: ClinicRecordStatusTypes.NEW,
    title: 'Новая',
    color: 'blue200',
  },
  {
    id: ClinicRecordStatusTypes.CANCELED,
    title: 'Отменена',
    color: 'red200',
  },
  {
    id: ClinicRecordStatusTypes.NOT_CONFIRMED,
    title: 'Не подтверждена',
    color: 'grey100',
  },
  {
    id: ClinicRecordStatusTypes.CONFIRMED,
    title: 'Подтверждена',
    color: 'green200',
  },
  /* {
    id: ClinicRecordStatusTypes.IN_PROGRESS,
    title: 'В работе',
    color: 'yellow200',
  } */
]
