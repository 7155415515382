export const ArticlesActionTypes = {
  SET_ARTICLES: 'SET_ARTICLES',
  ADD_ARTICLES: 'ADD_ARTICLES',
  ADD_MY_ARTICLES: 'ADD_MY_ARTICLES',
  ARTICLE_DELETE: 'ARTICLE_DELETE',
  SET_MY_ARTICLES: 'SET_MY_ARTICLES',
  SET_FETCH_ARTICLES: 'SET_FETCH_ARTICLES',
  SET_FETCH_MY_ARTICLES: 'SET_FETCH_MY_ARTICLES',
  SET_ARTICLES_MAX_OF_SET: 'SET_ARTICLES_MAX_OF_SET',
  SET_ARTICLES_MY_MAX_OF_SET: 'SET_ARTICLES_MY_MAX_OF_SET',
  SET_ARTICLES_EDIT_MODE: 'SET_ARTICLES_EDIT_MODE',
}
